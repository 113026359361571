import { apiSlice } from "./apiSlice";

const regionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllRegion: builder.query({
      query: () => ({
        url: "/regions",
      }),
      providesTags: ["regions"],
    }),
    addRegion: builder.mutation({
      query: (newData) => ({
        url: "region",
        method: "POST",
        body: newData,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["regions"],
    }),
    removeRegion: builder.mutation({
      query: (id) => ({
        url: `/region/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["regions"],
    }),
    editRegion: builder.mutation({
      query: (id, editData) => ({
        url: `/region/${id}`,
        method: "PATCH",
        body: editData,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["regions"],
    }),
  }),
});

export const {
  useGetAllRegionQuery,
  useAddRegionMutation,
  useRemoveRegionMutation,
  useEditRegionMutation,
} = regionApi;
