import React from "react";

const Payment = () => {
  return (
    <div className="p-8">
      <h2 className="text-center">Payment Completed by: Cash On delivery</h2>
    </div>
  );
};

export default Payment;
