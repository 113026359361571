import React, { useEffect } from "react";
import Container from "../Container";
import { useLocation } from "react-router";
import { useGetAllBlankBoxQuery } from "../../features/api/blankBoxApi";

const BlankBoxFilterSinglePage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const location = useLocation();
  const props = location?.state;

  const { data } = useGetAllBlankBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allBlankbox = data?.data;
  const filterBlankBox = allBlankbox?.filter((box) => box?.festival === props);

  return (
    <Container>
      <div className="my-6">
        <h2 className="text-center mb-8 font-bold text-[#433832] uppercase md:text-xl">
          {props}s Blank Box
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-5 gap-4 md:gap-10">
          {filterBlankBox?.map((box) => (
            <div>
              <img
                src={`https://www.giftshare.jp:5000/${box?.image}`}
                alt={box?.festival}
                className="w-full h-[150px] md:h-[200px] shadow-md"
              />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default BlankBoxFilterSinglePage;
