import { apiSlice } from "./apiSlice";

const loginApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUser: builder.query({
      query: () => ({
        url: "/users",
      }),
      providesTags: ["users"],
    }),
    addLoginUser: builder.mutation({
      query: (data) => ({
        url: "/login",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["users", "user"],
    }),
    getUser: builder.query({
      query: () => ({
        url: "/me",
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["user"],
    }),
    removeUser: builder.mutation({
      query: (id) => ({
        url: `/user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["users"],
    }),
    editUserprofile: builder.mutation({
      query: ({ id, ...editData }) => ({
        url: `/userprofile/${id}`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["users"],
    }),
  }),
});

export const {
  useGetAllUserQuery,
  useGetUserQuery,
  useAddLoginUserMutation,
  useRemoveUserMutation,
  useEditUserprofileMutation,
} = loginApi;
