import React, { useCallback, useRef, useState, useEffect } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

const Location = (props) => {
  const [coordinates, setCoordinates] = useState({
    lat: 35.6764,
    lng: 139.65,
  });

  useEffect(() => {
    const geocodeAddress = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            props?.data?.loc
          )}&key=AIzaSyCJ-MeLSdPxHk5mLGVhz7X6v2qGClEwLqw`
        );

        const data = await response.json();

        if (data.results && data.results.length > 0) {
          const location = data.results[0]?.geometry?.location;
          setCoordinates({
            lat: location.lat,
            lng: location.lng,
          });
        }
      } catch (error) {
        console.error("Error fetching coordinates:", error);
      }
    };

    geocodeAddress();
  }, [props?.data?.loc]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCJ-MeLSdPxHk5mLGVhz7X6v2qGClEwLqw",
  });

  const mapRef = useRef(null);

  const onMapLoaded = useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return "Error";
  if (!isLoaded) return "Maps";

  return (
    <div>
      <div className="py-6 text-center text-xl">
        <GoogleMap
          mapContainerStyle={{
            height: "600px",
          }}
          center={coordinates}
          zoom={18}
          options={{
            // mapTypeId: "satellite",
            mapTypeControl: true,
          }}
          onLoad={onMapLoaded}
        >
          <MarkerF position={coordinates} />
        </GoogleMap>
      </div>
    </div>
  );
};

export default Location;
