import React from "react";
import Container from "../../Components/Container";
import {
  useGetAllGiftBoxQuery,
  useSearchGiftBoxQuery,
} from "../../features/api/GiftBoxApi";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { AiFillStar } from "react-icons/ai";
import {
  useAddWishlistMutation,
  useGetWishlistsQuery,
  useRemoveWishlistMutation,
} from "../../features/api/wishlistApi";
import { toast } from "react-toastify";
import { Link, useLocation, useParams } from "react-router-dom";
import { useGetAllRatingQuery } from "../../features/api/ratingsApi";
import { useSelector } from "react-redux";

const SearchGiftbox = () => {
  const location = useLocation();

  const searchName = useSelector((state) => state.products.searchName);
  console.log("state from main", searchName);
  const { data: searchResults, isLoading: searchLoading } =
    useSearchGiftBoxQuery(location?.state);
  // console.log("navb", searchResults);
  // console.log(searchResults);
  const { data: giftbox, isLoading } = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allGiftBox = giftbox?.data;

  const { data: wslist } = useGetWishlistsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allWishlist = wslist?.data;
  const [addNewWishlist] = useAddWishlistMutation();
  const [removeWishlist] = useRemoveWishlistMutation();

  const handleWishlist = async (id) => {
    try {
      const saveData = { productId: id };

      await addNewWishlist(saveData);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await removeWishlist(id);
    } catch (error) {
      toast.error(error);
    }
  };

  const { data: rating } = useGetAllRatingQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const ratings = rating?.data;
  return (
    <Container>
      <div className="min-h-[calc(100vh-110px)] py-8">
        {isLoading || searchLoading ? (
          <div className="w-fll h-screen flex flex-col items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
          </div>
        ) : giftbox?.length === 0 ? (
          <p>There are no items</p>
        ) : (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-6">
            {allGiftBox?.map((box) => {
              const getRating = ratings?.filter(
                (rat) => rat?.pro_id === box?.name
              );
              const sumOfRating = getRating?.reduce(
                (sum, rat) => sum + +rat?.rating,
                0
              );
              const totalRating = getRating?.length;
              const data = { box, totalRating };

              const findWishlist = allWishlist?.find(
                (list) => list?.productId === box?._id
              );
              return (
                <div className="shadow-lg relative">
                  <Link to={`/giftbox/${box?.name}`} state={data}>
                    <div className="">
                      <img
                        src={`https://www.giftshare.jp:5000/${box?.images[0]}`}
                        className={`w-full h-[150px] md:h-[250px] rounded-t-2xl`}
                        alt={box?.festival}
                      />

                      <div className="p-4 flex flex-col gap-y-2">
                        <h6 className="card-title text-sm text-gray-400">
                          {box?.name}
                        </h6>
                        <h4 className="text-[#433832] font-serif text-lg">
                          JPY {box?.price}
                        </h4>
                        {getRating && (
                          <p className="flex items-center">
                            {sumOfRating}{" "}
                            <span className="text-yellow-500 text-xs">
                              <AiFillStar />
                            </span>
                            <span className="ml-1">({getRating?.length})</span>
                          </p>
                        )}
                      </div>
                    </div>
                  </Link>
                  {findWishlist ? (
                    <button
                      onClick={() => handleDelete(findWishlist?._id)}
                      className="text-xl text-red-500 absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer"
                      style={{ borderRadius: "0 10px 0 20px" }}
                    >
                      <FavoriteIcon />
                    </button>
                  ) : (
                    <button
                      onClick={() => handleWishlist(box?._id)}
                      className="text-xl text-white absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer"
                      style={{ borderRadius: "0 10px 0 20px" }}
                    >
                      <FavoriteIcon />
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Container>
  );
};

export default SearchGiftbox;
