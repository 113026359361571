import React, { useState } from "react";
import { AiTwotoneDelete } from "react-icons/ai";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";
import DataTable from "react-data-table-component";
import {
  useGetAllsubscribeForAdminQuery,
  useRemoveSubscribeMutation,
} from "../../../features/api/subscribeApi";
import { useGetAlltestingboxQuery } from "../../../features/api/testingBox";
import { toast } from "react-toastify";
import { useGetAllBrandsQuery } from "../../../features/api/brandApi";

const ManageSubscribe = () => {
  const { data, isLoading } = useGetAllsubscribeForAdminQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const subscribers = data?.data;

  const brands = useGetAllBrandsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const testingBoxs = useGetAlltestingboxQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const [removeSubscribeUser] = useRemoveSubscribeMutation();
  const deleteSubscriber = async (id) => {
    const confirm = window.confirm("Are your sure");
    if (confirm) {
      try {
        const response = await removeSubscribeUser(id);
        if (response) {
          toast.success("Delete Success...");
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const [searchText, setSearchText] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = (column, direction) => {
    setSortColumn(column.selector);
    setSortDirection(direction);
  };

  const filteredSubscribe = searchText
    ? subscribers?.filter((user) =>
        user?.email?.toLowerCase().includes(searchText.toLowerCase())
      )
    : subscribers;

  const sortedUsers =
    sortColumn && sortDirection
      ? [...filteredSubscribe]?.sort((a, b) => {
          if (sortDirection === "asc") {
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
          } else if (sortDirection === "desc") {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
          }
          return 0;
        })
      : filteredSubscribe;

  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Subscriber Name",
      cell: (row) => <>{row?.userName}</>,
      sortable: true,
      width: "200px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      width: "250px",
    },
    // {
    //   name: "Testing-box",
    //   cell: (row) => {
    //     const findTestingBox=testingBoxs?.find(box=>box?._id===row?.testBoxId)
    //     return <div>{findTestingBox?.name}</div>;
    //   },
    //   sortable: true,
    //   width:'200px'
    // },
    {
      name: "Brand",
      cell: (row) => {
        const findTestingBox = testingBoxs?.find(
          (box) => box?._id == row?.testBoxId
        );
         const brandName = brands?.find((b) => b?._id === findTestingBox?.brand);
        return <>{brandName?.name}</>;
      },
      sortable: true,
    },
    {
      name: "Package Name",
      cell: (row) => <>{row?.plan}</>,
      sortable: true,
      width: "150px",
    },
    {
      name: "Address",
      cell: (row) => <>{row?.alternativeAddress}</>,
      sortable: true,
      width: "200px",
    },
    {
      name: "Status",
      cell: (row) => <>{row?.isEnabled===true?'Active':'Inactive'}</>,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            onClick={() => deleteSubscriber(row?._id)}
            className="text-red-500 flex justify-center"
            style={{ width: "40px", fontSize: "25px" }}
          >
            <AiTwotoneDelete></AiTwotoneDelete>
          </button>
        </>
      ),
      sortable: false,
    },
  ];

  const sortIconStyles = {
    base: "mr-1",
    sortNone: "hidden",
    sortAsc: "text-green-500",
    sortDesc: "text-red-500",
  };

  if (isLoading) {
    return (
      <p className="text-red-500 text-center mt-[25%] text-2xl">Loading...</p>
    );
  }
  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="p-8">
        <div className="overflow-x-auto">
          <h2 className="text-xl font-bold mt-2">Manage All Subscriber</h2>
          <div className="text-end">
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className=" mb-4 px-4 py-2 border border-gray-300 rounded"
            />
          </div>
          <DataTable
            columns={columns}
            data={sortedUsers}
            pagination
            highlightOnHover
            sortServer
            fixedHeader
            // responsive
            sortIconStyles={sortIconStyles}
            onSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageSubscribe;
