import React, { useEffect, useState } from "react";
import { AiTwotoneDelete } from "react-icons/ai";
import { useGetAllProductsQuery } from "../../../features/api/productsApi";
import { Link } from "react-router-dom";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";
import DataTable from "react-data-table-component";
import { useGetAllBrandsQuery } from "../../../features/api/brandApi";

const ProductList = () => {
  const products = useGetAllProductsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const brands = useGetAllBrandsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const uniqueBrands = Array.from(
    new Set(products?.map((item) => item?.brand?.name))
  );

  const [searchText, setSearchText] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = (column, direction) => {
    setSortColumn(column.selector);
    setSortDirection(direction);
  };

  const filteredBrands = searchText
    ? uniqueBrands.filter((brand) =>
        brand.toLowerCase().includes(searchText.toLowerCase())
      )
    : uniqueBrands;

  const sortedBrands =
    sortColumn && sortDirection
      ? [...filteredBrands].sort((a, b) => {
          if (sortDirection === "asc") {
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
          } else if (sortDirection === "desc") {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
          }
          return 0;
        })
      : filteredBrands;

  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },

    {
      name: "Brand",
      selector: (row) => <p>{row}</p>,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`/dashboard/allproduct/list`} state={row}>
            <button className="px-4 rounded py-1 bg-blue-600 text-white">
              view
            </button>
          </Link>
        </>
      ),
    },
  ];

  const sortIconStyles = {
    base: "mr-1",
    sortNone: "hidden",
    sortAsc: "text-green-500",
    sortDesc: "text-red-500",
  };

  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="p-8">
        <div className="overflow-x-auto">
          <h2 className="text-xl font-bold mt-2">All Products</h2>
          <div className="text-end">
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className=" mb-4 px-4 py-2 border border-gray-300 rounded"
            />
          </div>
          <DataTable
            columns={columns}
            data={sortedBrands}
            pagination
            highlightOnHover
            sortServer
            fixedHeader
            // responsive
            sortIconStyles={sortIconStyles}
            onSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductList;
