import { useForm } from "react-hook-form";
import bg from "../../assets/images/loginBg.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { AiOutlineRollback } from "react-icons/ai";
import CardNumberInput from "./CardNumberInput";
import "./Login.css";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useGetVipnumbersQuery } from "../../features/api/vipnumberApi";
import {
  useAddLoginUserMutation,
  useGetAllUserQuery,
  useGetUserQuery,
} from "../../features/api/loginApi";
import { useGetOrderItemsQuery } from "../../features/api/ordersitemsApi";
import { useDispatch } from "react-redux";
import { toggleUserLoggedin } from "../../features/Toggle/loggedinUserSlice";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const { data: vip } = useGetVipnumbersQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const vipNumbers = vip?.data;

  const { data: me } = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const getMe = me?.data;

  const { data: usrs } = useGetAllUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allUsers = usrs?.data;

  const [showFirstDiv, setShowFirstDiv] = useState(true);
  const handleSignupButtonClick = () => {
    setShowFirstDiv(false);
  };
  const handlePreviousButtonClick = () => {
    setShowFirstDiv(true);
  };

  const location = useLocation();
  const [numbers, setNumber] = useState("");
  const [error, setError] = useState(null);

  const { register, handleSubmit } = useForm();

  const { data: ordersItem } = useGetOrderItemsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const orderItems = ordersItem?.data;

  const [orders, setOrders] = useState([]);
  const filterDeliveredOrder = orders?.filter(
    (order) => order?.status === "Delivered"
  );
  const filterOrderIdAndRating = orderItems?.filter((order) => {
    return filterDeliveredOrder?.some(
      (fOrder) => fOrder?.orderId === order?.orderId
    );
  });
  const filterFalseOrder = filterOrderIdAndRating?.filter(
    (order) => order?.rating === false
  );
  if (filterFalseOrder?.length > 0) {
    localStorage.setItem("orderFound", "Yes");
    localStorage.setItem(
      "ratingProId",
      filterFalseOrder[filterFalseOrder?.length - 1]?.boxId
    );
    localStorage.setItem(
      "ratingOrderId",
      filterFalseOrder[filterFalseOrder?.length - 1]?.orderId
    );
  }

  const [addLoginUser] = useAddLoginUserMutation();
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    try {
      const res = await axios.get(
        `https://www.giftshare.jp:5000/api/v1/orderlist?role=user&email=${data?.email}`
      );
      setOrders(res?.data);
    } catch (error) {
      // toast.error(error?.response?.data?.error);
      console.log(error);
    }
    try {
      // const response = await axios.post(
      //   `https://www.giftshare.jp:5000/api/v1/login`,
      //   data
      // );

      const response = await addLoginUser(data).unwrap();
      console.log("Line 108: ", response);
      const accessToken = await response?.data?.token;
      localStorage.setItem("accessToken", accessToken);
      const from = location.state?.path || "/";
      dispatch(toggleUserLoggedin(true));
      if (response?.data?.user?.role === "admin") {
        navigate("/dashboard");
      } else if (response?.data?.user?.role === "user") {
        navigate(from, { replace: true });
      }
    } catch (error) {
      toast.error(error?.response?.error);
      // console.log(error?.response?.data?.error)
    }
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // const handleChange = (event) => {
  //   const input = event.target.value;
  //   const cleanedInput = input.replace(/\D/g, "");
  //   const formattedInput = cleanedInput.replace(/(.{4})/g, "$1 ");
  //   setNumber(formattedInput);
  // };

  /* 10 numbers generate start */
  const getToken = localStorage.getItem("accessToken");
  useEffect(() => {
    if (getMe?.role && getToken && getMe?.role === "user") {
      navigate("/user/dashboard");
    }
  }, [getMe?.role, getToken, navigate]);
  const [resId, setResId] = useState("");
  const [generatedNumbers, setGeneratedNumbers] = useState("");
  const generateNumbers = () => {
    let numbers = [];
    for (let i = 1; i <= 3; i++) {
      let num1 = padWithZeross(Math.floor(Math.random() * 10000));
      let num2 = padWithZeross(Math.floor(Math.random() * 10000));
      let num3 = padWithZeross(Math.floor(Math.random() * 10000));
      let num4 = padWithZeross(Math.floor(Math.random() * 10000));
      numbers.push(`${num1}${num2}${num3}${num4}`);
    }
    setGeneratedNumbers(numbers.join("\n"));
  };
  const padWithZeross = (number) => {
    let str = String(number);
    while (str.length < 4) {
      str = "0" + str;
    }
    return str;
  };

  useEffect(() => {
    if (generatedNumbers && resId) {
      const handleGenerate = async () => {
        try {
          await axios.post("https://www.giftshare.jp:5000/api/v1/vipNumber", {
            numbers: generatedNumbers.split("\n"),
            userId: resId,
            userType: "VIP",
          });
        } catch (error) {
          toast.error(error?.response);
        }
      };
      handleGenerate();
    }
  }, [generatedNumbers, resId]);
  /* 10 numbers generate end */

  const handleCardNumberChange = (e) => {
    const inputCardNumber = e.target.value.replace(/[^0-9]/g, "");
    setNumber(inputCardNumber);
  };

  const handleLoginByNumber = async (e) => {
    e.preventDefault();

    let numFind = false;
    for (const u of allUsers) {
      if (u?.numbers === numbers) {
        numFind = true;
        break;
      }
    }
    if (numFind) {
      try {
        const response = await axios.post(
          `https://www.giftshare.jp:5000/api/v1/loginnumber`,
          { numbers }
        );
        const accessToken = await response?.data?.data?.token;
        localStorage.setItem("accessToken", accessToken);

        if (response?.data?.data?.user?.role === "admin") {
          navigate("/dashboard");
        } else if (response?.data?.data?.user?.role === "user") {
          navigate("/user/dashboard");
        }
      } catch (error) {
        toast.error(error?.response?.data?.error);
      }
    } else {
      let matched = false;
      for (const dataItem of vipNumbers) {
        if (dataItem?.numbers === numbers) {
          matched = true;
          break;
        }
      }
      if (!matched) {
        toast.error("Numbers did not match");
        return;
      } else {
        try {
          generateNumbers();
          const sendData = {
            firstName,
            lastName,
            email,
            password,
            numbers,
          };

          const response = await axios.post(
            `https://www.giftshare.jp:5000/api/v1/signup`,
            sendData
          );
          const accessToken = response?.data?.token;
          localStorage.setItem("accessToken", accessToken);
          setResId(response?.data?.data?._id);
          // if (response?.data?.data?._id) {
          //   navigate("/user/dashboard");
          // }

          // const from = location.state?.from?.pathname || "/user/dashboard";
          // if (response?.data?.data?.user?.role === "admin") {
          //   navigate("/dashboard");
          // } else {
          //   navigate(from, { replace: true });
          // }
        } catch (error) {
          console.log(error);
          toast.error(error?.response?.data?.error);
        }
      }
    }
  };

  // handler For show pass
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className="flex min-h-[100vh] overflow-auto justify-center items-center demo-wrap"
      style={{ zIndex: "1" }}
    >
      <img className="demo-bg" src={bg} alt=""></img>
      {showFirstDiv ? (
        <div className="card bg-white shadow-xl overflow-auto demo-content">
          <div className="card-body">
            <h2 className="text-xl font-bold">
              Sign-in to complete your order
            </h2>
            <p>
              Don't have an account?{" "}
              <Link className="text-primary" to="/signup">
                Sign-up
              </Link>
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-control w-full max-w-xs">
                {/* <input
                type="email"
                placeholder="Your Email"
                className="input input-bordered w-full max-w-xs"
                {...register("email")} */}
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  size="small"
                  {...register("email")}
                />
                {/* /> */}
              </div>
              <div className="form-control w-full max-w-xs mt-4">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="filled-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    label="Password"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <AiFillEye />
                          ) : (
                            <AiFillEyeInvisible />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    {...register("password")}
                  />
                </FormControl>
              </div>

              <input
                className="mt-6 w-full max-w-xs text-white bg-[#9A583B] py-1  rounded cursor-pointer uppercase"
                type="submit"
                value="Login"
              />
            </form>
            <div className="divider">OR</div>
            <button
              onClick={handleSignupButtonClick}
              className="max-w-xs text-white bg-[#9A583B] py-1 rounded uppercase"
            >
              Login with VIP number card
            </button>
            {/* <div className="divider">OR</div> */}
            {/* <SocialLogin /> */}
          </div>
        </div>
      ) : (
        <div className="demo-content">
          <div className="bg-white p-6">
            <div className="flex items-center justify-between">
              <button onClick={handlePreviousButtonClick} className="mb-6">
                <AiOutlineRollback />
              </button>
            </div>
            <div>
              <form onSubmit={handleLoginByNumber}>
                <div className="w-full">
                  <CardNumberInput
                    value={numbers}
                    onChange={handleCardNumberChange}
                  />
                </div>

                <input
                  className="w-full mt-4 text-white bg-[#9A583B] py-1 rounded cursor-pointer"
                  type="submit"
                  value="Login"
                />
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;

// How to I use this number format 1111-2222-3333-4444 format on input field but I get feedback 1111222233334444 in a react project. Please give me code with proper example
