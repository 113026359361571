import React, { useState } from "react";
import { AiFillBackward } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEditFaqMutation } from "../../../../features/api/faqApi";

const FaqEdit = () => {
  const location = useLocation();
  const state = location?.state;
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  const navigate = useNavigate();
  const [editFaq] = useEditFaqMutation();
  const handleSubmitProduct = async () => {
    try {
      const data = {
        id: state?._id,
        question: title ? title : state?.question,
        answer: text ? text : state?.answer,
      };
      const response = await editFaq(data);

      if (response?.error) {
        toast.error("Error occurd...");
      } else {
        navigate("/dashboard/faq");
      }
    } catch (error) {
      console.error("Error creating product:", error.response.data);
      toast.error(error.response?.data?.error);
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center p-4">
        <h1 className="text-xl font-bold">Edit FAQ</h1>
        <div className="flex items-center gap-x-4">
          <Link
            to="/dashboard/faq"
            className="bg-[#4E362A] px-2 py-1 rounded text-white flex items-center gap-x-1"
          >
            <AiFillBackward /> Back{" "}
          </Link>
        </div>
      </div>
      <div className="p-4 m-4 border">
        <form className=" grid grid-cols-1 gap-6">
          <div className="flex flex-col gap-1">
            <label className="font-bold">Quetion</label>
            <input
              type="text"
              defaultValue={state?.question}
              onChange={(e) => setTitle(e.target.value)}
              className="border h-8 pl-1 focus:outline-none"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-bold">Answer</label>

            <textarea
              defaultValue={state?.answer}
              rows={4}
              onChange={(e) => setText(e.target.value)}
              className="border pl-1 focus:outline-none"
            />
          </div>
        </form>
        <button
          onClick={handleSubmitProduct}
          className="bg-blue-500 px-4 py-1 text-white font-bold block mx-auto mt-4"
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default FaqEdit;
