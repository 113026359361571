import React from "react";
import Container from "../../Components/Container";
import { useLocation, useParams } from "react-router";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link } from "react-router-dom";
import { useGetAllGiftBoxQuery } from "../../features/api/GiftBoxApi";
import {
  useAddWishlistMutation,
  useGetWishlistsQuery,
  useRemoveWishlistMutation,
} from "../../features/api/wishlistApi";
import { toast } from "react-toastify";
import { useGetAllRatingQuery } from "../../features/api/ratingsApi";
import { AiFillStar } from "react-icons/ai";

const FestivalGiftBox = () => {
  const location = useLocation();
  const prev = location?.state;

  const { data, isLoading } = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allGiftBox = data?.data || [];

  // const { data:g, isLoading:gL } = useGetGiftBoxPaginationQuery(1, {
  //   refetchOnMountOrArgChange: true,
  // });
  // const paginationData = g?.data || [];

  const filterGiftBox = allGiftBox?.filter(
    (box) => box?.festival === prev?._id
  );
  const { name } = useParams();

  const allWishlist = useGetWishlistsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const [addNewWishlist] = useAddWishlistMutation();
  const [removeWishlist] = useRemoveWishlistMutation();

  const handleWishlist = async (id) => {
    try {
      const saveData = { productId: id };

      await addNewWishlist(saveData);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await removeWishlist(id);
    } catch (error) {
      toast.error(error);
    }
  };

  const ratings = useGetAllRatingQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  if (isLoading) {
    return (
      <div className="flex flex-col items-center mt-12 md:mt-32">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        {/* <p className="mt-4 text-gray-900">Loading...</p> */}
      </div>
    );
  }
  if (filterGiftBox?.length === 0) {
    return (
      <Container>
        {" "}
        <div className="my-8 min-h-[calc(100vh-20vh)]">
          <p className="md:text-xl uppercase pb-2">
            {prev?.name}{" "}
            <span className="lowercase text-gray-400">
              {filterGiftBox?.length} items
            </span>
          </p>
          <hr className="bg-[#9A583B] h-0.5 mb-12" />
          <div className="flex flex-col items-center mt-12 md:mt-32 text-[#9A583B]">
            There have no item availabe
          </div>
        </div>
      </Container>
    );
  }
  return (
    <Container>
      <div className="my-8 min-h-[calc(100vh-20vh)]">
        <div className="flex items-center gap-x-2 text-sm mb-6 ">
          <Link to="/">festival</Link>
          <span>{">"}</span>
          <p className="text-orange-800 font-bold">{name}</p>
        </div>
        <p className="md:text-xl uppercase pb-2">
          {prev?.name}{" "}
          <span className="lowercase text-gray-400">
            {filterGiftBox?.length} items
          </span>
        </p>
        <hr className="bg-[#9A583B] h-0.5 mb-12" />
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-6">
          {filterGiftBox?.map((box) => {
            const getRating = ratings?.filter(
              (rat) => rat?.pro_id === box?.name
            );
            const sumOfRating = getRating?.reduce(
              (sum, rat) => sum + +rat?.rating,
              0
            );
            const totalRating = getRating?.length;
            const data = { box, prev, totalRating };

            const findWishlist = allWishlist?.find(
              (list) => list?.productId === box?._id
            );
            return (
              <div className="shadow-lg relative" key={box?._id}>
                {/* <Link to={`/festival/${state?.name}/${box?.name}`} state={data}> */}
                <Link
                  to={`/giftbox-items/box/${box?._id}/${box?.name}`}
                  state={totalRating}
                >
                  <div className="">
                    <img
                      src={`https://www.giftshare.jp:5000/${box?.images?.[0]}`}
                      className={`w-full h-[150px] md:h-[250px] rounded-t-2xl`}
                      alt={box?.name}
                    />

                    <div className="p-4 flex flex-col gap-y-2">
                      <h6 className="card-title text-sm text-gray-400">
                        {box?.name}
                      </h6>
                      <h4 className="text-[#433832] font-serif text-lg">
                        JPY {box?.price}
                      </h4>
                      {getRating && (
                        <p className="flex items-center">
                          {sumOfRating}{" "}
                          <span className="text-yellow-500 text-xs">
                            <AiFillStar />
                          </span>
                          <span className="ml-1">({getRating?.length})</span>
                        </p>
                      )}
                    </div>
                  </div>
                </Link>
                {findWishlist ? (
                  <button
                    onClick={() => handleDelete(findWishlist?._id)}
                    className="text-xl text-red-500 absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer"
                    style={{ borderRadius: "0 10px 0 20px" }}
                  >
                    <FavoriteIcon />
                  </button>
                ) : (
                  <button
                    onClick={() => handleWishlist(box?._id)}
                    className="text-xl text-white absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer"
                    style={{ borderRadius: "0 10px 0 20px" }}
                  >
                    <FavoriteIcon />
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default FestivalGiftBox;
