import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchProductsByName = createAsyncThunk(
  "products/fetchProductsByName",
  async (name) => {
    const response = await fetch(
      `https://www.giftshare.jp:5000/api/v1/giftbox/search?name=${encodeURIComponent(
        name
      )}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch giftbox");
    }
    return response.json();
  }
);

const searchSlice = createSlice({
  name: "products",
  initialState: { data: [], status: "idle", error: null, searchName: "" },
  reducers: {
    setSearchName: (state, action) => {
      state.searchName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsByName.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductsByName.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchProductsByName.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setSearchName } = searchSlice.actions;

export default searchSlice.reducer;
