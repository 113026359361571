import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { toast } from "react-toastify";
import { useGetAllBlankBoxQuery } from "../../features/api/blankBoxApi";
import { useGetAllGiftBoxQuery } from "../../features/api/GiftBoxApi";
import {
  useAddWishlistMutation,
  useGetWishlistsQuery,
  useRemoveWishlistMutation,
} from "../../features/api/wishlistApi";
import { useGetAllRatingQuery } from "../../features/api/ratingsApi";
import { useGetFestivalsQuery } from "../../features/api/festivalApi";
import { useGetFestivalImagesQuery } from "../../features/api/festivalimageApi";
import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";
import jaTranslator from "../../jpTranslator/ja.json";
import enTranslator from "../../enTranslator/en.json";

i18n.use(initReactI18next).init({
  resources: {
    ja: {
      translation: jaTranslator,
    },
    en: {
      translation: enTranslator,
    },
  },
  lng: "ja",
  fallbackLng: "ja",
  interpolation: {
    escapeValue: false,
  },
});

const FilteredGiftBox = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const { t } = useTranslation();

  const allWishlist = useGetWishlistsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  const [addNewWishlist] = useAddWishlistMutation();
  const [removeWishlist] = useRemoveWishlistMutation();
  const handleWishlist = async (id) => {
    try {
      const saveData = { productId: id };

      await addNewWishlist(saveData);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await removeWishlist(id);
    } catch (error) {
      toast.error(error);
    }
  };

  const ratings = useGetAllRatingQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const festivals = useGetFestivalsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const allBlankbox = useGetFestivalImagesQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const allGiftBox = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const findHPGB1 = allBlankbox?.find(
    (n) => n?.festival?.shortName === "HPGB1"
  );
  const findHPGB2 = allBlankbox?.find(
    (n) => n?.festival?.shortName === "HPGB2"
  );
  const findHPGB3 = allBlankbox?.find(
    (n) => n?.festival?.shortName === "HPGB3"
  );
  const findHPGB4 = allBlankbox?.find(
    (n) => n?.festival?.shortName === "HPGB4"
  );

  const birthdayBlankBox = allBlankbox?.filter(
    (box) => box?.festival?._id === findHPGB1?.festival?._id
  );
  const anniversaryBlankBox = allBlankbox?.filter(
    (box) => box?.festival?._id === findHPGB2?.festival?._id
  );
  const valentineBlankBox = allBlankbox?.filter(
    (box) => box?.festival?._id === findHPGB3?.festival?._id
  );
  const cristmasBlankBox = allBlankbox?.filter(
    (box) => box?.festival?._id === findHPGB4?.festival?._id
  );

  const birthdayBox = allGiftBox?.filter(
    (box) => box?.festival === findHPGB1?.festival?._id
  );
  const cristmasBox = allGiftBox?.filter(
    (box) => box?.festival === findHPGB2?.festival?._id
  );
  const valentineBox = allGiftBox?.filter(
    (box) => box?.festival === findHPGB3?.festival?._id
  );
  const anniversaryBox = allGiftBox?.filter(
    (box) => box?.festival === findHPGB4?.festival?._id
  );

  // Blank Box
  const renderFestivalBoxes = (festivalName, blankBoxList) => {
    return (
      <div className="w-full max-w-[1280px] mx-auto px-4">
        <div className="flex justify-between items-center text-sm border-b border-b-gray-400 pb-1">
          <span className="font-bold uppercase">{festivalName?.name}</span>
          <Link
            to="/giftboxes"
            state={festivalName?._id}
            className="font-bold text-gray-300"
          >
            View All
          </Link>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="py-4 flex flex-col gap-y-4">
            {blankBoxList?.slice(0, 2)?.map((box, index) => {
              return (
                <div key={box?._id}>
                  <img
                    src={`https://www.giftshare.jp:5000/${box?.image}`}
                    className={`rounded-3xl ${
                      index === 1 ? "h-[250px]" : "h-[150px]"
                    }`}
                    style={{
                      width: "100%",
                      boxShadow: "0px 0px 3px 1px #433832",
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="py-4 flex flex-col gap-y-4">
            {blankBoxList?.slice(2, 4)?.map((box, index) => {
              return (
                <div key={box?._id}>
                  <img
                    src={`https://www.giftshare.jp:5000/${box?.image}`}
                    className={`rounded-3xl ${
                      index === 0 ? "h-[250px]" : "h-[150px]"
                    }`}
                    style={{
                      width: "100%",
                      boxShadow: "0px 0px 3px 1px #433832",
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  // Gift Box
  const renderGiftBoxes = (festivalName, giftBoxList) => {
    return (
      <div>
        <div className="grid grid-cols-2 gap-1 w-full max-w-[1280px] mx-auto px-1 py-4">
          {giftBoxList?.slice(0, 6)?.map((box, index) => {
            const getRating = ratings?.filter(
              (rat) => rat?.pro_id === box?.name
            );
            const sumOfRating = getRating?.reduce(
              (sum, rat) => sum + +rat?.rating,
              0
            );
            const totalRating = getRating?.length;

            const findWishlist = allWishlist?.find(
              (list) => list?.productId === box?._id
            );
            return (
              <div className="relative rounded-2xl border" key={box?._id}>
                <Link
                  to={`/giftbox-items/box/${box?._id}/${box?.name}`}
                  state={totalRating}
                >
                  <div className="">
                    <img
                      src={`https://www.giftshare.jp:5000/${box?.images[0]}`}
                      className={`w-full h-[150px] md:h-[250px] rounded-t-2xl`}
                      alt={box?.name}
                    />

                    <div className="p-4 flex flex-col gap-y-2">
                      <h6 className="card-title text-sm text-gray-400">
                        {box?.name}
                      </h6>
                      <h4 className="text-[#433832] font-serif text-lg">
                        JPY {box?.price}
                      </h4>
                      {getRating && (
                        <p className="flex items-center">
                          {sumOfRating}{" "}
                          <span className="text-yellow-500 text-xs">
                            <AiFillStar />
                          </span>
                          <span className="ml-1">({getRating?.length})</span>
                        </p>
                      )}
                    </div>
                  </div>
                </Link>
                <button
                  onClick={
                    findWishlist
                      ? () => handleDelete(findWishlist?._id)
                      : () => handleWishlist(box?._id)
                  }
                  className={`text-xl absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer
                      ${findWishlist ? " text-red-500 " : " text-white "}`}
                  style={{ borderRadius: "0 10px 0 20px" }}
                >
                  <FavoriteIcon />
                </button>
              </div>
            );
          })}
        </div>
        {giftBoxList?.length > 0 && (
          <Link
            to="/giftboxes"
            state={festivalName?._id}
            className="font-bold text-[#733E24] flex justify-center"
          >
            {t("seemore")}
          </Link>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="block md:hidden bg-white mt-10">
        <div className="text-center w-full max-w-[1280px] mx-auto px-4">
          <p className="text-  py-5">
            For the Sweetest Moments in Life,
            <br />
            Share a Gift.
          </p>
        </div>

        <div className="">
          {renderFestivalBoxes(findHPGB1?.festival, birthdayBlankBox)}
          {renderGiftBoxes(findHPGB1?.festival, birthdayBox)}
        </div>

        <div className="mt-6">
          {renderFestivalBoxes(findHPGB2?.festival, cristmasBlankBox)}
          {renderGiftBoxes(findHPGB2?.festival, cristmasBox)}
        </div>

        <div className="mt-6">
          {renderFestivalBoxes(findHPGB3?.festival, anniversaryBlankBox)}
          {renderGiftBoxes(findHPGB3?.festival, anniversaryBox)}
        </div>

        <div className="mt-6">
          {renderFestivalBoxes(findHPGB4?.festival, valentineBlankBox)}
          {renderGiftBoxes(findHPGB4?.festival, valentineBox)}
        </div>
      </div>
    </>
  );
};

export default FilteredGiftBox;
