import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const NewRegister = ({ passRegion, passDistrict }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newData = {
      firstName: name,
      email: email,
      phone: phone,
      password: password,
      region: passRegion,
      district: passDistrict,
    };

    try {
      const response = await axios.post(
        `https://www.giftshare.jp:5000/api/v1/signup`,
        newData
      );
      const accessToken = response?.data?.token;
      localStorage.setItem("accessToken", accessToken);
      if (response?.status == 200) {
        document.body.style.overflow = "auto";
        navigate("/user/dashboard");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };

  return (
    <div>
      <button
        className=" w- bg-transparent  hover:bg-[#4E362A] text-[#4E362A] font-semibold hover:text-white py-2 px-4 border border-[#4E362A] hover:border-transparent rounded"
        onClick={openModal}
      >
        Join Now
      </button>

      {isOpen && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded md:w-[400px]">
            <button
              className="text-red-500 font-bold float-right"
              onClick={closeModal}
            >
              X
            </button>
            <div className="card bg-base-100 overflow-auto mt-4">
              <div className="card-body">
                <div className="text-center"></div>
                <form onSubmit={handleSubmit} className="flex flex-col gap-y-2">
                  <div className="form-control w-full ">
                    <input
                      type="text"
                      placeholder="Name"
                      required
                      className="input input-bordered w-full h-8 rounded-none focus:border-none "
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-control w-full ">
                    <input
                      type="email"
                      placeholder="Email"
                      required
                      className="input input-bordered w-full h-8 rounded-none focus:border-none "
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-control w-full ">
                    <input
                      type="tel"
                      placeholder="Phone"
                      required
                      className="input input-bordered w-full h-8 rounded-none focus:border-none "
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-control w-full ">
                    <input
                      type="password"
                      placeholder="Password"
                      className=" input input-bordered w-full h-8 rounded-none focus:border-none "
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <input
                    className="border border-[#4E362A] mt-4 w-full h-8 transition-all duration-300 text-[#4E362A] cursor-pointer hover:bg-[#4E362A] hover:text-white"
                    type="submit"
                    value="Submit"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewRegister;
