import React, { useState } from "react";
import { toast } from "react-toastify";
import { useGetAllBrandsQuery } from "../../../features/api/brandApi";
import axios from "axios";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";

const AddTestingBox = () => {
  const [name, setBoxName] = useState("");
  const [image, setBoxImage] = useState(null);
  const [brand, setBrandName] = useState("");
  console.log(brand);
  const [price, setPrice] = useState("");
  const [desc, setDesc] = useState("");

  const { data: brands } = useGetAllBrandsQuery();
  const allBrand = brands?.data;

  const handleSubmitTestingBox = async (e) => {
    e.preventDefault();

    const data = {
      name,
      image,
      brand,
      price,
      desc,
    };
    try {
      const response = await axios.post(
        "https://www.giftshare.jp:5000/api/v1/testingBox",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Reset the htmlForm inputs
      setBoxName("");
      setBoxImage(null);
      setBrandName("");
      setPrice("");
      setDesc("");

      if (response) {
        toast.success("Successfully added");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };

  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="flex justify-center overflow-auto items-center mt-12 px-8 ">
        <div
          className="card bg-base-100 overflow-auto mb-12 rounded-none w-full"
          style={{ boxShadow: "1px 0px 3px 1px lightblue" }}
        >
          <div className="card-body">
            <div className="text-center">
              <h2 className="text-xl font-bold">Add Testing-Box</h2>
            </div>
            {
              <form onSubmit={handleSubmitTestingBox} className="text-center">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 my-2  ">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setBoxName(e.target.value)}
                    placeholder="Testing-Box Name"
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none mb-2 mx-auto"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setBoxImage(e.target.files[0])}
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none"
                  />
                  <div>
                    <input
                      type="number"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      placeholder="Price"
                      className="input input-bordered mb-2 h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none"
                    />
                    <select
                      value={brand}
                      onChange={(e) => setBrandName(e.target.value)}
                      vlaue={brand}
                      className="input input-bordered lg:mt-4 h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none hidden lg:block"
                    >
                      <option value={""}>--Select Brand--</option>
                      {allBrand?.map((brand) => (
                        <option key={brand?._id} value={brand?._id}>
                          {brand?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <textarea
                    onChange={(e) => setDesc(e.target.value)}
                    rows="4"
                    value={desc}
                    className="block input-bordered border mx-auto mb-2 w-full p-1 text-sm rounded-none focus:border-none"
                    placeholder="Description...( Optional )"
                  ></textarea>

                  <select
                    value={brand}
                    onChange={(e) => setBrandName(e.target.value)}
                    vlaue={brand}
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none block lg:hidden"
                  >
                    <option value={""}>--Select Brand--</option>
                    {allBrand?.map((brand) => (
                      <option key={brand?._id} value={brand?._id}>
                        {brand?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex justify-around pt-6">
                  <button
                    type="submit"
                    className="bg-[#5e2006] px-4 py-1 font-bold text-white "
                  >
                    Save
                  </button>
                </div>
              </form>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTestingBox;
