import { apiSlice } from "./apiSlice";

const subscribeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllsubscribeForAdmin: builder.query({
      query: () => ({
        url: `/subscribe_admin`,
      }),
      providesTags: ["subscribe_admin"],
    }),
    getAllsubscribe: builder.query({
      query: (email) => ({
        url: `/subscribe?email=${email}`,
      }),
      providesTags: ["subscribe_admin"],
    }),
    addSubscribe: builder.mutation({
      query: (newCategory) => ({
        url: "subscribe",
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["subscribe_admin"],
    }),
    removeSubscribe: builder.mutation({
      query: (id) => ({
        url: `/subscribe/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["subscribe_admin"],
    }),
    editSubscribe: builder.mutation({
      query: (editData) => ({
        url: `subscribe`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["subscribe_admin"],
    }),
  }),
});

export const {
  useGetAllsubscribeForAdminQuery,
  useGetAllsubscribeQuery,
  useAddSubscribeMutation,
  useRemoveSubscribeMutation,
  useEditSubscribeMutation,
} = subscribeApi;
