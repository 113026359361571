// import React from "react";
// import "./HeaderMenu.css";
// import Container from "./Container";
// import { Link } from "react-router-dom";
// import { useGetFestivalsQuery } from "../features/api/festivalApi";

// const LoadingSpinner = () => (
//   <div className="w-full h-screen flex items-center justify-center">
//     <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
//   </div>
// );

// const renderFestivals = (festivals, isMobile) => (
//   <div
//     className={`grid grid-cols-${
//       isMobile ? 4 : 8
//     } border border-[#4E362A] rounded-lg p-2 justify-center`}
//   >
//     {festivals?.slice(0, 8)?.map((fes, index) => (
//       <Link
//         key={fes?._id}
//         to={`/festival/${fes?.name}`}
//         state={fes}
//         className={`border-[#4E362A] ${
//           index % (isMobile ? 4 : 8) === isMobile ? "border-r" : ""
//         } ${isMobile && index % 4 === 3 ? "border-r-0" : ""} mb-${
//           isMobile && index < 4 ? 6 : 0
//         } p-2 text-center relative`}
//       >
//         <div className="flex items-center justify-center pb-2">
//           <img
//             src={`https://www.giftshare.jp:5000/${fes?.image}`}
//             alt={fes?.name}
//           />
//         </div>
//         <span className="text-sm">{fes?.name}</span>
//         {isMobile && index < 4 && (
//           <div className="absolute w-[68px] h-[1px] bg-[#4E362A] left-2 -bottom-[14px]"></div>
//         )}
//       </Link>
//     ))}
//   </div>
// );

// const HeaderMenu = () => {
//   const { data, isLoading, isError } = useGetFestivalsQuery(null, {
//     refetchOnMountOrArgChange: true,
//   });
//   const festivals = data?.data || [];

//   if (isError) {
//     // Add error handling
//     return <div>Error fetching data</div>;
//   }

//   return (
//     <Container>
//       <div className="mb-8">
//         {/* Desktop view */}
//         <div className="hidden md:block">
//           {isLoading ? <LoadingSpinner /> : renderFestivals(festivals, false)}
//         </div>

//         {/* Mobile view */}
//         <div className="block md:hidden">
//           {isLoading ? <LoadingSpinner /> : renderFestivals(festivals, true)}
//         </div>
//       </div>
//     </Container>
//   );
// };

// export default HeaderMenu;

import React from "react";
import "./HeaderMenu.css";
import Container from "./Container";
import { Link } from "react-router-dom";
import { useGetFestivalsQuery } from "../features/api/festivalApi";

const HeaderMenu = () => {
  const { data, isLoading } = useGetFestivalsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const festivals = data?.data || [];
  return (
    <Container>
      <div className="mb-8">
        {/* for desktop device */}
        <div className="hidden md:block">
          {isLoading ? (
            <div className="w-fll h-screen flex flex-col items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
            </div>
          ) : (
            <div className="grid grid-cols-8 border border-[#4E362A] rounded-lg">
              {festivals?.slice(0, 8)?.map((fes) => (
                <Link
                  key={fes?._id}
                  to={`/festival/${fes?.name}`}
                  state={fes}
                  className="md:my-2 p-2 last:border-r-0 border-r border-b md:border-b-0 cursor-pointer border-[#4E362A] [&:nth-child(5)]:border-b-0 [&:nth-child(6)]:border-b-0 [&:nth-child(7)]:border-b-0 [&:nth-child(8)]:border-b-0 [&:nth-child(4)]:border-r-0 md:[&:nth-child(4)]:border-r"
                >
                  <div className="flex flex-col text-center items-center">
                    <span>
                      <img
                        className="w-16 pb-2"
                        src={`https://www.giftshare.jp:5000/${fes?.image}`}
                        alt={fes?.name}
                      />
                    </span>
                    <p className="md:text-sm">{fes?.name}</p>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>

        {/* for mobile device */}
        <div className="block md:hidden">
          {isLoading ? (
            <div className="w-fll h-screen flex flex-col items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
            </div>
          ) : (
            <div className="grid grid-cols-4 p-2 justify-center text-center border border-[#4E362A] rounded-lg">
              {festivals?.slice(0, 8)?.map((fes, index) => (
                <Link
                  key={fes?._id}
                  to={`/festival/${fes?.name}`}
                  state={fes}
                  className={` ${
                    index < 4 ? "mb-6" : ""
                  }  border-r border-[#4E362A] [&:nth-child(4)]:border-r-0 [&:nth-child(8)]:border-r-0 text-center relative py-2 px-1`}
                >
                  <div className="flex items-center justify-center pb-2">
                    <img
                      src={`https://www.giftshare.jp:5000/${fes?.image}`}
                      alt={fes?.name}
                    />
                  </div>
                  <span className="text-[7px] whitespace-nowrap">
                    {fes?.name}
                  </span>
                  {index < 4 && (
                    <div className="absolute w-[68px] h-[1px] bg-[#4E362A] left-2 -bottom-[14px]"></div>
                  )}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default HeaderMenu;
