import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { useGetUserQuery } from "../../features/api/loginApi";
import { useGetAllGiftBoxQuery } from "../../features/api/GiftBoxApi";

const RatingPopup = () => {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
    localStorage.removeItem("orderFound");
    localStorage.removeItem("ratingProId");
    localStorage.removeItem("ratingOrderId");
  };

  const getProductId = localStorage.getItem("ratingProId");
  const getOrderId = localStorage.getItem("ratingOrderId");

  const { data: giftBoxs } = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allGiftBox = giftBoxs?.data;
  const matchProduct = allGiftBox?.find((box) => box?._id === getProductId);

  const { data: me } = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const user = me?.data;
  const name = user?.firstName + " " + user?.lastName;

  const [value, setValue] = useState("1");
  const [comment, setComment] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postNew = {
      comment,
      rating: String(value),
      pro_id: getProductId,
      user_id: name,
    };
    const updateRatingStatus = {
      boxId: getProductId,
      orderId: "79787878-a",
      rating: true,
    };

    try {
      const response = await axios.post(
        `https://www.giftshare.jp:5000/api/v1/rating`,
        postNew
      );
      await axios.patch(
        `https://www.giftshare.jp:5000/api/v1/orderRating`,
        updateRatingStatus
      );
      if (response) {
        setIsOpen(false);
        document.body.style.overflow = "auto";
        toast.success(
          "Thank you for sharing your rating! your feedback help us strive for excellence and improve our service"
        );
        localStorage.removeItem("orderFound");
        localStorage.removeItem("ratingProId");
        localStorage.removeItem("ratingOrderId");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <div>
      {isOpen && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white mx-2 lg:mx-0 px-2 lg:p-2 rounded w-full h- lg:w-[500px] mt-14">
            <button
              className=" text-red-600 font-bold float-right"
              onClick={closeModal}
            >
              X
            </button>
            <div className="card bg-base-100 overflow-auto">
              <div className="card-body">
                <p className="text-center lg:text-xl font-bold">
                  Share Your thoughts
                </p>
                <p className="text-center text-slate-400 italic text-sm">
                  We hope you're enjoying your recent purchase of{" "}
                  <b>"{matchProduct?.name}"</b>. Your feedback means the world
                  to us, and we would greatly appreciate it if you could take a
                  moment to share your thoughts by leaving a product review.
                </p>
                <div className="flex items-center justify-center mx-auto gap-x-2">
                  <img
                    src={`https://www.giftshare.jp:5000/${matchProduct?.images[0]}`}
                    className="w-12 lg:w-16 h-12 lg:h-16 mt-2 "
                  />
                </div>
                <div className="text-center"></div>
                <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                    }}
                  >
                    <Typography component="legend">Rating</Typography>
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </Box>
                  <div className="form-control w-full ">
                    <label className="mb-1">Comment:</label>
                    <textarea
                      type="text"
                      className="border p-1 w-full rounded-none focus:outline-none hidden lg:block"
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>

                  <input
                    className="py-1 cursor-pointer mt-4 block m-auto w-full border-none text-white"
                    style={{ backgroundColor: "#9A583B" }}
                    type="submit"
                    value="Submit"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RatingPopup;
