import React, { useState } from "react";
import Container from "../Container";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";

const ContactWithUs = () => {
  return (
    <Container>
      {/* grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10 items-center justify-between */}
      <div className="bg-[#4E362A] mb-2 p-6 ">
        <h1 className="text-xl md:text-2xl mb-4 font-bold text-white text-center">
          Need Assistance? Contact Our Customer Care.
        </h1>
        <div className="text-white flex flex-col md:flex-row gap-4 md:gap-16 justify-between items-center">
          {" "}
          <div className="w-full">
            <span className="md:text-xl font-serif">TEL:</span>
            <p className="flex justify-between items-center mt-1 border border-[#635045] p-4 rounded-lg">
              <span className="text-sm md:text-lg font-mono">03-3351-3022</span>{" "}
              <a
                href={`tel:03-3351-3022`}
                className="w-[30px] md:w-[40px] h-[30px] md:h-[40px] rounded-full md:text-2xl flex items-center justify-center bg-[#635045]"
              >
                <AiFillPhone />
              </a>
            </p>
          </div>
          <div className="w-full">
            <span className="md:text-xl font-serif">Email:</span>
            <p className="flex justify-between items-center mt-1 border border-[#635045] p-4 rounded-lg">
              <span className="text-sm md:text-lg font-mono">
                support@giftshare.com
              </span>
              <a
                href={`mailto:mdborhanuddinmajumder058@gmail.com`}
                className="w-[30px] md:w-[40px] h-[30px] md:h-[40px] rounded-full md:text-2xl flex items-center justify-center bg-[#635045]"
              >
                {" "}
                <AiTwotoneMail className="" />
              </a>
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContactWithUs;
