import { apiSlice } from "./apiSlice";

const ordersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: () => "/orders",
      providesTags: ["orders"],
    }),
    addOrders: builder.mutation({
      query: (newCategory) => ({
        url: "/orders",
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["orders"],
    }),
    removeOrders: builder.mutation({
      query: (id) => ({
        url: `/order/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["orders"],
    }),
    editOrders: builder.mutation({
      query: ({ id, isEnabled }) => ({
        url: `/order/${id}`,
        method: "PATCH",
        body: { isEnabled: !isEnabled },
      }),
      invalidatesTags: ["orders"],
    }),
    editStatus: builder.mutation({
      query: ({ id, ...status }) => ({
        url: `/order-status/${id}`,
        method: "PATCH",
        body: status,
      }),
      invalidatesTags: ["orders"],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useAddOrdersMutation,
  useRemoveOrdersMutation,
  useEditOrdersMutation,
  useEditStatusMutation,
} = ordersApi;
