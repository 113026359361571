import React, { useEffect, useState } from "react";
import { AiOutlineMenu, AiOutlineCaretDown } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { BiSearch } from "react-icons/bi";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Logo from "../assets/images/Logo.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useGetWishlistsQuery } from "../features/api/wishlistApi";
import { toggleSidebar } from "../features/Toggle/sidebarSlice";
import { MdMenu } from "react-icons/md";
import { setSearchName } from "../features/searchSlice";
import {
  selectIsUserLoggedin,
  toggleUserLoggedin,
} from "../features/Toggle/loggedinUserSlice";
import {
  useGetExistingAffiliateCouponQuery,
  useRemoveCouponListMutation,
} from "../features/api/couponlistApi";
import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";
import jaTranslator from "../jpTranslator/ja.json";
import enTranslator from "../enTranslator/en.json";
i18n.use(initReactI18next).init({
  resources: {
    ja: {
      translation: jaTranslator,
    },
    en: {
      translation: enTranslator,
    },
  },
  lng: "ja",
  fallbackLng: "ja",
  interpolation: {
    escapeValue: false,
  },
});

const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const location = useLocation();
  // const searchName = useSelector((state) => state.products.searchName);
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(setSearchName(searchTerm));
  };
  const [fetchUserData, setFetchUserData] = useState(false);
  useEffect(() => {
    window.onload = () => {
      setFetchUserData(true);
    };
  }, []);

  const isDashboardPage = location.pathname.startsWith("/user/dashboard");
  const [isLoggedin, setIsLoggedin] = useState(false);

  const loginState = useSelector(selectIsUserLoggedin);

  useEffect(() => {
    loginState === true ? setIsLoggedin(true) : setIsLoggedin(false);
    setFetchUserData(true);
    // console.log("Line 51: ", loginState);
  }, [loginState]);
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (fetchUserData === true) {
      const checkUser = async () => {
        try {
          await fetch("https://www.giftshare.jp:5000/api/v1/me", {
            method: "GET",
            headers: {
              authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          })
            .then((res) => res.json())
            .then((data) => {
              setUser(data?.data);
              if (data?.data?._id) {
                dispatch(toggleUserLoggedin(true));
              }

              // console.log(data?.data?._id);
            });
        } catch (error) {
          console.error(error);
        } finally {
          setFetchUserData(false);
        }
      };
      checkUser();
    }
  }, [isLoggedin, fetchUserData, dispatch]);

  const { data: wslist, isLoading: listIsLoading } = useGetWishlistsQuery(
    null,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const allWishlist = wslist?.data;
  const { data: couponImage, isLoading: isCouponImageLoading } =
    useGetExistingAffiliateCouponQuery(user?._id);

  const [removeCouponList] = useRemoveCouponListMutation();

  const logOut = () => {
    // if (couponImage?.data?._id) {
    //   removeCouponList(couponImage?.data?._id);
    // }
    dispatch(toggleUserLoggedin(false));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("orderFound");
    localStorage.removeItem("ratingProId");
    localStorage.removeItem("ratingOrderId");
    navigate("/login");
    // setTimeout(() => {
    //   window.location.reload();
    // }, 0.5);
  };

  const [modelMenu, setModelMenu] = useState(false);
  const cart = useSelector((state) => state?.cart?.cart);
  return (
    <div
      className="sticky top-0 w-full px-6 md:px-12 bg-white"
      style={{ zIndex: "999" }}
    >
      {/* menu icon for mobile device */}
      <div
        onClick={() => setModelMenu(!modelMenu)}
        className="float-left text-white px-3 items-center hidden"
        // className="float-left text-white px-3 flex items-center lg:hidden "
        style={{ height: "70px", backgroundColor: "#433832" }}
      >
        <AiOutlineMenu
          className=""
          style={{ width: "30px", fontSize: "23px" }}
        />
      </div>

      {/* menu icon for desktop device */}
      <div
        onClick={() => setModelMenu(!modelMenu)}
        className="float-left text-white px-3 items-center hidden"
        // className="float-left text-white px-3 lg:flex items-center hidden"
        style={{
          height: "70px",
          backgroundColor: "#433832",
          cursor: "pointer",
        }}
      >
        <AiOutlineMenu style={{ width: "30px", fontSize: "23px" }} />
      </div>

      {/* model view Menu start*/}
      {modelMenu && (
        <div
          style={{
            backgroundColor: "#ebedf0",
            position: "absolute",
            width: "100%",
            height: "100vh",
            zIndex: "999",
          }}
        >
          <div className="">
            <div
              onClick={() => setModelMenu(!modelMenu)}
              className="float-left text-white p-4 "
              style={{
                height: "60px",
                backgroundColor: "#900C3F",
                cursor: "pointer",
              }}
            >
              <ImCross
                onClick={() => setModelMenu(false)}
                style={{
                  cursor: "pointer",
                  color: "white",
                  fontSize: "20px",
                }}
              />
            </div>
            <div
              className="d-flex justify-content-between align-items-center px-4 "
              style={{ height: "60px", backgroundColor: "#7B3F00" }}
            >
              {/* logo */}
              <div className="flex items-center h-full">
                <p className="text-white ml-4">Title</p>
              </div>
            </div>
          </div>

          <div>
            <h1 className="flex justify-center ">Borhan Uddin</h1>
          </div>
        </div>
      )}
      {/* model view Menu end*/}

      {/* backgroundColor: "#433832" */}

      {/* navbar */}
      <div
        className="flex justify-between items-center"
        style={{ height: "70px" }}
      >
        {/* logo */}
        <div className="flex items-center gap-x-1">
          {isDashboardPage && (
            <button onClick={handleToggleSidebar} className="lg:hidden">
              <MdMenu size={25} />
            </button>
          )}
          <Link to="/">
            <img
              src={Logo}
              className="w-[130px] md:w-[150px]"
              alt="GiftShare logo"
            />
          </Link>
        </div>

        {/* search bar */}
        <div className="relative hidden lg:block text-gray-600">
          <form onSubmit={handleSearch}>
            <input
              type="search"
              placeholder={t('search')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border border-[#4E362A] h-9 pl-4 pr-10 rounded-full focus:outline-none w-[500px]"
            />
            <button
              type="submit"
              className="absolute top-2 right-2 font-bold text-[#4E362A]"
            >
              {/* <Link to={`/giftbox/search?name=${searchTerm}`} state={searchTerm}> */}{" "}
              <BiSearch size={24} />
              {/* </Link> */}
            </button>
          </form>
          {/* <input
            className="bg-white border border-[#433832] h-10 px-5 pr-10 rounded-full text-sm placeholder:text-gray-500 focus:outline-none w-[500px]"
            type="search"
            name="search"
            placeholder="Search . . . ."
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
            }}
          /> */}
          {/* <button type="submit" className="absolute right-0 top-0 mt-2 mr-4 ">
            <svg
              className="h-4 w-4 fill-current"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.656 10.562c-1.031.813-2.344 1.313-3.75 1.313-3.313 0-6-2.688-6-6s2.687-6 6-6 6 2.688 6 6c0 1.406-.5 2.719-1.313 3.75l3.563 3.563-1.406 1.407-3.563-3.563zM6 8c0 1.656 1.344 3 3 3s3-1.344 3-3-1.344-3-3-3-3 1.344-3 3z" />
            </svg>
          </button> */}
        </div>

        {/* icon */}
        <div className="flex items-center gap-x-2 md:gap-x-4 text-xl md:text-2xl">
          {listIsLoading ? (
            <p>...</p>
          ) : allWishlist?.length > 0 ? (
            <button className="text-red-500 mr-2">
              <Link to="/wishlist-item">
                <FavoriteIcon />
              </Link>
            </button>
          ) : (
            <button className="text-[#4E362A]">
              <FavoriteBorderIcon />
            </button>
          )}

          {cart?.length > 0 ? (
            <p className="relative text-[#4E362A]">
              <Link to="/add-to-cart">
                <ShoppingCartOutlinedIcon />
              </Link>
              <span className="absolute top-[-6px] font-bold left-[18px] text-sm text-[#4E362A] flex justify-center items-center">
                {cart?.length}
              </span>
            </p>
          ) : (
            <p className="relative text-[#4E362A]">
              <ShoppingCartOutlinedIcon />

              <span className="absolute top-[-4px] font-bold left-[18px] text-sm text-[#4E362A] flex justify-center items-center">
                {cart?.length}
              </span>
            </p>
          )}

          <div className="dropdown dropdown-end">
            <label
              tabIndex={0}
              className="btn bg-transparent hover:bg-transparent border-none "
            >
              {isLoggedin /* &&   user?._id  */ ? (
                <p className="text-sm flex items-center text-[#433832] ">
                  <span className="hidden lg:block">
                    {user?._id &&
                      `${user?.firstName} ${
                        user?.lastName ? user?.lastName : ""
                      }`}
                    {user?._id && ` (${user?.earnedCoin - user?.usedCoin})`}
                  </span>
                  {user?._id && <AiOutlineCaretDown />}
                </p>
              ) : (
                <Link
                  to={"/login"}
                  className="bg-transparent mt-1 text-[#433832]"
                >
                  <AccountCircleOutlinedIcon />
                </Link>
              )}
            </label>
            {isLoggedin && user?._id && (
              <ul
                tabIndex={0}
                className="menu menu-compact dropdown-content mt-3 py-3 px-2 shadow bg-[#3B3B3B] text-white rounded-md w-52"
              >
                {user?.role && user?.role === "user" && (
                  <li /*  className={user?.role === "user" ? "hidden" : ""} */>
                    <Link to="/user/dashboard">Dashborad</Link>
                  </li>
                )}

                {user?.role && user?.role === "admin" && (
                  <li /* className={user?.role === "admin" ? "hidden" : ""} */>
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                )}
                {/* 
                <li className={user?.role === "admin" ? "hidden" : ""}>
                  <Link to="/user/dashboard">Dashboard</Link>
                </li> */}

                <li>
                  {" "}
                  <button onClick={logOut} className="">
                    Logout
                  </button>{" "}
                </li>
              </ul>
            )}
          </div>
          {/* {!user && (
      <label
        tabIndex={0}
        className="btn bg-transparent hover:bg-transparent border-none -mr-4"
      >
        <Link to="/signup" className="bg-transparent text-sm text-black">
          <span className="flex items-center">
            <SiGnuprivacyguard className="mr-1" />
            Signup
          </span>
        </Link>
      </label>
    )} */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
