import React, { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="min-h-[calc(100vh-190px)] text-justify p-8">
      <h1 className="font-bold">About Us</h1> Welcome to Giftshare Chocolate,
      where sweet dreams are crafted into delectable reality. At Giftshare
      Chocolate, we are passionate connoisseurs of the finest cocoa, dedicated
      to creating chocolate experiences that delight the senses and warm the
      heart. <h1 className="font-bold">Our Story</h1>
      Giftshare Chocolate was born out of a simple yet profound idea - the joy
      of sharing. We believe that chocolate has the unique power to connect
      people, spark conversations, and create moments of pure happiness. Our
      journey began with a desire to share this love for chocolate and the
      connections it forges. <h1 className="font-bold">
        Crafting Excellence
      </h1>{" "}
      Our artisans are the heart and soul of Giftshare Chocolate. With years of
      experience and an unwavering commitment to quality, they transform raw
      cocoa into exquisite works of art. Each piece of chocolate is meticulously
      crafted, infused with creativity, and sealed with care.{" "}
      <h1 className="font-bold">A World of Flavors</h1> Explore a symphony of
      flavors that transcend borders. From silky-smooth dark chocolate to creamy
      milk chocolate, from zesty fruit infusions to exotic spices, our diverse
      range of chocolates reflects the richness of cultures around the world.
      <h1 className="font-bold">Sustainability & Ethics</h1> At Giftshare
      Chocolate, we are devoted to preserving the environment and supporting the
      cocoa farming communities. We source our cocoa responsibly and ensure fair
      compensation for the farmers. Our eco-friendly practices extend to our
      packaging, which is designed with Mother Earth in mind.{" "}
      <h1 className="font-bold">Join Our Chocolate Journey</h1> Whether you're
      seeking a memorable gift or a personal indulgence, Giftshare Chocolate
      invites you to embark on a delightful chocolate journey with us. We look
      forward to becoming a part of your celebrations, milestones, and everyday
      moments. Thank you for choosing Giftshare Chocolate, where we transform
      cocoa beans into cherished memories, one bite at a time.
    </div>
  );
};

export default AboutUs;
