import React, { useState } from "react";
import OrderDetails from "./OrderDetails";
import {
  useEditStatusMutation,
  useGetOrdersQuery,
  useRemoveOrdersMutation,
} from "../../../features/api/ordersApi";
import { AiTwotoneDelete } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { useGetAllUserQuery } from "../../../features/api/loginApi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const Delivered = () => {
  const { data, isLoading } = useGetOrdersQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const orders = data?.data;
  const deliveredOrders = orders?.filter(
    (order) => order?.status === "Delivered"
  );

  const [removeOrder] = useRemoveOrdersMutation();
  const deleteUser = (id) => {
    const confirm = window.confirm("Are you want do delete?");
    if (confirm) {
      removeOrder(id);
    }
  };

  const [searchText, setSearchText] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = (column, direction) => {
    setSortColumn(column.selector);
    setSortDirection(direction);
  };

  const filteredUsers = searchText
    ? deliveredOrders?.filter((order) =>
        order?.orderId.toLowerCase().includes(searchText.toLowerCase())
      )
    : deliveredOrders;

  const sortedUsers =
    sortColumn && sortDirection
      ? [...filteredUsers].sort((a, b) => {
          if (sortDirection === "asc") {
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
          } else if (sortDirection === "desc") {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
          }
          return 0;
        })
      : filteredUsers;

  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Order Id",
      cell: (row) => <>{row?.orderId}</>,
      sortable: true,
    },

    {
      name: "Name",
      cell: (row) => <>{row?.shippName}</>,
      sortable: true,
      width: "160px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      width: "240px",
    },
    {
      name: "Amount",
      cell: (row) => <>¥{row?.subtotal}</>,
      sortable: true,
    },
    {
      name: "Discount",
      cell: (row) => <>{row?.discount ? <p>¥{row?.discount}</p> : 0}</>,
      sortable: true,
    },
    {
      name: "Total",
      cell: (row) => <>¥{row?.grandTotal}</>,
      sortable: true,
    },
    {
      name: "Created At",
      cell: (row) => <button>{formatDate(row?.createdAt)}</button>,
      sortable: true,
      width: "110px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className=" flex">
          <button>
            {" "}
            <Link
              to="/dashboard/orders/details"
              className="p-1 bg-blue-500 font-bold rounded text-xs text-white"
              state={row}
            >
              View
            </Link>
          </button>
          <button
            onClick={() => deleteUser(row?._id)}
            className="text-red-500 flex justify-center"
            style={{ width: "40px", fontSize: "25px" }}
          >
            <AiTwotoneDelete></AiTwotoneDelete>
          </button>
        </div>
      ),
      sortable: false,
      width: "130px",
    },
  ];

  const sortIconStyles = {
    base: "mr-1",
    sortNone: "hidden",
    sortAsc: "text-green-500",
    sortDesc: "text-red-500",
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let getDate = day + "-" + month + "-" + year;
    return getDate;
  }
  return (
    <div className="p-2 bg-[#F4F6F9] pt-4">
      <h2>Delivered Orders</h2>
      {isLoading ? (
        <div className="w-fll h-screen flex flex-col items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div>
          <div className="text-end">
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className=" mb-4 px-4 py-2 border border-gray-300 rounded"
            />
          </div>
          <DataTable
            columns={columns}
            data={sortedUsers}
            pagination
            highlightOnHover
            sortServer
            fixedHeader
            // responsive
            sortIconStyles={sortIconStyles}
            onSort={handleSort}
          />
        </div>
      )}
    </div>
  );
};

export default Delivered;
