import React from "react";
import { useLocation } from "react-router";
import Container from "../../../Components/Container";
import { useGetAllProductsQuery } from "../../../features/api/productsApi";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

const BrandDetails = () => {
  const location = useLocation();
  const brand = location?.state;

  // fetch all products
  const { data: getProducts } = useGetAllProductsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allProducts = getProducts?.data;

  return (
    <Container>
      <div className="py-8 flex flex-col gap-y-2 lg:gap-y-8">
        <div className="flex flex-col lg:flex-row gap-4 lg:items-start">
          <div className="flex-1 grid grid-cols-2 items-center justify-center gap-x-2 ">
            {brand?.images?.map((imge,idx) => {
              return (
                <div key={idx} className="flex justify-center">
                  <img
                    src={`https://www.giftshare.jp:5000/${imge}`}
                    className="lg:w-40 w-32"
                  />
                </div>
              );
            })}
          </div>
          <div className="flex-1">
            <Link
              to={`/dashboard/updatebrand/${brand?._id}`}
              className="text-blue-500 text-xl absolute right-10"
            >
              {" "}
              <FaEdit size={25} />
            </Link>

            <div className="flex flex-col lg:items-center">
              <div className="">
                <img
                  src={`https://www.giftshare.jp:5000/${brand?.logo}`}
                  className=""
                />
              </div>
              <div className="mt-2">
                <h1 className="font-bold">Brand: {brand?.name}</h1>
                <p>Location: {brand?.loc}</p>
                <p className="">Description: {brand?.desc}</p>
                <p className=" mt-2">History: {brand?.history}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h1 className="py-6 text-center font-bold">Gallery</h1>
          <div className="grid grid-cols-3 lg:grid-cols-5 gap-2">
            {brand?.gallery?.map((gal) => (
              <div>
                <img
                  src={`https://www.giftshare.jp:5000/${gal}`}
                  className="lg:w-28 lg:h-28 w-20 h-20"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BrandDetails;
