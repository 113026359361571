import axios from "axios";
import React, { useEffect, useState } from "react";
import { useGetUserQuery } from "../../../features/api/loginApi";
import { useGetAllGiftBoxQuery } from "../../../features/api/GiftBoxApi";
import { useGetAllBlankBoxQuery } from "../../../features/api/blankBoxApi";
import { useGetAlltestingboxQuery } from "../../../features/api/testingBox";
import { useGetOrderItemsQuery } from "../../../features/api/ordersitemsApi";
import { useLocation } from "react-router";
import { useGetOrderTestItemsQuery } from "../../../features/api/ordertestlistApi";

const OrderSinglePage = () => {
  const location = useLocation();
  const order = location?.state;

  const user = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const giftBox = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const testingBoxs = useGetAlltestingboxQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const blankBox = useGetAllBlankBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const orderItems = useGetOrderItemsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const orderTestItems = useGetOrderTestItemsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const [orders, setOrders] = useState([]);
  // const findOrder = orders?.find((ordr) => ordr);
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          `https://www.giftshare.jp:5000/api/v1/orderlist?role=${user?.role}&email=${user?.email}`
        );
        setOrders(response?.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [user?.role, user?.email]);

  const filterOrderItems = orderItems?.filter((item) => {
    return orders?.some((i) => i?.orderId === item?.orderId);
  });

  const filterOrderTestItems = orderTestItems?.filter((item) => {
    return orders?.some((i) => i?.orderId === item?.orderId);
  });

  const filterByOrId = filterOrderItems?.filter(
    (item) => item?.orderId === order?.orderId
  );
  const filterByOrTestId = filterOrderTestItems?.filter(
    (item) => item?.orderId === order?.orderId
  );

  return (
    <div>
      <div className="p-3 lg:p-8">
        <h2 className="text-xl font-bold my-2">
          Order Information ({" "}
          <span className="text-xs text-green-500">{order?.status}</span>)
        </h2>
        <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-y-0 bg-slate-100 p-4">
          <div className="flex-1">
            <h2 className="font-bold">Shipping Address</h2>
            <p>{order?.shippName}</p>
            <p>{order?.shippAddress}</p>
            <p>{order?.shippDistrict}</p>
            <p>{order?.shippRegion}</p>
            <p className="">
              <span>Mobile: </span> {order?.shippPhone}
            </p>
          </div>
          <div className="flex-1 lg:pr-28">
            <h2 className="font-bold">Order Summary</h2>
            <div className="flex items-center justify-between">
              <p>Sub-Total</p>
              <p>¥{order?.subtotal}</p>
            </div>
            <div className="flex items-center justify-between">
              <p>Discount</p>
              <p>¥{order?.discount}</p>
            </div>
            <div className="flex items-center justify-between">
              <p>Shipping Cost</p>
              <p>¥{order?.shippFee == "Free" ? 0 : order?.shippFee}</p>
            </div>
            <div className="flex items-center justify-between">
              <p>Grand-Total</p>
              <p>¥{order?.grandTotal}</p>
            </div>
            <div className="flex items-center justify-between">
              <p>Payment-Method</p>
              <p>
                {order?.paymentMethod == "COD"
                  ? "Cash-on-Delivery"
                  : order?.paymentMethod}
              </p>
            </div>
          </div>
        </div>
        <h2 className="text-xl font-bold my-2">Products</h2>
        <div className="overflow-x-auto">
          <table className="table w-full mt-2">
            {/* head */}
            <thead>
              <tr>
                <th>Product</th>
                <th>BoxImage</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
                {filterByOrId?.length > 0 && <th>Discount</th>}
                {filterByOrId?.length > 0 && <th>BlankImage</th>}
              </tr>
            </thead>
            <tbody>
              {filterByOrId?.length === 0 && filterByOrTestId?.length === 0 ? (
                <p>There have no products</p>
              ) : filterByOrId?.length > 0 ? (
                filterByOrId?.map((order) => {
                  const findBox = giftBox?.find(
                    (box) => box?._id === order?.boxId
                  );
                  const blankBoxImage = blankBox?.find(
                    (box) => box?._id === order?.blankBoxId
                  );
                  return (
                    <tr key={order?._id}>
                      <td>{findBox?.name}</td>
                      <td>
                        <img
                          src={`https://www.giftshare.jp:5000/${findBox?.images[0]}`}
                          className={`w-10 h-10 rounded-full`}
                        />
                      </td>
                      <td>¥{order?.price}</td>
                      <td>{order?.quantity}</td>
                      <td>¥{order?.price * order?.quantity}</td>
                      <td>¥{order?.couponDis}</td>
                      <td>
                        <img
                          src={`https://www.giftshare.jp:5000/${blankBoxImage?.image}`}
                          className={`w-10 h-10 rounded-full`}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : filterByOrTestId?.length > 0 ? (
                filterByOrTestId?.map((order) => {
                  const findBox = testingBoxs?.find(
                    (box) => box?._id === order?.testBoxId
                  );
                  return (
                    <tr key={order?._id}>
                      <td>{findBox?.name}</td>
                      <td>
                        <img
                          src={`https://www.giftshare.jp:5000/${findBox?.image}`}
                          className={`w-10 h-10 rounded-full`}
                        />
                      </td>
                      <td>¥{order?.price}</td>
                      <td>{order?.quantity}</td>
                      <td>¥{order?.price * order?.quantity}</td>
                    </tr>
                  );
                })
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrderSinglePage;

{
  /* <div className="flex flex-col items-center mt-12 md:mt-32">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
                </div> */
}
