import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAddBrandMutation } from "../../../features/api/brandApi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddBrand = () => {
  const [name, setName] = useState("");
  const [history, setHistory] = useState("");
  const [desc, setDesc] = useState("");
  const [loc, setLoc] = useState("");
  const [logo, setLogo] = useState(null);
  const [gallery, setGallery] = useState([]);
  const [images, setImages] = useState([]);

  const [addNewBrandMutation] = useAddBrandMutation();

  const handleSubmitBrand = async (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("name", name);
    form.append("history", history);
    form.append("desc", desc);
    form.append("loc", loc);
    form.append("logo", logo);

    for (let i = 0; i < gallery?.length; i++) {
      form.append("gallery", gallery[i]);
    }
    for (let i = 0; i < images?.length; i++) {
      form.append("images", images[i]);
    }

    try {
      const response = await axios.post(
        "https://www.giftshare.jp:5000/api/v1/brand",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      // const response = await addNewBrandMutation(formData);

      if (response) {
        // Reset the form inputs
        // setName("");
        // setDesc("");

        toast.success("Successfully added");
      }
    } catch (error) {
      console.error("Error creating product:", error.response.data);
      toast.error(error?.response?.data?.error);
    }
  };
  return (
    <div className="flex justify-center overflow-auto items-center mt-12">
      <div
        className="card bg-base-100 overflow-auto mb-12 rounded-none"
        style={{ boxShadow: "1px 0px 3px 1px lightblue" }}
      >
        <div className="card-body">
          <div className="text-center">
            <h2 className="text-xl font-bold">Add Brand</h2>
          </div>
          {
            <form onSubmit={handleSubmitBrand} className="text-center">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 my-2  ">
                <div>
                  <label>Name</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none mb-2 mx-auto"
                  />
                </div>
                <div>
                  <label>History</label>
                  <textarea
                    onChange={(e) => setHistory(e.target.value)}
                    rows="4"
                    value={history}
                    className="block input-bordered border mx-auto mb-2 w-full p-1 text-sm rounded-none focus:border-none"
                    placeholder="Description..."
                  ></textarea>
                </div>
                <div>
                  <label>Short Description</label>
                  <textarea
                    onChange={(e) => setDesc(e.target.value)}
                    rows="4"
                    value={desc}
                    className="block input-bordered border mx-auto mb-2 w-full p-1 text-sm rounded-none focus:border-none"
                    placeholder="Description..."
                  ></textarea>
                </div>
                <div>
                  <label>Location</label>
                  <textarea
                    onChange={(e) => setLoc(e.target.value)}
                    rows="4"
                    value={loc}
                    className="block input-bordered border mx-auto mb-2 w-full p-1 text-sm rounded-none focus:border-none"
                    placeholder="Description..."
                  ></textarea>
                </div>
                <div>
                  <label>Logo</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setLogo(e.target.files[0])}
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none"
                  />
                </div>
                <div>
                  <label>Gallery (Choose Multiple)</label>
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={(e) => setGallery(e.target.files)}
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none"
                  />
                </div>
              </div>
              <div className="mt-4 lg:mt-8">
                <label>Choose 2 file (Open & Close) </label>
                <input
                  type="file"
                  multiple
                  onChange={(e) => setImages(e.target.files)}
                  className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none mb-2 mx-auto"
                />
              </div>

              <div className="flex justify-around pt-6">
                <button
                  type="submit"
                  className="bg-[#5e2006] px-2 py-1 font-bold text-white "
                >
                  Save
                </button>
              </div>
            </form>
          }
        </div>
      </div>
    </div>
  );
};

export default AddBrand;
