import React from 'react';

const FestivalImageUpdate = () => {
    return (
        <div>
            festival iamge update
        </div>
    );
};

export default FestivalImageUpdate;