import axios from "axios";
import { useState } from "react";
import { useGetAllBrandsQuery } from "../../../features/api/brandApi";
import { useGetAllRegionQuery } from "../../../features/api/regionApi";
import { toast } from "react-toastify";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";
import { useLocation, useNavigate } from "react-router";

const UpdateTrendingGift = () => {
  const location = useLocation();
  const state = location?.state;

  const allBrand = useGetAllBrandsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  const brandName = allBrand?.find((b) => b?._id === state?.brand);

  const allRegion = useGetAllRegionQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const [brand, setBrand] = useState("");
  const [region, setRegion] = useState("");
  const [district, setDistrict] = useState("");

  const navigate = useNavigate();

  const selectDistrict = allRegion?.filter(
    (sregion) => sregion.region === region
  );

  const handleTrendGift = async (e) => {
    e.preventDefault();

    const data = {
      brand: brand ? brand : state?.brand,
      region: region ? region : state?.region,
      district: district ? district : state?.district,
    };
    try {
      const response = await axios.patch(
        `https://www.giftshare.jp:5000/api/v1/trendgift/${state?._id}`,
        data
      );
      if (response) {
        navigate("/dashboard/trendgiftlist");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="p-8">
        <h1 className="mb-4 text-blue-500 font-bold">Edit Trending Gift</h1>
        <div className="flex justify-center overflow-auto items-center mt-12">
          <div
            className="card bg-base-100 overflow-auto mb-12 rounded-none"
            style={{ boxShadow: "1px 0px 3px 1px lightblue" }}
          >
            <div className="card-body">
              {
                <form onSubmit={handleTrendGift} className="text-center">
                  <div className=" ">
                    <div className="flex flex-col">
                      {" "}
                      <label>Brand:</label>
                      <select
                        onChange={(e) => setBrand(e.target.value)}
                        className="border h-8 rounded-none focus:border-none w-full max-w-xs mx-auto mb-2"
                      >
                        <option value={""}>{brandName?.name}</option>
                        {allBrand?.map((brand) => (
                          <option key={brand?._id} value={brand?._id}>
                            {brand?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label>Region: </label>
                    <select
                      onChange={(e) => setRegion(e.target.value)}
                      className="border h-8 rounded-none focus:border-none w-full max-w-xs mx-auto mb-2"
                    >
                      <option>{state?.region}</option>
                      {allRegion?.map((region) => (
                        <option key={region?._id}>{region?.region}</option>
                      ))}
                    </select>
                  </div>

                  <div className="flex flex-col">
                    <label>District: </label>
                    <select
                      onChange={(e) => setDistrict(e.target.value)}
                      className="border h-8 rounded-none focus:border-none w-full max-w-xs mx-auto mb-2"
                    >
                      <option>{state?.district}</option>
                      {selectDistrict?.[0]?.district?.map((dst, i) => (
                        <option key={i}>{dst}</option>
                      ))}
                    </select>
                  </div>

                  <div className="flex justify-around pt-6">
                    <button
                      type="submit"
                      className="bg-[#5e2006] px-2 py-1 font-bold text-white "
                    >
                      update
                    </button>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateTrendingGift;
