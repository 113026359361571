import HeroSlider from "../Components/HeroSlider";
import HeaderMenu from "../Components/HeaderMenu";
import MobileSearch from "../Components/MobileSearch";
import CategorySwiper from "./CategorySwiper/CategorySwiper";
import SameDayDelivery from "../Components/SameDayDelivery";
import Regions from "../Components/Regions";
import Footer from "../Layout/Footer";
import GiftBox from "../Components/GiftBox";
import { useEffect, useState } from "react";
import Reviews from "../Components/Reviews/Reviews";
import NewsLetter from "../Components/NewsLetter/NewsLetter";
import Contact from "../Components/Contact/Contact";
import RegionsDistrict from "../Components/RegionsDistrict/RegionsDistrict";
import FilteredGiftBox from "../Components/FilterGiftBox/FilteredGiftBox";
import FilteredGiftBoxDesktop from "../Components/FilterGiftBox/FilteredGiftBoxDesktop";
import ContactWithUs from "../Components/Contact/ContactWithUs";

import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";
import jaTranslator from "../jpTranslator/ja.json";
import enTranslator from "../enTranslator/en.json";
import { useLocation } from "react-router";

i18n.use(initReactI18next).init({
  resources: {
    ja: {
      translation: jaTranslator,
    },
    en: {
      translation: enTranslator,
    },
  },
  lng: "ja",
  fallbackLng: "ja",
  interpolation: {
    escapeValue: false,
  },
});

const Home = () => {
  const { t } = useTranslation();
  // Use useLocation to get the current location
  const location = useLocation();

  // Extract the search (query) parameters from the location
  const searchParams = new URLSearchParams(location.search);
  if (searchParams.get("coupon")) {
    // Get the value of the "coupon" parameter
    const couponCode = searchParams.get("coupon");
    localStorage.setItem("couponCode", couponCode);
    console.log("couponCode:", couponCode);
  }

  return (
    <>
      <MobileSearch />
      <HeaderMenu />
      <HeroSlider />
      {/* <CategorySwiper /> */}
      {/* <Regions /> */}
      <RegionsDistrict />
      <FilteredGiftBox />
      <FilteredGiftBoxDesktop />
      {/* <GiftBox /> */}
      {/* <SameDayDelivery /> */}
      <Reviews />
      <NewsLetter />
      {/* <Contact /> */}
      <ContactWithUs />
      {/* <Footer /> */}
      {/* <div>
        <p>{t("hello")}</p>
        <p>{t("welcome")}</p>
      </div> */}
    </>
  );
};

export default Home;
