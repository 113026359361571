import { apiSlice } from "./apiSlice";

const ordertestlistApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrderTestItems: builder.query({
      query: () => "/orderTestItem",
      providesTags: ["orderTestItem"],
    }),
    addOrderTestItem: builder.mutation({
      query: (newCategory) => ({
        url: "/orderTestItem",
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["orderTestItem"],
    }),
    removeOrderTestItem: builder.mutation({
      query: (id) => ({
        url: `/orderTestItem/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["orderTestItem"],
    }),
    editOrderTestItem: builder.mutation({
      query: (editData) => ({
        url: `/orderTestItem`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["orderTestItem"],
    }),
  }),
});

export const {
  useGetOrderTestItemsQuery,
  useAddOrderTestItemMutation,
  useRemoveOrderTestItemMutation,
  useEditOrderTestItemMutation,
} = ordertestlistApi;
