import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ratingIcon from "../../../assets/images/rating.png";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { useGetUserQuery } from "../../../features/api/loginApi";
import { useAddProductRatingMutation } from "../../../features/api/productratingApi";

const RatingPopup = ({ info }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  const user = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const [value, setValue] = useState("1");

  const [addProductRating] = useAddProductRatingMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postNew = {
      pro_id: info?._id,
      user_id: user?._id,
      pro_id: info?._id,
      user_id: user?._id,
      rating: String(value),
    };
    try {
      const response = await addProductRating(postNew);
      if (response) {
        setIsOpen(false);
        document.body.style.overflow = "auto";
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <div>
      <button onClick={openModal}>
        <img src={ratingIcon} className="w-10 h-10" />
      </button>

      {isOpen && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white mx-2 lg:mx-0 px-2 lg:p-6 rounded w-full lg:w-[500px]">
            <button
              className=" text-red-600 font-bold float-right"
              onClick={closeModal}
            >
              X
            </button>
            <div className="card bg-base-100 overflow-auto">
              <div className="card-body">
                <div className="text-center"></div>
                <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                    }}
                  >
                    <Typography component="legend">Rating</Typography>
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </Box>

                  <input
                    className="py-1 cursor-pointer mt-6 block m-auto w-full border-none text-white"
                    style={{ backgroundColor: "#9A583B" }}
                    type="submit"
                    value="Done"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RatingPopup;
