import { apiSlice } from "./apiSlice";

const GiftBoxApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllGiftBox: builder.query({
      query: () => ({
        url: `/giftbox`,
      }),
      providesTags: ["giftbox"],
    }),
    getDefaultGiftBoxes: builder.query({
      query: () => ({
        url: `/giftbox`,
      }),
      providesTags: ["giftbox"],
    }),
    searchGiftBox: builder.query({
      query: (name) => ({
        url: `/giftbox/search?name=${name}`,
      }),
      invalidatesTags: ["giftbox"],
    }),
    getAllSelectGiftBox: builder.query({
      query: () => ({
        url: "/selectgiftbox",
      }),
      providesTags: ["giftbox"],
    }),
    addGiftBox: builder.mutation({
      query: (newData) => ({
        url: `/giftbox`,
        method: "POST",
        body: newData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["giftbox"],
    }),
    updateBooleanBox: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/giftbox/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["giftbox"],
    }),
    removeGiftBox: builder.mutation({
      query: (id) => ({
        url: `/giftbox/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["giftbox"],
    }),
  }),
});

export const {
  useGetAllGiftBoxQuery,
  useGetDefaultGiftBoxesQuery,
  useSearchGiftBoxQuery,
  useGetAllSelectGiftBoxQuery,
  useAddGiftBoxMutation,
  useUpdateBooleanBoxMutation,
  useRemoveGiftBoxMutation,
} = GiftBoxApi;

/* 
  const handleDefaultBoxes = async (id) => {
    const response = await axios.get(
      `https://giftshare.jp:5000/api/v1/giftbox?festival=${id}&isEnabled=true`
    );
    // console.log("FilteredGiftBox: Line 102 ", response?.data.data);
    return response?.data?.data;
  };


*/
