import { apiSlice } from "./apiSlice";

const ratingsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllRating: builder.query({
      query: () => "rating",
      providesTags: ["rating"],
    }),
    addRating: builder.mutation({
      query: (newData) => ({
        url: "rating",
        method: "POST",
        body: newData,
      }),
      invalidatesTags: ["rating"],
    }),
    removeRating: builder.mutation({
      query: (id) => ({
        url: `rating/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["rating"],
    }),
    editRating: builder.mutation({
      query: (editData) => ({
        url: `rating`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["rating"],
    }),
  }),
});

export const {
  useGetAllRatingQuery,
  useAddRatingMutation,
  useRemoveRatingMutation,
  useEditRatingMutation,
} = ratingsApi;
