import React from "react";
import Container from "../Container";
import { AiFillMessage } from "react-icons/ai";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const NewsLetter = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    try {
      const data = { email, message };
      const response = await axios.post(
        "https://www.giftshare.jp:5000/api/v1/newsletter",
        data
      );
      if (response) {
        setEmail("");
        setMessage("");
        toast.success("Newsletter sent successful!!!");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Container>
      <div className="bg-[#4E362A] p-6 grid grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-y-0 md:gap-x-10 my-2">
        <div className="text-white">
          <h1 className="md:text-xl font-bold">NewsLetter Updates!!!</h1>
          <p className="mt-2 text-sm">
            Get unique & trendy gift ideas and best offers devivered to your
            inbox
          </p>
        </div>
        <div className="flex flex-col gap-y-3 relative z-10">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border h-8 md:h-10 w-full pl-1 rounded-sm placeholder:text-sm focus:outline-none"
            placeholder="Enter Email ID"
            required
          />
          {show && (
            <textarea
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="focus:outline-none p-1"
              placeholder="Text your message"
            />
          )}
          <button
            onClick={handleSubmit}
            className="block w-full h-8 md:h-10 text-white bg-blue-600 uppercase font-bold rounded-sm z-10"
          >
            Submit
          </button>
          <button
            onClick={() => setShow(!show)}
            className="text-xl md:text-2xl bg-green-500 shadow-lg text-white w-8 md:w-10 h-8 md:h-10 flex items-center justify-center absolute bottom-6 left-2 z-50"
            style={{ borderRadius: "20px 2px 18px 26px" }}
          >
            <AiFillMessage />
          </button>
        </div>
      </div>
    </Container>
  );
};

export default NewsLetter;
