import React from "react";
import { useLocation } from "react-router";

const TestingBoxDetails = () => {
  const location = useLocation();
  const box = location?.state;

  return (
    <div className="p-8 flex justify-around items-center">
      <div>
        <h2>Testing-Box Name: {box?.name}</h2>
        <h2>Price: {box?.price}</h2>
        <h2>Brand Name: {box?.brand}</h2>
      </div>
      <div>
        <img
          src={`https://www.giftshare.jp:5000/${box?.image}`}
          className="w-48 h-48"
        />
      </div>
    </div>
  );
};

export default TestingBoxDetails;
