import { apiSlice } from "./apiSlice";

const brandApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBrands: builder.query({
      query: () => ({
        url: "/brand",
      }),
      providesTags: ["brand"],
    }),
    addBrand: builder.mutation({
      query: (newData) => ({
        url: "/brand",
        method: "POST",
        body: newData,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["brand"],
    }),
    removeBrand: builder.mutation({
      query: (id) => ({
        url: `/brand/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["brand"],
    }),
  }),
});

export const {
  useGetAllBrandsQuery,
  useAddBrandMutation,
  useRemoveBrandMutation,
} = brandApi;
