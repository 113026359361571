import React from 'react';

const CouponConfigCreate = () => {
    return (
        <div>
            coupon config create
        </div>
    );
};

export default CouponConfigCreate;