import React, { useState } from "react";
import { useForm } from "react-hook-form";
import bg from "../../assets/images/loginBg.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import SocialLogin from "../../Components/SocialLigin/SocialLogin";
import { toast } from "react-toastify";
import "./Login.css";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [numbers, setNumbers] = useState("");

  const onSubmit = async (data) => {
    // let matched = false;
    // for (const dataItem of vipNumbers) {
    //   if (dataItem.numbers === numbers) {
    //     matched = true;
    //     break;
    //   }
    // }
    // if (!matched) {
    //   toast.error("Numbers did not match");
    //   return;
    // }
    try {
      const { password, confirmPassword } = data;
      const sendData = { ...data, numbers };

      if (password != confirmPassword) {
        setError("Password did not matched");
      } else {
        const response = await axios.post(
          `https://www.giftshare.jp:5000/api/v1/signup`,
          sendData
        );
        const accessToken = response?.data?.token;
        localStorage.setItem("accessToken", accessToken);

        const from = location.state?.from?.pathname || "/user/dashboard";
        if (response?.data?.data?.user?.role === "admin") {
          navigate("/dashboard");
        } else {
          navigate(from, { replace: true });
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="flex lg:h-screen justify-center overflow-auto items-center demo-wrap">
      <img class="demo-bg" src={bg} alt=""></img>
      <div className="card bg-base-100 overflow-auto shadow-xl mb-12 demo-content">
        <div className="card-body">
          <div className="text-center">
            <h2 className="text-xl font-bold">
              Sign up to complete your order
            </h2>
            <p>
              Already have an account?{" "}
              <Link className="text-primary" to="/login">
                Sign in
              </Link>
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid lg:grid-cols-2 gap-3 lg:gap-4  gap-x-8">
              <div className="form-control w-full max-w-xs">
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  size="small"
                  {...register("firstName")}
                />
                {/* <label className="label">
                  <span className="label-text">First Name</span>
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  className="input input-bordered w-full h-8 rounded-none focus:border-none max-w-xs"
                  {...register("firstName")}
                /> */}
              </div>
              <div className="form-control w-full gap-3 lg:gap-4  max-w-xs">
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  {...register("lastName")}
                />
              </div>
            </div>
            <div className="form-control w-full lg:gap-4 gap-3 ">
              <TextField
                sx={{ mt: 1 }}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                size="small"
                // className="input input-bordered w-full h-8 rounded-none focus:border-none "
                {...register("email")}
              />
            </div>

            {/* password  */}

            <div className="mt-2 gap-3 lg:gap-4 grid lg:grid-cols-2 gap-x-8">
              <FormControl variant="outlined">
                <InputLabel htmlFor="filled-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  label="Password"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...register("password")}
                />
              </FormControl>

              <FormControl variant="outlined">
                <InputLabel htmlFor="filled-adornment-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  label="Confirm Password"
                  size="small"
                  type={showConfirmPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <AiFillEye />
                        ) : (
                          <AiFillEyeInvisible />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...register("confirmPassword")}
                />
              </FormControl>
            </div>
            {error && (
              <p className="text-center text-red-500 font-bold text-sm mt-4">
                {error}
              </p>
            )}
            <input
              className="w-full mt-6 text-white bg-[#9A583B] py-1 rounded"
              style={{ backgroundColor: "#9A583B" }}
              type="submit"
              value="Sign Up"
            />
          </form>
          {/* <div className="divider ">OR</div>
          <SocialLogin /> */}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
