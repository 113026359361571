import React, { useState } from "react";
import {
  useEditConfigMutation,
  useEditCouponConfigMutation,
  useGetCouponConfigsQuery,
} from "../../../../features/api/couponconfigApi";
import { toast } from "react-toastify";

const CouponConfig = () => {
  const { data } = useGetCouponConfigsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const couponsConfig = data?.data;

  const [amount, setAmount] = useState("");
  const [editCouponConfig] = useEditCouponConfigMutation();
  const handleUpdete = async (coupon) => {
    const editData = {
      id: coupon?._id,
      amount: amount ? amount : coupon?.amount,
    };
    if (amount) {
      const confirm = window.confirm("Do you want to change current amount?");
      if (confirm) {
        try {
          const response = await editCouponConfig(editData);
          if (response) {
            toast.success(response?.data?.status);
            setAmount("");
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      toast.warning(
        "Please give new amount if you want to change current amount"
      );
    }
  };

  return (
    <div className="bg-slate-200 flex items-center justify-center h-full">
      <div className="lg:w-[400px] h-[100px] w-[300px] shadow-md bg-white rounded">
        {couponsConfig?.map((coupon) => {
          return (
            <div key={coupon?._id} className="relative">
              <input
                type="text"
                onChange={(e) => setAmount(e.target.value)}
                defaultValue={coupon?.amount}
                className="border w-full focus:outline-none h-10 text-center text-2xl"
              />
              <p className="absolute top-1.5 left-1 cursor-not-allowed">Amount: {coupon?.amount}%</p>
              <button onClick={() => handleUpdete(coupon)} className="border px-4 py-1 mt-2 block m-auto rounded">Update</button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CouponConfig;
