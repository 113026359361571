import { apiSlice } from "./apiSlice";

const blankBoxApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBlankBox: builder.query({
      query: () => ({
        url: "/blankBox",
      }),
      providesTags: ["blankBox"],
    }),
    addBlankbox: builder.mutation({
      query: (newCategory) => ({
        url: "blankBox",
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["blankBox"],
    }),
    removeBlankBox: builder.mutation({
      query: (id) => ({
        url: `/blankBox/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["blankBox"],
    }),
    editBlankbox: builder.mutation({
      query: (editData) => ({
        url: `blankBox`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["blankBox"],
    }),
  }),
});

export const {
  useGetAllBlankBoxQuery,
  useAddBlankboxMutation,
  useRemoveBlankBoxMutation,
  useEditBlankboxMutation,
} = blankBoxApi;
