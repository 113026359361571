import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./IngredientsMobile.css";
import { FreeMode, Navigation, Pagination, Keyboard } from "swiper";

const IngredientsMobile = ({ data }) => {
  // const allPhotos = data?.length > 0 && data[0]?.gallery;
  return (
    <div className="">
      <PhotoProvider>
        <Swiper
          loop={true}
          // navigation={true}
          keyboard={true}
          slidesPerView={3}
          spaceBetween={10}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination, Navigation, Keyboard]}
          className="gmswiper"
        >
          {data?.gallery?.map((g, index) => (
            <SwiperSlide className="gmswiper-slider" key={index}>
              <PhotoView src={`https://www.giftshare.jp:5000/${g}`}>
                <img
                  src={`https://www.giftshare.jp:5000/${g}`}
                  alt=""
                  className="w-[200px] h-[70px] cursor-pointer"
                />
                {/* <img src={data.image} className="" style={{ width: "250px" }} /> */}
              </PhotoView>
            </SwiperSlide>
          ))}
        </Swiper>
      </PhotoProvider>
      {/* <PhotoProvider>
      {allPhotos?.map((d, i) => (
        <PhotoView src={`https://www.giftshare.jp:5000/${d}`}>
          <img
            src={`https://www.giftshare.jp:5000/${d}`}
            alt=""
            className="w-[200px]"
          />
        </PhotoView>
      ))}
    </PhotoProvider> */}
    </div>
  );
};

export default IngredientsMobile;
