import React from "react";
import { Link } from "react-router-dom";
import { useGetUserQuery } from "../../../features/api/loginApi";
import { useGetAllEarninghistoryQuery } from "../../../features/api/pointsHistory";

const EarningHistory = () => {
  const { data } = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const user = data?.data;

  const { data: earninghistory } = useGetAllEarninghistoryQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const couponHistorys = earninghistory?.data;

  const filterHistory = couponHistorys?.filter(
    (coupon) => coupon?.userId === user?._id && coupon?.type === "E"
  );

  const totalPoints = filterHistory?.reduce((sum, item) => sum + item?.coin, 0);

  function formatDate(dateString) {
    const bangladeshiDate = new Date(dateString).toLocaleString("en-BD", {
      timeZone: "Asia/Dhaka",
    });
    // const japaneseDate = new Date(dateString).toLocaleString("ja-JP", {
    //   timeZone: "Asia/Tokyo",
    // });
    return bangladeshiDate;
  }
  return (
    <div className="p-8">
      <div className="flex justify-between mb-4">
        {" "}
        <h1 className="text-xl font-bold">Earning Points Transaction</h1>
        <Link to="/user/dashboard" className="text-red-500 font-bold">
          X
        </Link>
      </div>
      <div>
        <table className="table w-full mt-2">
          {/* head */}
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>Date & Time</th>
              <th>Description</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            {filterHistory?.map((history, index) => {
              return (
                <tr key={history?._id}>
                  <th>{index + 1}</th>
                  <td>{formatDate(history?.createdAt)}</td>
                  <td>{history?.desc}</td>
                  <td>{history?.coin}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {" "}
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th colspan="4">Total: {totalPoints}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default EarningHistory;
