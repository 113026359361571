import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main";
import Home from "../Pages/Home";
import Contact from "../Pages/ContactUs/Contact";
import NotFound from "../Pages/NotFound";
import DeliveryGifts from "../Pages/DeliveryGifts";
import DeliveryGiftsDetails from "../Pages/DeliveryGiftsDetails";
import CartProducts from "../Pages/CartProduct/CartProducts";
import Login from "../Pages/Login/Login";
import SignUp from "../Pages/Login/SignUp";
import BrandsItem from "../Pages/BrandsItem/BrandsItem";
import DashboardLayout from "../Layout/DashboardLayout";
import Dashboard from "../Pages/Dashboard/Dashboard";
import AllUsers from "../Pages/Dashboard/AllUsers/AllUsers";
import AddBrand from "../Pages/Dashboard/BrandProducts/AddBrand";
import NewAddRegion from "../Pages/Dashboard/AddRegion/AddRegion";
import AddProdusts from "../Pages/Dashboard/AddProducts/AddProducts";
import AddGiftItems from "../Pages/Dashboard/GiftBox/AddGiftItems";
import RegionList from "../Pages/Dashboard/AddRegion/RegionList";
import AddEvent from "../Pages/Dashboard/Event/AddEvent";
import ProductList from "../Pages/Dashboard/ProductList/ProductList";
import AddTrendingGift from "../Pages/Dashboard/TrendingGift/AddTrendingGift";
import BrandList from "../Pages/Dashboard/BrandProducts/BrandList";
import EventList from "../Pages/Dashboard/Event/EventList";
import GiftItemList from "../Pages/Dashboard/GiftBox/GiftItemList";
import TrendingGiftList from "../Pages/Dashboard/TrendingGift/TrendingGiftList";
import UpdateProducts from "../Pages/Dashboard/UpdateProducts/UpdateProducts";
import UpdateRegionDistrict from "../Pages/Dashboard/UpdateRegionDistrict/UpdateRegionDistrict";
import UpdateBrand from "../Pages/Dashboard/BrandProducts/UpdateBrand";
import UpdateGiftBox from "../Pages/Dashboard/GiftBox/UpdateGiftBox";
import UpdateEvent from "../Pages/Dashboard/Event/UpdateEvent";
import AllVisitors from "../Pages/Dashboard/AllUsers/AllVisitors";
import AddToCart from "../Pages/AddToCart/AddToCart";
import AddBlankBox from "../Pages/Dashboard/BlankBox/AddBlankBox";
import BlankBoxList from "../Pages/Dashboard/BlankBox/BlankBoxList";
import NewDashboard from "../Pages/Dashboard/NewDashboard";
import UpdateBlankBox from "../Pages/Dashboard/BlankBox/UpdateBlankBox";
import UserDashboard from "../Pages/UserDashboard/UserDashboard";
import DashbordHome from "../Pages/UserDashboard/DashbordHome";
import Order from "../Pages/UserDashboard/Order/Order";
import RequireAuth from "../Components/PrivateRoute/RequireAuth";
import Checkout from "../Pages/Checkout/Checkout";
import ShippingAddress from "../Pages/UserDashboard/ShippingAddress/ShippingAddress";
import Payment from "../Pages/UserDashboard/Payment/Payment";
import DashboardAuth from "../Components/PrivateRoute/DashboardAuth/DashboardAuth";
import Orders from "../Pages/Dashboard/Orders/Orders";
import Delivered from "../Pages/Dashboard/Orders/Delivered";
import PositionWithLevel from "../Pages/Dashboard/AllUsers/PositionWithLevel";
import Subscription from "../Pages/UserDashboard/Subscription";
import SubscriptionUser from "../Pages/Dashboard/AllUsers/PackageSubscription/SubscriptionUser";
import Affiliate from "../Pages/Dashboard/AllUsers/Affiliate";
import CreateCoupon from "../Pages/Dashboard/AllUsers/CreateCoupon";
import UpdateCoupon from "../Pages/Dashboard/AllUsers/UpdateCoupon";
import UserAffiliate from "../Pages/UserDashboard/UserAffiliate/UserAffiliate";
import EarningHistory from "../Pages/UserDashboard/CouponHistory/EarningHistory";
import UsedHistory from "../Pages/UserDashboard/CouponHistory/UsedHistory";
import AllCouponHistory from "../Pages/Dashboard/AllUsers/AllCouponHistory";
import VipNumbers from "../Pages/Dashboard/AllUsers/VipNumber/VipNumbers";
import GenerateNumber from "../Pages/Dashboard/AllUsers/VipNumber/GenerateNumber";
import Profile from "../Pages/UserDashboard/Profile/Profile";
import UpdateProfile from "../Pages/UserDashboard/Profile/UpdateProfile";
import ManageSubscribe from "../Pages/Dashboard/Subscriber/ManageSubscribe";
import ManageTestingBox from "../Pages/Dashboard/TestingBox/ManageTestingBox";
import AddTestingBox from "../Pages/Dashboard/TestingBox/AddTestingBox";
import TestingBoxDetails from "../Pages/Dashboard/TestingBox/TestingBoxDetails";
import Festivals from "../Pages/Dashboard/GiftBox/Festivals";
import AddFestival from "../Pages/Dashboard/GiftBox/AddFestival";
import EditFestival from "../Pages/Dashboard/GiftBox/EditFestival";
import Newsletter from "../Pages/Dashboard/AllUsers/Newsletter";
import FestivalGiftBox from "../Pages/Festival_giftBox/FestivalGiftBox";
import FestivalGiftBoxDetail from "../Pages/Festival_giftBox/FestivalGiftBoxDetail";
import GiftBoxFilterSinglePage from "../Components/FilterGiftBox/GiftBoxFilterSinglePage";
import BlankBoxFilterSinglePage from "../Components/FilterGiftBox/BlankBoxFilterSinglePage";
import WishlistItems from "../Components/WishlistItems";
import UpdateTestingBox from "../Pages/Dashboard/TestingBox/UpdateTestingBox";
import UpdateTrendingGift from "../Pages/Dashboard/TrendingGift/UpdateTrendingGift";
import TestUpload from "../Pages/Dashboard/BrandProducts/TestUpload";
import OrderList from "../Pages/UserDashboard/Order/OrderList";
import OrderTest from "../Pages/UserDashboard/Order/OrderTest";
import RegionDefault from "../Pages/Dashboard/AddRegion/RegionDefault";
import OrderSinglePage from "../Pages/Dashboard/Orders/OrderSinglePage";
import SliderSetting from "../Pages/Dashboard/Settings/SliderSetting/SliderSetting";
import SliderSettingEdit from "../Pages/Dashboard/Settings/SliderSetting/SliderSettingEdit";
import SliderSettingCreate from "../Pages/Dashboard/Settings/SliderSetting/SliderSettingCreate";
import SearchGiftbox from "../Pages/SearchGiftbox/SearchGiftbox";
import SingleGiftbox from "../Pages/Dashboard/GiftBox/SingleGiftbox";
import BrandDetails from "../Pages/Dashboard/BrandProducts/BrandDetails";
import Another from "../Pages/BrandsItem/Another";
import AboutUs from "../Pages/AboutUs/AboutUs";
import TramsCondition from "../Pages/TarmsCondition/TramsCondition";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import BrandwiseProducts from "../Pages/Dashboard/ProductList/BrandwiseProducts";
import GiftboxSinglePage from "../Pages/GiftboxSinglePage/GiftboxSinglePage";
import CouponConfig from "../Pages/Dashboard/AllUsers/Coupon/CouponConfig";
import CouponConfigCreate from "../Pages/Dashboard/AllUsers/Coupon/CouponConfigCreate";
import CouponConfigUpdate from "../Pages/Dashboard/AllUsers/Coupon/CouponConfigUpdate";
import Workshop from "../Pages/Workshop/Workshop";
import ChocolateKit from "../Pages/ChocolateKit/ChocolateKit";
import ProductRating from "../Pages/UserDashboard/ProductRating/ProductRating";
import Faq from "../Pages/Dashboard/Settings/Faq/Faq";
import FaqCreate from "../Pages/Dashboard/Settings/Faq/FaqCreate";
import FaqEdit from "../Pages/Dashboard/Settings/Faq/FaqEdit";
import TestingProductRating from "../Pages/Dashboard/Settings/TestingProductRating/TestingProductRating";
import FestivalImages from "../Pages/Dashboard/GiftBox/FestivalImages";
import FestivalImageCreate from "../Pages/Dashboard/GiftBox/FestivalImageCreate";
import FestivalImageUpdate from "../Pages/Dashboard/GiftBox/FestivalImageUpdate";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/test",
        element: <Another />,
      },
      {
        path: "/contact-us",
        element: <Contact />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/trams-condition",
        element: <TramsCondition />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/giftboxes",
        element: <GiftBoxFilterSinglePage />,
      },
      {
        path: "/blankboxes",
        element: <BlankBoxFilterSinglePage />,
      },
      {
        path: "/festival/:name",
        element: <FestivalGiftBox />,
      },
      {
        path: "/festival/:name/:subname",
        element: <FestivalGiftBoxDetail />,
      },
      {
        path: "/brands/:name",
        element: <BrandsItem />,
      },
      {
        path: "/wishlist-item",
        element: <WishlistItems />,
      },
      {
        path: "/same-day-delivery-gifts",
        element: <DeliveryGifts />,
      },
      {
        path: "/delivery/:name",
        element: <DeliveryGiftsDetails />,
      },
      {
        path: "/carts",
        element: <CartProducts />,
      },
      {
        path: "/add-to-cart",
        element: <AddToCart />,
      },
      {
        path: "/checkout",
        element: (
          <RequireAuth>
            <Checkout />
          </RequireAuth>
        ),
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
      {
        path: "/newdashboard",
        element: <NewDashboard />,
      },
      {
        path: "/giftbox/search",
        element: <SearchGiftbox />,
      },
      {
        path: "/giftbox-items/box/:boxId/:box",
        element: <GiftboxSinglePage />,
      },
      {
        path: "/workshop",
        element: <Workshop />,
      },
      {
        path: "/chocolate-kits",
        element: <ChocolateKit />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "/dashboard",
        element: (
          <DashboardAuth allowedRoles={["admin"]}>
            <Dashboard />
          </DashboardAuth>
        ),
      },
      {
        path: "/dashboard/addregion",
        element: <NewAddRegion />,
      },
      {
        path: "/dashboard/regionlist",
        element: <RegionList />,
      },
      {
        path: "/dashboard/region/default",
        element: <RegionDefault />,
      },
      {
        path: "/dashboard/updateregionlist/:id",
        element: <UpdateRegionDistrict />,
      },
      {
        path: "/dashboard/alluser",
        element: <AllUsers />,
      },
      {
        path: "/dashboard/newsletter",
        element: <Newsletter />,
      },
      {
        path: "/dashboard/level_user",
        element: <PositionWithLevel />,
      },
      {
        path: "/dashboard/visitor",
        element: <AllVisitors />,
      },
      {
        path: "/dashboard/vip",
        element: <VipNumbers />,
      },
      {
        path: "/dashboard/generateVipNumber",
        element: <GenerateNumber />,
      },
      {
        path: "/dashboard/subscription",
        element: <SubscriptionUser />,
      },
      {
        path: "/dashboard/affiliate",
        element: <Affiliate />,
      },
      {
        path: "/dashboard/coupon-config",
        element: <CouponConfig />,
      },
      {
        path: "/dashboard/coupon-config/create",
        element: <CouponConfigCreate />,
      },
      {
        path: "/dashboard/coupon-config/edit",
        element: <CouponConfigUpdate />,
      },
      {
        path: "/dashboard/points-history",
        element: <AllCouponHistory />,
      },
      {
        path: "/dashboard/affiliate/create",
        element: <CreateCoupon />,
      },
      {
        path: "/dashboard/affiliate/update",
        element: <UpdateCoupon />,
      },
      {
        path: "/dashboard/addbrand",
        element: <AddBrand />,
      },
      {
        path: "/dashboard/brandlist",
        element: <BrandList />,
      },
      {
        path: "/dashboard/brandlist/details",
        element: <BrandDetails />,
      },
      {
        path: "/dashboard/updatebrand/:id",
        element: <UpdateBrand />,
      },
      {
        path: "/dashboard/addproduct",
        element: <AddProdusts />,
      },
      {
        path: "/dashboard/allproduct",
        element: <ProductList />,
      },
      {
        path: "/dashboard/allproduct/list",
        element: <BrandwiseProducts />,
      },
      {
        path: "/dashboard/updateproductlist/:id",
        element: <UpdateProducts />,
      },
      {
        path: "/dashboard/addgiftitem",
        element: <AddGiftItems />,
      },
      {
        path: "/dashboard/giftitemlist",
        element: <GiftItemList />,
      },
      {
        path: "/dashboard/festival",
        element: <Festivals />,
      },
      {
        path: "/dashboard/festival/create",
        element: <AddFestival />,
      },
      {
        path: "/dashboard/festival/edit",
        element: <EditFestival />,
      },
      {
        path: "/dashboard/manage-testing-box",
        element: <ManageTestingBox />,
      },
      {
        path: "/dashboard/add-testing-box",
        element: <AddTestingBox />,
      },
      {
        path: "/dashboard/edit-testingbox",
        element: <UpdateTestingBox />,
      },
      {
        path: "/dashboard/updategiftboxitem/:id",
        element: <UpdateGiftBox />,
      },
      {
        path: "/dashboard/giftbox/box-details",
        element: <SingleGiftbox />,
      },
      {
        path: "/dashboard/addgift",
        element: <AddTrendingGift />,
      },
      {
        path: "/dashboard/trendgiftlist",
        element: <TrendingGiftList />,
      },
      {
        path: "/dashboard/trendgift-edit",
        element: <UpdateTrendingGift />,
      },
      {
        path: "/dashboard/addevent",
        element: <AddEvent />,
      },
      {
        path: "/dashboard/eventlist",
        element: <EventList />,
      },
      {
        path: "/dashboard/test",
        element: <TestUpload />,
      },
      {
        path: "/dashboard/updateevent/:id",
        element: <UpdateEvent />,
      },
      {
        path: "/dashboard/addblankbox",
        element: <AddBlankBox />,
      },
      {
        path: "/dashboard/blanklist",
        element: <BlankBoxList />,
      },
      {
        path: "/dashboard/updateBlankBox/:id",
        element: <UpdateBlankBox />,
      },
      {
        path: "/dashboard/orders",
        element: <Orders />,
      },
      {
        path: "/dashboard/orders/details",
        element: <OrderSinglePage />,
      },
      {
        path: "/dashboard/delivered",
        element: <Delivered />,
      },
      {
        path: "/dashboard/manage-subscriber",
        element: <ManageSubscribe />,
      },
      {
        path: "/dashboard/slider-setting",
        element: <SliderSetting />,
      },
      {
        path: "/dashboard/slider-setting/create",
        element: <SliderSettingCreate />,
      },
      {
        path: "/dashboard/slider-setting/edit",
        element: <SliderSettingEdit />,
      },
      {
        path: "/dashboard/faq",
        element: <Faq />,
      },
      {
        path: "/dashboard/faq/create",
        element: <FaqCreate />,
      },
      {
        path: "/dashboard/faq/edit",
        element: <FaqEdit />,
      },
      {
        path: "/dashboard/testing-product-rating",
        element: <TestingProductRating />,
      },
      {
        path: "/dashboard/festival/image",
        element: <FestivalImages />,
      },
      {
        path: "/dashboard/festival/image/create",
        element: <FestivalImageCreate />,
      },
      {
        path: "/dashboard/festival/image/edit",
        element: <FestivalImageUpdate />,
      },
    ],
  },
  {
    path: "/user/dashboard",
    element: (
      <DashboardAuth allowedRoles={["user"]}>
        <UserDashboard />
      </DashboardAuth>
    ),
    children: [
      {
        path: "/user/dashboard",
        element: <DashbordHome />,
      },
      {
        path: "/user/dashboard/order-history",
        element: <OrderList />,
      },
      {
        path: "/user/dashboard/order-list",
        element: <Order />,
      },
      {
        path: "/user/dashboard/order-list-test",
        element: <OrderTest />,
      },
      {
        path: "/user/dashboard/earning-history",
        element: <EarningHistory />,
      },
      {
        path: "/user/dashboard/used-history",
        element: <UsedHistory />,
      },
      {
        path: "/user/dashboard/shipping-address",
        element: <ShippingAddress />,
      },
      {
        path: "/user/dashboard/payment",
        element: <Payment />,
      },
      {
        path: "/user/dashboard/subscription",
        element: <Subscription />,
      },
      {
        path: "/user/dashboard/affiliate",
        element: <UserAffiliate />,
      },
      {
        path: "/user/dashboard/profile",
        element: <Profile />,
      },
      {
        path: "/user/dashboard/rating",
        element: <ProductRating />,
      },
      {
        path: "/user/dashboard/update-profile",
        element: <UpdateProfile />,
      },
      {
        path: "/user/dashboard/testing-box-details",
        element: <TestingBoxDetails />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default routes;
