import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetUserQuery } from "../../../features/api/loginApi";
import axios from "axios";
import { useGetAllGiftBoxQuery } from "../../../features/api/GiftBoxApi";
import { useGetOrderItemsQuery } from "../../../features/api/ordersitemsApi";
import { AiFillCheckCircle } from "react-icons/ai";
import { useGetAlltestingboxQuery } from "../../../features/api/testingBox";
import { useGetOrderTestItemsQuery } from "../../../features/api/ordertestlistApi";

const OrderList = () => {
  const { data } = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const user = data?.data;

  const { data: allGiftBox } = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const giftBox = allGiftBox?.data;

  const { data: testbox } = useGetAlltestingboxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const testingBoxs = testbox?.data;

  const { data: ordersItem, isLoading } = useGetOrderItemsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const orderItems = ordersItem?.data;

  const { data: ordersTestItem } = useGetOrderTestItemsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const orderTestItems = ordersTestItem?.data;

  const [orders, setOrders] = useState([]);
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          `https://www.giftshare.jp:5000/api/v1/orderlist?role=${user?.role}&email=${user?.email}`
        );
        setOrders(response?.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [user?.role, user?.email]);

  //   const filterOrderItems = orderItems?.filter((item) => {
  //     return orders?.some((i) => i?.orderId === item?.orderId);
  //   });
  //   console.log(filterOrderItems)

  //   const filterGiftBox = giftBox?.filter((item) => {
  //     return filterOrderItems?.some((i) => i?.boxId === item?._id);
  //   });
  //   console.log(filterGiftBox);

  return (
    <div>
      <div className="p-4 flex flex-col gap-y-4">
        {isLoading ? (
          <div className="flex flex-col items-center mt-12 md:mt-32">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
          </div>
        ) : orders?.length === 0 ? (
          <p>There have no order prodcuts</p>
        ) : (
          orders?.map((order) => {
            const filterOrderItems = orderItems?.filter(
              (item) => item?.orderId === order?.orderId
            );
            const filterGiftBox = giftBox?.filter((box) => {
              return filterOrderItems?.some((item) => item?.boxId === box?._id);
            });

            const findOrderTestItems = orderTestItems?.find(
              (item) => item?.orderId === order?.orderId
            );

            const findTestingBox = testingBoxs?.find(
              (box) => box?._id === findOrderTestItems?.testBoxId
            );

            return (
              <div className="flex items-center justify-between bg-slate-100 p-3 shadow">
                <h2 className="font-bold text-sm flex items-center gap-x-1">
                  #ORD-{order?.orderId}{" "}
                  {order?.status === "Delivered" && (
                    <span className="text-green-500">
                      <AiFillCheckCircle />
                    </span>
                  )}
                </h2>
                <div className="flex flex-col lg:flex-row">
                  {filterGiftBox?.map((box) => (
                    <div key={box?._id}>
                      <img
                        src={`https://www.giftshare.jp:5000/${box?.images[0]}`}
                        className={`lg:w-10 lg:h-10 w-6 h-6 rounded-full shadow-lg`}
                      />
                    </div>
                  ))}
                  {findTestingBox && (
                    <div>
                      <img
                        src={`https://www.giftshare.jp:5000/${findTestingBox?.image}`}
                        className={`lg:w-10 lg:h-10 w-6 h-6 rounded-full shadow-lg`}
                      />
                    </div>
                  )}
                </div>

                <p className="text-red-600 font-bold">¥{order?.grandTotal}</p>
                {order?.orderId?.length > 8 ? (
                  <button className="lg:px-3 px-2 lg:py-1 text-sm lg:text-lg rounded bg-[#4E362A] text-white">
                    <Link to="/user/dashboard/order-list" state={order}>
                      View
                    </Link>
                  </button>
                ) : (
                  <button className="lg:px-3 px-2 lg:py-1 text-sm lg:text-lg rounded bg-[#4E362A] text-white">
                    <Link to="/user/dashboard/order-list-test" state={order}>
                      View
                    </Link>
                  </button>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default OrderList;
