import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const TestUpload = () => {
  const [name, setName] = useState("");
  const [history, setHistory] = useState("");
  const [desc, setDesc] = useState("");
  const [loc, setLoc] = useState("");
  const [logo, setLogo] = useState(null);
  const [gallery, setGallery] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("name", name);
    form.append("history", history);
    form.append("desc", desc);
    form.append("loc", loc);
    form.append("logo", logo);

    for (let i = 0; i < gallery?.length; i++) {
      form.append("gallery", gallery[i]);
    }

    try {
      const response = await axios.post(
        "https://www.giftshare.jp:5000/api/v1/multipleupload",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response) {
        toast.success("Successfully added");
      }
    } catch (error) {
      console.error("AxiosError:", error);
      console.error("Response:", error.response);
    }
  };
  return (
    <div className="flex justify-center overflow-auto items-center mt-12">
      <div
        className="card bg-base-100 overflow-auto mb-12 rounded-none"
        style={{ boxShadow: "1px 0px 3px 1px lightblue" }}
      >
        <div className="card-body">
          <div className="text-center">
            <h2 className="text-xl font-bold">Add Brand</h2>
          </div>

          <form onSubmit={handleSubmit} className="text-center">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 my-2  ">
              <div>
                <label>Name:</label>
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  className="border"
                />
              </div>
              <div>
                <label>History</label>
                <textarea
                  rows={4}
                  onChange={(e) => setHistory(e.target.value)}
                  className="border"
                />
              </div>
              <div>
                <label>Description</label>
                <textarea
                  rows={2}
                  onChange={(e) => setDesc(e.target.value)}
                  className="border"
                />
              </div>
              <div>
                <label>Location</label>
                <textarea
                  rows={2}
                  onChange={(e) => setLoc(e.target.value)}
                  className="border"
                />
              </div>
              <div>
                <label>Logo</label>
                <input
                  type="file"
                  onChange={(e) => setLogo(e.target.files[0])}
                />
              </div>
              <div>
                <label>Gallery:</label>
                <input
                  type="file"
                  multiple
                  // accept=".jpg, .jpeg, .png"
                  onChange={(e) => setGallery(e.target.files)}
                />
              </div>
            </div>

            <button type="submit">Upload</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TestUpload;
