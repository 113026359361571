import React, { useState } from "react";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Rating } from "@mui/material";
import { useGetAllBlankBoxQuery } from "../../features/api/blankBoxApi";
import {
  useAddWishlistMutation,
  useGetWishlistsQuery,
  useRemoveWishlistMutation,
} from "../../features/api/wishlistApi";
import { toast } from "react-toastify";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { BsFillBox2HeartFill, BsFillCartCheckFill } from "react-icons/bs";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import {
  EffectCoverflow,
  FreeMode,
  EffectCards,
  Navigation,
  Pagination,
  Keyboard,
} from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../features/cart/cartSlice";
import { addToBlank } from "../../features/cart/blankSlice";

const FestivalGiftBoxDetail = () => {
  const { name, subname } = useParams();
  const location = useLocation();
  const state = location?.state;
  const { box, prev, totalRating } = state;
  const { data } = useGetAllBlankBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allBlankbox = data?.data;

  const filterBlankBox = allBlankbox?.filter(
    (bux) => bux?.festival === box?.festival
  );

  const [selectOpenClose, setSelectOpenClose] = useState(null);
  const [active, setActive] = useState(0);
  const handleOpenClose = (image, index) => {
    setActive(index);
    setSelectOpenClose(image);
  };

  const allWishlist = useGetWishlistsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const findWishlist = allWishlist?.find(
    (list) => list?.productId === box?._id
  );

  const [addNewWishlist] = useAddWishlistMutation();
  const [removeWishlist] = useRemoveWishlistMutation();

  const handleWishlist = async (id) => {
    try {
      const saveData = { productId: id };

      await addNewWishlist(saveData);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await removeWishlist(id);
    } catch (error) {
      toast.error(error);
    }
  };

  // toggle button for desktop
  const [activeDiv, setActiveDiv] = useState(1);
  const toggleDiv = (divIndex) => {
    setActiveDiv(divIndex);
  };

  const dispatch = useDispatch();

  const cart = useSelector((state) => state?.cart?.cart);
  const exist = cart?.find((c) => c?.name === box?.name);
  const [copy, setCopy] = useState({});
  const chooseGiftBox = (box) => {
    setCopy(box);
  };
  const handleAddToCart = () => {
    dispatch(addToCart(box));
    dispatch(addToBlank(copy));
  };

  const [value, setValue] = useState(5);
  return (
    <div className="w-full max-w-[1280px] mx-auto">
      <div className="flex m-6 items-center gap-x-2 text-sm ">
        <Link to="/">festival</Link>
        <span>{">"}</span>
        <Link to={`/festival/${name}`} state={prev}>
          {name}
        </Link>
        <span>{">"}</span>
        <p className="text-orange-800 font-bold">{subname}</p>
      </div>

      <div className="lg:h-[90vh] flex flex-col md:flex-row gap-16 lg:p-6 border-t lg:border-t-0">
        <div className="relative flex flex-col lg:flex-row flex-1 justify-center items-center bg-white shadow ">
          <div className="flex flex-row order-2 lg:order-1 lg:flex-col items-center gap-x-6 bg-white shadow-sm px-4 py-2">
            {box?.images?.map((imgs, index) => (
              <div>
                <button onClick={() => handleOpenClose(imgs, index)}>
                  <img
                    src={`https://www.giftshare.jp:5000/${imgs}`}
                    className={`w-16 h-16 lg:w-20 lg:h-20 ${
                      active === index ? "border border-[#433832]" : "border-0"
                    }`}
                  />
                </button>
              </div>
            ))}
          </div>
          <div className="order-1 lg:order-2">
            {selectOpenClose ? (
              <img
                src={`https://www.giftshare.jp:5000/${selectOpenClose}`}
                className="w-[370px] h-[350px] lg:w-[450px] lg:h-[450px] object-cover"
              />
            ) : (
              <img
                src={`https://www.giftshare.jp:5000/${box?.images[0]}`}
                className="w-[370px] h-[350px] lg:w-[450px] lg:h-[450px] object-cover"
              />
            )}
            {findWishlist ? (
              <button
                onClick={() => handleDelete(findWishlist?._id)}
                className="text-xl text-red-500 absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer"
                style={{ borderRadius: "0 10px 0 20px" }}
              >
                <FavoriteIcon />
              </button>
            ) : (
              <button
                onClick={() => handleWishlist(box?._id)}
                className="text-xl text-white absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer"
                style={{ borderRadius: "0 10px 0 20px" }}
              >
                <FavoriteIcon />
              </button>
            )}
          </div>
        </div>
        <div className="overflow-auto flex-1 flex flex-col gap-y-2 bg-white px-6 lg:px-0">
          <p className=" text-[#433832]">
            <span className="md:text-xl uppercase font-bold">{box?.name}</span>{" "}
            <span>( {box?.festival} )</span>
          </p>
          <div className="flex items-center gap-x-2 ">
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              size="small"
            />
            <p className="text-gray-500">{totalRating} ratings</p>
          </div>
          <hr />

          <p className="font-bold text-xl text-[#6b5549]">JPY {box?.price}</p>
          <div>
            <p className="flex items-center gap-x-1">
              <span className="text-slate-600">
                You can choose a custom blank box
              </span>{" "}
              <span className="text-[#6b5549]">
                <BsFillBox2HeartFill />
              </span>
            </p>

            <div className="flex items-center py-6 overflow-auto justify-center bg-purple-50">
              <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[EffectCards]}
                freeMode={true}
                pagination={true}
                keyboard={true}
                className="blankSwiper"
              >
                {filterBlankBox?.map((box) => {
                  return (
                    <SwiperSlide
                      className="blankSwiper-slide relative cursor-pointer"
                      onClick={() => chooseGiftBox(box)}
                    >
                      <img
                        src={`https://www.giftshare.jp:5000/${box?.image}`}
                        // className="object-cover w-full h-[120px] "
                      />
                      {copy?._id === box?._id && (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <h2 className="text-white bg-black bg-opacity-50 p-1">
                            Selected
                          </h2>
                        </div>
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
          <div className="">
            <div className="flex justify-center border-b space-x-12 pt-6 mb-4">
              <button
                className={`addGoogleFond font-mono ${
                  activeDiv === 1
                    ? "border-b pb-2 border-[#4E362A]"
                    : "hover:border-b pb-2 border-[#4E362A] transition-all duration-200 ease-in-out"
                }`}
                onClick={() => toggleDiv(1)}
              >
                Giftbox Info & Description
              </button>
            </div>
            {activeDiv === 1 && (
              <div>
                <p className="font-light text-sm text-justify lg:px-4">
                  {box?.desc}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="fixed z-50 bottom-2 lg:bottom-4 lg:right-8 right-0 rounded uppercase bg-[#4E362A] text-white w-[180px] py-2 text-center">
        {exist?.name === box?.name ? (
          <button className="bg-[#4E362A] cursor-text text-white font-bold mx-auto gap-x-2 rounded flex items-center">
            Has been added
          </button>
        ) : (
          <button
            onClick={handleAddToCart}
            className="bg-[#4E362A] text-white font-bold mx-auto gap-x-2 rounded flex items-center"
          >
            <BsFillCartCheckFill size={20} /> Add To Cart
          </button>
        )}
      </div>
    </div>
  );
};

export default FestivalGiftBoxDetail;
