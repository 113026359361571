import React from 'react';

const DescriptionMobile = ({children}) => {
    return (
        <div>
            <h5 className="mb-4 text-[#9A583B] font-bold font-serif lg:hidden text-center">
              {children}
            </h5>
        </div>
    );
};

export default DescriptionMobile;