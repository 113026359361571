import { apiSlice } from "./apiSlice";

const pointsHistory = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllEarninghistory: builder.query({
      query: () => ({
        url: "/couponHistory",
      }),
      providesTags: ["couponHistory"],
    }),
    addEarninghistory: builder.mutation({
      query: (newCategory) => ({
        url: "couponHistory",
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["couponHistory"],
    }),
    removeEarninghistory: builder.mutation({
      query: (id) => ({
        url: `/couponHistory/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["couponHistory"],
    }),
    editEarninghistory: builder.mutation({
      query: (editData) => ({
        url: `couponHistory`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["couponHistory"],
    }),
  }),
});

export const {
useGetAllEarninghistoryQuery,
useAddEarninghistoryMutation,
useRemoveEarninghistoryMutation,
useEditEarninghistoryMutation
} = pointsHistory;
