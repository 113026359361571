import { apiSlice } from "./apiSlice";

const couponconfigApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCouponConfigs: builder.query({
      query: () => ({
        url: "/affiliate-coupon-config",
      }),
      providesTags: ["affiliate-coupon-config"],
    }),
    addCouponConfig: builder.mutation({
      query: (newdata) => ({
        url: "affiliate-coupon-config",
        method: "POST",
        body: newdata,
      }),
      invalidatesTags: ["affiliate-coupon-config"],
    }),
    removeCouponConfig: builder.mutation({
      query: (id) => ({
        url: `/affiliate-coupon-config/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["affiliate-coupon-config"],
    }),
    editCouponConfig: builder.mutation({
      query: ({ id, ...editData }) => ({
        url: `/affiliate-coupon-config/${id}`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["affiliate-coupon-config"],
    }),
  }),
});

export const {
  useGetCouponConfigsQuery,
  useAddCouponConfigMutation,
  useRemoveCouponConfigMutation,
  useEditCouponConfigMutation,
} = couponconfigApi;
