import React, { useEffect, useRef, useState } from "react";
import Container from "../Container";
import { useLocation } from "react-router";
import { useGetAllGiftBoxQuery } from "../../features/api/GiftBoxApi";
import { Link } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useGetAllRatingQuery } from "../../features/api/ratingsApi";
import {
  useAddWishlistMutation,
  useGetWishlistsQuery,
  useRemoveWishlistMutation,
} from "../../features/api/wishlistApi";
import { toast } from "react-toastify";
import { useGetFestivalsQuery } from "../../features/api/festivalApi";

const GiftBoxFilterSinglePage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const location = useLocation();
  const props = location?.state;

  const [defaultBoxes, setDefaultBoxes] = useState(20);

  const festivals = useGetFestivalsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  const festivalName = festivals?.find((fes) => fes?._id === props);

  const { data, isLoading } = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const giftBox = data?.data;

  const filterGiftBox = giftBox?.filter((box) => box?.festival === props);

  const ratings = useGetAllRatingQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const allWishlist = useGetWishlistsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  const [addNewWishlist] = useAddWishlistMutation();
  const [removeWishlist] = useRemoveWishlistMutation();
  const handleWishlist = async (id) => {
    try {
      const saveData = { productId: id };

      await addNewWishlist(saveData);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await removeWishlist(id);
    } catch (error) {
      toast.error(error);
    }
  };

  const giftBoxFunction = (boxes) => {
    return boxes?.slice(0, defaultBoxes)?.map((box) => {
      const getRating = ratings?.filter((rat) => rat?.pro_id === box?.name);
      const sumOfRating = getRating?.reduce(
        (sum, rat) => sum + +rat?.rating,
        0
      );
      const totalRating = getRating?.length;

      const findWishlist = allWishlist?.find(
        (list) => list?.productId === box?._id
      );
      return (
        <div className="relative rounded-2xl border " key={box?._id}>
          <Link
            to={`/giftbox-items/box/${box?._id}/${box?.name}`}
            state={totalRating}
          >
            <div className="">
              <img
                src={`https://www.giftshare.jp:5000/${box?.images[0]}`}
                className={`w-full h-[150px] md:h-[250px] rounded-t-2xl`}
                alt={box?.name}
              />

              <div className="p-4 flex flex-col gap-y-2">
                <h6 className="card-title text-sm text-gray-400">
                  {box?.name}
                </h6>
                <h4 className="text-[#433832] font-serif text-lg">
                  JPY {box?.price}
                </h4>
                {getRating && (
                  <p className="flex items-center">
                    {sumOfRating}{" "}
                    <span className="text-yellow-500 text-xs">
                      {/* <AiFillStar /> */}
                    </span>
                    <span className="ml-1">({getRating?.length})</span>
                  </p>
                )}
              </div>
            </div>
          </Link>
          <button
            onClick={
              findWishlist
                ? () => handleDelete(findWishlist?._id)
                : () => handleWishlist(box?._id)
            }
            className={`text-xl absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer
          ${findWishlist ? " text-red-500 " : " text-white "}`}
            style={{ borderRadius: "0 10px 0 20px" }}
          >
            <FavoriteIcon />
          </button>
        </div>
      );
    });
  };

  return (
    <Container>
      <div className="my-6 min-h-[90vh]">
        <h2 className="text-center mb-8 font-bold text-[#433832] uppercase text-sm md:text-xl">
          {props ? festivalName?.name + "箱" : "All Giftboxes"}
        </h2>
        {isLoading ? (
          <div className="w-fll h-screen flex flex-col items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-10">
            {props ? giftBoxFunction(filterGiftBox) : giftBoxFunction(giftBox)}
          </div>
        )}
        <button
          onClick={() => setDefaultBoxes(defaultBoxes + 20)}
          className={`text-orange-950 mt-4 mx-auto block`}
        >
          See More...
        </button>
      </div>
    </Container>
  );
};

export default GiftBoxFilterSinglePage;
