import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const UpdateBrand = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [history, setHistory] = useState("");
  const [desc, setDesc] = useState("");
  const [loc, setLoc] = useState("");
  const [logo, setLogo] = useState(null);
  const [gallery, setGallery] = useState([]);
  const [images, setImages] = useState([]);

  const [brand, setBrand] = useState({});
  useEffect(() => {
    const url = `https://www.giftshare.jp:5000/api/v1/brand/${id}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => setBrand(data?.data));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // update brand with id
  const updateBrandById = async () => {
    const form = new FormData();
    form.append("name", name ? name : brand?.name);
    form.append("history", history ? history : brand?.history);
    form.append("desc", desc ? desc : brand?.desc);
    form.append("loc", loc ? loc : brand?.loc);
    form.append("logo", logo ? logo : brand?.logo);

    if (gallery && gallery?.length > 0) {
      for (let i = 0; i < gallery?.length; i++) {
        form.append("gallery", gallery[i]);
      }
    }

    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        form.append("images", images[i]);
      }
    }
    try {
      const response = await axios.patch(
        `https://www.giftshare.jp:5000/api/v1/brand/${id}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response) {
        navigate("/dashboard/brandlist");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };

  const [term, setTerm] = useState(false);

  const handleTerm = () => {
    setTerm(!term);
  };
  return (
    <div className="flex justify-center overflow-auto items-center p-4">
      <div
        className="card bg-base-100 overflow-auto mb-12 rounded-none w-full shadow"
      >
        <div className="px-2 py-4">
          <div className="text-center">
            <h2 className="text-xl font-bold">Update Brand</h2>
          </div>
          {
            <form onSubmit={handleSubmit} className="text-center">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 my-2  ">
                <div>
                  <label>Name</label>
                  <input
                    type="text"
                    defaultValue={brand?.name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none mb-2 mx-auto"
                  />
                </div>
                <div>
                  <label>History</label>
                  <textarea
                    onChange={(e) => setHistory(e.target.value)}
                    rows="4"
                    defaultValue={brand?.history}
                    className="block input-bordered border mx-auto mb-2 w-full p-1 text-sm rounded-none focus:border-none"
                    placeholder="Description..."
                  ></textarea>
                </div>
                <div>
                  <label>Short Description</label>
                  <textarea
                    onChange={(e) => setDesc(e.target.value)}
                    rows="4"
                    defaultValue={brand?.desc}
                    className="block input-bordered border mx-auto mb-2 w-full p-1 text-sm rounded-none focus:border-none"
                    placeholder="Description..."
                  ></textarea>
                </div>
                <div>
                  <label>Location</label>
                  <textarea
                    onChange={(e) => setLoc(e.target.value)}
                    rows="4"
                    defaultValue={brand?.loc}
                    className="block input-bordered border mx-auto mb-2 w-full p-1 text-sm rounded-none focus:border-none"
                    placeholder="Description..."
                  ></textarea>
                </div>
                <div>
                  <label>Logo</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setLogo(e.target.files[0])}
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none"
                  />
                </div>
                <div>
                  <label>Gallery (Choose Multiple)</label>
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={(e) => setGallery(e.target.files)}
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none"
                  />
                </div>
              </div>
              <div className="mt-4 lg:mt-8">
                <label>Choose 2 file (Open & Close) </label>
                <input
                  type="file"
                  multiple
                  onChange={(e) => setImages(e.target.files)}
                  className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none mb-2 mx-auto"
                />
              </div>

              <div className="flex justify-around pt-6">
                <div className="flex flex-col gap-y-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox text-indigo-600 h-5 w-5"
                      checked={term}
                      onChange={handleTerm}
                    />
                    <span className="ml-2 text-gray-900">
                      Do you want to update?
                    </span>
                  </label>
                  <button
                    onClick={updateBrandById}
                    type="submit"
                    className={`mt-4 px-4 py-2 rounded ${
                      term
                        ? "bg-[#5e2006] text-white cursor-pointer"
                        : "bg-gray-400 text-gray-700 cursor-not-allowed"
                    }`}
                    disabled={!term}
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          }
        </div>
      </div>
    </div>
  );
};

export default UpdateBrand;
