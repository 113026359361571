import { RouterProvider } from "react-router-dom";
import "./App.css";
import routes from "./routes/routes";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <div className="">
        <RouterProvider router={routes} />
        {/* <ToastContainer position="top-center" autoClose={2000} /> */}
        <ToastContainer
          position="top-center"
          autoClose={2000}
          // hideProgressBar
          theme="dark"
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </HelmetProvider>
  );
}

export default App;
