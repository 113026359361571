import React, { useEffect, useRef, useState } from "react";
import MetaDecoratorVipAffiliateCouponSwiper from "./MetaDecorator/MetaDecoratorVipAffiliateCouponSwiper";
import { FaFacebook, FaShareAlt } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { useGetExistingAffiliateCouponQuery } from "../../features/api/couponlistApi";

const VipAffiliateCouponSwiper = ({ userId, findUserAffiliateCoupon }) => {
  const [vipAffiliateCouponSwiperCard, setVipAffiliateCouponSwiperCard] =
    useState({});
  const [couponUrl, setCouponUrl] = useState("");
  // ? existingCoupon => this is for validating if valid coupon is already present for user
  const { data: existingCoupon, isLoading: existingCouponLoading } =
    useGetExistingAffiliateCouponQuery(userId, {
      refetchOnMountOrArgChange: true,
    });
  // !existingCouponLoading &&
  //   console.log("Existing Coupon from Swiper: ", existingCoupon);
  useEffect(() => {
    if (!existingCouponLoading) {
      setVipAffiliateCouponSwiperCard(existingCoupon?.data);
      setCouponUrl(existingCoupon?.data?.coupon);
    }
  }, [existingCouponLoading, existingCoupon?.data]);

  const slideRef = useRef(null);

  const handleDownloadImage = async () => {
    try {
      // Fetch the image from the API URL
      const response = await fetch(`https://www.giftshare.jp:5000/${couponUrl}`);
      const blob = await response.blob();

      // Create a data URL from the image blob
      const dataUrl = URL.createObjectURL(blob);

      // Create a canvas element to convert the image to canvas
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const img = new Image();
      img.src = dataUrl;

      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0, img.width, img.height);

        // Create a link to download the image
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "coupon.png";
        link.click();

        // Clean up the URL object to release resources
        URL.revokeObjectURL(dataUrl);
      };
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };
  const handlePopupImagePreview = () => {
    console.log("Loggedin user ID: ", userId);
  };

  const shareUrl = `https://giftshare.jp/?coupon=${encodeURIComponent(
    findUserAffiliateCoupon?.code
  )}`;

  const quote =
    "This is a test share from GiftShare app. Be sure to Check out the store!!!";

  const handleFacebook = (url, qt) => {
    // console.log("Sharing on facebook started");
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}&quote=${encodeURIComponent(qt)}`;

    // Adjust width and height based on your preference
    const width = 600;
    const height = 400;

    // Calculate the center of the screen for positioning
    const left = (window.innerWidth - width) / 2 + window.screenX;
    const top = (window.innerHeight - height) / 2 + window.screenY;

    // Open a popup window with the Facebook share URL
    window.open(
      facebookShareUrl,
      "FacebookSharePopup",
      `width=${width},height=${height},left=${left},top=${top}`
    );
    // console.log(findUserAffiliateCoupon);
    // console.log(shareUrl, "Shared on facebook successfully");
  };

  const shareWhatsapp = (url, qt) => {
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      qt
    )}%20${encodeURIComponent(url)}`;

    // Adjust width and height based on your preference
    const width = 900;
    const height = 600;

    // Calculate the center of the screen for positioning
    const left = (window.innerWidth - width) / 2 + window.screenX;
    const top = (window.innerHeight - height) / 2 + window.screenY;

    // Open a popup window with the WhatsApp share URL
    window.open(
      whatsappShareUrl,
      "WhatsAppSharePopup",
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  const shareTwitter = (url, qt) => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      url
    )}&text=${encodeURIComponent(qt)}`;

    const width = 900;
    const height = 600;

    const left = (window.innerWidth - width) / 2 + window.screenX;
    const top = (window.innerHeight - height) / 2 + window.screenY;

    // Open a popup window with the Twitter share URL
    const twitterWindow = window.open(
      twitterShareUrl,
      "TwitterSharePopup",
      `width=${width},height=${height},left=${left},top=${top}`
    );

    // Check if the window is closed every second
    const checkTwitterWindow = setInterval(() => {
      if (twitterWindow.closed) {
        clearInterval(checkTwitterWindow);
      }
    }, 1000);

    // Attempt to close the popup window after 5 seconds (adjust as needed)
    setTimeout(() => {
      if (!twitterWindow.closed) {
        twitterWindow.close();
      }
    }, 5000);
  };

  return (
    <>
      <div className="block mx-auto">
        {!existingCouponLoading && vipAffiliateCouponSwiperCard?.coupon && (
          <MetaDecoratorVipAffiliateCouponSwiper
            shareUrl={shareUrl}
            couponImageUrl={couponUrl}
          />
        )}

        <div className="flex justify-center items-center gap-3">
          <div className="bg-transparent">
            {findUserAffiliateCoupon && (
              <div ref={slideRef} className="relative ">
                {couponUrl !== "" && (
                  <img
                    // src={`https://www.giftshare.jp:5000/${encodeURIComponent(
                    //   couponUrl
                    // )}`}
                    src={`https://www.giftshare.jp:5000/${couponUrl}`}
                    alt="coupon swiper"
                    className="w-[250px] cursor-pointer"
                    onClick={handleDownloadImage}
                  />
                )}
              </div>
            )}
            <p className={`text-center text-xs italic text-slate-400 mt-2`}>
              Download this coupon card by click!!!
            </p>
          </div>
          {/* The button to open modal */}
          {!existingCouponLoading && (
            <label
              htmlFor="my_modal_6"
              className="bg-[#693921] p-3 flex justify-center items-center rounded-full  cursor-pointer  transition-all duration-300 ease-linear  hover:shadow-2xl"
            >
              <FaShareAlt
                className="text-2xl -ml-[2px] fill-[#DBC178]"
                onClick={() => {
                  handlePopupImagePreview();
                }}
              />
            </label>
          )}
        </div>

        {/* Put this part before </body> tag */}
        {!existingCouponLoading && (
          <>
            <input type="checkbox" id="my_modal_6" className="modal-toggle" />
            <div className="modal">
              <div className="modal-box bg-[#693921] max-w-xs">
                <h3 className="mb-3 font-bold text-lg text-white text-center">
                  Share on your social network !
                </h3>

                {/*           {couponUrl !== "" && (
                  <img
                    // src={`https://www.giftshare.jp:5000/${encodeURIComponent(
                    //   couponUrl
                    // )}`}
                    src={`https://www.giftshare.jp:5000/${couponUrl}`}
                    // src={`https://www.giftshare.jp:5000/${couponUrl}`}
                    alt="coupon swiper"
                    className="w-[250px] mx-auto"
                  />
                )} */}
                <div className="flex gap-3 justify-center items-center">
                  <div className="bg-white w-max h-max mt-4 rounded-full box-border">
                    <FaFacebook
                      className="text-5xl m-[2px] fill-[#0866FF] border-[#0866FF] cursor-pointer"
                      onClick={() => {
                        handleFacebook(shareUrl, quote);
                      }}
                    />
                  </div>
                  <div className="bg-white w-max h-max mt-4 rounded-full">
                    <IoLogoWhatsapp
                      className="text-5xl fill-[#2BB741] border-[#2BB741] cursor-pointer"
                      onClick={() => {
                        shareWhatsapp(shareUrl, quote);
                      }}
                    />
                  </div>{" "}
                  <div className="bg-white w-max  h-max mt-4 rounded-md">
                    <FaSquareXTwitter
                      className=" text-5xl fill-black border-[#2BB741] cursor-pointer"
                      onClick={() => {
                        shareTwitter(shareUrl, quote);
                      }}
                    />
                  </div>
                </div>

                <div className="modal-action">
                  <label
                    htmlFor="my_modal_6"
                    className="py-2 px-4 bg-[#DBC178] text-[#693921] hover:bg-[#372219] text-base font-bold hover:text-[#DBC178] border-0 rounded-full transition-all ease-in-out duration-500"
                  >
                    X
                  </label>
                </div>
              </div>
            </div>
          </>
        )}

        <input type="checkbox" id="my_modal_6" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box bg-[#693921] max-w-xs">
            <h3 className="mb-3 font-bold text-lg text-white text-center">
              Share on your social network !
            </h3>

            <>
              <img
                src={decodeURIComponent(
                  `https://www.giftshare.jp:5000/${encodeURIComponent(
                    vipAffiliateCouponSwiperCard?.coupon
                  )}`
                )}
                alt="coupon swiper"
                className="rounded-2xl"
              />
              <div className="flex gap-3 justify-center items-center">
                <div className="bg-white w-max h-max mt-4 rounded-full box-border">
                  <FaFacebook
                    className="text-5xl m-[2px] fill-[#0866FF] border-[#0866FF] cursor-pointer"
                    onClick={() => {
                      handleFacebook(shareUrl, quote);
                    }}
                  />
                </div>
                <div className="bg-white w-max h-max mt-4 rounded-full">
                  <IoLogoWhatsapp
                    className="text-5xl fill-[#2BB741] border-[#2BB741] cursor-pointer"
                    onClick={() => {
                      shareWhatsapp(shareUrl, quote);
                    }}
                  />
                </div>{" "}
                <div className="bg-white w-max  h-max mt-4 rounded-md">
                  <FaSquareXTwitter
                    className=" text-5xl fill-black border-[#2BB741] cursor-pointer"
                    onClick={() => {
                      shareTwitter(shareUrl, quote);
                    }}
                  />
                </div>
              </div>
            </>

            <div className="modal-action">
              <label
                htmlFor="my_modal_6"
                className="py-2 px-4 bg-[#DBC178] text-[#693921] hover:bg-[#372219] text-base font-bold hover:text-[#DBC178] border-0 rounded-full transition-all ease-in-out duration-500"
              >
                X
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VipAffiliateCouponSwiper;
