import React from "react";
import { useGetAllUserQuery } from "../../../features/api/loginApi";
import { useGetAllEarninghistoryQuery } from "../../../features/api/pointsHistory";

const AllCouponHistory = () => {
  const { data } = useGetAllUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const users = data?.data;

  const { data:couponHistory } = useGetAllEarninghistoryQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const couponHistorys = couponHistory?.data;


  function formatDate(dateString) {
    const bangladeshiDate = new Date(dateString).toLocaleString("en-BD", {
      timeZone: "Asia/Dhaka",
    });
    // const japaneseDate = new Date(dateString).toLocaleString("ja-JP", {
    //   timeZone: "Asia/Tokyo",
    // });
    return bangladeshiDate;
  }
  return (
    <div className="p-8">
      <div className="flex justify-between mb-4">
        {" "}
        <h1 className="text-xl font-bold">All Points Transaction</h1>
      </div>
      <div>
        <table className="table w-full mt-2">
          {/* head */}
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>Date & Time</th>
              <th>Email</th>
              <th>Description</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            {couponHistorys?.map((history, index) => {
              const user = users?.find((user) => user?._id === history?.userId);
              return (
                <tr key={history?._id}>
                  <th>{index + 1}</th>
                  <td>{formatDate(history?.createdAt)}</td>
                  <td>{user?.email}</td>
                  <td>{history?.desc}</td>
                  <td>{history?.coin}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllCouponHistory;
