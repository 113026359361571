import React, { useState } from "react";
import { AiFillBackward } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAddFaqMutation } from "../../../../features/api/faqApi";

const FaqCreate = () => {
  const [question, setTitle] = useState("");
  const [answer, setText] = useState("");

  const navigate = useNavigate();
  const [newFaq] = useAddFaqMutation();
  const handleSubmitProduct = async () => {
    try {
      const newFaqData = { question, answer };

      const response = await newFaq(newFaqData);

      if (response) {
        toast.success("Successfully added");
        navigate("/dashboard/faq");
      }
    } catch (error) {
      console.error("Error creating product:", error.response.data);
      toast.error(error.response?.data?.error);
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center p-4">
        <h1 className="text-xl font-bold">Create FAQ</h1>
        <div className="flex items-center gap-x-4">
          <Link
            to="/dashboard/faq"
            className="bg-[#4E362A] px-2 py-1 rounded text-white flex items-center gap-x-1"
          >
            <AiFillBackward /> Back{" "}
          </Link>
        </div>
      </div>
      <div className="p-4 m-4 border">
        <form className=" grid grid-cols-1 gap-6">
          <div className="flex flex-col gap-1">
            <label className="font-bold">Quetion</label>
            <input
              type="text"
              onChange={(e) => setTitle(e.target.value)}
              className="border h-8 pl-1 focus:outline-none"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-bold">answer</label>

            <textarea
            rows={4}
              onChange={(e) => setText(e.target.value)}
              className="border pl-1 focus:outline-none"
            />
          </div>
        </form>
        <button
          onClick={handleSubmitProduct}
          className="bg-blue-500 px-4 py-1 text-white font-bold block mx-auto mt-4"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default FaqCreate;
