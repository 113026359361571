import React, { useEffect } from "react";
import Container from "./Container";
import { useGetAllGiftBoxQuery } from "../features/api/GiftBoxApi";
import { AiFillStar } from "react-icons/ai";
import { Link } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { toast } from "react-toastify";
import {
  useGetWishlistsQuery,
  useRemoveWishlistMutation,
} from "../features/api/wishlistApi";
import { useGetAllRatingQuery } from "../features/api/ratingsApi";

const WishlistItems = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const { data: wishlist, isLoading: listIsLoading } = useGetWishlistsQuery(
    null,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const allWishlist = wishlist?.data;
  const [removeWishlist] = useRemoveWishlistMutation();

  const { data } = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allGiftBox = data?.data;
  const filterGiftBoxByWishlistId = allGiftBox?.filter((giftBox) => {
    return allWishlist?.some((list) => list?.productId === giftBox?._id);
  });
  const { data: rating } = useGetAllRatingQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const ratings = rating?.rating;

  const handleDelete = async (id) => {
    try {
      await removeWishlist(id);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Container>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-10 my-8 min-h-[70vh]">
        {filterGiftBoxByWishlistId?.length === 0 ? (
          <p className="text-red-500 mt-12">
            There are no wishlist items{" "}
            <Link to="/" className="text-sm text-blue-500 underline ml-2">
              Go Back
            </Link>
          </p>
        ) : (
          filterGiftBoxByWishlistId?.map((box) => {
            const getRating = ratings?.filter(
              (rat) => rat?.pro_id === box?.name
            );
            const sumOfRating = getRating?.reduce(
              (sum, rat) => sum + +rat?.rating,
              0
            );
            const totalRating = getRating?.length;
            const passState = { box, totalRating };

            const findWishlist = allWishlist?.find(
              (list) => list?.productId === box?._id
            );
            return (
              <div key={box?._id}>
                <div className="relative rounded-2xl border ">
                  <Link
                    to={`/giftbox-items/box/${box?._id}/${box?.name}`}
                    state={totalRating}
                  >
                    <div className="">
                      <img
                        src={`https://www.giftshare.jp:5000/${box?.images[0]}`}
                        className={`w-full h-[200px] lg:h-[272px] rounded-t-2xl`}
                        alt={box?.name}
                      />

                      <div className="p-4 flex flex-col gap-y-2">
                        <h6 className="card-title text-sm text-gray-400">
                          {box?.name}
                        </h6>
                        <h4 className="text-[#433832] font-serif text-lg">
                          JPY {box?.price}
                        </h4>
                        {getRating && (
                          <p className="flex items-center">
                            {sumOfRating}{" "}
                            <span className="text-yellow-500 text-xs">
                              <AiFillStar />
                            </span>
                            <span className="ml-1">({getRating?.length})</span>
                          </p>
                        )}
                      </div>
                    </div>
                  </Link>
                  <button
                    onClick={() => handleDelete(findWishlist?._id)}
                    className="text-xl text-red-500 absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer"
                    style={{ borderRadius: "0 10px 0 20px" }}
                  >
                    <FavoriteIcon />
                  </button>
                </div>
              </div>
            );
          })
        )}
      </div>
    </Container>
  );
};

export default WishlistItems;
