import { apiSlice } from "./apiSlice";

const newsletterApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllNewsletter: builder.query({
      query: () => ({
        url: "/newsletter",
      }),
      providesTags: ["newsletter"],
    }),
    addNewsletter: builder.mutation({
      query: (newCategory) => ({
        url: "newsletter",
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["newsletter"],
    }),
    removeNewsletter: builder.mutation({
      query: (id) => ({
        url: `/newsletter/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["newsletter"],
    }),
    editNewsletter: builder.mutation({
      query: (editData) => ({
        url: `newsletter`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["newsletter"],
    }),
  }),
});

export const {
  useGetAllNewsletterQuery,
  useAddNewsletterMutation,
  useRemoveNewsletterMutation,
  useEditNewsletterMutation,
} = newsletterApi;
