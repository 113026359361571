import React from "react";
import Container from "../../Components/Container";
import { useGetAlltestingboxQuery } from "../../features/api/testingBox";
import { useGetAllsubscribeQuery } from "../../features/api/subscribeApi";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetUserQuery } from "../../features/api/loginApi";
import { useGetAllBrandsQuery } from "../../features/api/brandApi";

const Subscription = () => {
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const user = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  const email = user?.email;

  const { data, isLoading } = useGetAllsubscribeQuery(email, {
    refetchOnMountOrArgChange: true,
  });
  const subscribers = data?.data;

  const testingBoxs = useGetAlltestingboxQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const brands = useGetAllBrandsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  console.log(brands);

  const filterTestingBox = testingBoxs?.filter((box) => {
    return subscribers?.some((sub) => sub?.testBoxId === box?._id);
  });
  const findTestingBox = filterTestingBox?.find((box) => box);

  const brandName = brands?.find((b) => b?._id === findTestingBox?.brand);

  return (
    <Container>
      <h2 className="text-center text-2xl font-bold mt-6 text-yellow-900">
        Your Monthly Subscription Items
        {/* <p>Generated Order ID: {orderId}</p> */}
      </h2>
      {isLoading ? (
        <Stack>
          <CircularProgress variant="determinate" value={progress} />
        </Stack>
      ) : subscribers?.length === 0 ? (
        <p>There is no subscribe offer</p>
      ) : (
        <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 gap-8">
          {subscribers?.map((sub) => (
            <div className="rounded bg-slate-50 shadow-lg" key={sub?._id}>
              <div className="card-body items-center text-center ">
                <h2 className="card-title text-xl font-serif">
                  ¥{findTestingBox?.price}/{sub?.name}
                </h2>
                <div className="flex flex-col gap-2 py-4 text-center">
                  <img
                    src={`https://www.giftshare.jp:5000/${findTestingBox?.image}`}
                    className="w-[200px] h-[180px]"
                  />
                  <h2 className=" font-light">{findTestingBox?.name}</h2>
                  <h2>Brand: {brandName?.name}</h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Container>
  );
};

export default Subscription;
