import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";
import { toast } from "react-toastify";
import { useAddRegionMutation } from "../../../features/api/regionApi";

const NewAddRegion = () => {
  const [region, setRegion] = useState("");
  const [district, setDistricts] = useState([]);

  const handleDistrictChange = (index, value) => {
    console.log(index, value);
    const updatedDistrict = [...district];
    updatedDistrict[index] = value;
    setDistricts(updatedDistrict);
  };

  const handleAddDistrict = () => {
    const newDistrict = {};
    setDistricts([...district, newDistrict]);
  };

  const handleRemoveDistrict = (index) => {
    const updatedDistrcit = [...district];
    updatedDistrcit.splice(index, 1);
    setDistricts(updatedDistrcit);
  };

  const [addRegionMutation] = useAddRegionMutation();

  const handleSubmitDist = async (e) => {
    e.preventDefault();

    const newDistrictData = {
      region,
      district,
    };
    try {
      const response = await addRegionMutation(newDistrictData);

      setRegion("");
      setDistricts([]);

      if (response) {
        toast.success("Successfully Added!!");
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="p-8">
        {/* <h1 className="mb-4 text-blue-500 font-bold">Add Region</h1> */}
        <div className="flex justify-center overflow-auto items-center mt-12">
          <div
            className="card bg-base-100 overflow-auto mb-12 rounded-none"
            style={{ boxShadow: "1px 0px 3px 1px lightblue" }}
          >
            <div className="card-body">
              <div className="text-center">
                <h2 className="text-xl font-bold">Add Region And District</h2>
              </div>
              <form onSubmit={handleSubmitDist}>
                <div className="grid grid-cols-1  my-2">
                  <input
                    type="text"
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                    placeholder="Region"
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs"
                  />
                </div>

                <div>
                  <h1 className="text-xl font-bold my-2">District</h1>
                  {district.map((district, index) => (
                    <div
                      className="flex flex-col items-center gap-2"
                      key={index}
                    >
                      <div className="flex items-center">
                        <input
                          type="text"
                          value={district?.name}
                          onChange={(e) =>
                            handleDistrictChange(index, e.target.value)
                          }
                          placeholder="District"
                          className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs mb-1"
                        />

                        <button
                          className="flex"
                          type="button"
                          onClick={() => handleRemoveDistrict(index)}
                        >
                          <div className=" ml-2 text-red-600">
                            <FaTrash />
                          </div>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-around pt-6">
                  <button
                    type="button"
                    onClick={handleAddDistrict}
                    className="bg-green-500 px-2 py-1 font-bold text-white"
                  >
                    Add
                  </button>

                  <button
                    type="submit"
                    className="bg-blue-500 px-2 py-1 font-bold text-white "
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAddRegion;
