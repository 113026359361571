import { apiSlice } from "./apiSlice";

const sliderApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSliers: builder.query({
      query: () => "slider",
      providesTags: ["slider"],
    }),
    addSlider: builder.mutation({
      query: (newData) => ({
        url: "slider",
        method: "POST",
        body: newData,
      }),
      invalidatesTags: ["slider"],
    }),
    removeSlider: builder.mutation({
      query: (id) => ({
        url: `slider/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["slider"],
    }),
    editSlider: builder.mutation({
      query: ({ id, ...editData }) => ({
        url: `slider/${id}`,
        method: "PATCH",
        body: editData,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["slider"],
    }),
  }),
});

export const {
  useGetSliersQuery,
  useAddSliderMutation,
  useRemoveSliderMutation,
  useEditSliderMutation,
} = sliderApi;
