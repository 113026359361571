import { apiSlice } from "./apiSlice";

const subspackageApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubspackage: builder.query({
      query: () => ({
        url: "/package",
      }),
      providesTags: ["package"],
    }),
    addSubspackage: builder.mutation({
      query: (newCategory) => ({
        url: "package",
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["package"],
    }),
    removeSubspackage: builder.mutation({
      query: (id) => ({
        url: `/package/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["package"],
    }),
    editSubspackage: builder.mutation({
      query: (editData) => ({
        url: `package`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["package"],
    }),
  }),
});

export const {
  useGetSubspackageQuery,
  useAddSubspackageMutation,
  useRemoveSubspackageMutation,
  useEditSubspackageMutation,
} = subspackageApi;
