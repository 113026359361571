import React, { useState } from "react";
import { useGetAllRegionQuery } from "../../features/api/regionApi";
import { useGetAllTrendGiftQuery } from "../../features/api/trendingGift";
import { useGetAllBrandsQuery } from "../../features/api/brandApi";
import { Typewriter } from "react-simple-typewriter";

// react swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "./RegionDistrict.css";
import {
  EffectCoverflow,
  FreeMode,
  Navigation,
  Pagination,
  Keyboard,
} from "swiper";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useGetAllGiftBoxQuery } from "../../features/api/GiftBoxApi";
import { useGetRegionDefaultsQuery } from "../../features/api/regiondefaultApi";

import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";
import jaTranslator from "../../jpTranslator/ja.json";
import enTranslator from "../../enTranslator/en.json";
import top from "../../assets/images/top.png";
import bottom from "../../assets/images/bottom.png";

i18n.use(initReactI18next).init({
  resources: {
    ja: {
      translation: jaTranslator,
    },
    en: {
      translation: enTranslator,
    },
  },
  lng: "ja",
  fallbackLng: "ja",
  interpolation: {
    escapeValue: false,
  },
});

const RegionsDistrict = () => {
  const { t } = useTranslation();

  const {
    data: regionData,
    isLoading: regionLoading,
    error: regionError,
  } = useGetAllRegionQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const regions = regionData?.data;

  // const { data: regionDefault } = useGetRegionDefaultsQuery(null, {
  //   refetchOnMountOrArgChange: true,
  // });
  // const byDefault = regionDefault?.data;

  const byDefault = useGetRegionDefaultsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const getDefaultRegion = regions?.find(
    (region) => region?.region === byDefault?.[0]?.region
  );

  const { data: trend, isLoading } = useGetAllTrendGiftQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const trendGift = trend?.data;

  const allBrands = useGetAllBrandsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const { data } = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allGiftBox = data?.data;

  // onclick handler of region function
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [passRegion, setPassRegion] = useState(null);
  const [active, setActive] = useState(null);
  const handleRegion = (r, i) => {
    setPassRegion(r?.region);
    setSelectedRegion(r);
    setActive(i);
  };

  // onclick handler of district button
  const [disActive, setDisActive] = useState(null);
  const [passDistrict, setPassDistrict] = useState(null);

  let selectedTrending;
  if (!passDistrict) {
    selectedTrending = trendGift?.filter(
      (trend) => trend?.district === byDefault?.[0]?.district
    );
  } else {
    selectedTrending = trendGift?.filter(
      (trend) => trend?.district === passDistrict
    );
  }

  const handleDistrictWiseTrendingGift = (d, index) => {
    setPassDistrict(d);
    setDisActive(index);
  };

  const trendingBrands = allBrands?.filter((item) => {
    return selectedTrending?.some((trending) => trending?.brand === item?._id);
  });

  // desktop version
  const [startIndexDesk, setStartIndexDesk] = useState(0);

  const handlePrevClickDesk = () => {
    setStartIndexDesk(Math.max(0, startIndexDesk - 15));
  };

  const handleNextClickDesk = () => {
    setStartIndexDesk(
      Math.min(selectedRegion?.district?.length - 15, startIndexDesk + 15)
    );
  };

  // mobile version
  const [startIndex, setStartIndex] = useState(0);

  const handlePrevClick = () => {
    setStartIndex(Math.max(0, startIndex - 9));
  };

  const handleNextClick = () => {
    setStartIndex(
      Math.min(selectedRegion?.district?.length - 9, startIndex + 9)
    );
  };

  if (regionLoading || isLoading) {
    return (
      <div className="flex flex-col items-center mt-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (regionError) {
    return (
      <div className="text-center text-red-500 font-bold">Data not found</div>
    );
  }
  return (
    <div className="w-full max-w-[1280px] mx-auto px-0 md:px-6">
      <div className="mt-6 mb-2 md:mt-8">
        <div className="flex justify-center">
          <img src={top} className="object-cover" alt="cover" />
        </div>
        {/* <div className="font-fmly text-center text-[#433832]">
          <h4 className="md:text-2xl font-bold">{t("regH1")}</h4>
          <h3
          className="text-xl md:text-3xl font-sans my-3 md:my-4 uppercase"
          style={{ letterSpacing: "2px" }}
          >
          {t("regH2")}
          </h3>
          <h1 className="text-3xl md:text-5xl mb-6 md:mb-8">
          {" "}
          <span className="text-[#4E362A] mr-">
          <Typewriter
          words={[t("regH31"), t("regH32"), t("regH33")]}
          loop={Infinity}
          cursor
          cursorStyle=""
          typeSpeed={200}
          deleteSpeed={50}
          delaySpeed={20000}
          />
          </span>
          <span className="text-[#815742]"> {t("regH3")}</span>
          </h1>
        </div> */}

        <div className="font-fmly text-center flex justify-center md:pt-10 pt-6 lg:pb-6 pb-8 px-2 lg:px-0 bg-purple-50">
          <img src={bottom} className="object-cover" alt="cover" />
          {/* <p className="font-mono md:text-xl">{t("regH4-1")}</p>
          <p className="text-xl md:text-2xl pt-2 md:pt-4 md:px-20">
            {t("regH5")}
          </p> */}
        </div>

        {/*Region for Desktop  */}
        <div className=" hidden lg:block bg-purple-50 pb-6">
          <Swiper
            loop={true}
            navigation={true}
            keyboard={true}
            slidesPerView={6}
            spaceBetween={0}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Navigation, Keyboard]}
            className=" rswiper"
          >
            {regions?.map((r, index) => {
              return (
                <SwiperSlide
                  onClick={() => handleRegion(r, index)}
                  className="rswiper-slider cursor-pointer px-10"
                  key={r?._id}
                >
                  <div className="lg:text-xl font-bold text-wrap">
                    <span
                      className={`font-sans ${
                        selectedRegion && active === index
                          ? "text-red-500"
                          : !selectedRegion &&
                            getDefaultRegion?.region === r?.region
                          ? "text-green-500"
                          : ""
                      }`}
                    >
                      {r?.region}
                    </span>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        {/*Region for Mobile  */}
        <div className=" block lg:hidden bg-purple-50 pb-6">
          <Swiper
            loop={true}
            navigation={true}
            keyboard={true}
            slidesPerView={3}
            spaceBetween={0}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Navigation, Keyboard]}
            className="rmswiper"
          >
            {regions?.map((r, index) => (
              <SwiperSlide
                onClick={() => handleRegion(r, index)}
                className="rmswiper-slider cursor-pointer px-10"
                key={r?._id}
              >
                <div className="lg:text-xl font-bold text-wrap">
                  <span
                    className={`font-sans ${
                      selectedRegion && active === index
                        ? "text-red-500"
                        : !selectedRegion &&
                          getDefaultRegion?.region === r?.region
                        ? "text-green-500"
                        : ""
                    }`}
                  >
                    {r?.region}
                  </span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/*District for Desktop  */}
        <div className="hidden lg:block">
          {selectedRegion ? (
            <div className="flex flex-col items-center bg-purple-50 py-6">
              <div className="flex items-center">
                <button
                  onClick={handlePrevClickDesk}
                  disabled={startIndexDesk === 0}
                  className={`${
                    startIndexDesk === 0 ? "text-gray-400" : "text-blue-500"
                  } `}
                >
                  <FiChevronLeft size={40} />
                </button>
                <div className="grid grid-cols-5 justify-between px-4 items-center gap-4 min-w-[700px] max-w-[700px]">
                  {selectedRegion?.district
                    ?.slice(startIndexDesk, startIndexDesk + 15)
                    ?.map((item, index) => (
                      <div
                        onClick={() =>
                          handleDistrictWiseTrendingGift(item, index)
                        }
                        key={index}
                        className={` cursor-pointer bg-[#4E362A] bg-opacity-80 text-center rounded-full ${
                          disActive === index ? "text-orange-500" : "text-white"
                        }`}
                      >
                        {item}
                      </div>
                    ))}
                </div>

                <button
                  onClick={handleNextClickDesk}
                  disabled={
                    startIndexDesk + 15 >= selectedRegion?.district?.length
                  }
                  className={` disabled:text-gray-400`}
                >
                  <FiChevronRight
                    className={`${
                      startIndexDesk + 15 >= selectedRegion?.district?.length
                        ? "text-gray-400"
                        : "text-blue-500"
                    }`}
                    size={40}
                  />
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center bg-purple-50 py-6">
              <div className="flex items-center">
                <button
                  onClick={handlePrevClickDesk}
                  disabled={startIndexDesk === 0}
                  className={`${
                    startIndexDesk === 0 ? "text-gray-400" : "text-blue-500"
                  } `}
                >
                  <FiChevronLeft size={40} />
                </button>
                <div className="grid grid-cols-5 justify-between px-4 items-center gap-4 min-w-[700px] max-w-[700px]">
                  {getDefaultRegion?.district
                    ?.slice(startIndexDesk, startIndexDesk + 15)
                    ?.map((item, index) => (
                      <div
                        onClick={() =>
                          handleDistrictWiseTrendingGift(item, index)
                        }
                        key={index}
                        className={` cursor-pointer bg-[#4E362A] bg-opacity-80 text-center rounded-full ${
                          disActive != null && disActive === index
                            ? "text-yellow-500"
                            : disActive == null &&
                              byDefault[0]?.district === item
                            ? "text-blue-500"
                            : "text-white"
                        }`}
                      >
                        {item}
                      </div>
                    ))}
                </div>

                <button
                  onClick={handleNextClickDesk}
                  disabled={
                    startIndexDesk + 15 >= selectedRegion?.district?.length ||
                    !selectedRegion
                  }
                  className={` disabled:text-gray-400`}
                >
                  <FiChevronRight
                    className={`${
                      startIndexDesk + 15 >= selectedRegion?.district?.length ||
                      !selectedRegion
                        ? "text-gray-400"
                        : "text-blue-500"
                    }`}
                    size={40}
                  />
                </button>
              </div>
            </div>
          )}
        </div>

        {/*District for mobile  */}
        <div className="block lg:hidden ">
          {selectedRegion ? (
            <div className="flex flex-col items-center bg-purple-50 pb-4">
              <div className="flex items-center">
                <button
                  onClick={handlePrevClick}
                  disabled={startIndex === 0}
                  className={`${
                    startIndex === 0 ? "text-gray-400" : "text-blue-500"
                  } `}
                >
                  <FiChevronLeft size={30} />
                </button>
                <div className="grid grid-cols-3 gap-4 px-4">
                  {selectedRegion?.district
                    ?.slice(startIndex, startIndex + 9)
                    ?.map((item, index) => (
                      <div
                        onClick={() =>
                          handleDistrictWiseTrendingGift(item, index)
                        }
                        key={index}
                        className={`font-bold cursor-pointer bg-[#4E362A] bg-opacity-80 text-center rounded-full ${
                          disActive === index ? "text-red-500" : "text-white"
                        }`}
                      >
                        {item}
                      </div>
                    ))}
                </div>

                <button
                  onClick={handleNextClick}
                  disabled={startIndex + 9 >= selectedRegion?.district?.length}
                  className={` disabled:text-gray-400`}
                >
                  <FiChevronRight
                    className={`${
                      startIndex + 9 >= selectedRegion?.district?.length
                        ? "text-gray-400"
                        : "text-blue-500"
                    }`}
                    size={30}
                  />
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center bg-purple-50 pb-4">
              <div className="flex items-center">
                <button
                  onClick={handlePrevClick}
                  disabled={startIndex === 0}
                  className={`${
                    startIndex === 0 ? "text-gray-400" : "text-blue-500"
                  } `}
                >
                  <FiChevronLeft size={30} />
                </button>
                <div className="grid grid-cols-3 gap-4 px-4">
                  {getDefaultRegion?.district
                    ?.slice(startIndex, startIndex + 9)
                    ?.map((item, index) => (
                      <div
                        onClick={() =>
                          handleDistrictWiseTrendingGift(item, index)
                        }
                        key={index}
                        className={`font-bold cursor-pointer bg-[#4E362A] bg-opacity-80 text-center rounded-full ${
                          disActive != null && disActive === index
                            ? "text-yellow-500"
                            : disActive == null &&
                              byDefault[0]?.district === item
                            ? "text-blue-500"
                            : "text-white"
                        }`}
                      >
                        {item}
                      </div>
                    ))}
                </div>

                <button
                  onClick={handleNextClick}
                  disabled={
                    startIndex + 9 >= selectedRegion?.district?.length ||
                    !selectedRegion
                  }
                  className={` disabled:text-gray-400`}
                >
                  <FiChevronRight
                    className={`${
                      startIndex + 9 >= selectedRegion?.district?.length ||
                      !selectedRegion
                        ? "text-gray-400"
                        : "text-blue-500"
                    }`}
                    size={30}
                  />
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Selected trending brands for desktop*/}
        <div className="hidden md:block">
          {trendingBrands?.length > 0 && (
            <div className="flex items-center pb-6 overflow-auto justify-center bg-purple-50">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: -15,
                  depth: 100,
                  modifier: 4,
                  slideShadows: true,
                }}
                modules={[EffectCoverflow, Pagination]}
                className="trendswiper"
              >
                {trendingBrands?.map((item) => {
                  const passToBrandPage = {
                    item,
                    passRegion: passRegion
                      ? passRegion
                      : byDefault?.[0]?.region,
                    passDistrict: passDistrict
                      ? passDistrict
                      : byDefault?.[0]?.district,
                  };
                  return (
                    <SwiperSlide
                      className="trendswiper-slide relative border shadow-lg bg-white p-1"
                      key={item?._id}
                    >
                      <Link
                        to={`/brands/${item?.name}`}
                        state={passToBrandPage}
                        className="flex flex-col items-center justify-between"
                      >
                        <img
                          src={`https://www.giftshare.jp:5000/${item?.logo}`}
                          alt={item?.name}
                          className="logo"
                        />
                        <img
                          src={`https://www.giftshare.jp:5000/${item?.images?.[1]}`}
                          alt={item?.name}
                          className="image"
                        />
                      </Link>
                      <p
                        className="font-bold text-[#433832] bg-white  text-center "
                        style={{ height: "20px" }}
                      >
                        {item?.name}
                      </p>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          )}
        </div>

        {/* Selected trending brands for mobile*/}
        <div className="block md:hidden ">
          {trendingBrands?.length > 0 && (
            <div className="flex items-center overflow-auto justify-center bg-purple-50 pb-6 -mt-2">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: -15,
                  depth: 100,
                  modifier: 2,
                  slideShadows: true,
                }}
                modules={[EffectCoverflow, Pagination]}
                className="trendswipermobile"
              >
                {trendingBrands?.map((item) => {
                  const passToBrandPage = {
                    item,
                    passRegion: passRegion
                      ? passRegion
                      : byDefault?.[0]?.region,
                    passDistrict: passDistrict
                      ? passDistrict
                      : byDefault?.[0]?.district,
                  };
                  return (
                    <SwiperSlide
                      className="trendswipermobile-slide relative shadow-lg"
                      key={item?._id}
                    >
                      <Link
                        to={`/brands/${item?.name}`}
                        state={passToBrandPage}
                        className="flex flex-col items-center h-full justify-between bg-white p-1"
                      >
                        <img
                          src={`https://www.giftshare.jp:5000/${item?.logo}`}
                          alt={item?.name}
                          className="logomobile"
                        />
                        <img
                          src={`https://www.giftshare.jp:5000/${item?.images?.[1]}`}
                          alt={item?.name}
                          className="imagemobile"
                        />
                        <p className="font-bold text-sm py-1 text-[#433832] bg-white w-full text-center absolute bottom-0 left-0">
                          {item?.name}
                        </p>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegionsDistrict;
