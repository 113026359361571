import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import { AiFillEdit, AiFillPlusSquare } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import {
  useGetAllFaqQuery,
  useRemoveFaqMutation,
} from "../../../../features/api/faqApi";

const Faq = () => {
  const { data, isLoading } = useGetAllFaqQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const faqs = data?.data;

  const [removeFaq] = useRemoveFaqMutation();
  const handleDelete = async (id) => {
    const confirm = window.confirm("Are you sure?");
    if (confirm) {
      try {
        const response = await removeFaq(id);
        if (response) {
          toast.success("Delete successfull...");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div className="px-8 pb-8">
      <div className="my-4 flex justify-end">
        <Link to="/dashboard/faq/create">
          <button className="px-2 gap-x-1 rounded py-1 bg-[#4E362A] text-white flex items-center  justify-">
            {" "}
            <AiFillPlusSquare size={25} />
            <span>Create</span>
          </button>
        </Link>
      </div>
      {isLoading ? (
        <div className="w-fll h-screen flex flex-col items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : faqs?.length === 0 ? (
        <p>There are no FAQ</p>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {faqs?.map((faq) => {
            return (
              <Card key={faq?._id}>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="h5">
                      {faq?.question}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="text-justify"
                      color="text.secondary"
                    >
                      {faq?.answer}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <div className="flex justify-between items-center w-full">
                    <button className="text-blue-500">
                      <Link to="/dashboard/faq/edit" state={faq}>
                        {" "}
                        <AiFillEdit size={25} />
                      </Link>
                    </button>
                    <button
                      onClick={() => handleDelete(faq?._id)}
                      className="text-red-500"
                    >
                      {" "}
                      <MdDelete size={25} />
                    </button>
                  </div>
                </CardActions>
              </Card>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Faq;
