import React, { useEffect } from "react";
import LanguageChanger from "../Components/LanguageChanger/LanguageChanger";
import {
  selectLanguage,
  toggleLanguage,
} from "../features/Toggle/languageSlice";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { MdLanguage } from "react-icons/md";
import { Link } from "react-router-dom";
import i18n from "i18next";
import jaTranslator from "../jpTranslator/ja.json";
import enTranslator from "../enTranslator/en.json";

i18n.use(initReactI18next).init({
  resources: {
    ja: {
      translation: jaTranslator,
    },
    en: {
      translation: enTranslator,
    },
  },
  lng: "ja",
  fallbackLng: "ja",
  interpolation: {
    escapeValue: false,
  },
});

const Footer = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const lang = useSelector(selectLanguage);

  const storedLanguage = localStorage.getItem("language") || "ja";
  useEffect(() => {
    i18n.changeLanguage(storedLanguage);

    localStorage.setItem("language", storedLanguage);
  }, [storedLanguage, i18n]);

  const toggleLanguageHandler = () => {
    dispatch(toggleLanguage());

    const newLanguage = lang === "ja" ? "en" : "ja";
    localStorage.setItem("language", newLanguage);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 0.5);
  };
  return (
    <div>
      <div className="flex flex-col items-center justify-center gap-2 bg-gray-900 lg:bg-white py-3 text-center text-sm italic">
        <div className="mb-2 grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-0  text-gray-300 lg:text-[#733E24]">
          <Link
            to="/about-us"
            className="border lg:border-r-0 w-[175px] lg:w-[200px] lg:border-[#4E362A] py-2  lg:bg-white hover:bg-gray-200 "
          >
            {t("about")}
          </Link>
          <Link
            to="/privacy-policy"
            className="border lg:border-r-0 w-[175px] lg:w-[200px] lg:border-[#4E362A]  px-4 py-2  lg:bg-white hover:bg-gray-200"
          >
            {t("policy")}
          </Link>
          <Link
            to="/trams-condition"
            className="border lg:border-r-0 w-[175px] lg:w-[200px] lg:border-[#4E362A] px-4 py-2  lg:bg-white hover:bg-gray-200"
          >
            {t("terms")}
          </Link>
          <Link
            to="/contact-us"
            className="border w-[175px] lg:w-[200px] lg:border-[#4E362A] px-4 py-2  lg:bg-white hover:bg-gray-200"
          >
            {t("contact")}
          </Link>
        </div>
        <div>
          {lang === "ja" ? (
            <button
              onClick={toggleLanguageHandler}
              className=" flex items-center gap-x-1 text-white lg:text-black"
            >
              <span className="">
                <MdLanguage size={18} />
              </span>
              ENGLISH
            </button>
          ) : (
            <button
              onClick={toggleLanguageHandler}
              className=" flex items-center gap-x-1 text-white lg:text-black"
            >
              <span className="">
                <MdLanguage size={18} />{" "}
              </span>
              JAPANESE
            </button>
          )}
        </div>{" "}
        <p className="px-2 text-white lg:text-black"> © Giftshare, Inc</p>
      </div>
    </div>
  );
};

export default Footer;
