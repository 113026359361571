import React from "react";
import { useLocation } from "react-router";
import {
  useGetAllProductsQuery,
  useRemoveProductMutation,
} from "../../../features/api/productsApi";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const BrandwiseProducts = () => {
  const location = useLocation();
  const brand = location?.state;

  const products = useGetAllProductsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  const filterBrands = products?.filter((pro) => pro?.brand?.name === brand);

  const itemsPerRow = 4;
  const rows = [];
  for (let i = 0; i < filterBrands?.length; i += itemsPerRow) {
    rows.push(filterBrands?.slice(i, i + itemsPerRow));
  }

  const [removeProduct] = useRemoveProductMutation();
  const handleDelete = (id) => {
    const confirm = window.confirm("Are you sure you want to delete?");
    if (confirm) {
      removeProduct(id);
      toast.success("Successfully Deleted");
    }
  };

  return (
    <div className="p-8">
      <h1 className="font-bold">Brand: {brand}</h1>
      <div className="">
        {rows?.map((rowsItems, rowIndex) => (
          <div
            key={rowIndex}
            className={`grid grid-cols-4 gap-4 p-8 ${
              rowIndex < rows.length - 1
                ? "border-b border-dashed border-[#4E362A]"
                : ""
            }`}
          >
            <PhotoProvider>
              {rowsItems?.map((product, index) => (
                <div
                  key={product?._id}
                  className={`text-center ${
                    index % 4 === 0
                      ? ""
                      : "border-l border-dashed border-[#4E362A]"
                  } p-4`}
                >
                  <div className="flex items-center justify-center cursor-pointer">
                    {/* <img
                    src={`https://www.giftshare.jp:5000/${product?.image}`}
                    alt={product?.name}
                    className="w-32 "
                  /> */}
                    <PhotoView src={`https://www.giftshare.jp:5000/${product?.image}`}>
                      <img
                        src={`https://www.giftshare.jp:5000/${product?.image}`}
                        alt={product?.name}
                        className="w-32 "
                      />
                    </PhotoView>
                  </div>
                  <div>
                    <p className="font-bold py-2">{product?.name}</p>
                    <p className="text-xs">{product?.desc}</p>
                  </div>
                  <div className="mt-4">
                    <Link to={`/dashboard/updateproductlist/${product?._id}`}>
                      <button className="px-2 bg-blue-600 text-white mr-2">
                        Edit
                      </button>
                    </Link>
                    <button
                      onClick={() => handleDelete(product?._id)}
                      className="px-2 bg-red-600 text-white"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </PhotoProvider>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandwiseProducts;

{
  /* <div>
  <Link to={`/dashboard/updateproductlist/${row?._id}`}>
    <button className="px-2 bg-blue-600 text-white mr-2">Edit</button>
  </Link>
  <button
    onClick={() => handleDelete(row?._id)}
    className="px-2 bg-red-600 text-white"
  >
    Delete
  </button>
</div>; */
}

// const [removeProduct] = useRemoveProductMutation();
// const handleDelete = (id) => {
//     const confirm = window.confirm("Are you sure you want to delete?");
//     if (confirm) {
//       removeProduct(id);
//       toast.success("Successfully Deleted");
//     }
//   };

//   <img
//     src={`https://www.giftshare.jp:5000/${row?.image}`}
//     className="w-16"
//     alt="Product"
//   />
