import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./DeliveryGiftsDetails.css";

// import required modules
import { FreeMode, Navigation, Pagination, Keyboard } from "swiper";

// import data
import { sameDayDeliveryData } from "../data";
import { useGetAllProductsQuery } from "../features/api/productsApi";
import { useGetAllGiftBoxQuery } from "../features/api/GiftBoxApi";
import { useState } from "react";
import SlickSlider from "../Components/SlickSlider/SlickSlider";
import { Link } from "react-router-dom/dist";
import { useGetAllBlankBoxQuery } from "../features/api/blankBoxApi";
import DescriptionMobile from "../Components/DescriptionMobile";
import Description from "../Components/Description";
// import SlickSlider from "../Components/SlickSlider/SlickSlider";

const DeliveryGiftsDetails = () => {
  const [matchFestival, setMatchFestival] = useState([]);
  const [selectedBlankBox, setSelectedBlankBox] = useState();
  const location = useLocation();
  const data = location?.state;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  //All product
  const { data: getProducts } = useGetAllProductsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allProducts = getProducts?.data;

  // Blank Box
  const { data: getAllBlankBox } = useGetAllBlankBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allBlankBox = getAllBlankBox?.data;

  const giftboxproduct = allProducts?.filter((product) => {
    return data?.productList?.some((p) => p === product.name);
  });

  // fetch all gift box data
  const { data: getGiftBox } = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allGiftbox = getGiftBox?.data;

  // handle festival
  const handleFestival = (festival) => {
    const matchedFestival = allBlankBox?.filter(
      (f) => f?.festival === festival
    );
    setMatchFestival(matchedFestival);
    setSelectedBlankBox(null);
  };

  const [selectedGiftBox, setSelectedGiftBox] = useState(null);
  const handleGiftBox = (boxName) => {
    const rest = similarGiftBox?.find((box) => box?.name === boxName);
    setSelectedGiftBox(rest);
  };

  // Similar Gift Box
  const similarGiftBox = allGiftbox?.filter((giftbox) => {
    return data?._id !== giftbox?._id;
  });
  return (
    <div className="">
      <div className="lg:px-48">
        {selectedGiftBox ? (
          <div className="flex flex-col lg:flex-row lg:gap-12 p-4 lg:p-12">
            <div className="flex justify-center w-full">
              <img
                className="hidden lg:block w-[600px] h-[300px]"
                src={`https://www.giftshare.jp:5000/${selectedGiftBox?.image}`}
              />
              <img
                className="block lg:hidden"
                src={`https://www.giftshare.jp:5000/${selectedGiftBox?.image}`}
                style={{ width: "200px" }}
              />
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-center pe-4 flex-column pt-3 pt-lg-0 space-y-4 w-full">
              <p className="text-xl text-[#9A583B] font-bold">
                {selectedGiftBox?.name}
              </p>
              <p className="text-sm lg:text-base lg:w-3/4">
                {" "}
                {selectedGiftBox?.desc}
              </p>
              <h5 className=" text-center lg:text-left font-semibold">
                Price: ¥{selectedGiftBox?.price}
              </h5>
              <Link
                to={`/addtocart/${selectedGiftBox?.name}`}
                state={{
                  data: selectedGiftBox ? selectedGiftBox : data,
                  selectedBlankBox: selectedBlankBox,
                }}
              >
                <div className="mt-4 w-full text-center lg:text-left">
                  <button className="px-4 py-2 bg-[#9A583B] mx-auto text-white font-bold rounded">
                    Add To Cart
                  </button>
                </div>
              </Link>
            </div>
          </div>
        ) : (
          <div className="flex flex-col lg:flex-row lg:gap-12 p-4 lg:p-12">
            <div className="flex justify-center w-full">
              <img
                className="hidden lg:block w-[600px] h-[300px]"
                src={`https://www.giftshare.jp:5000/${data?.image}`}
              />
              <img
                className="block lg:hidden"
                src={`https://www.giftshare.jp:5000/${data?.image}`}
                style={{ width: "200px" }}
              />
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-center pe-4 flex-column pt-3 pt-lg-0 space-y-4 w-full">
              <p className="text-xl text-[#9A583B] font-bold">{data?.name}</p>
              <p className=" text-sm lg:text-base lg:w-3/4 "> {data?.desc}</p>
              <h5 className="text-center lg:text-left font-semibold">
                Price: ¥{data?.price}
              </h5>
              <Link
                to={`/addtocart/${data?.name}`}
                state={{
                  data: selectedGiftBox ? selectedGiftBox : data,
                  selectedBlankBox: selectedBlankBox,
                }}
              >
                <div className="mt-4 w-full text-center lg:text-left">
                  <button className="px-4 py-2 bg-[#9A583B] mx-auto text-white font-bold rounded">
                    Add To Cart
                  </button>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>

      {/* // Relatred Festival  */}

      <div className="row py-5 px-4 lg:px-12 ">
        {matchFestival.length > 0 && (
          <h4 className="text-center pb-3 text-xl font-bold">
            {matchFestival[0]?.festival} Festival
          </h4>
        )}

        <div className="">
          <Swiper
            loop={true}
            navigation={true}
            keyboard={true}
            slidesPerView={4}
            spaceBetween={2}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination, Navigation, Keyboard]}
            className="gboxswiperr "
          >
            {selectedBlankBox ? (
              <div>
                <img
                  src={`https://www.giftshare.jp:5000/${selectedBlankBox?.image}`}
                  className="w-32 h-32 object-cover"
                />
                <p>{selectedBlankBox?.name}</p>
              </div>
            ) : (
              matchFestival?.map((data, index) => (
                <SwiperSlide
                  onClick={() => setSelectedBlankBox(data)}
                  className="gboxswiper-slider py-6"
                  key={index}
                >
                  <img
                    src={`https://www.giftshare.jp:5000/${data?.image}`}
                    className="w-32 h-32 object-cover"
                  />
                  <p>{data?.name}</p>
                </SwiperSlide>
              ))
            )}
          </Swiper>
        </div>
      </div>

      {/* // Giftbox match product  */}
      {/* <div className="row py-5 px-4 lg:px-12">
        <Description>{data.name} Productuy</Description>
        <div className="">
          <Swiper
            loop={true}
            navigation={true}
            keyboard={true}
            slidesPerView={4}
            spaceBetween={2}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination, Navigation, Keyboard]}
            className="gboxproswiperr"
          >
            {giftboxproduct?.map((data, index) => (
              <SwiperSlide
                className="gboxproswiper-slider pt-4 pb-8"
                key={index}
              >
                <img
                  src={`https://www.giftshare.jp:5000/${data?.image}`}
                  className="h-48 w-48 object-cover"
                />
                <p>{data?.name}</p>
                <p>
                  <small>{data.desc}</small>
                </p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div> */}

      {/* selected gift box product list start */}
      <SlickSlider data={data} giftboxproduct={giftboxproduct} />

      {/* selected gift box product list end */}

      {/* //Simiar gift box  DeskTop Device */}
      <div className="row py-5 px-4 lg:px-12">
        <Description>Similar Gift Box</Description>
        <div className="hidden md:block">
          <Swiper
            loop={true}
            navigation={true}
            keyboard={true}
            slidesPerView={4}
            spaceBetween={2}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination, Navigation, Keyboard]}
            className=" sgboxswiperr"
          >
            {similarGiftBox?.map((data, index) => (
              <SwiperSlide
                onClick={() => handleGiftBox(data.name)}
                className="sgboxswiper-slider py-6 pb-8"
              >
                <img
                  src={`https://www.giftshare.jp:5000/${data?.image}`}
                  className="h-36 w-36 lg:h-48 lg:w-48 object-cover cursor-pointer"
                />
                <p>{data?.name}</p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* For MobileDevice  */}
        <DescriptionMobile>Similar Gift Box</DescriptionMobile>
        <div className="lg:hidden">
          <Swiper
            loop={true}
            navigation={true}
            keyboard={true}
            slidesPerView={3}
            spaceBetween={5}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination, Navigation, Keyboard]}
            className=" sgboxswiperr"
          >
            {similarGiftBox?.map((data, index) => (
              <SwiperSlide
                onClick={() => handleGiftBox(data.name)}
                className="sgboxswiper-slider py-6 pb-8"
              >
                <img
                  src={`https://www.giftshare.jp:5000/${data?.image}`}
                  className="h-36 w-36 lg:h-48 lg:w-48 object-cover cursor-pointer"
                />
                <p>{data?.name}</p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="row py-5">
        <h4 className="text-center pb-3">Recommended for You</h4>
        <div className="">
          <Swiper
            loop={true}
            navigation={true}
            keyboard={true}
            slidesPerView={5}
            spaceBetween={10}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination, Navigation, Keyboard]}
            className=" swiperr"
          >
            {sameDayDeliveryData.map((data, index) => (
              <SwiperSlide className="swiper-slider">
                <img
                  src={data.image}
                  className="d-none d-lg-block"
                  style={{ width: "250px" }}
                />
                <img
                  src={data.image}
                  className="d-block d-lg-none"
                  style={{ height: "70px" }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default DeliveryGiftsDetails;
