import React, { useState } from "react";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";
import axios from "axios";
import { toast } from "react-toastify";

const AddFestival = () => {
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [svg, setSvg] = useState(null);

  const handleSubmitGiftItem = async (e) => {
    e.preventDefault();

    const data = {
      name,
      shortName,
      image: svg,
    };
    try {
      const response = await axios.post(
        "https://www.giftshare.jp:5000/api/v1/festival",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Reset the htmlForm inputs
      setName("");
      setSvg(null);

      if (response) {
        toast.success("Successfully added");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };
  return (
    <div className="p-8">
      <DashBoardMenu />
      <div className="flex justify-center overflow-auto items-center mt-12 px-8 ">
        <div
          className="card bg-base-100 overflow-auto mb-12 rounded-none w-full"
          style={{ boxShadow: "1px 0px 3px 1px lightblue" }}
        >
          <div className="card-body">
            <div className="text-center">
              <h2 className="text-xl font-bold">Add new festival</h2>
            </div>
            {
              <form onSubmit={handleSubmitGiftItem} className="text-center">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 my-2  ">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Festival Name"
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none mb-2 mx-auto"
                  />
                  <select
                    onChange={(e) => setShortName(e.target.value)}
                    className="input-bordered input h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none mb-2 mx-auto"
                  >
                    <option value="HPGB1">HPGB1</option>
                    <option value="HPGB2">HPGB2</option>
                    <option value="HPGB3">HPGB3</option>
                    <option value="HPGB4">HPGB4</option>
                    <option value="Others">OTHERS</option>
                  </select>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setSvg(e.target.files[0])}
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none mb-2 mx-auto"
                  />
                </div>

                <div className="flex justify-around pt-6">
                  <button
                    type="submit"
                    className="bg-[#5e2006] px-4 rounded py-1 font-bold text-white "
                  >
                    Save
                  </button>
                </div>
              </form>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFestival;
