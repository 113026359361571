import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { removeAllFromCart } from "../../features/cart/cartSlice";

const OrderConfirmationPopup = ({ onClose }) => {
  //   const [visible, setVisible] = useState(true);

  //   useEffect(() => {
  //     const timer = setTimeout(() => {
  //       setVisible(false);
  //       onClose();
  //     }, 5000);

  //     return () => clearTimeout(timer);
  //   }, [onClose]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
//   const handleClick = () => {
//     onclose();
//     setIsLoading(true);
//     setTimeout(() => {
//       window.location.reload();
//     }, 2000);
//   };
//   if (isLoading) {
//     setTimeout(() => {
//       navigate("/user/dashboard");
//     }, 2000);
//   }
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div className="bg-slate-100 p-8 lg:p-24 rounded-lg shadow-lg">
        <p className="text-xl font-semibold mb-4">Thank you for your order!</p>
        <button
          className="px-4 py-2 block mx-auto bg-[#4E362A] text-white rounded"
          onClick={() => {
            dispatch(removeAllFromCart("all"));
            onClose();
            navigate("/user/dashboard/order-history");
          }}
        >
          ok
        </button>
      </div>
    </div>
    // <div
    //   className={`fixed top-0 left-0 w-full h-full flex items-center justify-center ${
    //     visible ? 'visible' : 'invisible'
    //   } transition-opacity duration-300 ease-in-out`}
    // >
    //   <div className="bg-white p-8 rounded-lg shadow-lg">
    //     <p className="text-xl font-semibold mb-4">Thank you for your order!</p>
    //     <button
    //       className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
    //       onClick={() => {
    //         setVisible(false);
    //         onClose();
    //       }}
    //     >
    //       OK
    //     </button>
    //   </div>
    // </div>
  );
};

export default OrderConfirmationPopup;
