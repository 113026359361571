import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ratingIcon from "../../../assets/images/rating.png";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { useGetAllGiftBoxQuery } from "../../../features/api/GiftBoxApi";
import { useGetUserQuery } from "../../../features/api/loginApi";
import { useGetAlltestingboxQuery } from "../../../features/api/testingBox";

const Ratings = ({ info, status }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  const { data: giftBox } = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allGiftBox = giftBox?.data;

  const { data: testbox } = useGetAlltestingboxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const testingBoxs = testbox?.data;

  const { data: me } = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const user = me?.data;

  const findGiftBox = allGiftBox?.find((box) => box?._id === info?.boxId);
  const findTestBox = testingBoxs?.find((box) => box?._id === info?.testBoxId);

  const [value, setValue] = useState("1");
  const [comment, setComment] = useState("");

  const name = user?.firstName + " " + user?.lastName;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postNew = {
      comment,
      rating: String(value),
      pro_id: findGiftBox?._id || findTestBox?._id,
      user_id: name,
    };
    try {
      const response = await axios.post(
        `https://www.giftshare.jp:5000/api/v1/rating`,
        postNew
      );
      await axios.patch(`https://www.giftshare.jp:5000/api/v1/orderItem/${info?._id}`, {
        rating: true,
      });
      if (response) {
        setIsOpen(false);
        document.body.style.overflow = "auto";
        toast.success(
          "Thank you for sharing your rating! your feedback help us strive for excellence and improve our service"
        );
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <div>
      <button onClick={openModal}>
        <img src={ratingIcon} className="w-10 h-10" />
      </button>

      {isOpen && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white mx-2 lg:mx-0 px-2 lg:p-6 rounded w-full h- lg:w-[500px] mt-12">
            <button
              className=" text-red-600 font-bold float-right"
              onClick={closeModal}
            >
              X
            </button>
            <div className="card bg-base-100 overflow-auto">
              <div className="card-body">
                <div className="text-center"></div>
                <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                  <p className="text-red-500"> {status}</p>
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                    }}
                  >
                    <Typography component="legend">Rating</Typography>
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </Box>
                  <div className="form-control w-full ">
                    <label className="mb-1">Comment:</label>
                    <textarea
                      type="text"
                      className="border p-1 w-full rounded-none focus:outline-none hidden lg:block"
                      onChange={(e) => setComment(e.target.value)}
                    />
                    <textarea
                      type="text"
                      className="border p-1 w-full rounded-none focus:outline-none block lg:hidden"
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                  {/* <div className="form-control w-full ">
                    <label className="mb-1">Rating:</label>
                    <input
                      type="text"
                      required
                      className="input input-bordered w-full h-8 rounded-none focus:border-none "
                      {...register("rating")}
                    />
                  </div> */}

                  <input
                    className="py-1 cursor-pointer mt-6 block m-auto w-full border-none text-white"
                    style={{ backgroundColor: "#9A583B" }}
                    type="submit"
                    value="Submit"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ratings;
