import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isUserLoggedin: false,
};

const loggedinUserSlice = createSlice({
  name: "loggedinUser",
  initialState,
  reducers: {
    toggleUserLoggedin: (state, action) => {
      state.isUserLoggedin = action.payload;
    },
  },
});

export const { toggleUserLoggedin } = loggedinUserSlice.actions;
export const selectIsUserLoggedin = (state) =>
  state.loggedinUser.isUserLoggedin;
export default loggedinUserSlice.reducer;
