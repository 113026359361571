import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetUserQuery } from "../../features/api/loginApi";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import OrderConfirmationPopup from "./OrderConfirmationPopup";

const CheckoutMobile = ({ cart, blank, discount }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [shipping, setShippingMethod] = useState("Free");
  const [paymentMethod, setPaymentMethod] = useState("COD");
  console.log(paymentMethod);
  const [shippName, setName] = useState("");
  const [shippEmail, setEmail] = useState("");
  const [shippZip, setZip] = useState("");
  const [shippPhone, setPhone] = useState("");
  const [shippAddress, setAddress] = useState("");
  const [shippDistrict, setDistrict] = useState("");
  const [shippRegion, setRegion] = useState("");

  const [pointsMethod, setPointsMethod] = useState("");

  const getShipping = shipping === "Free" ? 0 : 10;
  const subTotals = cart?.reduce(
    (total, item) => total + item?.price * item?.quantity,
    0
  );
  const grandTotal = subTotals + getShipping - discount;

  const { data, isLoading } = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const user = data?.data;
  const email = user?.email;

  // genereate order number by uuid
  function generateOrderNumber() {
    const uuid = uuidv4();
    const truncatedOrderNumber = uuid.substr(0, 10);
    return truncatedOrderNumber;
  }
  const orderNumber = generateOrderNumber();

  const [showPopup, setShowPopup] = useState(false);
  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleCheckout = async () => {
    const data = {
      shippName,
      shippEmail,
      shippRegion,
      shippDistrict,
      shippZip,
      shippPhone,
      shippAddress,
      email,
      subtotal: subTotals,
      discount,
      grandTotal,
      paymentMethod: paymentMethod,
      shippFee: shipping,
      orderId: orderNumber,
    };
    const data2 = {
      cart,
      blank,
      orderId: orderNumber,
    };
    try {
      const response = await axios.post(
        "https://www.giftshare.jp:5000/api/v1/orders",
        data
      );
      console.log(response);
      await axios.post("https://www.giftshare.jp:5000/api/v1/orderItem", data2);
      if (response) {
        setShowPopup(true);
        localStorage.removeItem("checkout");
      }
    } catch (error) {
      toast.error(error.response?.data?.error);
    }
  };

  const [activeDiv, setActiveDiv] = useState(1);
  const toggleDiv = (divIndex) => {
    setActiveDiv(divIndex);
  };
  return (
    <div>
      <div className="space-y-3 border-b pb-6">
        {cart?.map((cat, i) => {
          const exist = blank?.find((b) => b?.festival === cat?.festival);
          return (
            <div className="flex items-center justify-between " key={cat?._id}>
              <div className="flex items-center gap-x-2">
                <div className="relative">
                  <img
                    src={`https://www.giftshare.jp:5000/${cat?.images[0]}`}
                    className="lg:w-20 lg:h-20 w-12 h-12 p-1 border"
                  />
                  <p className="lg:w-6 lg:h-6 w-4 h-4 text-sm rounded-full bg-[#4E362A] text-white flex items-center justify-center absolute -top-2 -right-2">
                    {cat?.quantity}
                  </p>
                </div>

                <div className="item relative">
                  <h2 className="text-sm">{cat?.name}</h2>

                  {exist && (
                    <p className="text-xs underline text-blue-600">
                      ( You chossed a blank box )
                    </p>
                  )}
                  {exist?.image && (
                    <img
                      src={`https://www.giftshare.jp:5000/${exist?.image}`}
                      className=" item-image"
                    />
                  )}
                </div>
              </div>
              <p className="text-sm">¥{cat?.price}</p>
            </div>
          );
        })}
      </div>

      <div className="">
        {activeDiv === 1 && (
          <div>
            <div>
              <div className="mt-6">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    <span className="font-bold">Shipping method</span>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Free"
                    name="radio-buttons-group"
                    onChange={(e) => setShippingMethod(e.target.value)}
                  >
                    <FormControlLabel
                      value="Free"
                      control={<Radio />}
                      label="Free delivery - Free shipping"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="mt-6">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    <span className="font-bold">Payment method</span>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="COD"
                    name="radio-buttons-group"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    <FormControlLabel
                      value="COD"
                      control={<Radio />}
                      label="Cash on delivery (COD)"
                    />
                    <FormControlLabel
                      value="amazon"
                      control={<Radio />}
                      label="Payment via amazon"
                    />
                    {paymentMethod === "amazon" && (
                      <div className="bg-gray-100 p-4 border border-gray-300 my-2 rounded">
                        <div className="space-y-2 text-sm lg:space-y-4 border-b py-6 w-[270px] mx-auto">
                          <div className="flex items-center justify-between">
                            <p>Subtotal:</p>
                            <p className="font-bold">¥{subTotals}</p>
                          </div>
                          <div className="flex items-center justify-between">
                            <p>Discount:</p>
                            <p className="font-bold">¥{discount}</p>
                          </div>
                          <div className="flex items-center justify-between">
                            <p>Shipping fee:</p>
                            <p className="font-bold">¥{getShipping}</p>
                          </div>
                          <div className="flex items-center justify-between">
                            <p className="font-bold">Total:</p>
                            <p className="font-bold text-xl">¥{grandTotal}</p>
                          </div>
                        </div>
                        <button
                          // onClick={handleCheckout}
                          className="w-full py-1 bg-[#4E362A] rounded text-white mt-2"
                        >
                          Checkout
                        </button>
                      </div>
                    )}
                    <FormControlLabel
                      value="points"
                      control={<Radio />}
                      label="Pay by points"
                    />
                  </RadioGroup>
                </FormControl>
                {paymentMethod === "points" && (
                  <div className="lg:w-[270px] border p-2">
                    <div className="bg-gray-200 p-2">
                      <p className="font-bold text-sm">Current Points Amount</p>
                      <p className="flex items-center gap-x-2 font-bold">
                        <span className="bg-red-600 w-5 h-5 text-white text-sm flex items-center justify-center rounded-full">
                          P
                        </span>{" "}
                        {user?.earnedCoin - user?.usedCoin}{" "}
                        <span className="text-xs font-normal">Points</span>
                      </p>
                    </div>
                    <div className="w-full">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          // defaultValue="COD"
                          name="radio-buttons-group"
                          onChange={(e) => setPointsMethod(e.target.value)}
                        >
                          <FormControlLabel
                            value="allpoints"
                            control={<Radio />}
                            label="Use all of the amount"
                          />
                          <FormControlLabel
                            value="somepoints"
                            control={<Radio />}
                            label="Use some of the amount"
                          />
                          {pointsMethod === "somepoints" && (
                            <input
                              type="number"
                              className="border border-gray-400 w-[290px] focus:outline-none mt-2 mb-4 p-1 "
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {paymentMethod !== "amazon" && (
              <button
                onClick={() => toggleDiv(2)}
                className="bg-[#4E362A] text-white px-4 py-1 rounded float-right my-3"
              >
                Next
              </button>
            )}
          </div>
        )}
        {activeDiv === 2 && (
          <div>
            <div>
              <p className="font-sans font-bold mb-4">Shipping information</p>
              <form className="space-y-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div>
                    <input
                      type="text"
                      value={shippName}
                      onChange={(e) => setName(e.target.value)}
                      className={`border ${
                        shippName === "" ? "border-red-500" : ""
                      } w-full placeholder:text-sm p-1 rounded focus:outline-none`}
                      placeholder="Fullname"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      value={shippPhone}
                      onChange={(e) => setPhone(e.target.value)}
                      className={`border ${
                        shippPhone === "" ? "border-red-500" : ""
                      } w-full placeholder:text-sm p-1 rounded focus:outline-none`}
                      placeholder="Phone"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      value={shippAddress}
                      onChange={(e) => setAddress(e.target.value)}
                      className={`border ${
                        shippAddress === "" ? "border-red-500" : ""
                      } w-full placeholder:text-sm p-1 rounded focus:outline-none`}
                      placeholder="Address"
                    />
                  </div>

                  <div>
                    <input
                      type="email"
                      value={shippEmail}
                      onChange={(e) => setEmail(e.target.value)}
                      className="border w-full placeholder:text-sm p-1 rounded focus:outline-none"
                      placeholder="Email"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      value={shippRegion}
                      onChange={(e) => setRegion(e.target.value)}
                      className="border w-full placeholder:text-sm p-1 rounded focus:outline-none"
                      placeholder="Region"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      value={shippDistrict}
                      onChange={(e) => setDistrict(e.target.value)}
                      className="border w-full placeholder:text-sm p-1 rounded focus:outline-none"
                      placeholder="District"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      value={shippZip}
                      onChange={(e) => setZip(e.target.value)}
                      className="border w-full placeholder:text-sm p-1 rounded focus:outline-none"
                      placeholder="Zip"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="flex items-center justify-between gap-x-2">
              <button
                onClick={() => toggleDiv(1)}
                className="bg-[#4E362A] text-white px-4 py-1 rounded my-3"
              >
                Back
              </button>
              <button
                onClick={() => {
                  if (
                    shippName === "" ||
                    shippPhone === "" ||
                    shippAddress === ""
                  ) {
                    toast.warning("Please fill up your require field");
                  } else {
                    toggleDiv(3);
                  }
                }}
                className="bg-[#4E362A] text-white px-4 py-1 rounded my-3"
              >
                Next
              </button>
            </div>
          </div>
        )}
        {activeDiv === 3 && (
          <div>
            <div className="space-y-2 text-sm lg:space-y-4 border-b py-6">
              <div className="flex items-center justify-between">
                <p>Subtotal:</p>
                <p className="font-bold">¥{subTotals}</p>
              </div>
              <div className="flex items-center justify-between">
                <p>Discount:</p>
                <p className="font-bold">¥{discount}</p>
              </div>
              <div className="flex items-center justify-between">
                <p>Shipping fee:</p>
                <p className="font-bold">¥{getShipping}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-bold">Total:</p>
                <p className="font-bold text-xl">¥{grandTotal}</p>
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <button
                onClick={() => toggleDiv(2)}
                className="bg-[#4E362A] text-white px-4 py-1 rounded my-3"
              >
                Back
              </button>
              <button
                onClick={handleCheckout}
                className="bg-[#4E362A] text-white px-4 py-1 rounded my-3"
              >
                Checkout
              </button>
              {showPopup && (
                <OrderConfirmationPopup onClose={handlePopupClose} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutMobile;
