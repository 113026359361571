import React, { useState } from "react";
import { AiFillFolderAdd, AiTwotoneDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";
import DataTable from "react-data-table-component";
import {
  useGetFestivalsQuery,
  useRemoveFestivalMutation,
} from "../../../features/api/festivalApi";

const Festivals = () => {
  const { data } = useGetFestivalsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const festivals = data?.data;

  const [revmoeFestivalMutation] = useRemoveFestivalMutation();

  const handleDelete = async (id) => {
    const confirm = window.confirm("Are you want do delete?");
    if (confirm) {
      try {
        // const response = await axios.delete(
        //   `https://www.giftshare.jp:5000/api/v1/festival/${id}`
        // );
        const response = await revmoeFestivalMutation(id);
        if (response) {
          toast.success("Delete Successfull!!");
        }
      } catch (error) {
        toast.error(error?.response?.data?.error);
      }
    }
  };

  const [searchText, setSearchText] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = (column, direction) => {
    setSortColumn(column.selector);
    setSortDirection(direction);
  };

  const sortedFestivals =
    sortColumn && sortDirection
      ? [...festivals].sort((a, b) => {
          if (sortDirection === "asc") {
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
          } else if (sortDirection === "desc") {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
          }
          return 0;
        })
      : festivals;

  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) => index + 1,
      sortable: false,
    },

    {
      name: "Festival name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Section",
      selector: "shortName",
      sortable: true,
    },
    {
      name: "SVG",
      cell: (row) => (
        <img src={`https://www.giftshare.jp:5000/${row?.image}`} className="w-8 h-8" />
      ),
      sortable: false,
    },

    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="text-blue-500"
            style={{ width: "40px", fontSize: "25px" }}
          >
            <Link to={`/dashboard/festival/edit`} state={row}>
              {" "}
              <FaEdit />
            </Link>
          </button>
          <button
            onClick={(e) => handleDelete(row?._id)}
            className="text-red-500"
            style={{ width: "40px", fontSize: "25px" }}
          >
            <AiTwotoneDelete />
          </button>
        </>
      ),
      sortable: false,
    },
  ];

  const sortIconStyles = {
    base: "mr-1",
    sortNone: "hidden",
    sortAsc: "text-green-500",
    sortDesc: "text-red-500",
  };

  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="p-8">
        <div className="overflow-x-auto">
          <h2 className="text-xl font-bold mt-2">Manage Festival</h2>
          <div className="flex items-center justify-between">
            <Link to="/dashboard/festival/create">
              <AiFillFolderAdd size={30} className="text-blue-500" />
            </Link>
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className=" mb-4 px-4 py-2 border border-gray-300 rounded"
            />
          </div>
          <DataTable
            columns={columns}
            data={sortedFestivals}
            pagination
            highlightOnHover
            sortServer
            fixedHeader
            responsive
            sortIconStyles={sortIconStyles}
            onSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
};

export default Festivals;
