import React, { useState } from "react";
import axios from "axios";
import { AiFillBackward } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEditSliderMutation } from "../../../../features/api/sliderApi";

const SliderSettingEdit = () => {
  const location = useLocation();
  const state = location?.state;
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [button, setButton] = useState("");
  const [image, setImage] = useState(null);

  const navigate = useNavigate();
  const [editSlider] = useEditSliderMutation();
  const handleSubmitProduct = async () => {
    try {
      const data = {
        id: state?._id,
        title: title ? title : state?.title,
        text: text ? text : state?.text,
        button: button ? button : state?.button,
        image: image ? image : state?.image,
      };

      const response = await axios.patch(
        `https://www.giftshare.jp:5000/api/v1/slider/${state?._id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      // const response = await editSlider(data);

      if (response?.error) {
        toast.error("Error occurd...");
      } else {
        navigate("/dashboard/slider-setting");
      }
    } catch (error) {
      console.error("Error creating product:", error.response.data);
      toast.error(error.response?.data?.error);
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center p-4">
        <h1 className="text-xl font-bold">Edit Slider</h1>
        <div className="flex items-center gap-x-4">
          <Link
            to="/dashboard/slider-setting"
            className="bg-[#4E362A] px-2 py-1 rounded text-white flex items-center gap-x-1"
          >
            <AiFillBackward /> Back{" "}
          </Link>
        </div>
      </div>
      <div className="p-4 m-4 border">
        <form className=" grid grid-cols-1 lg:grid-cols-2  gap-6">
          <div className="flex flex-col gap-1">
            <label className="font-bold">Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
              className="border h-8 pl-1 focus:outline-none"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-bold">Title</label>
            <input
              type="text"
              defaultValue={state?.title}
              onChange={(e) => setTitle(e.target.value)}
              className="border h-8 pl-1 focus:outline-none"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-bold">Text</label>
            <input
              type="text"
              defaultValue={state?.text}
              onChange={(e) => setText(e.target.value)}
              className="border h-8 pl-1 focus:outline-none"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-bold">Button</label>
            <input
              type="text"
              defaultValue={state?.button}
              onChange={(e) => setButton(e.target.value)}
              className="border h-8 pl-1 focus:outline-none"
            />
          </div>
        </form>
        <button
          onClick={handleSubmitProduct}
          className="bg-blue-500 px-4 py-1 text-white font-bold block mx-auto mt-4"
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default SliderSettingEdit;
