import React, { useEffect, useState } from "react";
import { useGetAllProductsQuery } from "../../../features/api/productsApi";
import { useGetUserQuery } from "../../../features/api/loginApi";
import { useGetAlltestingboxQuery } from "../../../features/api/testingBox";
import { useGetOrderTestItemsQuery } from "../../../features/api/ordertestlistApi";
import axios from "axios";
import RatingPopup from "./RatingPopup";
import { useGetAllProductRatingQuery } from "../../../features/api/productratingApi";

const ProductRating = () => {
  const user = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const productRatings = useGetAllProductRatingQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  const filterProductRatings = productRatings?.filter(
    (pro) => pro?.user_id === user?._id
  );

  const products = useGetAllProductsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const testingBoxs = useGetAlltestingboxQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const orderTestItems = useGetOrderTestItemsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const [orders, setOrders] = useState([]);
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          `https://www.giftshare.jp:5000/api/v1/orderlist?role=${user?.role}&email=${user?.email}`
        );
        setOrders(response?.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [user?.role, user?.email]);

  const filterDeliverOrder = orders?.filter(
    (order) => order?.status === "Delivered"
  );

  const filterTestOrder = orderTestItems?.filter((box) => {
    return filterDeliverOrder?.some((order) => order?.orderId === box?.orderId);
  });

  const filtrTestBox = testingBoxs?.filter((box) => {
    return filterTestOrder?.some((order) => order?.testBoxId === box?._id);
  });

  const filterProductByBrand = products?.filter((product) => {
    return filtrTestBox?.some((box) => box?.brand === product?.brand);
  });

  const itemsPerRow = 4;
  const rows = [];
  for (let i = 0; i < filterProductByBrand?.length; i += itemsPerRow) {
    rows.push(filterProductByBrand?.slice(i, i + itemsPerRow));
  }

  return (
    <div>
      {/* For Desktop device */}
      {filterProductByBrand?.length === 0 ? (
        <div className="w-fll h-screen flex flex-col items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="hidden md:block p-4">
          {rows?.map((rowsItems, rowIndex) => (
            <div
              key={rowIndex}
              className={`grid grid-cols-4 gap-4 p-6 ${
                rowIndex < rows.length - 1
                  ? "border-b border-dashed border-[#4E362A]"
                  : ""
              }`}
            >
              {rowsItems?.map((product, index) => {
                const findRatPro = filterProductRatings?.find(
                  (pro) => pro?.pro_id == product?._id
                );
                return (
                  <div
                    key={product?._id}
                    className={`text-center ${
                      index % 4 === 0
                        ? ""
                        : "border-l border-dashed border-[#4E362A]"
                    } p-4`}
                  >
                    <div className="flex items-center justify-center">
                      <img
                        src={`https://www.giftshare.jp:5000/${product?.image}`}
                        alt={product?.name}
                        className="w-32 "
                      />
                    </div>
                    <div>
                      <p className="font-bold py-2">{product?.name}</p>
                      <p className="text-xs">{product?.desc}</p>
                      {findRatPro ? (
                        <p className="mt-4">
                          Rating {findRatPro?.rating} out of 5
                        </p>
                      ) : (
                        <button className="px-3 py-1 mt-4 bg-orange-950  rounded-sm">
                          <RatingPopup info={product} />
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      )}

      {/* For mobile device */}
      {filterProductByBrand?.length === 0 ? (
        <div className="w-fll h-screen flex flex-col items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="px-4 my-4 block md:hidden">
          {filterProductByBrand?.map((product) => {
            const findRatPro = filterProductRatings?.find(
              (pro) => pro?.pro_id == product?._id
            );
            return (
              <div
                key={product?._id}
                className={`flex flex-col items-center py-6 my-4 border border-dashed border-[#4E362A]`}
              >
                <div className="flex borde rounded-lg border-orange-950 p-2 items-center justify-center cursor-pointer">
                  <img
                    src={`https://www.giftshare.jp:5000/${product?.image}`}
                    alt={product?.name}
                    className="w-48"
                  />
                </div>
                <div className="flex flex-col items-center mt-4">
                  <p className="font-bold py-2 text-3xl">{product?.name}</p>
                  <p className="text-xl px-2 text-center">{product?.desc}</p>
                  {findRatPro ? (
                    <p className="mt-4">Rating {findRatPro?.rating} out of 5</p>
                  ) : (
                    <button className="px-3 py-1 mt-4 bg-orange-950  rounded-sm">
                      <RatingPopup info={product} />
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ProductRating;
