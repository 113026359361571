import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import {
  useGetSliersQuery,
  useRemoveSliderMutation,
} from "../../../../features/api/sliderApi";
import { AiFillEdit, AiFillPlusSquare } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";

const SliderSetting = () => {
  const { data, isLoading } = useGetSliersQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const sliders = data?.data;

  const [removeSlider] = useRemoveSliderMutation();
  const handleDelete = async (data) => {
    try {
      // const response = await axios.delete(
      //   `https://www.giftshare.jp:5000/api/v1/slider/${data?._id}`
      // );
      const response = await removeSlider(data?._id);
      if (response) {
        toast.success("Delete successfull...");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="px-8 pb-8">
      <div className="my-4 flex justify-end">
        <Link to="/dashboard/slider-setting/create">
          <button className="px-2 gap-x-1 rounded py-1 bg-[#4E362A] text-white flex items-center  justify-">
            {" "}
            <AiFillPlusSquare size={25} />
            <span>Create</span>
          </button>
        </Link>
      </div>
      {isLoading ? (
        <div className="w-fll h-screen flex flex-col items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : sliders?.length === 0 ? (
        <p>There are no slider</p>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {sliders?.map((slider) => {
            return (
              <Card key={slider?._id}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={`https://www.giftshare.jp:5000/${slider?.image}`}
                    alt={slider?.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5">
                      {slider?.title}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {slider?.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <div className="flex justify-between items-center w-full">
                    <Button size="small">
                      <span className="bg-[#4E362A] text-blue-200 px-4 py-1 rounded">
                        {" "}
                        {slider?.button}
                      </span>
                    </Button>
                    <div className="flex gap-x-2">
                      <button className="text-blue-500">
                        <Link
                          to="/dashboard/slider-setting/edit"
                          state={slider}
                        >
                          {" "}
                          <AiFillEdit size={25} />
                        </Link>
                      </button>
                      <button
                        onClick={() => handleDelete(slider)}
                        className="text-red-500"
                      >
                        {" "}
                        <MdDelete size={25} />
                      </button>
                    </div>
                  </div>
                </CardActions>
              </Card>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SliderSetting;
