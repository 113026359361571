import React, { useRef, useState } from "react";
import html2canvas from "html2canvas";
import "./VipCardSwiper.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import {
  EffectCoverflow,
  FreeMode,
  EffectCards,
  Navigation,
  Pagination,
  Keyboard,
} from "swiper";
import vipCard from "../../assets/images/vip/VIP-Card.png";

const VipCardSwiper = ({ filterVipNumbers, filterUsersByNum }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setCurrentSlideIndex(swiper.activeIndex);
  };

  const handleDownloadImage = () => {
    const currentSlide = document.querySelector(
      `.vipswiper-slide:nth-child(${currentSlideIndex + 1})`
    );

    if (currentSlide) {
      html2canvas(currentSlide).then(function (canvas) {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "VIP-CARD.png";

        link.click();
      });
    }
  };
  return (
    <div>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        freeMode={true}
        pagination={true}
        keyboard={true}
        className="vipswiper"
        onSlideChange={handleSlideChange}
      >
        {filterVipNumbers?.map((num, idx) => {
          const matchedNum = filterUsersByNum?.find(
            (uNum) => uNum?.numbers === num?.numbers
          );
          return (
            <SwiperSlide
              key={idx}
              className={`vipswiper-slide  ${
                matchedNum ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              onClick={handleDownloadImage}
            >
              {matchedNum ? (
                <div>
                  <img
                    src={vipCard}
                    className="relative"
                    alt="Vip Card Swiper"
                  />
                  <p
                    className="absolute left-[20%] bottom-[27%] text-[#DAA520] "
                    style={{ fontSize: "12px" }}
                  >
                    {num?.numbers?.slice(0, 4) + "-XXXX-XXXX-XXXX"}
                  </p>
                </div>
              ) : (
                <div>
                  <img
                    src={vipCard}
                    className="relative"
                    alt="Vip Card Swiper"
                  />
                  <p
                    className="absolute left-[20%] bottom-[27%] text-[#DAA520] "
                    style={{ fontSize: "12px" }}
                  >
                    {num?.numbers?.match(/.{1,4}/g)?.join("-")}
                  </p>
                </div>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default VipCardSwiper;
