import React, { useRef } from "react";
import { ImCoinYen } from "react-icons/im";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import TestingBoxModal from "../Dashboard/TestingBox/TestingBoxModal";
import { useGetAlltestingboxQuery } from "../../features/api/testingBox";
import { useGetVipnumbersQuery } from "../../features/api/vipnumberApi";
import {
  useEditUserprofileMutation,
  useGetAllUserQuery,
  useGetUserQuery,
} from "../../features/api/loginApi";
import { useGetAllTrendGiftQuery } from "../../features/api/trendingGift";
import { toast } from "react-toastify";
import RatingPopup from "./RatingPopup";
import VipCardSwiper from "./VipCardSwiper";
import {
  useAddCouponListMutation,
  useGetCouponListQuery,
  useGetExistingAffiliateCouponQuery,
} from "../../features/api/couponlistApi";
import randomstring from "randomstring";
import VipAffiliateCouponSwiper from "./VipAffiliateCouponSwiper";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import couponImage from "../../assets/images/vip/VIP-Card.png";
import html2canvas from "html2canvas";
import { useGetAllFaqQuery } from "../../features/api/faqApi";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const DashbordHome = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // ? Used for creating new coupon when there is no valid coupon for
  const [createUserAffiliateCoupon] = useAddCouponListMutation();

  const { data } = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const user = data?.data;

  const { data: existingCoupon } = useGetExistingAffiliateCouponQuery(
    user?._id,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const faqs = useGetAllFaqQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const [affiliateCouponCode, setAffiliateCouponCode] = useState("");

  const imageContainerRef = useRef(null);

  // // Helper function to draw a rounded image on the canvas
  // const drawRoundedImage = (ctx, image, x, y, width, height, borderRadius) => {
  //   ctx.beginPath();
  //   ctx.moveTo(x + borderRadius, y);
  //   ctx.arcTo(x + width, y, x + width, y + height, borderRadius);
  //   ctx.arcTo(x + width, y + height, x, y + height, borderRadius);
  //   ctx.arcTo(x, y + height, x, y, borderRadius);
  //   ctx.arcTo(x, y, x + width, y, borderRadius);
  //   ctx.closePath();
  //   ctx.clip();
  //   ctx.drawImage(image, x, y, width, height);
  // };

  // const drawRoundedImage = (ctx, image, x, y, width, height, borderRadius) => {
  //   ctx.save();

  //   // Create a rounded rectangle as a mask
  //   ctx.beginPath();
  //   ctx.moveTo(x + borderRadius, y);
  //   ctx.arcTo(x + width, y, x + width, y + height, borderRadius);
  //   ctx.arcTo(x + width, y + height, x, y + height, borderRadius);
  //   ctx.arcTo(x, y + height, x, y, borderRadius);
  //   ctx.arcTo(x, y, x + width, y, borderRadius);
  //   ctx.closePath();
  //   ctx.clip();

  //   // Draw the image inside the rounded rectangle mask
  //   ctx.drawImage(image, x, y, width, height);

  //   ctx.restore();
  // };

  const drawRoundedImage = (
    ctx,
    image,
    x,
    y,
    width,
    height,
    borderRadius,
    cropBottom
  ) => {
    ctx.save();

    // Adjust the height to crop from the bottom
    const croppedHeight = cropBottom ? height + 0.8 : height;

    // Create a rounded rectangle as a mask
    ctx.beginPath();
    ctx.moveTo(x + borderRadius, y);
    ctx.arcTo(x + width, y, x + width, y + croppedHeight, borderRadius);
    ctx.arcTo(x + width, y + croppedHeight, x, y + croppedHeight, borderRadius);
    ctx.arcTo(x, y + croppedHeight, x, y, borderRadius);
    ctx.arcTo(x, y, x + width, y, borderRadius);
    ctx.closePath();
    ctx.clip();

    // Draw the image inside the rounded rectangle mask
    ctx.drawImage(image, x, y, width, croppedHeight);

    ctx.restore();
  };

  // ? useEffect => for creating new coupon when there is no valid coupon
  useEffect(() => {
    // ? generateAndUploadImage => used to post request for uploading image
    const generateAndUploadImage = async () => {
      // ? if affiliateCouponCode is set and imageContainerRef has the imgae inside
      if (affiliateCouponCode !== "" && imageContainerRef.current) {
        const canvasOriginal = await html2canvas(imageContainerRef.current);

        // Create a new canvas for the rounded image
        const canvasRounded = document.createElement("canvas");
        const ctxRounded = canvasRounded.getContext("2d");

        const width = canvasOriginal.width;
        const height = canvasOriginal.height;

        canvasRounded.width = width;
        canvasRounded.height = height;

        // Draw the rounded image on the new canvas
        drawRoundedImage(
          ctxRounded,
          canvasOriginal,
          0,
          0,
          width,
          height,
          15, // specify the border radius here
          true // specify whether to crop from the bottom
        );

        // Convert the rounded canvas to blob
        const blob = await new Promise((resolve) =>
          canvasRounded.toBlob(resolve, "image/png")
        );
        // console.log("blob: ", blob);
        // ? inserting blob to formData for post request
        const formData = new FormData();
        formData.append("image", blob, "image.png");
        // ? axios.post
        try {
          const res = await axios.post(
            "https://www.giftshare.jp:5000/api/v1/affiliate-coupon-list/affiliate-coupon-image",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          return res;
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }
    };
    // ? if there is no existing coupon the operation will proceed
    if (existingCoupon?.isExisting === false) {
      // console.log("Coupon does not exist");
      // ? code => random coupon string
      const code = randomstring.generate({
        length: 8,
        charset: "hex",
      });
      // ? createAffiliateCoupon => async function that will be called to create new coupon
      const createAffiliateCoupon = async () => {
        /**
         * ? affiliateCouponCode => if affiliateCoupon is empty string then
         * ? new random generated string will be set as affiliateCouponCode
         * ? new affiliateCoupon will be set as the coupon code for the user
         */
        if (affiliateCouponCode === "") {
          setAffiliateCouponCode(code);
        }
        // ? invoking generateAndUploadImage function
        const createdImage = await generateAndUploadImage();
        // console.log("Created Image Info :", createdImage);
        // ? if affiliateCoupon is set as the coupon code for the user
        // ? and Coupon Image is successfully uploaded proceed to the following steps
        if (
          affiliateCouponCode !== "" &&
          createdImage?.data?.success === true
        ) {
          try {
            const newData = {
              code: affiliateCouponCode,
              userId: user?._id,
              coupon: createdImage?.data?.couponImage?.path,
            };

            // console.log("Coupon Data", newData);
            const res = await createUserAffiliateCoupon(newData).unwrap();
            console.log("Create New Coupon Response", res);
          } catch (error) {
            console.log(error);
          }
        }
      };
      createAffiliateCoupon();
    }
    // setCoponcaptcha(code);
  }, [
    createUserAffiliateCoupon,
    affiliateCouponCode,
    user?._id,
    existingCoupon?.isExisting,
  ]);

  const { data: allUser } = useGetAllUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const users = allUser?.data;

  const { data: testbox } = useGetAlltestingboxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const testingBoxs = testbox?.data;

  const { data: trend } = useGetAllTrendGiftQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const trendingGift = trend?.data;

  const filterTestingBox = testingBoxs?.filter((box) => {
    return trendingGift?.some((trend) => trend?.brand === box?.brand);
  });

  const { data: vip } = useGetVipnumbersQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const vipNumbers = vip?.data;

  const { data: couponlist } = useGetCouponListQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const couponList = couponlist?.data;
  const findUserAffiliateCoupon = couponList?.find(
    (coupon) => coupon?.userId === user?._id
  );

  const filterVipNumbers = vipNumbers?.filter(
    (num) => num?.userId === user?._id
  );

  const filterUsersByNum = users?.filter((user) => {
    return filterVipNumbers?.some(
      (vipNum) => vipNum?.numbers === user?.numbers
    );
  });
  const countForAgain = filterVipNumbers?.length - filterUsersByNum?.length;

  const getOrder = localStorage.getItem("orderFound");

  const [number, setNumber] = useState(null);
  const findNumber = number?.find((num) => num);
  const handleNumber = () => {
    let numbers = [];
    let num1 = padWithZeros(Math.floor(Math.random() * 10000));
    let num2 = padWithZeros(Math.floor(Math.random() * 10000));
    let num3 = padWithZeros(Math.floor(Math.random() * 10000));
    let num4 = padWithZeros(Math.floor(Math.random() * 10000));
    numbers.push(`${num1}${num2}${num3}${num4}`);
    setNumber(numbers);
  };
  const padWithZeros = (number) => {
    let str = String(number);
    while (str.length < 4) {
      str = "0" + str;
    }
    return str;
  };

  const navigate = useNavigate();
  const [updateProfile] = useEditUserprofileMutation();
  const handleConfirm = async () => {
    const data = {
      id: user?._id,
      numbers: findNumber,
    };
    try {
      const response = await updateProfile(data);
      if (response) {
        navigate("/user/dashboard/profile");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const numberMatched = vipNumbers?.filter(
    (num) => num?.numbers === findNumber
  );
  const findMatched = numberMatched?.find((num) => num);

  const [generatedNumbers, setGeneratedNumbers] = useState("");
  const generateNumbers = () => {
    let numbers = [];
    for (let i = 1; i <= 3; i++) {
      let num1 = padWithZeross(Math.floor(Math.random() * 10000));
      let num2 = padWithZeross(Math.floor(Math.random() * 10000));
      let num3 = padWithZeross(Math.floor(Math.random() * 10000));
      let num4 = padWithZeross(Math.floor(Math.random() * 10000));
      numbers.push(`${num1}${num2}${num3}${num4}`);
    }
    setGeneratedNumbers(numbers.join("\n"));
  };
  const padWithZeross = (number) => {
    let str = String(number);
    while (str.length < 4) {
      str = "0" + str;
    }
    return str;
  };
  // ? vipnumbers => generating random numstring for vip coupon
  useEffect(() => {
    if (generatedNumbers) {
      const handleGenerate = async () => {
        try {
          const response = await axios.post(
            "https://www.giftshare.jp:5000/api/v1/vipNumber",
            {
              numbers: generatedNumbers.split("\n"),
              userId: user?._id,
              userType: "VIP",
            }
          );
          if (response) {
            toast.success("Request Successfully...!");
          }
        } catch (error) {
          toast.error(error?.response);
        }
      };
      handleGenerate();
    }
  }, [generatedNumbers, user?._id]);
  // console.log("Testing:", testingBoxs);
  return (
    <div className="bg-[#733E24] p-2 lg:p-3 lg:pt-4 lg:h-[90vh] overflow-auto">
      {getOrder === "Yes" && <RatingPopup />}
      <div className="grid grid-cols-3 gap-3 mb-3 md:mb-3">
        <Link to="/user/dashboard/earning-history">
          <div className="flex items-center px-4 py-4 bg-black text-white rounded-lg justify-between bg-opacity-30">
            <ImCoinYen className="text-3xl hidden lg:block rounded-full bg-white p-1 text-black" />
            <div className="text-center lg:text-right">
              <h2 className="text-xl">
                {user?.earnedCoin ? user?.earnedCoin : 0}
              </h2>
              <p>Earning Points</p>
            </div>
          </div>
        </Link>
        <Link to="/user/dashboard/used-history">
          <div className="flex items-center px-4 py-4 bg-black text-white rounded-lg justify-between bg-opacity-30">
            <ImCoinYen className="text-3xl hidden lg:block rounded-full bg-white p-1 text-black" />
            <div className="text-center lg:text-right">
              <p className="text-xl">{user?.usedCoin ? user?.usedCoin : 0}</p>
              <p>Used Points</p>
            </div>
          </div>
        </Link>
        <div className="flex items-center px-4 py-4 bg-black text-white rounded-lg justify-between bg-opacity-30">
          <ImCoinYen className="text-3xl hidden lg:block rounded-full bg-white p-1 text-black" />
          <div className="text-center lg:text-right">
            <h2 className="text-xl">{user?.earnedCoin - user?.usedCoin}</h2>
            <p>Remaining Points</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-3">
        <div className="order-2 lg:order-1 space-y-3 lg:w-[66.3%]">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
            <div className="px-4 py-4 bg-black text-white rounded-lg justify-between bg-opacity-30">
              {user?.numbers ? (
                <div>
                  <p className="text-center">
                    Please share VIP cards with your friend
                  </p>

                  <div>
                    <VipCardSwiper
                      filterVipNumbers={filterVipNumbers}
                      filterUsersByNum={filterUsersByNum}
                    />
                  </div>
                  {countForAgain === 0 && (
                    <button
                      onClick={generateNumbers}
                      className="px-4 py-1 border mx-auto block mt-4 rounded-lg transition-all hover:border-[#733E24] duration-200 ease-in-out hover:bg-[#733E24] hover:text-white"
                    >
                      Request
                    </button>
                  )}
                  <p
                    className={`text-center text-xs italic text-slate-400 ${
                      countForAgain === 0 && "hidden"
                    }`}
                  >
                    Download any card by click!!!
                  </p>
                </div>
              ) : (
                <div>
                  <p className=" ">normal user</p>
                </div>
              )}
            </div>
            <div className="px-4 py-4 bg-black rounded-lg justify-between bg-opacity-30">
              <div>
                <div>
                  {faqs?.map((faq) => (
                    <Accordion
                      key={faq?._id}
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                      sx={{
                        textAlign: "justify",
                      }}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <Typography className="text-black">
                          {faq?.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className="text-white bg-black bg-opacity-60 p-4">
                          {faq?.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 flex items-center px-4 py-4 bg-black text-white rounded-lg justify-between bg-opacity-30">
            {/* <div className="relative">
              <img src={couponGif} className="w-[200px] z-10 " />
              {findUserAffiliateCoupon && (
                <p className="absolute left-2 z-50 top-2">
                  {findUserAffiliateCoupon?.code}
                </p>
              )}
            </div> */}
            {user?._id && (
              <VipAffiliateCouponSwiper
                userId={user?._id}
                findUserAffiliateCoupon={findUserAffiliateCoupon}
              />
            )}
          </div>
        </div>
        <div className="order-1 lg:order-2 lg:w-[32%] px-4 py-4 bg-black text-white rounded-lg justify-between bg-opacity-30">
          <h2 className="text-center mb-2">Chocolate Testing Box</h2>

          <div className="h-[400px] w-full overflow-auto m-auto">
            {user?.numbers ? (
              testingBoxs?.map((boxs) => {
                return (
                  <TestingBoxModal boxs={boxs} key={boxs?._id} />
                  // <div className="relative">
                  //   <Link to="/user/dashboard/testing-box-details" state={boxs}>
                  //     {" "}
                  //     <img
                  //       src={`https://www.giftshare.jp:5000/${boxs?.image}`}
                  //     />
                  //   </Link>
                  //   <p className="absolute left-1 top-1 text-black   px-2 py-1">
                  //     {boxs?.brand}
                  //   </p>

                  // </div>
                );
              })
            ) : user?.region && user?.district ? (
              filterTestingBox?.map((boxs) => {
                return <TestingBoxModal boxs={boxs} key={boxs?._id} />;
              })
            ) : (
              <div>
                <p className="text-justify">
                  This is our Subscription section. If your want to get this
                  facilities Please join with us as a mambership Number
                </p>
                <button
                  onClick={handleNumber}
                  className="px-3 mt-4 block mx-auto py-1 bg-black"
                >
                  Get VIP Number
                </button>
                <p className="flex justify-center items-center mt-6">
                  {number && number[0]?.match(/.{1,4}/g)?.join("-")}
                </p>
                {findMatched?.numbers && (
                  <p className="text-center">
                    ( Not available. Please click again )
                  </p>
                )}
                {number && !findMatched?.numbers && (
                  <button
                    onClick={handleConfirm}
                    className="px-3 mt-6 block mx-auto py-1 bg-black"
                  >
                    Confirm
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {affiliateCouponCode !== "" && (
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
              backgroundColor: "#AB8C49",
            }}
          >
            {
              <div className="d-flex flex-column justify-content-center align-items-center bg-[#AB8C49]">
                {/* This is where the image is created */}
                <div
                  ref={imageContainerRef}
                  style={{
                    width: "250px",
                    position: "relative",
                    backgroundColor: "#AB8C49",
                  }}
                >
                  {/* This div is not rendered in the UI */}
                  <img
                    src={couponImage}
                    alt="Background"
                    style={{ width: "250px" }}
                  />

                  <p
                    style={{
                      position: "absolute",
                      left: "35%",
                      bottom: "45px",
                      color: "#daa520",
                      fontWeight: "bold",
                    }}
                  >
                    {affiliateCouponCode}
                  </p>
                </div>
              </div>
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default DashbordHome;
