import React from "react";
import Container from "../../Components/Container";

const ChocolateKit = () => {
  return (
    <Container>
      <div className="min-h-[70vh] flex items-center justify-center">
        Comming soon
      </div>
    </Container>
  );
};

export default ChocolateKit;
