import React, { useState } from "react";
import OrderDetails from "./OrderDetails";
import {
  useEditStatusMutation,
  useGetOrdersQuery,
  useRemoveOrdersMutation,
} from "../../../features/api/ordersApi";
import { AiTwotoneDelete } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { useGetAllUserQuery } from "../../../features/api/loginApi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const Orders = () => {
  const { data: getAllUsers } = useGetAllUserQuery();
  const allUsers = getAllUsers?.data;

  const { data, isLoading } = useGetOrdersQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const orders = data?.data;
  const notDeliveredOrders = orders?.filter(
    (order) => order?.status !== "Delivered"
  );

  const orderStatus = ["Pending", "In Progress", "Delivered", "Canceled"];
  const COIN_VALUES = [0, 100, 50, 30, 12, 6, 2];

  const [updateStatus] = useEditStatusMutation();
  const handleStatusChange = async (e, row) => {
    const newStatus = e.target.value;
    const user = allUsers?.find((user) => user?.email === row?.email);
    if (!user) {
      console.error("User not found");
      return;
    }
    const userPositionIndex =
      allUsers.findIndex((user) => user?.email === row?.email) + 1;

    const userLevel =
      userPositionIndex <= 10
        ? 1
        : userPositionIndex <= 110
        ? 2
        : userPositionIndex <= 1110
        ? 3
        : userPositionIndex <= 11110
        ? 4
        : userPositionIndex <= 111110
        ? 5
        : userPositionIndex <= 1111110
        ? 6
        : 0;
    const getCoin = COIN_VALUES[userLevel];
    const parentUsers = [];
    for (let level = userLevel - 1; level >= 1; level--) {
      const levelMultiplier = Math.ceil(
        userPositionIndex / 10 ** (userLevel - level)
      );
      const parentPosition = `${level}:${levelMultiplier}`;
      const filteredUsers = allUsers.filter((user) => {
        const [level, position] = parentPosition.split(":").map(Number);
        return level > level;
      });

      if (filteredUsers.length > 0) {
        parentUsers.push(filteredUsers[0]);
      }
    }

    const confirm = window.confirm("Are you sure?");
    if (confirm) {
      if (newStatus == "Delivered") {
        try {
          const data = {
            id: row?._id,
            status: newStatus,
            shippName: row?.shippName,
            email: row?.email,
          };
          const response = await updateStatus(data);
          if (response?.data) {
            toast.success("Successfully changed...");
          }
          await axios.post("https://www.giftshare.jp:5000/api/v1/couponHistory", {
            userId: user._id,
            coin: getCoin,
            desc: `You Earned ${getCoin} points from this order #OR${row?.orderId}`,
            type: "E",
          });
          await axios.patch(`https://www.giftshare.jp:5000/api/v1/user/${user._id}`, {
            earnedCoin: getCoin,
          });

          for (let i = 0; i < parentUsers.length; i++) {
            const earnedCoin = COIN_VALUES[i + 1];
            if (parentUsers[i]) {
              await axios.patch(
                `https://www.giftshare.jp:5000/api/v1/user/${parentUsers[i]._id}`,
                {
                  earnedCoin,
                }
              );

              await axios.post("https://www.giftshare.jp:5000/api/v1/couponHistory", {
                userId: parentUsers[i]._id,
                coin: earnedCoin,
                desc: `You Earned ${earnedCoin} commission points`,
                type: "E",
              });
            }
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        const data = {
          id: row?._id,
          status: newStatus,
          shippName: row?.shippName,
          email: row?.email,
        };
        const response = await updateStatus(data);
        if (response?.data) {
          toast.success("Successfully changed...");
        }
      }
    }
  };

  const [removeOrder] = useRemoveOrdersMutation();
  const [searchText, setSearchText] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const deleteUser = (id) => {
    const confirm = window.confirm("Are you want do delete?");
    if (confirm) {
      removeOrder(id);
    }
  };

  const handleSort = (column, direction) => {
    setSortColumn(column.selector);
    setSortDirection(direction);
  };

  const filteredUsers = searchText
    ? notDeliveredOrders?.filter((order) =>
        order?.orderId.toLowerCase().includes(searchText.toLowerCase())
      )
    : notDeliveredOrders;

  const sortedUsers =
    sortColumn && sortDirection
      ? [...filteredUsers].sort((a, b) => {
          if (sortDirection === "asc") {
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
          } else if (sortDirection === "desc") {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
          }
          return 0;
        })
      : filteredUsers;

  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Order Id",
      cell: (row) => <>{row?.orderId}</>,
      sortable: true,
    },

    {
      name: "Name",
      cell: (row) => <>{row?.shippName}</>,
      sortable: true,
      width: "160px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      width: "240px",
    },
    {
      name: "Amount",
      cell: (row) => <>¥{row?.subtotal}</>,
      sortable: true,
    },
    {
      name: "Discount",
      cell: (row) => <>{row?.discount ? <p>¥{row?.discount}</p> : 0}</>,
      sortable: true,
    },
    {
      name: "Total",
      cell: (row) => <>¥{row?.grandTotal}</>,
      sortable: true,
    },
    {
      name: "Order Status",
      cell: (row) => (
        <select onChange={(e) => handleStatusChange(e, row)}>
          <option value="">--{row?.status}--</option>
          {orderStatus?.map((sts) => (
            <option value={sts}>{sts}</option>
          ))}
        </select>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Created At",
      cell: (row) => <button>{formatDate(row?.createdAt)}</button>,
      sortable: true,
      width: "110px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className=" flex">
          <button>
            {" "}
            <Link
              to="/dashboard/orders/details"
              className="p-1 bg-blue-500 font-bold rounded text-xs text-white"
              state={row}
            >
              View
            </Link>
          </button>
          <button
            onClick={() => deleteUser(row?._id)}
            className="text-red-500 flex justify-center"
            style={{ width: "40px", fontSize: "25px" }}
          >
            <AiTwotoneDelete></AiTwotoneDelete>
          </button>
        </div>
      ),
      sortable: false,
      width: "130px",
    },
  ];

  const sortIconStyles = {
    base: "mr-1",
    sortNone: "hidden",
    sortAsc: "text-green-500",
    sortDesc: "text-red-500",
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let getDate = day + "-" + month + "-" + year;
    return getDate;
  }
  return (
    <div className="p-2 bg-[#F4F6F9] pt-4">
      {/* <p>user position: {userPosition}</p>
      <p>Parent Levels: {parentLevels.join(", ")}</p>
      <p>Parent Positions: {parentPositions.join(", ")}</p> */}
      {isLoading ? (
        <div className="w-fll h-screen flex flex-col items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div>
          <div className="text-end">
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className=" mb-4 px-4 py-2 border border-gray-300 rounded"
            />
          </div>
          <DataTable
            columns={columns}
            data={sortedUsers}
            pagination
            highlightOnHover
            sortServer
            fixedHeader
            // responsive
            sortIconStyles={sortIconStyles}
            onSort={handleSort}
          />
        </div>
        // <div className="grid grid-cols-2 lg:grid-cols-6 gap-2 ">
        //   {orders?.map((order, index) => (
        //     <div className="p-4 bg-white shadow-md text-center" key={index}>
        //       <OrderDetails order={order} />
        //       <p className=" font-bold mt-4">
        //         {order?.isEnabled === true ? (
        //           <span className="text-red-500">Delivered</span>
        //         ) : (
        //           <span className="text-blue-500">Pending</span>
        //         )}
        //       </p>
        //     </div>
        //   ))}
        // </div>
      )}
    </div>
  );
};

export default Orders;
