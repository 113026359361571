import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetAllBlankBoxQuery } from "../../features/api/blankBoxApi";
// import required modules
import { InputAdornment, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../Components/Container";
import {
  removeFromCart,
  increaseQuantity,
  decreaseQuantity,
  setDiscount,
  resetDiscount,
} from "../../features/cart/cartSlice";
import "./AddToCart.css";
import { useGetAffilatesQuery } from "../../features/api/affilateApi";
import { toast } from "react-toastify";
import { useGetCouponConfigsQuery } from "../../features/api/couponconfigApi";
import { useGetCouponListQuery } from "../../features/api/couponlistApi";

const AddToCart = () => {
  const cart = useSelector((state) => state?.cart?.cart);
  const blank = useSelector((state) => state?.blank?.blank);
  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState("");

  const { data: couponconfig } = useGetCouponConfigsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const couponsConfig = couponconfig?.data;
  const findCouponConfig = couponsConfig?.find((d) => d);
  const setAmount = findCouponConfig?.amount;
  // console.log(setAmount)

  const { data: couponlist } = useGetCouponListQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const couponList = couponlist?.data;
  const findCoupon = couponList?.find((d) => d?.code == coupon);
  const findCouponMatch = findCoupon?.code;
  const findMatchByCoupon = findCoupon?.userId;
  // console.log(findMatchByCoupon);

  const subTotal = cart?.reduce(
    (total, item) => total + item?.price * item?.quantity,
    0
  );
  const discount =
    coupon === findCouponMatch ? (subTotal * setAmount) / 100 : 0;
  const grandTotal = subTotal - discount;

  const sendStateToCheckout = {
    cart,
    blank,
    discount,
    findMatchByCoupon,
  };

  localStorage.setItem("checkout", JSON.stringify(sendStateToCheckout));

  return (
    <Container>
      <div className="mb-6 min-h-[77vh]">
        <div className="flex flex-col">
          <table class="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden  my-5">
            <thead class="text-white">
              {cart?.map((data) => (
                <tr class="bg-[#4E362A] flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                  <th class="p-3 text-left">Product</th>
                  <th class="p-3 text-center">Price</th>
                  <th class="p-3 text-center">Quantity</th>
                  <th class="p-3 text-center">Total</th>
                  <th class="p-3 text-center">Actions</th>
                </tr>
              ))}
            </thead>

            <tbody class="flex-1 sm:flex-none">
              {cart?.map((box) => {
                return (
                  <tr class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                    <td class="border-grey-light border p-3 flex items-center gap-x-2">
                      {box.images?.length > 0 && (
                        <img
                          src={`https://www.giftshare.jp:5000/${box?.images[0]}`}
                          className="w-6 lg:w-16 h-6 lg:h-16 bg-white"
                        />
                      )}
                      <p className="capitalize text-center font-semibold text-sm block lg:hidden">
                        {box?.name}
                      </p>
                      <p className="capitalize text-center font-semibold hidden lg:block">
                        {box?.name}
                      </p>
                    </td>
                    <td class="border-grey-light border text-center p-3   cursor-pointer">
                      <p>¥{box?.price}</p>
                    </td>
                    <td className="border-grey-light border p-3 cursor-pointer">
                      <div className="flex flex-row justify-center h-10  text-center rounded-lg relative bg-transparent mt-1">
                        <button
                          onClick={() => dispatch(decreaseQuantity(box))}
                          className=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
                        >
                          <span className="m-auto text-2xl font-thin">−</span>
                        </button>
                        <span className="outline-none focus:outline-none text-center px-4 bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700">
                          {box?.quantity}
                        </span>
                        <button
                          onClick={() => dispatch(increaseQuantity(box))}
                          className="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
                        >
                          <span className="m-auto text-2xl font-thin">+</span>
                        </button>
                      </div>
                    </td>
                    <td class="border-grey-light border p-3 text-center  cursor-pointer">
                      <p>¥{box?.quantity * box?.price}</p>
                    </td>

                    <td
                      onClick={() => dispatch(removeFromCart(box))}
                      class="border-grey-light border p-1 text-center text-red-500 font-bold cursor-pointer"
                    >
                      <button>x</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="flex items-center px-4 "></div>

          <div className="flex ">
            <div className="flex-1 hidden lg:block">
              <div className="">
                <TextField
                  id="outlined-basic"
                  label="Coupon Code"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setCoupon(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiInputBase-root.Mui-disabled": {
                      "& > fieldset": {
                        borderColor: `#9A583B`,
                      },
                    },
                  }}
                />
                {coupon && coupon !== findCouponMatch ? (
                  <p className="text-red-500 font-bold text-xs">
                    Coupon didn't matched
                  </p>
                ) : (
                  coupon &&
                  coupon === findCouponMatch && (
                    <p className="text-xs font-bold text-green-500">
                      You get {setAmount}% discount
                    </p>
                  )
                )}
              </div>
            </div>
            <div className="flex-1 hidden lg:block"></div>
            <div className="flex-1">
              <h2 className="text-xl font-bold mb-3">Cart Totals</h2>
              <div className=" ">
                <div className="flex items-center p-2 justify-between border">
                  <p>Subtotal</p> <p>{subTotal}</p>
                </div>
                <div className="flex items-center p-2 justify-between border">
                  <p>Discount</p> <p>{discount}</p>
                </div>
                <div className="flex items-center p-2 justify-between border">
                  <p>Grand Total: </p> <p>{grandTotal}</p>
                </div>
                <button className="btn btn-sm bg-[#4E362A] hover:bg-[#4E362A] mt-2">
                  <Link to="/checkout"> Proceed To Checkout</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AddToCart;
