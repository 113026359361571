import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const productToAdd = action.payload;
      const existingProduct = state.cart.find(
        (product) => product._id === productToAdd._id
      );

      if (existingProduct) {
        existingProduct.quantity += 1;
      } else {
        state.cart.push({ ...productToAdd, quantity: 1, discountAmt: 0 });
      }
    },
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter(
        (product) => product._id !== action.payload._id
      );
    },
    removeAllFromCart: (state, action) => {
      if (action.payload === "all") {
        state.cart = [];
      }
    },
    increaseQuantity: (state, action) => {
      const productToIncrease = state.cart.find(
        (product) => product._id === action.payload._id
      );
      if (productToIncrease) {
        productToIncrease.quantity += 1;
      }
    },
    decreaseQuantity: (state, action) => {
      const productToDecrease = state.cart.find(
        (product) => product._id === action.payload._id
      );
      if (productToDecrease && productToDecrease.quantity > 1) {
        productToDecrease.quantity -= 1;
      }
    },
    setDiscount: (state, action) => {
      const { productId, discountAmt } = action.payload;
      const product = state.cart?.find((product) => product._id === productId);
      if (product) {
        product.discountAmt = discountAmt;
      }
    },
    resetDiscount: (state, action) => {
      const productId = action.payload;
      const product = state.cart?.find((product) => product._id === productId);
      if (product) {
        product.discountAmt = 0;
      }
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  removeAllFromCart,
  increaseQuantity,
  decreaseQuantity,
  setDiscount,
  resetDiscount,
} = cartSlice.actions;

export default cartSlice.reducer;
