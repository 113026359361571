import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://www.giftshare.jp:5000/api/v1",
  }),
  tagTypes: [
    "regions",
    "users",
    "user",
    "me",
    "userprofile",
    "product",
    "trend",
    "brand",
    "evn",
    "evnusr",
    "blankBox",
    "giftbox",
    "rating",
    "festival",
    "wishlist",
    "testingBox",
    "couponHistory",
    "affiliate",
    "vipNumber",
    "subscribe",
    "newsletter",
    "package",
    "orders",
    "orderItem",
    "orderTestItem",
    "region-default",
    "slider",
    "affiliate-coupon-config",
    "affiliate-coupon-list",
    "subscribe_admin",
    "product-rating",
    "festivalImage",
  ],
  endpoints: (builder) => ({}),
});
