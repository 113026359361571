import React from "react";
import InputMask from "react-input-mask";

const CardNumberInput = ({ value, onChange }) => {
  return (
    <InputMask
      mask="9999-9999-9999-9999"
      value={value}
      onChange={onChange}
      maskChar=""
      className="border p-1 w-full h-8 focus:outline-none"
      placeholder="0000-0000-0000-0000"
    />
  );
};

export default CardNumberInput;
