import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

const GiftBoxProductList = ({ proList }) => {
  // console.log(proList)
  const itemsPerRow = 4;
  const rows = [];
  for (let i = 0; i < proList?.length; i += itemsPerRow) {
    rows.push(proList?.slice(i, i + itemsPerRow));
  }

  return (
    <div className="">
      {rows?.map((rowsItems, rowIndex) => (
        <div
          key={rowIndex}
          className={`grid grid-cols-4 gap-4 p-8 ${
            rowIndex < rows.length - 1
              ? "border-b border-dashed border-[#4E362A]"
              : ""
          }`}
        >
          <PhotoProvider>
            {rowsItems?.map((product, index) => (
              <div
                key={product?._id}
                className={`text-center ${
                  index % 4 === 0
                    ? ""
                    : "border-l border-dashed border-[#4E362A]"
                } p-4`}
              >
                <div className="flex items-center justify-center cursor-pointer">
                  <PhotoView src={`https://www.giftshare.jp:5000/${product?.image}`}>
                    <img
                      src={`https://www.giftshare.jp:5000/${product?.image}`}
                      alt={product?.name}
                      className="w-32 "
                    />
                  </PhotoView>
                </div>
                <div>
                  <p className="font-bold py-2">{product?.name}</p>
                  <p className="text-xs">{product?.desc}</p>
                </div>
              </div>
            ))}
          </PhotoProvider>
        </div>
      ))}
    </div>
  );
};

export default GiftBoxProductList;
