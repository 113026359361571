import { apiSlice } from "./apiSlice";

const couponlistApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCouponList: builder.query({
      query: () => ({
        url: "/affiliate-coupon-list",
      }),
      providesTags: ["affiliate-coupon-list"],
    }),
    getExistingAffiliateCoupon: builder.query({
      query: (userId) => ({
        url: `/affiliate-coupon-list/existing/${userId}`,
      }),
      providesTags: ["affiliate-coupon-list"],
    }),
    addCouponList: builder.mutation({
      query: (newdata) => ({
        url: "affiliate-coupon-list",
        method: "POST",
        body: newdata,
      }),
      invalidatesTags: ["affiliate-coupon-list"],
    }),
    uploadCouponImage: builder.mutation({
      query: (data) => ({
        url: "affiliate-coupon-list/affiliate-coupon-image",
        method: "POST",
        contentType: "multipart/form-data",
        body: data,
      }),
    }),
    removeCouponList: builder.mutation({
      query: (id) => ({
        url: `/affiliate-coupon-list/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["affiliate-coupon-list"],
    }),
    editCouponList: builder.mutation({
      query: ({ id, ...editData }) => ({
        url: `/affiliate-coupon-list/${id}`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["affiliate-coupon-list"],
    }),
  }),
});

export const {
  useGetCouponListQuery,
  useGetExistingAffiliateCouponQuery,
  useUploadCouponImageMutation,
  useAddCouponListMutation,
  useRemoveCouponListMutation,
  useEditCouponListMutation,
} = couponlistApi;
