import { apiSlice } from "./apiSlice";

const ordersitemsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrderItems: builder.query({
      query: () => "/orderItem",
      providesTags: ["orderItem"],
    }),
    addOrderItem: builder.mutation({
      query: (newCategory) => ({
        url: "/orderItem",
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["orderItem"],
    }),
    removeOrderItem: builder.mutation({
      query: (id) => ({
        url: `/orderItem/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["orderItem"],
    }),
    editOrderItem: builder.mutation({
      query: (editData) => ({
        url: `/orderItem`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["orderItem"],
    }),
  }),
});

export const {
  useGetOrderItemsQuery,
  useAddOrderItemMutation,
  useRemoveOrderItemMutation,
  useEditOrderItemMutation,
} = ordersitemsApi;
