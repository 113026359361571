import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="min-h-[calc(100vh-190px)] p-8 text-justify">
      <h1 className="font-bold">Privacy Policy</h1> Effective Date: [Insert
      Date] At Giftshare Chocolate, we take your privacy seriously. This Privacy
      Policy outlines our practices concerning the collection, use, and
      protection of your personal information. By using our website or services,
      you consent to the practices described in this policy.
      <h1 className="font-bold"> 1. Information We Collect</h1>
      We may collect the following types of information: Personal Information:
      This includes your name, email address, shipping address, and payment
      information when you make a purchase or create an account. Non-Personal
      Information: We may collect non-personal information such as your IP
      address, browser type, and device information for analytical and security
      purposes. Cookies: We use cookies to enhance your browsing experience and
      provide personalized content. You can manage your cookie preferences in
      your browser settings.
      <h1 className="font-bold">2. How We Use Your Information</h1>
      We use your information for the following purposes: Order Fulfillment: To
      process and deliver your orders. Customer Support: To provide customer
      service and support. Marketing: To send you promotional and marketing
      communications if you have opted in. Analytics: To improve our website and
      services through analytics and market research.
      <h1 className="font-bold">3. Sharing Your Information</h1>
      We do not sell, trade, or rent your personal information to third parties.
      However, we may share your information with trusted service providers who
      assist us in operating our website and fulfilling orders.
      <h1 className="font-bold"> 4. Data Security</h1>
      We employ reasonable security measures to protect your information.
      However, no data transmission over the internet can be guaranteed as 100%
      secure.
      <h1 className="font-bold"> 5. Your Choices</h1>
      You can manage your personal information by accessing your account
      settings. You can opt out of marketing communications at any time by using
      the unsubscribe link provided in our emails.
    </div>
  );
};

export default PrivacyPolicy;
