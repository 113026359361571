import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./Ingredients.css";

// import required modules
import { FreeMode, Navigation, Pagination, Keyboard } from "swiper";

const Ingredients = ({ data }) => {
  // const allPhotos = data?.length > 0 && data[0]?.gallery;
  return (
    <PhotoProvider>
      <Swiper
        loop={true}
        // navigation={true}
        keyboard={true}
        slidesPerView={3}
        spaceBetween={10}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination, Navigation, Keyboard]}
        className="gswiper"
      >
        {data?.gallery?.map((g, index) => (
          <SwiperSlide className="gswiper-slider" key={index}>
            <PhotoView src={`https://www.giftshare.jp:5000/${g}`}>
              <img
                src={`https://www.giftshare.jp:5000/${g}`}
                alt=""
                className="w-[300px] h-[100px] cursor-pointer"
              />
              {/* <img src={data.image} className="" style={{ width: "250px" }} /> */}
            </PhotoView>
          </SwiperSlide>
        ))}
      </Swiper>
    </PhotoProvider>
    // <div className="flex overflow-auto">
    //   <PhotoProvider>
    //     {allPhotos?.map((d, i) => (
    //       <PhotoView src={`https://www.giftshare.jp:5000/${d}`}>
    // <img
    //   src={`https://www.giftshare.jp:5000/${d}`}
    //   alt=""
    //   className="w-[300px] cursor-pointer"
    // />
    //       </PhotoView>
    //     ))}
    //   </PhotoProvider>
    // </div>
  );
};

export default Ingredients;
