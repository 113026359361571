import React from "react";
import { RxCrossCircled } from "react-icons/rx";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  MdDashboard,
  MdOutlineSubscriptions,
  MdOutlineLocalShipping,
} from "react-icons/md";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { TbLogout } from "react-icons/tb";
import { RiMoneyCnyBoxLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsSidebarOpen,
  toggleSidebar,
} from "../../features/Toggle/sidebarSlice";

const SidebarMobile = () => {
  const dispatch = useDispatch();
  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };
  const isSidebarOpen = useSelector(selectIsSidebarOpen);

  const location = useLocation();
  const { pathname } = location;
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  return (
    <div
      className={`bg-gray-200 h-screen overflow-auto fixed z-50 w-[60%] transition-transform duration-300 transform p-4 block lg:hidden text-[#9A583B] ${
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <List>
        <button
          className="absolute text-red-500 text-2xl right-2 top-2 block lg:hidden"
          onClick={handleToggleSidebar}
        >
          <RxCrossCircled />
        </button>
        <Link to="/user/dashboard" onClick={handleToggleSidebar}>
          <ListItem
            className={`text-sm ${
              pathname === "/user/dashboard"
                ? "bg-[#9A583B] text-white font-bold mt-3"
                : ""
            }`}
          >
            <ListItemPrefix>
              <MdDashboard className="h-4 w-4" />
            </ListItemPrefix>
            Dashboard
          </ListItem>
        </Link>

        {/* Order History  */}

        <Link
          to="/user/dashboard/order-history"
          className="flex items-center gap-x-1"
          onClick={handleToggleSidebar}
        >
          <ListItem
            className={`text-sm ${
              pathname === "/user/dashboard/order-history"
                ? "bg-[#9A583B] text-white font-bold"
                : ""
            }`}
          >
            <ListItemPrefix>
              <AiOutlineUnorderedList className="h-5 w-5" />
            </ListItemPrefix>
            Order History
          </ListItem>
        </Link>

        {/* <Link
              to="/user/dashboard/order-history"
              className="flex items-center gap-x-1"
            >
              <ListItem
                className={`${
                  pathname === "/user/dashboard/wishlist"
                    ? "bg-[#9A583B] text-white font-bold"
                    : ""
                }`}
              >
                <ListItemPrefix>
                  <RiFileList3Line className="h-5 w-5" />
                </ListItemPrefix>
                Wish List
              </ListItem>
            </Link> */}
        {/* <Link to="/user/dashboard/subscription">
              <ListItem>
                <button
                  className={`${
                    pathname === "/user/dashboard/subscription"
                      ? "bg-blue-600 text-white"
                      : "bg-yellow-900 text-slate-50"
                  } mt-2 btn btn-outline hover:bg-slate-100 outline-none hover:outline-none hover:text-yellow-900 hover:border-none border-none `}
                >
                  Add Subscription
                </button>
              </ListItem>
            </Link> */}
        <Accordion
          open={open === 1}
          icon={
            <ChevronDownIcon
              strokeWidth={3}
              className={`mx-auto h-4 w-4 transition-transform ${
                open === 1 ? "rotate-180" : ""
              }`}
            />
          }
        >
          <ListItem className="p-0" selected={open === 1}>
            <AccordionHeader
              onClick={() => handleOpen(1)}
              className="border-b-0 p-3"
            >
              <ListItemPrefix>
                <MdOutlineSubscriptions className="h-4 w-4" />
              </ListItemPrefix>
              <Typography
                color="blue-gray"
                className="mr-auto font-normal text-sm"
              >
                Subscription
              </Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-1 overflow-hidden">
            <List className="p-0">
              <Link to="/user/dashboard/subscription">
                <ListItem>
                  <ListItemPrefix>
                    <ChevronDownIcon strokeWidth={3} className="h-3 w-5" />
                  </ListItemPrefix>
                  <button className="text-sm">Add Subscription</button>
                </ListItem>
              </Link>
            </List>
          </AccordionBody>
        </Accordion>

        {/* Affiliate Coupon for Product  */}
        <Link
          to="/user/dashboard/affiliate"
          className="flex items-center gap-x-1"
        >
          <ListItem
            className={`text-sm ${
              pathname === "/user/dashboard/affiliate"
                ? "bg-[#9A583B] text-white font-bold"
                : ""
            }`}
          >
            <ListItemPrefix>
              <RiMoneyCnyBoxLine className="h-4 w-4" />
            </ListItemPrefix>
            Affiliate Coupons
          </ListItem>
        </Link>
        <Link
          to="/user/dashboard/profile"
          className="flex items-center gap-x-1"
        >
          <ListItem
            className={`${
              pathname === "/user/dashboard/profile"
                ? "bg-[#9A583B] text-white font-bold"
                : ""
            }`}
          >
            <ListItemPrefix>
              <Cog6ToothIcon className="h-5 w-5" />
            </ListItemPrefix>
            Profile
          </ListItem>
        </Link>
        <Link to="/user/dashboard/rating" className="flex items-center gap-x-1">
          <ListItem
            className={`${
              pathname === "/user/dashboard/rating"
                ? "bg-[#9A583B] text-white font-bold"
                : ""
            }`}
          >
            <ListItemPrefix>
              <Cog6ToothIcon className="h-5 w-5" />
            </ListItemPrefix>
            Rating Us
          </ListItem>
        </Link>
      </List>
    </div>
  );
};

export default SidebarMobile;
