import React from "react";
import { Link } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import { useGetUserQuery } from "../../../features/api/loginApi";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Profile = () => {
  const { data: me } = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const user = me?.data;

  return (
    <div className="p-8">
      <div className="shadow-md flex flex-col text-center  items-center mx-auto lg:w-[400px]">
        <div className="">
          {/* <Avatar
            alt={user?.firstName}
            src="/static/images/avatar/1.jpg"
            className="block m-auto "
            sx={{ width: 56, height: 56 }}
          /> */}
          <div className="relative">
            <AccountCircleIcon sx={{ width: 64, height: 64 }} />
            <Link
              to="/user/dashboard/update-profile"
              className="text-blue-500 font-bold absolute right-24 bottom-0"
              state={user}
            >
              <AiFillEdit size={30} />
            </Link>
          </div>
          <p className="text-2xl text-blue-900 font-bold">
            {user?.firstName + " "}
            {user?.lastName}
          </p>
          <div className="flex flex-col items-start gap-y-1 py-2">
            {user?.numbers && (
              <p className="">
                <span className="font-bold">VIP Number:</span>{" "}
                {user?.numbers?.match(/.{1,4}/g)?.join("-")}
              </p>
            )}
            <p className="">
              <span className="font-bold">Email:</span> {user?.email}
            </p>
            <p className="">
              <span className="font-bold">Password:</span> {user?.password}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
