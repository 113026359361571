import React, { useState } from "react";
import { AiTwotoneDelete } from "react-icons/ai";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import { useGetAllNewsletterQuery } from "../../../features/api/newsletterApi";

const Newsletter = () => {
  const { data, isLoading } = useGetAllNewsletterQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const letters = data?.data;

  const [searchText, setSearchText] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const deleteUser = async (id) => {
    const confirm = window.confirm("Are you want do delete?");
    if (confirm) {
      try {
        const response = await axios.delete(
          `https://www.giftshare.jp:5000/api/v1/newsletter/${id}`
        );
        if (response) {
          toast.success("Delete successful!!!");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleSort = (column, direction) => {
    setSortColumn(column.selector);
    setSortDirection(direction);
  };

  const filterdLetter = searchText
    ? letters.filter((user) =>
        user?.email.toLowerCase().includes(searchText.toLowerCase())
      )
    : letters;

  const sortedLetter =
    sortColumn && sortDirection
      ? [...filterdLetter].sort((a, b) => {
          if (sortDirection === "asc") {
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
          } else if (sortDirection === "desc") {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
          }
          return 0;
        })
      : filterdLetter;

  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            onClick={() => deleteUser(row?._id)}
            className="text-red-500 flex justify-center"
            style={{ width: "40px", fontSize: "25px" }}
          >
            <AiTwotoneDelete></AiTwotoneDelete>
          </button>
        </>
      ),
      sortable: false,
    },
  ];

  const sortIconStyles = {
    base: "mr-1",
    sortNone: "hidden",
    sortAsc: "text-green-500",
    sortDesc: "text-red-500",
  };

  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="p-8">
        <div className="overflow-x-auto">
          <h2 className="text-xl font-bold mt-2">Manage Newsletter email</h2>
          <div className="text-end">
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className=" mb-4 px-4 py-2 border border-gray-300 rounded"
            />
          </div>
          <DataTable
            columns={columns}
            data={sortedLetter}
            pagination
            highlightOnHover
            sortServer
            fixedHeader
            // responsive
            sortIconStyles={sortIconStyles}
            onSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
