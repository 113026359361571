import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineRollback } from "react-icons/ai";
import { useGetUserQuery } from "../../../../features/api/loginApi";

const GenerateNumber = () => {
  const { data: me } = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const getMe = me?.data;

  const [value, setValue] = useState(null);
  const [generatedNumbers, setGeneratedNumbers] = useState("");
  const generateNumbers = () => {
    let numbers = [];
    for (let i = 0; i < value; i++) {
      let num1 = padWithZeros(Math.floor(Math.random() * 10000));
      let num2 = padWithZeros(Math.floor(Math.random() * 10000));
      let num3 = padWithZeros(Math.floor(Math.random() * 10000));
      let num4 = padWithZeros(Math.floor(Math.random() * 10000));
      numbers.push(`${num1}${num2}${num3}${num4}`);
      console.log(numbers);
    }
    setGeneratedNumbers(numbers.join("\n"));
  };
  const padWithZeros = (number) => {
    let str = String(number);
    while (str.length < 4) {
      str = "0" + str;
    }
    return str;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    generateNumbers();
  };

  const navigate = useNavigate();
  const saveToDatabase = async () => {
    try {
      const response = await axios.post(
        "https://www.giftshare.jp:5000/api/v1/vipNumber",
        {
          numbers: generatedNumbers.split("\n"),
          userId: getMe?._id,
          userType: getMe?.role,
        }
      );
      if (response) {
        // toast.success("Numbers saved to database successfully!");
        navigate("/dashboard/vip");
      }
    } catch (error) {
      toast.error("Error saving numbers to database.", error?.response);
    }
  };
  return (
    <div className="p-10">
      <Link
        to="/dashboard/vip"
        className="ml-auto block bg-green-500 text-white font-bold px-4 py-1 w-12"
      >
        <AiOutlineRollback />
      </Link>
      <form onSubmit={handleSubmit}>
        <input
          type="number"
          onChange={(e) => setValue(e.target.value)}
          className="border w-20 p-1 focus:outline-none"
        />
        <input
          type="submit"
          value="Generate"
          className="border cursor-pointer px-2 py-1"
        />
      </form>
      <br />
      <textarea
        value={generatedNumbers?.match(/.{1,4}/g)?.join(" ")}
        readOnly
        rows={14}
        cols={22}
        className="p-2"
      />
      <button
        onClick={saveToDatabase}
        className="px-4 py-1 bg-green-500 text-white font-bold block mt-4"
      >
        Save
      </button>
    </div>
  );
};

export default GenerateNumber;
