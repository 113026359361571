import React from "react";
import { BiSearch } from "react-icons/bi";
import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";
import jaTranslator from "../jpTranslator/ja.json";
import enTranslator from "../enTranslator/en.json";
i18n.use(initReactI18next).init({
  resources: {
    ja: {
      translation: jaTranslator,
    },
    en: {
      translation: enTranslator,
    },
  },
  lng: "ja",
  fallbackLng: "ja",
  interpolation: {
    escapeValue: false,
  },
});

const MobileSearch = () => {
  const { t } = useTranslation();
  return (
    <div className="p-6">
      <div className="relative block md:hidden">
        <input
          type="search"
          className=" placeholder:text-xl placeholder:italic focus:outline-0 h-12 w-full rounded-full pl-4 pr-14 placeholder:text-gray-800 bg-slate-100"
          placeholder={t("search")}
        />
        <button className="absolute top-2 right-4 font-bold text-[#433832]">
          <BiSearch size={34} />
        </button>
      </div>
    </div>
  );
};

export default MobileSearch;
