import { apiSlice } from "./apiSlice";

const affilateApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAffilates: builder.query({
      query: () => ({
        url: "/affiliate",
      }),
      providesTags: ["affiliate"],
    }),
    addAffilate: builder.mutation({
      query: (newCategory) => ({
        url: "affiliate",
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["affiliate"],
    }),
    removeAffilate: builder.mutation({
      query: (id) => ({
        url: `/affiliate/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["affiliate"],
    }),
    editAffilate: builder.mutation({
      query: (editData) => ({
        url: `affiliate`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["affiliate"],
    }),
  }),
});

export const {
  useGetAffilatesQuery,
  useAddAffilateMutation,
  useRemoveAffilateMutation,
  useEditAffilateMutation,
} = affilateApi;
