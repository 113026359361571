import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "../features/cart/cartSlice";
import { apiSlice } from "../features/api/apiSlice";
import sidebarReducer from "../features/Toggle/sidebarSlice";
import blankSlice from "../features/cart/blankSlice";
import languageReducer from "../features/Toggle/languageSlice";
import searchReducer from "../features/searchSlice";
import loggedinUserReducer from "../features/Toggle/loggedinUserSlice";
// import apiSlice from "../features/api/apiSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    cart: cartSlice,
    blank: blankSlice,
    sidebar: sidebarReducer,
    lang: languageReducer,
    products: searchReducer,
    loggedinUser: loggedinUserReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

// product= [
//   {
//       "_id": "64f77bcaa05a6146e35dca32",
//       "name": "Anniversary Giftbox",
//       "price": 4000,
//       "desc": "This is giftbox description",
//       "festival": "Marrige Anniversary",
//   },
//   {
//       "_id": "64f8cd60311c242b80b54bbd",
//       "name": "Birthday Giftbox",
//       "price": 3500,
//       "desc": "Send Free Happy Birthday Gift Boxes Card to Loved Ones on Birthday & Greeting Cards by Davia. It's 100% free, and you also can use your own customized birthday calendar and birthday reminders.",
//       "festival": "BirthDay Gift",
//   },
//   {
//       "_id": "64fa3c535aacb28cb2819ae2",
//       "name": "Valentine GiftBox",
//       "price": 6000,
//       "desc": "This is giftbox description ",
//       "festival": "Valentine Gift",
//   },
//   {
//       "_id": "6519d4e00c447664c07b9a02",
//       "name": "checng",
//       "price": 2321,
//       "desc": "This is giftbox description ",
//       "festival": "BirthDay Gift",
//   }
// ]
