import React from "react";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";
import { useGetAllGiftBoxQuery } from "../../../features/api/GiftBoxApi";
import { FacebookShareButton, FacebookIcon } from "react-share";
import { useGetAffilatesQuery } from "../../../features/api/affilateApi";

const UserAffiliate = () => {
  const { data: coupon } = useGetAffilatesQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const coupons = coupon?.data;

  const filterCoupons = coupons?.filter((coupon) => coupon?.isEnable === true);
  const { data } = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allGiftBox = data?.data;

  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="p-8">
        <div className="overflow-x-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold ">Manage Affiliate Coupon</h2>
          </div>
          <table className="table w-full mt-2">
            {/* head */}
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Coupon Code</th>
                <th>amount(%)</th>
                <th>GiftBox</th>
                <th>Share</th>
              </tr>
            </thead>
            <tbody>
              {filterCoupons?.map((coupon, i) => {
                const finding = allGiftBox?.find(
                  (box) => box?.name === coupon?.giftBox
                );
                // console.log(finding);
                return (
                  <tr key={coupon?._id}>
                    <th>{i + 1}</th>
                    <td>{coupon?.code}</td>
                    <td>{coupon?.amount}</td>
                    <td className="">
                      <img
                        src={`https://www.giftshare.jp:5000/${finding?.image}`}
                        className="w-12 h-12"
                      />
                      <span className="text-sm">{finding?.name}</span>
                    </td>
                    <td>
                      <FacebookShareButton
                        url={`https://andy-production.netlify.app/addtocart/GiftBox%201`}
                        quote={`Check out this product: ${finding?.name}`}
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserAffiliate;

// I have an array data as like products=[{id:1,name:mango, price:23, image:mango.jpeg},{id:2,name:apple, price: 30, image:apple.jpeg},{id:3,name:banana, price: 4, image:banana.jpeg}]. So, after map this array I want to display this products by table format as like,
// Sl no.   name     price    image       share                  action
// 1        mango     23    mango.jpeg   facebook, whatsapp      Add-to-Cart
// 2        apple     30    apple.jpeg   facebook, whatsapp      Add-to-Cart
// 3        banana     4    banana.jpeg  facebook, whatsapp      Add-to-Cart

// Now If I click Add-to-Cart button then go to cart page by individual product id. And If I click facebook button then data share into facebook news feeds with user id who is logged in. but I want to display only Image and name on facebook news feeds.So, how can I do this using react. Please give me code with proper solution
