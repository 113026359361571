import React from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../swiper.css";

import { Pagination, Navigation, Autoplay } from "swiper";
import { heroSliderLarge } from "../data";
import { heroSliderMobile } from "../data";

import Container from "./Container";

const HeroSlider = () => {
  return (
    <Container>
      <div className="hidden md:block">
        <Swiper
          modules={[Pagination, Navigation, Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          className=""
        >
          {heroSliderLarge?.map((slide) => {
            const { image } = slide;
            return (
              <SwiperSlide key={slide?.id}>
                <Link
                  to={`${slide?.id == 2 ? "/giftboxes" : "user/dashboard"}`}
                  className="pb-4 px-lg-5"
                >
                  <img
                    className={`rounded-lg object-cover`}
                    style={{ width: "100%" }}
                    src={image}
                    alt=""
                  />
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="block md:hidden">
        <Swiper
          modules={[Pagination, Navigation, Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          className=""
        >
          {heroSliderMobile?.map((slide) => {
            const { image } = slide;
            return (
              <SwiperSlide key={slide?.id}>
                <Link
                  to={`${slide?.id == 2 ? "/giftboxes" : "user/dashboard"}`}
                  className="pb-4 px-lg-5"
                >
                  <img
                    className={`rounded-lg object-cover`}
                    style={{ width: "100%" }}
                    src={image}
                    alt=""
                  />
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </Container>
  );
};

export default HeroSlider;
