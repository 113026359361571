import React, { useEffect, useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdAttachEmail } from "react-icons/md";
import { TiWorld } from "react-icons/ti";
import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";
import jaTranslator from "../../jpTranslator/ja.json";
import enTranslator from "../../enTranslator/en.json";
i18n.use(initReactI18next).init({
  resources: {
    ja: {
      translation: jaTranslator,
    },
    en: {
      translation: enTranslator,
    },
  },
  lng: "ja",
  fallbackLng: "ja",
  interpolation: {
    escapeValue: false,
  },
});

const Contact = () => {
  const [updateQuery, setUpdateQuery] = useState(false);
  const [mailQueryType, setMailQueryType] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerMessage, setCustomerMessage] = useState("");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setMailQueryType("Select");
  }, []);

  const { t } = useTranslation();
  const [queryTypes, setQueryTypes] = useState([]);
  useEffect(() => {
    const types = [
      "Place a new order",
      "Payment related issue",
      "Web tech issue",
      "Support",
      "Complaint",
      "Order belated",
      "Others",
    ];
    const filter = types.filter((t) => t !== mailQueryType);
    setQueryTypes(filter);
    setUpdateQuery(false);
  }, [mailQueryType, queryTypes]);
  return (
    <div className="min-h-[calc(100vh-190px)] p-4 lg:p-8 text-justify flex flex-col lg:flex-row justify-center items-center gap-4 lg:gap-0 text-[#513529] page-bg">
      {/* <h1 className="font-bold">Contact Us</h1> */}
      <div className="w-full md:w-[790px] ms-18 -me-0 lg:-me-28 xl:-me-32 py-10 px-2 sm:px-6 md:px-8 2xl:px-12 bg-[#fff] rounded-md shadow-md shadow-[#513529]">
        <div className="text-start px-2">
          <h1 className="text-sm font-bold">Let's get in touch</h1>
          <p className="text-3xl font-semibold mb-6">
            We're open for any suggestions!
          </p>
        </div>
        <div className="w-full grid grid-cols-1 lg:gap-x-8 2xl:gap-x-12 3xl:gap-x-16 gap-y-4 mx-auto px-2 items-start">
          <div className="flex items-center gap-x-2 text-start">
            <span className="text-3xl font-semibold p-1 rounded-full">
              <IoLocationSharp />
            </span>
            <p className="text-wrap font-medium">{t("companyaddress")}</p>
          </div>
          <div className="flex items-center xl:justify-start gap-x-2">
            <span className="text-2xl font-semibold p-2 rounded-full">
              <FaPhoneAlt />
            </span>
            <p className="font-medium">
              {/* <span>Phone:</span> */}
              <span>03-3351-3022</span>
            </p>
          </div>
          <div className="flex items-center gap-x-2 ">
            <span className="text-2xl font-semibold p-2 rounded-full">
              <MdAttachEmail />
            </span>
            <p className="font-medium">
              {/* <span>Email:</span> */}
              <span>info@giftshare.com</span>
            </p>
          </div>
          <div className="flex items-center xl:justify-start gap-x-2">
            <span className="text-3xl font-semibold p-1 rounded-full ">
              <TiWorld />
            </span>
            <p className="font-medium">andy.portfolio.com</p>
          </div>
        </div>
      </div>
      <div className="w-full md:w-[790px] bg-[#fff] py-4 lg:py-10 px-2 sm:px-6 md:px-8 2xl:px-12 rounded-md shadow-sm shadow-[#513529]">
        <div className="text-start my-2 lg:my-4 px-2">
          <h1 className=" text-3xl font-bold">Send us a message..</h1>
        </div>
        <div>
          <div className="my-4 flex flex-col md:flex-row justify-center items-center text-center">
            <label className="w-full md:w-1/2 px-4 py-[9px] bg-[#F5EBE5] font-semibold rounded-t-md md:rounded-t-none md:rounded-l-md md:rounded-tl-md">
              Type of your query
            </label>
            <select
              className="w-full md:w-1/2 py-2 px-2 flex-1 outline-none border-0 rounded-b-md md:rounded-b-none md:rounded-r-md md:rounded-br-md bg-[#513529] text-[#F5EBE5] text-center"
              onChange={(e) => {
                setUpdateQuery(true);
                setMailQueryType((prev) => e.target.value);
              }}
            >
              <option>-- {mailQueryType} --</option>
              {!updateQuery &&
                queryTypes?.map((st, i) => (
                  <option key={i} value={st}>
                    {st}
                  </option>
                ))}
            </select>
          </div>
          <div className="my-4 flex justify-center items-center">
            <label
              htmlFor="customerName"
              className="w-[105px] px-6 py-2 bg-[#513529] font-semibold rounded-l-md text-[#F5EBE5]"
            >
              Name
            </label>
            <input
              type="text"
              id="customerName"
              value={customerName}
              placeholder="Enter your full name ..."
              className="w-full py-2 px-4 flex-1 outline-none border-0 rounded-r-md bg-[#F5EBE5] text-base"
              onChange={(e) => setCustomerName(e.target.value)}
              required
            />
          </div>
          <div className="my-4 flex justify-center items-center">
            <label
              htmlFor="customerEmail"
              className="w-[105px] px-6 py-2 bg-[#513529] font-semibold rounded-l-md text-[#F5EBE5]"
            >
              Email
            </label>
            <input
              type="text"
              id="customerEmail"
              value={customerEmail}
              placeholder="Enter your email ..."
              className="w-full py-2 px-4 flex-1 outline-none border-0 rounded-r-md bg-[#F5EBE5] text-base"
              onChange={(e) => setCustomerEmail(e.target.value)}
              required
            />
          </div>{" "}
          <div className="my-4 flex justify-center items-center">
            <label
              htmlFor="customerNumber"
              className="w-[105px] px-6 py-2 bg-[#513529] font-semibold rounded-l-md text-[#F5EBE5]"
            >
              Mobile
            </label>
            <input
              type="text"
              id="customerNumber"
              value={customerNumber}
              placeholder="Enter your number ..."
              className="w-full py-2 px-4 flex-1 outline-none border-0 rounded-r-md bg-[#F5EBE5] text-base"
              onChange={(e) => setCustomerNumber(e.target.value)}
              required
            />
          </div>{" "}
          <div className="my-4 flex justify-center items-center">
            {/* <label
              htmlFor="customerMessage"
              className="px-6 py-2 bg-[#513529] font-semibold rounded-l-md text-[#F5EBE5]"
            >
              Mobile
            </label> */}
            <textarea
              type="text"
              id="customerMessage"
              value={customerMessage}
              rows={8}
              placeholder="Enter your message ..."
              className="py-2 px-4 flex-1 outline-none border-0 rounded-md bg-[#F5EBE5] text-base"
              onChange={(e) => setCustomerMessage(e.target.value)}
              required
            />
          </div>
          <div className="my-4 flex justify-center items-center">
            {/* <label
              htmlFor="customerMessage"
              className="px-6 py-2 bg-[#513529] font-semibold rounded-l-md text-[#F5EBE5]"
            >
              Mobile
            </label> */}
            <input
              type="submit"
              className="py-3 px-4 flex-1 outline-none border-0 rounded-md bg-[#523529] text-[#F5EBE5] text-lg font-semibold cursor-pointer"
              value="Send"
            />
          </div>
        </div>
        <div className="w-full"></div>
      </div>
      {/* Have questions, comments, or just want to get in touch? We're here to
      assist you. Feel free to reach out through any of the following methods:
      Email: [Insert Your Email Address] Phone: [Insert Your Phone Number]
      Mailing Address: [Insert Your Mailing Address] Social Media: Connect with
      us on [Insert Social Media Platforms] We strive to respond to all
      inquiries promptly and provide the best possible customer service. Your
      feedback is important to us, so please don't hesitate to get in touch. */}
    </div>
  );
};

export default Contact;
