import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination, Navigation, Keyboard } from "swiper";
import { AiFillStar } from "react-icons/ai";
import Container from "../Container";

import { useGetAllRatingQuery } from "../../features/api/ratingsApi";
import { useGetAllGiftBoxQuery } from "../../features/api/GiftBoxApi";
import { useGetAlltestingboxQuery } from "../../features/api/testingBox";
const Reviews = () => {
  const {
    data: rating,
    error,
    isLoading: ratingLoading,
  } = useGetAllRatingQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const ratings = rating?.data;

  const { data: giftBox, isLoading: boxLoading } = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allGiftBox = giftBox?.data;

  const { data: testbox } = useGetAlltestingboxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const testingBoxs = testbox?.data;

  const filterImage = allGiftBox?.filter((box) => {
    return ratings?.some((rat) => rat?.pro_id === box?._id);
  });

  const filterTestImage = testingBoxs?.filter((box) => {
    return ratings?.some((rat) => rat?.pro_id === box?.name);
  });

  const sumOfRating = ratings?.reduce((sum, rat) => sum + +rat?.rating, 0);
  const avrgRating = Number(sumOfRating) / ratings?.length;

  const getDate = (d) => {
    let date = new Date(d);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let getDates = day + "-" + month + "-" + year;
    return getDates;
  };

  const StarRating = (rating) => {
    const fullStars = Math.floor(rating);
    const stars = [];
    for (let i = 1; i <= fullStars; i++) {
      stars.push(
        <span className="" key={i}>
          <AiFillStar />
        </span>
      );
    }
    return <span className="flex items-center gap-x-1">{stars}</span>;
  };

  if (ratingLoading) {
    return (
      <div className="w-fll h-screen flex flex-col items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }
  if (error) {
    return <p>{error} error occurd</p>;
  }

  return (
    <Container>
      <div className="bg-[#733e24] py-6 mt-2">
        {" "}
        <div className="text-white px-6">
          <h1 className="text-2xl font-bold">Customer Stories</h1>
          <p className="text-sm">
            ( Rated {avrgRating.toFixed(2)}/5 based on {ratings?.length} ratings
            )
          </p>
        </div>
        <div className="">
          <div className="hidden md:block">
            <Swiper
              // loop={true}
              navigation={true}
              keyboard={true}
              slidesPerView={3}
              spaceBetween={30}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination, Navigation, Keyboard]}
              className="rgboxswiperr hidden md:block"
            >
              {ratings?.map((rat, index) => {
                const matchedProduct = allGiftBox?.find(
                  (box) => box?._id === rat?.pro_id
                );
                return (
                  <SwiperSlide
                    className="rgboxswiper-slider p-6"
                    key={rat?._id}
                  >
                    <div className="border border-slate-200 p-1 rounded-xl">
                      <div className="bg-slate-200  text-black flex items-center justify-center flex-col gap-y-3 py-4 rounded-lg">
                        <div className="flex flex-col items-center">
                          <div className="border border-white p-1 rounded-xl">
                            <img
                              src={`https://www.giftshare.jp:5000/${
                                matchedProduct?.images[0] ||
                                filterTestImage?.[filterTestImage?.length - 1]
                                  ?.image
                              }`}
                              className="h-16 w-16 object-cover rounded-lg"
                            />
                          </div>
                          <p>{matchedProduct?.name}</p>
                          <p className="pt-2 flex items-center gap-x-2 text-sm">
                            <span className="text-yellow-500">
                              {StarRating(rat?.rating)}
                            </span>
                          </p>
                        </div>

                        <p className=" font-serif text-center relative">
                          <span className="text-4xl absolute text-slate-700 -left-6 -bottom-2">
                            &#10077;
                          </span>
                          <span className="text-slate-500 italic">
                            {rat?.comment}
                          </span>
                          <span className="text-4xl absolute text-slate-700 -right-6 top-2">
                            &#10078;
                          </span>
                        </p>
                        <p className="font-light">- {rat?.user_id}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          <div className="block md:hidden">
            <Swiper
              // loop={true}
              navigation={true}
              keyboard={true}
              slidesPerView={1}
              //   spaceBetween={30}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination, Navigation, Keyboard]}
              className="rgboxswiperr"
            >
              {ratings?.map((rat, index) => {
                const boxImage = allGiftBox?.filter(
                  (box) => box?._id === rat?.pro_id
                );
                return (
                  <SwiperSlide
                    className="rgboxswiper-slider p-6"
                    key={rat?._id}
                  >
                    <div className="border border-slate-200 p-1 rounded-xl">
                      <div className="bg-slate-200 text-black flex items-center justify-center flex-col gap-y-2 py-3 rounded-lg">
                        <div className="flex flex-col items-center">
                          <div className="border border-white p-1 rounded-xl">
                            {filterImage?.length > 0 && (
                              <img
                                src={`https://www.giftshare.jp:5000/${filterImage[index]?.image}`}
                                className="h-12 w-12 object-cover rounded-lg"
                              />
                            )}
                          </div>
                          <p className="pt-2 flex items-center gap-x-2 text-sm">
                            <span className="text-yellow-500">
                              {StarRating(rat?.rating)}
                            </span>
                            | <span>{getDate(rat?.createdAt)}</span>
                          </p>
                        </div>

                        <p className=" font-serif text-center text-sm relative">
                          <span className="text-4xl absolute text-slate-700 -left-6 -bottom-2">
                            &#10077;
                          </span>
                          <span className="text-slate-500 italic">
                            {rat?.comment}
                          </span>
                          <span className="text-4xl absolute text-slate-700 -right-6 top-2">
                            &#10078;
                          </span>
                        </p>
                        <p className="font-light text-sm">- {rat?.user_id}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Reviews;
