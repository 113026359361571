import React, { useState } from "react";

const History = ({ data }) => {
  const [visible, setVisible] = useState(false);
  const [defaults, setDefaults] = useState(150);
  return (
    <div>
      <div className="py-6 px-2 lg:px-48 text-center lg:text-xl italic">
        <p>
          {data?.history?.slice(0, defaults)}{" "}
          {visible ? (
            <span
              onClick={() => {
                setDefaults(150);
                setVisible(!visible);
              }}
              className="text-sm cursor-pointer text-[#4E362A] font-bold"
            >
              ...See Less
            </span>
          ) : (
            <span
              onClick={() => {
                setDefaults(data?.history?.length);
                setVisible(!visible);
              }}
              className="text-sm cursor-pointer text-[#4E362A] font-bold"
            >
              ...See More
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default History;
