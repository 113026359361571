import { Link, useLocation } from "react-router-dom";
import { useGetAllGiftBoxQuery } from "../../features/api/GiftBoxApi";
import { useGetAllProductsQuery } from "../../features/api/productsApi";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import "./BrandsItem.css";
import { ImMinus, ImPlus } from "react-icons/im";
import { MdGroups } from "react-icons/md";
import "glightbox/dist/css/glightbox.min.css";
import Description from "../../Components/Description";
import DescriptionMobile from "../../Components/DescriptionMobile";
import { useGetAllBrandsQuery } from "../../features/api/brandApi";
import { useGetAllRatingQuery } from "../../features/api/ratingsApi";
import { AiFillStar } from "react-icons/ai";
import FavoriteIcon from "@mui/icons-material/Favorite";
import GiftBoxProductList from "./GiftBoxProductList";
import Ingredients from "./Ingredients";
import ProductListMobile from "./ProductListMobile";
import IngredientsMobile from "./IngredientsMobile";
import { toast } from "react-toastify";
import {
  useAddWishlistMutation,
  useGetWishlistsQuery,
  useRemoveWishlistMutation,
} from "../../features/api/wishlistApi";
import { useGetAllTrendGiftQuery } from "../../features/api/trendingGift";

// react swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import {
  EffectCoverflow,
  FreeMode,
  Navigation,
  Pagination,
  Keyboard,
} from "swiper";
import NewRegister from "./NewRegister";
import Location from "./Location";
import History from "./History";

import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";
import jaTranslator from "../../jpTranslator/ja.json";
import enTranslator from "../../enTranslator/en.json";

i18n.use(initReactI18next).init({
  resources: {
    ja: {
      translation: jaTranslator,
    },
    en: {
      translation: enTranslator,
    },
  },
  lng: "ja",
  fallbackLng: "ja",
  interpolation: {
    escapeValue: false,
  },
});

const BrandsItem = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const { t } = useTranslation();

  const location = useLocation();

  const brand = location?.state?.item;
  const passRegion = location?.state?.passRegion;
  const passDistrict = location?.state?.passDistrict;

  const getAllBrands = useGetAllBrandsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const allGiftbox = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const trendGift = useGetAllTrendGiftQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  // const filterTrendGift = trendGift?.filter(
  //   (trend) =>
  //     trend?.region === passRegion &&
  //     trend?.district === passDistrict &&
  //     trend?.brand !== brand?._id
  // );
  const filterTrendGift = trendGift?.filter(
    (trend) => trend?.brand !== brand?._id
  );

  const allRating = useGetAllRatingQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const allProducts = useGetAllProductsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const filteredProducts = useMemo(() => {
    return allProducts?.filter((pro) => pro?.brand === brand?._id);
  }, [allProducts, brand]);

  const remainingBrands = useMemo(() => {
    return getAllBrands?.filter((brand) => {
      return filterTrendGift?.some((trend) => trend?.brand === brand?._id);
    });
  }, [getAllBrands, filterTrendGift]);

  const similarGiftBox = useMemo(() => {
    return allGiftbox?.filter((box) => {
      return filterTrendGift?.some((trend) => trend?.brand === box?.brand);
    });
  }, [allGiftbox, filterTrendGift]);

  const [visibleBoxMobile, setVisibleBoxMobile] = useState(6);

  const handleShowMoreBoxMobile = () => {
    const nextVisibleItems = visibleBoxMobile + 6;
    if (nextVisibleItems <= similarGiftBox?.length) {
      setVisibleBoxMobile(nextVisibleItems);
    } else {
      setVisibleBoxMobile(similarGiftBox?.length);
    }
  };

  const allWishlist = useGetWishlistsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  const [addNewWishlist] = useAddWishlistMutation();
  const [removeWishlist] = useRemoveWishlistMutation();

  const handleWishlist = async (id) => {
    try {
      const saveData = { productId: id };

      await addNewWishlist(saveData);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await removeWishlist(id);
    } catch (error) {
      toast.error(error);
    }
  };

  // toggle button for desktop
  const [activeDiv, setActiveDiv] = useState(1);
  const toggleDiv = (divIndex) => {
    setActiveDiv(divIndex);
  };

  // toggle button for mobile
  const [activeFirst, setActiveFirst] = useState(false);
  const [indexFirst, setIndexFirst] = useState(0);
  const [activeSecond, setActiveSecond] = useState(false);
  const [indexSecond, setIndexSecond] = useState(0);
  const [activeThird, setActiveThird] = useState(false);
  const [indexThird, setIndexThird] = useState(0);
  const [activeFourth, setActiveFourth] = useState(false);
  const [indexFourth, setIndexFourth] = useState(0);

  const setToggleFirstDiv = (value) => {
    setIndexFirst(value);
    setIndexSecond(0);
    setIndexThird(0);
    setIndexFourth(0);
    setActiveFirst(!activeFirst);
    setActiveSecond(false);
    setActiveThird(false);
    setActiveFourth(false);
  };

  const setToggleSecondDiv = (value) => {
    setIndexSecond(value);
    setIndexFirst(0);
    setIndexThird(0);
    setIndexFourth(0);
    setActiveSecond(!activeSecond);
    setActiveFirst(false);
    setActiveThird(false);
    setActiveFourth(false);
  };

  const setToggleThirdDiv = (value) => {
    setIndexThird(value);
    setIndexFirst(0);
    setIndexSecond(0);
    setIndexFourth(0);
    setActiveThird(!activeThird);
    setActiveFirst(false);
    setActiveSecond(false);
    setActiveFourth(false);
  };

  const setToggleFourthDiv = (value) => {
    setIndexFourth(value);
    setIndexFirst(0);
    setIndexSecond(0);
    setIndexThird(0);
    setActiveFourth(!activeFourth);
    setActiveFirst(false);
    setActiveSecond(false);
    setActiveThird(false);
  };

  const [selectOpenClose, setSelectOpenClose] = useState(null);
  const [active, setActive] = useState(0);
  const handleOpenClose = (image, index) => {
    setActive(index);
    setSelectOpenClose(image);
  };

  return (
    <div className="w-full max-w- mx-auto md:pb-8">
      <div className="bg-gray-100 pt-2 md:pt-4">
        <div className="flex flex-col lg:flex-row py-2 lg:px-4 items-center lg:gap-x-24 bg-white mb-2 md:mb-4">
          <div>
            <img
              src={`https://www.giftshare.jp:5000/${brand?.logo}`}
              alt={brand?.name}
              className="lg:h-24 h-16"
            />
          </div>
          <p className="font-serif lg:text-2xl">{brand?.name}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10 py-6 bg-white">
          <div className="relative flex flex-col lg:flex-row flex-1 justify-center items-center bg-white ">
            <div className="flex flex-row order-2 lg:order-1 lg:flex-col items-center justify-center gap-3 bg-white shadow-sm px-4 py-2 flex-wrap lg:flex-nowrap">
              {brand?.images?.map((imgs, index) => (
                <div key={index}>
                  <button onClick={() => handleOpenClose(imgs, index)}>
                    <img
                      src={`https://www.giftshare.jp:5000/${imgs}`}
                      alt="Trending Brand"
                      className={`w-20 h-20 ${
                        active === index
                          ? "border border-[#4E362A]"
                          : "border-0"
                      }`}
                    />
                    
                  </button>
                </div>
              ))}
            </div>
            <div className="order-1 lg:order-2">
              {selectOpenClose ? (
                <img
                  src={`https://www.giftshare.jp:5000/${selectOpenClose}`}
                  alt="Trending Brand"
                  className="w-[370px] h-[350px] lg:w-[450px] lg:h-[450px] object-cover"
                />
              ) : (
                <img
                  src={`https://www.giftshare.jp:5000/${brand?.images?.[0]}`}
                  alt="Giftshare Trending Brand"
                  className="w-[370px] h-[350px] lg:w-[450px] lg:h-[450px] object-cover"
                />
              )}
            </div>
          </div>
          <div className="mt-4 md:mt-0 p-4 md:p-12 bg-[#FAF8F5] flex flex-col gap-3 md:gap-4">
            <p className="font-sans text-justify font-light">{brand?.desc}</p>
          </div>
        </div>

        {/* toggle button with div for desktop*/}
        <div className="bg-white px-6 hidden lg:block">
          <div className="flex justify-center space-x-12 py-6">
            <button
              className={`addGoogleFond font-mono uppercase text-3xl ${
                activeDiv === 1
                  ? "border-b-2 pb-2 border-[#4E362A]"
                  : "hover:border-b-2 pb-2 border-[#4E362A] transition-all duration-200 ease-in-out"
              }`}
              onClick={() => toggleDiv(1)}
            >
              {t("Assortment")}
            </button>
            <button
              className={`addGoogleFond font-mono uppercase text-3xl ${
                activeDiv === 2
                  ? "border-b-2 pb-2 border-[#4E362A]"
                  : "hover:border-b-2 pb-2 border-[#4E362A] transition-all duration-200 ease-in-out"
              }`}
              onClick={() => toggleDiv(2)}
            >
              {t("Gallery")}
            </button>
            <button
              className={`addGoogleFond font-mono uppercase text-3xl ${
                activeDiv === 3
                  ? "border-b-2 pb-2 border-[#4E362A]"
                  : "hover:border-b-2 pb-2 border-[#4E362A] transition-all duration-200 ease-in-out"
              }`}
              onClick={() => toggleDiv(3)}
            >
              {t("Location")}
            </button>
            <button
              className={`addGoogleFond font-mono uppercase text-3xl ${
                activeDiv === 4
                  ? "border-b-2 pb-2 border-[#4E362A]"
                  : "hover:border-b-2 pb-2 border-[#4E362A] transition-all duration-200 ease-in-out"
              }`}
              onClick={() => toggleDiv(4)}
            >
              {t("History")}
            </button>
          </div>
          {activeDiv === 1 && (
            <div>
              {" "}
              <GiftBoxProductList proList={filteredProducts} />
            </div>
          )}
          {activeDiv === 2 && (
            <div>
              {" "}
              <Ingredients data={brand} />{" "}
            </div>
          )}
          {activeDiv === 3 && (
            <div>
              <Location data={brand} />
            </div>
          )}
          {activeDiv === 4 && (
            <div>
              <History data={brand} />
            </div>
          )}
        </div>

        {/* toggle button with div for mobile*/}
        <div className="bg-white p-4 block lg:hidden">
          <div className="pb-4">
            <div
              onClick={() => setToggleFirstDiv(1)}
              className="flex items-center justify-between"
            >
              <button className={`font-mono uppercase text-xl`}>
                {t("Assortment")}
              </button>
              {activeFirst ? (
                <span>
                  <ImMinus />
                </span>
              ) : (
                <span>
                  <ImPlus />
                </span>
              )}
            </div>
            {indexFirst === 1 && activeFirst && (
              <ProductListMobile proList={filteredProducts} />
            )}
          </div>

          <div className="">
            <div
              onClick={() => setToggleThirdDiv(3)}
              className="flex items-center justify-between border-t border-dashed py-4"
            >
              <button className={`font-mono uppercase text-xl`}>
                {t("Gallery")}
              </button>
              {activeThird ? (
                <span>
                  <ImMinus />
                </span>
              ) : (
                <span>
                  <ImPlus />
                </span>
              )}
            </div>
            {indexThird === 3 && activeThird && (
              <h1>
                <IngredientsMobile data={brand} />
              </h1>
            )}
          </div>

          <div className="">
            <div
              onClick={() => setToggleFourthDiv(4)}
              className="flex items-center justify-between border-t border-dashed py-4"
            >
              <button className={`font-mono uppercase text-xl`}>
                {t("Location")}
              </button>
              {activeFourth ? (
                <span>
                  <ImMinus />
                </span>
              ) : (
                <span>
                  <ImPlus />
                </span>
              )}
            </div>
            {indexFourth === 4 && activeFourth && (
              <div>
                <Location data={brand} />
              </div>
            )}
          </div>

          <div className="">
            <div
              onClick={() => setToggleSecondDiv(2)}
              className="flex items-center justify-between border-t border-dashed pt-4"
            >
              <button className={`font-mono uppercase text-xl`}>
                {t("History")}
              </button>
              {activeSecond ? (
                <span>
                  <ImMinus />
                </span>
              ) : (
                <span>
                  <ImPlus />
                </span>
              )}
            </div>
            {indexSecond === 2 && activeSecond && (
              <div>
                <History data={brand} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="bg-[#FAF5FF] py-8 px-1 md:pl-8 lg:pl-20">
        <div>
          <div className="flex items-center py-2 justify-center gap-x-3">
            <MdGroups size={60} className="text-orange-600" />
            <div>
              <h1 className="text-2xl uppercase">{passDistrict}</h1>
              <h1 className="text-4xl italic font-mono font-bold bg-gradient-to-r from-[orange] to-[red] bg-clip-text text-transparent">
                Community
              </h1>
            </div>
          </div>
          <p className="text-base md:text-xl font-sans italic mt-2 text-center md:text-center flex flex-col md:flex-row gap-y-2 justify-center gap-x-2">
            <span>{t("Community1")}</span>
            <span className="hidden md:inline">/</span>
            <Link to="/workshop" className="underline underline-offset-2">
              {t("Community2")}
            </Link>
            <span className="hidden md:inline">/</span>
            <Link to="/chocolate-kits" className="underline underline-offset-2">
              {t("Community3")}
            </Link>
            <span className="hidden md:inline">/</span>
            <span> {t("Community4")}</span>
          </p>
        </div>
      </div>

      {/* Selected trending brands for desktop*/}
      <div className="hidden lg:block">
        {remainingBrands?.length > 0 && (
          <div className="bg-[#FAF5FF] pt-6">
            <Swiper
              slidesPerView={4}
              spaceBetween={30}
              // centeredSlides={true}
              keyboard={true}
              loop={true}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination, Keyboard]}
              className="communitySwiper"
            >
              {remainingBrands?.map((item) => {
                const passToBrandPage = { item, passRegion, passDistrict };
                return (
                  <SwiperSlide
                    key={item?._id}
                    className="p-2 bg-white"
                    // style={{ boxShadow: "0.5px -2px 8px 0.5px #433832" }}
                  >
                    <Link
                      to={`/brands/${item?.name}`}
                      state={passToBrandPage}
                      className="communitySwiper-slide flex justify-between flex-col border border-[#433832] border-dashed p-2"
                    >
                      <img
                        src={`https://www.giftshare.jp:5000/${item?.logo}`}
                        alt={item?.name}
                        className="log"
                      />
                      <img
                        src={`https://www.giftshare.jp:5000/${item?.images?.[0]}`}
                        alt={item?.name}
                        className="img"
                      />
                      <p className="font-bold">{item?.name}</p>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}
      </div>

      {/* Selected trending brands for mobile*/}
      <div className="block md:hidden">
        {remainingBrands?.length > 0 && (
          <div className="bg-[#FAF5FF]">
            <Swiper
              slidesPerView={1}
              spaceBetween={80}
              centeredSlides={true}
              loop={true}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination]}
              className="communitySwiper-mobile"
            >
              {remainingBrands?.map((item) => {
                const passToBrandPage = { item, passRegion, passDistrict };
                return (
                  <SwiperSlide
                    key={item?._id}
                    className="p-2 bg-white"
                    // style={{ boxShadow: "0.5px -2px 8px 0.5px #433832" }}
                  >
                    <Link
                      to={`/brands/${item?.name}`}
                      state={passToBrandPage}
                      className="communitySwiper-slide-mobile flex justify-between flex-col border border-[#433832] border-dashed p-2"
                    >
                      <img
                        src={`https://www.giftshare.jp:5000/${item?.logo}`}
                        alt={item?.name}
                        className="log"
                      />
                      <img
                        src={`https://www.giftshare.jp:5000/${item?.images?.[0]}`}
                        alt={item?.name}
                        className="img"
                      />
                      <p className="font-bold"> {item?.name}</p>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}
      </div>

      {/* New register for testing box by district */}
      <div className="  py-8 text-center lg:text-xl font-sans bg-[#FAF5FF]">
        <p className="mb-5 lg:w-[70%] mx-auto italic px-4 lg:px-0">
          If you want to test this brand chocolate please join this community
          for ordering testing chocolate from your dashboard.
        </p>
        <NewRegister passRegion={passRegion} passDistrict={passDistrict} />
      </div>

      {/* Similar Gift Box for desktop*/}
      <div className="mt-12 hidden lg:block px-6">
        <Description>Similar Gift Box</Description>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-2 md:gap-10 mt-8">
          {similarGiftBox?.map((box) => {
            const getRating = allRating?.filter(
              (rat) => rat?.pro_id === box?.name
            );
            const sumOfRating = getRating?.reduce(
              (sum, rat) => sum + +rat?.rating,
              0
            );
            const totalRating = getRating?.length;
            const passState = { box, totalRating };

            const findWishlist = allWishlist?.find(
              (list) => list?.productId === box?._id
            );
            return (
              <div className="relative rounded-2xl border " key={box?._id}>
                {/* <Link to={`/giftbox/${box?.name}`} state={passState}> */}
                <Link
                  to={`/giftbox-items/box/${box?._id}/${box?.name}`}
                  state={totalRating}
                >
                  <div className="">
                    <img
                      src={`https://www.giftshare.jp:5000/${box?.images[0]}`}
                      className={`w-full lg:h-[272px] rounded-t-2xl`}
                      alt={box?.name}
                    />

                    <div className="p-4 flex flex-col gap-y-2">
                      <h6 className="card-title text-sm text-gray-400">
                        {box?.name}
                      </h6>
                      <h4 className="text-[#433832] font-serif text-lg">
                        JPY {box?.price}
                      </h4>
                      {getRating && (
                        <p className="flex items-center">
                          {sumOfRating}{" "}
                          <span className="text-yellow-500 text-xs">
                            <AiFillStar />
                          </span>
                          <span className="ml-1">({getRating?.length})</span>
                        </p>
                      )}
                    </div>
                  </div>
                </Link>
                <button
                  onClick={
                    findWishlist
                      ? () => handleDelete(findWishlist?._id)
                      : () => handleWishlist(box?._id)
                  }
                  className={`text-xl absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer
                      ${findWishlist ? " text-red-500 " : " text-white "}`}
                  style={{ borderRadius: "0 10px 0 20px" }}
                >
                  <FavoriteIcon />
                </button>
              </div>
            );
          })}
        </div>
      </div>

      {/* similar gift box for mobile */}
      <div className="mt-8 block lg:hidden px-1">
        <DescriptionMobile>Similar Gift Box</DescriptionMobile>
        <div className="my-6 grid grid-cols-2 gap-1">
          {similarGiftBox?.slice(0, visibleBoxMobile)?.map((box, index) => {
            const getRating = allRating?.filter(
              (rat) => rat?.pro_id === box?.name
            );
            const sumOfRating = getRating?.reduce(
              (sum, rat) => sum + +rat?.rating,
              0
            );
            const totalRating = getRating?.length;
            const passState = { box, totalRating };

            const findWishlist = allWishlist?.find(
              (list) => list?.productId === box?._id
            );
            return (
              <div key={box?._id} className="">
                <div className="relative rounded-2xl border ">
                  {/* <Link to={`/giftbox/${box?.name}`} state={passState}> */}
                  <Link
                    to={`/giftbox-items/box/${box?._id}/${box?.name}`}
                    state={totalRating}
                  >
                    <div className="">
                      <img
                        src={`https://www.giftshare.jp:5000/${box?.images[0]}`}
                        className={`w-full h-[150px] rounded-t-2xl`}
                        alt={box?.name}
                      />

                      <div className="p-4 flex flex-col gap-y-2">
                        <h6 className="card-title text-sm text-gray-400">
                          {box?.name}
                        </h6>
                        <h4 className="text-[#433832] font-serif text-lg">
                          JPY {box?.price}
                        </h4>
                        {getRating && (
                          <p className="flex items-center">
                            {sumOfRating}{" "}
                            <span className="text-yellow-500 text-xs">
                              <AiFillStar />
                            </span>
                            <span className="ml-1">({getRating?.length})</span>
                          </p>
                        )}
                      </div>
                    </div>
                  </Link>
                  <button
                    onClick={
                      findWishlist
                        ? () => handleDelete(findWishlist?._id)
                        : () => handleWishlist(box?._id)
                    }
                    className={`text-xl absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer
                      ${findWishlist ? " text-red-500 " : " text-white "}`}
                    style={{ borderRadius: "0 10px 0 20px" }}
                  >
                    <FavoriteIcon />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        {visibleBoxMobile < similarGiftBox?.length && (
          <button
            onClick={handleShowMoreBoxMobile}
            className="bg-[#4E362A] text-white px-3 py-1 rounded block mx-auto mb-4"
          >
            Show More
          </button>
        )}
      </div>
    </div>
  );
};

export default BrandsItem;
