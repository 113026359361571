import { apiSlice } from "./apiSlice";

const wishlistApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWishlists: builder.query({
      query: () => "wishlist",
      providesTags: ["wishlist"],
    }),
    addWishlist: builder.mutation({
      query: (newCategory) => ({
        url: "wishlist",
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["wishlist"],
    }),
    removeWishlist: builder.mutation({
      query: (id) => ({
        url: `wishlist/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["wishlist"],
    }),
    editWishlist: builder.mutation({
      query: (editData) => ({
        url: `wishlist`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["wishlist"],
    }),
  }),
});

export const {
  useGetWishlistsQuery,
  useAddWishlistMutation,
  useRemoveWishlistMutation,
  useEditWishlistMutation,
} = wishlistApi;
