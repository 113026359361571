import { apiSlice } from "./apiSlice";

const vipnumberApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVipnumbers: builder.query({
      query: () => ({
        url: "/vipNumber",
      }),
      providesTags: ["vipNumber"],
    }),
    addVipnumber: builder.mutation({
      query: (newCategory) => ({
        url: "vipNumber",
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["vipNumber"],
    }),
    removeVipnumber: builder.mutation({
      query: (id) => ({
        url: `/vipNumber/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["vipNumber"],
    }),
    editVipnumber: builder.mutation({
      query: (editData) => ({
        url: `vipNumber`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["vipNumber"],
    }),
  }),
});

export const {
useGetVipnumbersQuery,
useAddVipnumberMutation,
useRemoveVipnumberMutation,
useEditVipnumberMutation
} = vipnumberApi;
