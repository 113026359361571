import React from "react";
import { Helmet } from "react-helmet-async";

const MetaDecoratorVipAffiliateCouponSwiper = ({
  shareUrl,
  couponImageUrl,
}) => {
  /* 
    const completeUrl = `https://enchanting-fenglisu-8eb9f5.netlify.app/?coupon=${encodeURIComponent(
    coupon
  )}`;
  */
  // const decodedImageUrl = couponImageUrl.replace("%2F", "/");
  const absoluteImageUrl = `https://www.giftshare.jp:5000/${encodeURIComponent(
    couponImageUrl
  )}`;
  // console.log(absoluteImageUrl);

  return (
    <Helmet>
      <meta
        property="og:title"
        content="Discount Coupon"
        data-react-helmet={true}
      />
      <meta
        property="og:description"
        content="Discount coupon code"
        data-react-helmet={true}
      />
      <meta property="og:type" content="website" data-react-helmet={true} />
      <meta
        property="og:url"
        content={`https://incredible-frangollo-f81366.netlify.app/`}
        data-react-helmet={true}
      />
      <meta
        property="fb:app_id"
        content="1255815975094440"
        data-react-helmet={true}
      />
      <meta
        property="og:image"
        content={absoluteImageUrl}
        data-react-helmet={true}
      />
      <meta
        property="og:image:type"
        content="image/png"
        data-react-helmet={true}
      />
      <meta property="og:image:width" content="1200" data-react-helmet={true} />
      <meta property="og:image:height" content="630" data-react-helmet={true} />

      {/* Twitter  */}
      <meta
        property="twitter:card"
        content="Giftshare: Share tasty sweetness with your friends and family"
      />

      <meta
        property="twitter:title"
        content="Meta Tags — GiftShare Preview Demo"
      />
      <meta
        property="twitter:description"
        content="With Meta Tags you can edit and experiment with your content then preview how your webpage will look on Google, Facebook, Twitter and more!"
      />
      <meta property="twitter:image" content={absoluteImageUrl} />
    </Helmet>
  );
};

export default MetaDecoratorVipAffiliateCouponSwiper;
