import { apiSlice } from "./apiSlice";

const festivalApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFestivals: builder.query({
      query: () => "festival",
      providesTags: ["festival"],
    }),
    addFestival: builder.mutation({
      query: (newCategory) => ({
        url: "festival",
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["festival"],
    }),
    removeFestival: builder.mutation({
      query: (id) => ({
        url: `festival/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["festival"],
    }),
    editFestival: builder.mutation({
      query: (editData) => ({
        url: `festival`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["festival"],
    }),
  }),
});

export const {
  useGetFestivalsQuery,
  useAddFestivalMutation,
  useRemoveFestivalMutation,
  useEditFestivalMutation,
} = festivalApi;
