import { apiSlice } from "./apiSlice";

const faqApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllFaq: builder.query({
      query: () => "/faq-all",
      providesTags: ["faq-all"],
    }),
    addFaq: builder.mutation({
      query: (newData) => ({
        url: "/faq-post",
        method: "POST",
        body: newData,
      }),
      invalidatesTags: ["faq-all"],
    }),
    removeFaq: builder.mutation({
      query: (id) => ({
        url: `/faq-remove/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["faq-all"],
    }),
    editFaq: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/faq-edit/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["faq-all"],
    }),
  }),
});

export const {
  useGetAllFaqQuery,
  useAddFaqMutation,
  useRemoveFaqMutation,
  useEditFaqMutation,
} = faqApi;
