import React from "react";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";
import { AiFillEdit, AiOutlinePlus, AiTwotoneDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useGetAllGiftBoxQuery } from "../../../features/api/GiftBoxApi";
import {
  useGetAffilatesQuery,
  useRemoveAffilateMutation,
} from "../../../features/api/affilateApi";

const Affiliate = () => {
  const { data: aflt } = useGetAffilatesQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const coupons = aflt?.data;

  const [removeAffilate] = useRemoveAffilateMutation();

  const { data } = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allGiftBox = data?.data;

  const handleEnable = async (id, isEnable) => {
    try {
      const response = await axios.patch(
        `https://www.giftshare.jp:5000/api/v1/affiliate/${id}`,
        {
          isEnable: !isEnable,
        }
      );
    } catch (error) {
      console.log(error?.response);
      toast.error(error.response?.data?.error);
    }
  };
  const handleDelete = async (id) => {
    const confirm = window.confirm("Are You Sure?");
    if (confirm) {
      try {
        const response = await removeAffilate(id);
        if (response) {
          toast.success("Successfully remove");
        }
      } catch (error) {
        console.log(error?.response);
        toast.error(error.response?.data?.error);
      }
    }
  };

  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="p-8">
        <div className="overflow-x-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold ">Manage Affiliate Coupon</h2>
            <Link to="/dashboard/affiliate/create">
              <button className="px-2 py-1 bg-blue-500 text-white  rounded flex items-center gap-x-1 font-light">
                <span className="">
                  {" "}
                  <AiOutlinePlus />{" "}
                </span>{" "}
                Add
              </button>
            </Link>
          </div>
          <table className="table w-full mt-2">
            {/* head */}
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Coupon Code</th>
                <th>amount(%)</th>
                <th>GiftBox</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {coupons?.map((coupon, i) => {
                const finding = allGiftBox?.find(
                  (box) => box?.name === coupon?.giftBox
                );
                // console.log(finding);
                return (
                  <tr key={coupon._id}>
                    <th>{i + 1}</th>
                    <td>{coupon?.code}</td>
                    <td>{coupon?.amount}</td>
                    <td className="">
                      {finding?.images?.length > 0 && (
                        <img
                          src={`https://www.giftshare.jp:5000/${finding?.images[0]}`}
                          className="w-12 h-12"
                        />
                      )}
                      <span className="text-sm">{finding?.name}</span>
                    </td>

                    <td>
                      <div className="flex items-center">
                        <Link to="/dashboard/affiliate/update" state={coupon}>
                          {" "}
                          <button
                            className="text-blue-500 flex justify-center"
                            style={{ width: "40px", fontSize: "25px" }}
                          >
                            <AiFillEdit />
                          </button>
                        </Link>
                        <button
                          onClick={() => handleDelete(coupon?._id)}
                          className="text-red-500 flex justify-center"
                          style={{ width: "40px", fontSize: "25px" }}
                        >
                          <AiTwotoneDelete />
                        </button>
                        <button
                          onClick={() =>
                            handleEnable(coupon?._id, coupon?.isEnable)
                          }
                          className={`${
                            coupon?.isEnable === true
                              ? "bg-green-500 px-2 py-1 text-xs text-white font-bold rounded"
                              : "bg-red-500 px-2 py-1 text-xs text-white font-bold rounded"
                          }`}
                        >
                          {coupon?.isEnable === false ? "Disable" : "Enable"}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Affiliate;
