import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetAllBrandsQuery } from "../../../features/api/brandApi";
import axios from "axios";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";
import { useAddGiftBoxMutation } from "../../../features/api/GiftBoxApi";
import { useGetFestivalsQuery } from "../../../features/api/festivalApi";
import { useGetAllProductsQuery } from "../../../features/api/productsApi";

const AddGiftItems = () => {
  const [name, setBoxName] = useState("");
  const [images, setImages] = useState([]);
  const [price, setPrice] = useState("");
  const [desc, setDesc] = useState("");
  const [festival, setFestival] = useState("");
  const [brand, setBrandName] = useState("");
  const [productList, setProductList] = useState([]);

  const { data } = useGetFestivalsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const festivals = data?.data;

  const [selectAll, setSelectAll] = useState(false);

  const allBrand = useGetAllBrandsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const products = useGetAllProductsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  const brandProducts = products?.filter((pro) => pro?.brand?._id === brand);

  const handlePruductchange = (name) => {
    const isSelected = productList.includes(name);
    if (isSelected) {
      setProductList(productList.filter((item) => item !== name));
    } else {
      setProductList([...productList, name]);
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allNames = brandProducts.map((item) => item.name);
      setProductList(allNames);
    } else {
      setProductList([]);
    }
    setSelectAll(!selectAll);
  };

  const [addGiftboxMutation] = useAddGiftBoxMutation();

  const handleSubmitGiftItem = async (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("name", name);
    form.append("price", price);
    form.append("desc", desc);
    form.append("festival", festival);
    form.append("brand", brand);
    form.append("productList", productList);

    for (let i = 0; i < images?.length; i++) {
      form.append("images", images[i]);
    }

    try {
      const response = await axios.post(
        "https://www.giftshare.jp:5000/api/v1/giftbox",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response) {
        toast.success("Successfully added");
        setBrandName("");
        setFestival("");
        setBoxName("");
        setPrice("");
        setDesc("");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };

  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="flex justify-center overflow-auto items-center mt-12 px-8 ">
        <div
          className="card bg-base-100 overflow-auto mb-12 rounded-none w-full"
          style={{ boxShadow: "1px 0px 3px 1px lightblue" }}
        >
          <div className="card-body">
            <div className="text-center">
              <h2 className="text-xl font-bold">Add Gift Items</h2>
            </div>
            {
              <form onSubmit={handleSubmitGiftItem} className="text-center">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 my-2  ">
                  <div>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setBoxName(e.target.value)}
                      placeholder="Gift Box Name"
                      className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none mb-2 mx-auto"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label>Open & Close Box</label>
                    <input
                      type="file"
                      multiple
                      onChange={(e) => setImages(e.target.files)}
                      className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none mb-2 mx-auto"
                    />
                  </div>
                  <select
                    onChange={(e) => setFestival(e.target.value)}
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none mb-2 mx-auto"
                  >
                    <option value="">--select festival--</option>
                    {festivals?.map((name) => (
                      <option value={name?._id} key={name?._id}>
                        {name?.name}
                      </option>
                    ))}
                  </select>
                  <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="Price"
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none mb-2 mx-auto"
                  />
                  <select
                    value={brand}
                    onChange={(e) => setBrandName(e.target.value)}
                    vlaue={brand}
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none hidden lg:block"
                  >
                    <option value={""}>--Select Brand--</option>
                    {allBrand?.map((brand, index) => (
                      <option key={index} value={brand?._id}>
                        {brand?.name}
                      </option>
                    ))}
                  </select>
                  <textarea
                    onChange={(e) => setDesc(e.target.value)}
                    rows="4"
                    value={desc}
                    className="input input-bordered h-16 rounded-none focus:border-none w-full max-w-xs lg:max-w-none mb-2 mx-auto"
                    placeholder="Description..."
                  ></textarea>

                  <select
                    value={brand}
                    onChange={(e) => setBrandName(e.target.value)}
                    vlaue={brand}
                    className="input input-bordered h-8 rounded-none focus:border-none w-full max-w-xs lg:max-w-none block lg:hidden mx-auto"
                  >
                    <option value={""}>--Select Brand--</option>
                    {allBrand?.map((brand, index) => (
                      <option key={index} value={brand?._id}>
                        {brand?.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Checkbox  */}
                <div className="dropdown dropdown-end">
                  <label tabIndex={0} className="ml-2  cursor-pointer">
                    Please Select The Product (Total Brand Product:{" "}
                    {brandProducts?.length})
                  </label>
                  <div>
                    <label className="inline-flex items-center gap-x-1 cursor-pointer my-2">
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                      {selectAll ? "Unselect All" : "Select All"}
                    </label>
                  </div>
                  <div className="grid grid-cols-2 lg:grid-cols-6 gap-4">
                    {brandProducts?.map((product) => (
                      <label
                        key={product._id}
                        className="inline-flex items-center gap-x-1"
                      >
                        <input
                          type="checkbox"
                          className="form-checkbox"
                          value={product?.name}
                          checked={productList.includes(product?.name)}
                          onChange={() => handlePruductchange(product?.name)}
                        />
                        {product?.name}
                      </label>
                    ))}
                  </div>
                </div>

                <div className="flex justify-around pt-6">
                  <button
                    type="submit"
                    className="bg-[#5e2006] px-4 rounded py-1 font-bold text-white "
                  >
                    Save
                  </button>
                </div>
              </form>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddGiftItems;
