import React, { useState } from "react";
import { useGetAllRegionQuery } from "../../../features/api/regionApi";
import { toast } from "react-toastify";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";
import {
  useEditRegionDefaultMutation,
  useGetRegionDefaultsQuery,
} from "../../../features/api/regiondefaultApi";

const RegionDefault = () => {
  const { data: gregion } = useGetAllRegionQuery();
  const allRegion = gregion?.data;

  const { data: regionDefault } = useGetRegionDefaultsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const byDefault = regionDefault?.data;
  const findByDefault = byDefault?.find((data) => data);

  const [region, setRegion] = useState("");
  const [district, setDistrict] = useState("");

  const selectDistrict = allRegion?.filter(
    (sregion) => sregion.region === region
  );

  const [updateDefault] = useEditRegionDefaultMutation();
  const handleTrendGift = async (e) => {
    e.preventDefault();

    const data = {
      id: findByDefault?._id,
      region: region ? region : findByDefault?.region,
      district: district ? district : findByDefault?.district,
    };
    try {
      const response = await updateDefault(data);

      if (response) {
        toast.success("Update Successfull...!!!");
        setRegion("");
        setDistrict("");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="p-8">
        <h1 className="mb-4 text-blue-500 font-bold">
          Change Default Region and District
        </h1>
        <div className="flex justify-center overflow-auto items-center mt-12">
          <div
            className="card bg-base-100 overflow-auto mb-12 rounded-none"
            style={{ boxShadow: "1px 0px 3px 1px lightblue" }}
          >
            <div className="card-body">
              {
                <form onSubmit={handleTrendGift} className="text-center">
                  <select
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                    className="border h-8 rounded-none focus:border-none w-full max-w-xs mx-auto mb-2"
                  >
                    <option value={findByDefault?.region}>
                      --{findByDefault?.region}--
                    </option>
                    {allRegion?.map((region) => (
                      <option key={region?._id} value={region?.region}>
                        {region?.region}
                      </option>
                    ))}
                  </select>
                  <select
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                    className="border h-8 rounded-none focus:border-none w-full max-w-xs mx-auto mb-2"
                  >
                    <option value={findByDefault?.district}>
                      --{findByDefault?.district}--
                    </option>
                    {selectDistrict?.[0]?.district?.map((dst, i) => (
                      <option key={i} value={dst}>
                        {dst}
                      </option>
                    ))}
                  </select>

                  <div className="flex justify-around pt-6">
                    <button
                      type="submit"
                      className="bg-[#5e2006] px-2 py-1 font-bold text-white "
                    >
                      Save
                    </button>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegionDefault;
