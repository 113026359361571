import React, { useState } from "react";
import { AiTwotoneDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";
import DataTable from "react-data-table-component";
import {
  useGetAlltestingboxQuery,
  useRemoveTestingboxMutation,
} from "../../../features/api/testingBox";
import { useGetAllBrandsQuery } from "../../../features/api/brandApi";
const ManageTestingBox = () => {
  const { data, isLoading } = useGetAlltestingboxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const testingBoxs = data?.data;

  const brands = useGetAllBrandsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const [removeTestingBox] = useRemoveTestingboxMutation();
  const handleDelete = async (id) => {
    const confirm = window.confirm("Are you want do delete?");
    if (confirm) {
      try {
        // const response = await axios.delete(
        //   `https://www.giftshare.jp:5000/api/v1/testingBox/${id}`
        // );
        const response = await removeTestingBox(id);

        if (response) {
          toast.success("Delete Successfull!!");
        }
      } catch (error) {
        console.error("Error updating data:", error);
      }
    }
  };

  // enable or disable
  const handleToggle = async (id, isEnabled) => {
    try {
      const response = await axios.patch(
        `https://www.giftshare.jp:5000/api/v1/testingBox/${id}`,
        {
          isEnabled: !isEnabled,
        }
      );
      if (response) {
        setTimeout(() => {
          window.location.reload();
        }, 50);
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  const [searchText, setSearchText] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = (column, direction) => {
    setSortColumn(column.selector);
    setSortDirection(direction);
  };

  const sortedTestingBox =
    sortColumn && sortDirection
      ? [...testingBoxs].sort((a, b) => {
          if (sortDirection === "asc") {
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
          } else if (sortDirection === "desc") {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
          }
          return 0;
        })
      : testingBoxs;

  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) => index + 1,
      sortable: false,
    },

    {
      name: "Testing-Box Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Price",
      selector: "price",
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`https://www.giftshare.jp:5000/${row?.image}`}
          className="w-16 h-16"
        />
      ),
      sortable: false,
    },
    {
      name: "Brand Name",
      selector: (row) => {
        const brandName = brands?.find((b) => b?._id === row?.brand);
        return <p className="">{brandName?.name}</p>;
      },
      sortable: true,
      width: "300px",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="text-blue-500"
            style={{ width: "40px", fontSize: "25px" }}
          >
            <Link to={`/dashboard/edit-testingbox`} state={row}>
              {" "}
              <FaEdit />
            </Link>
          </button>
          <button
            onClick={(e) => handleDelete(row?._id)}
            className="text-red-500"
            style={{ width: "40px", fontSize: "25px" }}
          >
            <AiTwotoneDelete />
          </button>
        </>
      ),
      sortable: false,
    },
  ];

  const sortIconStyles = {
    base: "mr-1",
    sortNone: "hidden",
    sortAsc: "text-green-500",
    sortDesc: "text-red-500",
  };

  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="p-8">
        <div className="overflow-x-auto">
          <h2 className="text-xl font-bold mt-2">Manage Testing-Box</h2>
          <div className="text-end">
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className=" mb-4 px-4 py-2 border border-gray-300 rounded"
            />
          </div>
          {isLoading ? (
            <div className="w-fll h-[80vh] flex flex-col items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={sortedTestingBox}
              pagination
              highlightOnHover
              sortServer
              fixedHeader
              responsive
              sortIconStyles={sortIconStyles}
              onSort={handleSort}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageTestingBox;
