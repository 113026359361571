import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

const ProductListMobile = ({ proList }) => {
  return (
    <div className="h-[700px] border-8 my-4 border-orange-950 overflow-y-auto">
      <PhotoProvider>
        {proList?.map((product, index) => {
          return (
            <div
              key={product?._id}
              className={`flex flex-col items-center gap-x-5 py-6 last:border-b-0 border-b border-dashed border-[#4E362A]`}
            >
              <div className="flex border-2 rounded-lg border-orange-950 p-2 items-center justify-center cursor-pointer">
                <PhotoView src={`https://www.giftshare.jp:5000/${product?.image}`}>
                  <img
                    src={`https://www.giftshare.jp:5000/${product?.image}`}
                    alt={product?.name}
                    className="w-64"
                  />
                </PhotoView>
              </div>
              <div className="flex flex-col items-center mt-4">
                <p className="font-bold py-2 text-3xl">{product?.name}</p>
                <p className="text-xl">{product?.desc}</p>
              </div>
            </div>
          );
        })}
      </PhotoProvider>
    </div>
  );
};

export default ProductListMobile;
