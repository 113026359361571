import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blank: [],
};

const blankSlice = createSlice({
  name: "blank",
  initialState,
  reducers: {
    addToBlank: (state, action) => {
      const productToAdd = action.payload;
      state.blank.push(productToAdd);
    },
    removeFromBlank: (state, action) => {
      state.blank = state.blank.filter(
        (product) => product._id !== action.payload._id
      );
    },
  },
});

export const { addToBlank, removeFromBlank } = blankSlice.actions;

export default blankSlice.reducer;
