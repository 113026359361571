import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./CheckoutDesktop.css";
import { useGetUserQuery } from "../../features/api/loginApi";
import axios from "axios";
import { toast } from "react-toastify";
import OrderConfirmationPopup from "./OrderConfirmationPopup";
import { useNavigate } from "react-router";

const CheckoutDesktop = ({ cart, blank, discount, passShippDetails }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const getData = JSON.parse(localStorage.getItem("checkout"));
  const userId = getData?.findMatchByCoupon;
  console.log(userId);

  const {
    shippName,
    shippEmail,
    shippRegion,
    shippDistrict,
    shippZip,
    shippPhone,
    shippAddress,
    paymentMethod,
    shippFee,
    pointsMethod,
    inputPoints,
  } = passShippDetails;

  const getShipping = shippFee === "Free" ? 0 : 10;
  const subTotals = cart?.reduce(
    (total, item) => total + item?.price * item?.quantity,
    0
  );
  const grandTotal = subTotals + getShipping - discount;

  const { data, isLoading } = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const user = data?.data;
  const email = user?.email;

  // genereate order number by uuid
  function generateOrderNumber() {
    const uuid = uuidv4();
    const truncatedOrderNumber = uuid.substr(0, 10);
    return truncatedOrderNumber;
  }
  const orderNumber = generateOrderNumber();

  const [showPopup, setShowPopup] = useState(false);
  const handlePopupClose = () => {
    setShowPopup(false);
  };
  const navigate = useNavigate();
  const handleCheckout = async () => {
    const data = {
      shippName,
      shippEmail,
      shippRegion,
      shippDistrict,
      shippZip,
      shippPhone,
      shippAddress,
      email,
      subtotal: subTotals,
      discount,
      grandTotal,
      paymentMethod,
      shippFee,
      orderId: orderNumber,
      userId,
    };
    const data2 = {
      cart,
      blank,
      orderId: orderNumber,
    };
    try {
      const response = await axios.post(
        "https://www.giftshare.jp:5000/api/v1/orders",
        data
      );

      if (response?.data) {
        setShowPopup(true);
        localStorage.removeItem("checkout");
      }
    } catch (error) {
      console.log("res1error", error);
    }

    try {
      const response2 = await axios.post(
        "https://www.giftshare.jp:5000/api/v1/orderItem",
        data2
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className=" lg:px-6 ">
      <div className="flex justify-between items-center">
        {" "}
        <p className="mb-4 font-bold">Products</p>
        <p className="mb-4 font-bold">Price</p>
      </div>
      <div className="space-y-3 border-b pb-6">
        {cart?.map((cat, i) => {
          const exist = blank?.find((b) => b?.festival === cat?.festival);
          return (
            <div className="flex items-center justify-between " key={cat?._id}>
              <div className="flex items-center gap-x-2">
                <div className="relative">
                  <img
                    src={`https://www.giftshare.jp:5000/${cat?.images?.[0]}`}
                    className="lg:w-20 lg:h-20 w-12 h-12 p-1 border"
                  />
                  <p className="lg:w-6 lg:h-6 w-4 h-4 text-sm rounded-full bg-[#4E362A] text-white flex items-center justify-center absolute -top-2 -right-2">
                    {cat?.quantity}
                  </p>
                </div>

                <div className="item relative">
                  <h2 className="text-sm">{cat?.name}</h2>

                  {exist && (
                    <p className="text-xs underline text-blue-600">
                      ( You chossed a blank box )
                    </p>
                  )}
                  {exist?.image && (
                    <img
                      src={`https://www.giftshare.jp:5000/${exist?.image}`}
                      className=" item-image"
                    />
                  )}
                </div>
              </div>
              <p className="text-sm">¥{cat?.price}</p>
            </div>
          );
        })}
      </div>
      <div className="space-y-2 text-sm lg:space-y-4 border-b py-6">
        <div className="flex items-center justify-between">
          <p>Subtotal:</p>
          <p className="font-bold">¥{subTotals}</p>
        </div>
        <div className="flex items-center justify-between">
          <p>Discount:</p>
          <p className="font-bold">¥{discount}</p>
        </div>
        <div className="flex items-center justify-between">
          <p>Shipping fee:</p>
          <p className="font-bold">¥{getShipping}</p>
        </div>
        <div className="flex items-center justify-between">
          <p className="font-bold">Total:</p>
          <p className="font-bold text-xl">¥{grandTotal}</p>
        </div>
      </div>
      <button
        onClick={handleCheckout}
        className="px-4 py-1 bg-[#4E362A] rounded text-white mt-2"
      >
        Checkout
      </button>
      {showPopup && <OrderConfirmationPopup onClose={handlePopupClose} />}
    </div>
  );
};

export default CheckoutDesktop;

// const [cod, setCod] = useState("");
// const [term, setTerm] = useState(false);
// const handleChecked = (e) => {
//   setTerm(!term);
//   if (cod === "Cash-on-Delivery") {
//     setCod("");
//   } else {
//     setCod("Cash-on-Delivery");
//   }
// };

{
  /* <label className="label cursor-pointer w-40 mx-auto">
<span className="label-text">Cash on Delivery</span>
<input
  onClick={handleChecked}
  type="radio"
  name="radio-10"
  className="radio checked:bg-blue-500"
  checked={term}
/>
</label> */
}
