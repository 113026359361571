import React from "react";
import { useLocation } from "react-router";
import Container from "../../../Components/Container";
import { useGetAllProductsQuery } from "../../../features/api/productsApi";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useGetFestivalsQuery } from "../../../features/api/festivalApi";
import { useGetAllBrandsQuery } from "../../../features/api/brandApi";

const SingleGiftbox = () => {
  const location = useLocation();
  const stateBox = location?.state;

  const productNames = stateBox?.productList[0]?.split(",");

  const festivals = useGetFestivalsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const festivalName = festivals?.find(
    (fes) => fes?._id === stateBox?.festival
  );

  const brands = useGetAllBrandsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  const brandName = brands?.find((b) => b?._id === stateBox?.brand);

  // fetch all products
  const allProducts = useGetAllProductsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const filteredProducts = allProducts?.filter((item) =>
    productNames.includes(item?.name)
  );
  return (
    <Container>
      <div className="py-8 flex flex-col gap-y-2 lg:gap-y-8">
        <div className="flex flex-col lg:flex-row gap-y-4 lg:items-start gap-5">
          <div className="flex-1 gap-x-4 grid grid-cols-2 gap-4">
            {stateBox?.images?.map((imge, idx) => {
              return (
                <div
                  key={idx}
                  className="border flex items-center justify-center"
                >
                  <img
                    src={`https://www.giftshare.jp:5000/${imge}`}
                    className="lg:w-40 w-32"
                    alt={stateBox?.name}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex-1">
            <Link
              to={`/dashboard/updategiftboxitem/${stateBox?._id}`}
              className="text-blue-500 text-xl"
            >
              {" "}
              <FaEdit />
            </Link>
            <h1 className="flex flex-col lg:items-center gap-x-1 lg:flex-row">
              <span className="text-2xl font-bold">{stateBox?.name}</span> (
              {festivalName?.name})
            </h1>

            <p className="font-bold text-orange-600 lg:text-xl">
              ¥{stateBox?.price}
            </p>
            <p className="font-bold mt-2">{brandName?.name} brand</p>
            <p className="lg:mt-2 text-justify">{stateBox?.desc}</p>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-3 lg:grid-cols-5">
            {filteredProducts?.map((pro) => {
              return (
                <div className="flex flex-col items-center justify-center">
                  <img
                    src={`https://www.giftshare.jp:5000/${pro?.image}`}
                    className="lg:w-24 w-20"
                    alt="giftbox"
                  />
                  <p>{pro?.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SingleGiftbox;
