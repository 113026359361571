import { apiSlice } from "./apiSlice";

const productratingApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProductRating: builder.query({
      query: () => "/product-rating",
      providesTags: ["product-rating"],
    }),
    addProductRating: builder.mutation({
      query: (newData) => ({
        url: "/product-rating",
        method: "POST",
        body: newData,
      }),
      invalidatesTags: ["product-rating"],
    }),
    removeProductRating: builder.mutation({
      query: (id) => ({
        url: `/product-rating/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["product-rating"],
    }),
    editProductRating: builder.mutation({
      query: (editData) => ({
        url: `product-rating`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["product-rating"],
    }),
  }),
});

export const {
  useGetAllProductRatingQuery,
  useAddProductRatingMutation,
  useRemoveProductRatingMutation,
  useEditProductRatingMutation,
} = productratingApi;
