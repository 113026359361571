import React, { useState } from "react";
import { AiOutlineReload, AiOutlineRollback } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGetAllGiftBoxQuery } from "../../../features/api/GiftBoxApi";
import axios from "axios";
import { toast } from "react-toastify";
import randomstring from "randomstring";

const UpdateCoupon = () => {
  const location = useLocation();
  const coupon = location?.state;
  const { data } = useGetAllGiftBoxQuery();
  const giftBox = data?.data;
  const navigate = useNavigate();

  const [amount, setAmount] = useState(0);
  const [giftBoxs, setGiftBox] = useState("");
  const [couponCode, setCouponCode] = useState("");

  const generateCouponCode = () => {
    const code = randomstring.generate({
      length: 8,
      charset: "alphanumeric",
    });

    setCouponCode(code);
  };

  const handleUpdate = async () => {
    try {
      const data = {
        code: couponCode ? couponCode : coupon?.code,
        amount: amount ? amount : coupon?.amount,
        giftBox: giftBoxs ? giftBoxs : coupon?.giftBox,
      };

      const response = await axios.patch(
        `https://www.giftshare.jp:5000/api/v1/affiliate/${coupon?._id}`,
        data
      );

      if (response) {
        navigate("/dashboard/affiliate");
      }
    } catch (error) {
      console.log(error?.response);
      toast.error(error.response?.data?.error);
    }
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-4 border-b border-b-gray-300 pb-4">
        <h2 className="text-xl font-bold ">Update Affiliate Coupon</h2>
        <Link to="/dashboard/affiliate">
          <button className="px-2 py-1 bg-blue-500 text-white  rounded flex items-center gap-x-1 font-light">
            <span className="">
              {" "}
              <AiOutlineRollback />{" "}
            </span>{" "}
            Back
          </button>
        </Link>
      </div>
      <div className="mt-6">
        <div className="w-[400px]">
          <div className="flex flex-col gap-2 ">
            <div className="w-full flex items-center relative">
              <label className="w-[42%]">Coupon Code :</label>
              <input
                type="text"
                value={couponCode ? couponCode : coupon?.code}
                className="border pl-1 h-8 rounded w-[58%]"
              />
              <button
                onClick={generateCouponCode}
                className="absolute right-2 text-xl"
              >
                <AiOutlineReload />
              </button>
            </div>
            <div className="flex items-center w-full">
              <label className="w-[42%]">Discount Amount (%) :</label>
              <input
                type="number"
                defaultValue={coupon?.amount}
                onChange={(e) => setAmount(e.target.value)}
                className="border pl-1 h-8 rounded w-[58%]"
              />
            </div>
            <div className="flex items-center w-full">
              <label className="w-[42%]">GiftBox :</label>
              <select
                defaultValue={coupon?.giftBox}
                onChange={(e) => setGiftBox(e.target.value)}
                className="border pl-1 h-8 rounded w-[58%]"
              >
                <option className="font-extralight">--select--</option>
                {giftBox?.map((box) => (
                  <option key={box?._id}>{box?.name}</option>
                ))}
              </select>
            </div>
          </div>
          <button
            onClick={handleUpdate}
            className="px-4 py-1 bg-blue-600 text-white font-bold rounded mt-4 cursor-pointer"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateCoupon;
