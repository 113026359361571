import React, { useState } from "react";
import axios from "axios";
import { AiFillBackward } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SliderSettingCreate = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [button, setButton] = useState("");
  const [image, setImage] = useState(null);

  const navigate = useNavigate();
  const handleSubmitProduct = async () => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("text", text);
      formData.append("button", button);
      formData.append("image", image);

      const response = await axios.post(
        "https://www.giftshare.jp:5000/api/v1/slider",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response) {
        toast.success("Successfully added");
        navigate("/dashboard/slider-setting");
      }
    } catch (error) {
      console.error("Error creating product:", error.response.data);
      toast.error(error.response?.data?.error);
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center p-4">
        <h1 className="text-xl font-bold">Create Slider</h1>
        <div className="flex items-center gap-x-4">
          <Link
            to="/dashboard/slider-setting"
            className="bg-[#4E362A] px-2 py-1 rounded text-white flex items-center gap-x-1"
          >
            <AiFillBackward /> Back{" "}
          </Link>
        </div>
      </div>
      <div className="p-4 m-4 border">
        <form className=" grid grid-cols-1 lg:grid-cols-2  gap-6">
          <div className="flex flex-col gap-1">
            <label className="font-bold">Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
              className="border h-8 pl-1 focus:outline-none"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-bold">Title</label>
            <input
              type="text"
              onChange={(e) => setTitle(e.target.value)}
              className="border h-8 pl-1 focus:outline-none"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-bold">Text</label>
            <input
              type="text"
              onChange={(e) => setText(e.target.value)}
              className="border h-8 pl-1 focus:outline-none"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-bold">Button</label>
            <input
              type="text"
              onChange={(e) => setButton(e.target.value)}
              className="border h-8 pl-1 focus:outline-none"
            />
          </div>
        </form>
        <button
          onClick={handleSubmitProduct}
          className="bg-blue-500 px-4 py-1 text-white font-bold block mx-auto mt-4"
        >
          Add Slider
        </button>
      </div>
    </div>
  );
};

export default SliderSettingCreate;
