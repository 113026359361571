import axios from "axios";
import React, { useState } from "react";
import { AiOutlineRollback } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Description from "../../../Components/Description";
import DescriptionMobile from "../../../Components/DescriptionMobile";
import { TextField } from "@mui/material";
import { useEditUserprofileMutation } from "../../../features/api/loginApi";

const UpdateProfile = () => {
  const location = useLocation();
  const userData = location?.state;

  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  
  const [updateProfile] = useEditUserprofileMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      id:userData?._id,
      firstName: firstName ? firstName : userData?.firstName,
      lastName: lastName ? lastName : userData?.lastName,
      email: email ? email : userData?.email,
      password: password ? password : userData?.password,
    };
    try {
      const response =await updateProfile(data)
      if (response) {
        navigate("/user/dashboard/profile");
      }
    } catch (error) {
      console.log(error?.response);
    }
  };
  return (
    <div className="p-8 ">
      <Link to="/user/dashboard/profile">
        {" "}
        <AiOutlineRollback />{" "}
      </Link>
      <h5 className="text-center text-[#9A583B] font-bold lg:hidden ">
        Edit Profile
      </h5>
      <div className="flex justify-center mt-4 lg:mt-10">
        <div className="grid grid-cols-1 gap-3 lg:gap-4 w-full lg:w-2/4 gap-x-8">
          <form onSubmit={handleSubmit} className="flex flex-col">
            {/* <div>
            <label>First Name: </label>
            <input
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
            defaultValue={userData?.firstName}
            className="border h-8 pl-1"
            />
          </div> */}
            <div className="form-control w-full lg:gap-4  ">
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                onChange={(e) => setFirstName(e.target.value)}
                defaultValue={userData?.firstName}
              />
            </div>
            {/* <div>
            <label>Last Name: </label>
            <input
              type="text"
              onChange={(e) => setLastName(e.target.value)}
              defaultValue={userData?.lastName}
              className="border h-8 pl-1"
            />
          </div> */}
            <div className="form-control w-full lg:gap-4 mt-4 ">
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                onChange={(e) => setLastName(e.target.value)}
                defaultValue={userData?.lastName}
              />
            </div>
            {/* <div>
              <label>Email: </label>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                defaultValue={userData?.email}
                className="border h-8 pl-1"
              />
            </div> */}
            <div className="form-control w-full lg:gap-4 mt-4 ">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                defaultValue={userData?.email}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
            {/* <div>
              <label>Password: </label>
              <input
                type="text"
                onChange={(e) => setPassword(e.target.value)}
                defaultValue={userData?.password}
                className="border h-8 pl-1"
              />
            </div> */}
            <div className="form-control w-full lg:gap-4 mt-4 ">
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                defaultValue={userData?.password}
              />
            </div>
            <div className="block mx-auto mt-4 ">
              <input
                type="submit"
                value="Update"
                className="border-none h-8 rounded-e-sm font-sm pl-2 w-32 cursor-pointer bg-[#9A583A] text-white"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfile;
