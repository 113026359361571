import React from "react";
import Sidebar from "./Sidebar";
import SidebarMobile from "./SidebarMobile";

const UserDashboard = () => {
  return (
    <div className="flex">
      <Sidebar className="" />
      <SidebarMobile />
      {/* <div className=" z-10  flex-1">
        <Outlet />
      </div> */}
    </div>
  );
};

export default UserDashboard;
