import React, { useState } from "react";
import { toast } from "react-toastify";
import { AiOutlineFolderAdd } from "react-icons/ai";
import { useGetAllBrandsQuery } from "../../../../features/api/brandApi";
import { useAddSubspackageMutation } from "../../../../features/api/subspackageApi";

const CreatePackage = () => {
  const { data } = useGetAllBrandsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allBrand = data?.data;

  const [addSubspackage] = useAddSubspackageMutation();

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleOptionClick = (event, optionValue) => {
    event.preventDefault();

    if (selectedOptions.includes(optionValue)) {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter((value) => value !== optionValue)
      );
    } else {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        optionValue,
      ]);
    }
  };

  const [name, setName] = useState("");
  const [amount, setAmount] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { name, amount, brand: selectedOptions };
    try {
      // const response = await axios.post(
      //   `https://www.giftshare.jp:5000/api/v1/package`,
      //   data
      // );
      const response = await addSubspackage(data);
      if (response) {
        setName("");
        setAmount("");
        setSelectedOptions("");
        setIsOpen(false);
        document.body.style.overflow = "auto";
      }
    } catch (error) {
      console.log(
        error?.response?.data?.message || error?.response?.data?.error
      );
      toast.error(
        error?.response?.data?.message || error?.response?.data?.error
      );
    }
  };
  return (
    <div>
      <button onClick={openModal} className="text-4xl text-blue-500 font-bold">
        <AiOutlineFolderAdd />
      </button>

      {isOpen && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-md lg:w-[600px] lg:mt-12">
            <button
              className="text-red-500 font-bold float-right"
              onClick={closeModal}
            >
              X
            </button>
            <div className="card bg-base-100 overflow-auto mt-4">
              <div className="card-body">
                <div className="text-center">
                  <h2 className="text-xl font-bold">Create Your New Package</h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="flex gap-x-2">
                    <div className="form-control w-full ">
                      <label className="label">Package Name</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="input input-bordered w-full h-8 rounded-none focus:border-none "
                        required
                      />
                    </div>
                    <div className="form-control w-full ">
                      <label className="label">Package Rate</label>
                      <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="input input-bordered w-full h-8 rounded-none focus:border-none "
                        required
                      />
                    </div>
                  </div>
                  <div className="form-control w-full ">
                    <label className="label">Package Product</label>
                    <select
                      value={selectedOptions}
                      multiple
                      className="max-h-[200px] overflow-auto"
                      required
                    >
                      {allBrand?.map((brand, i) => (
                        <option
                          onClick={(e) => handleOptionClick(e, brand?.name)}
                        >
                          {brand?.name}
                        </option>
                      ))}
                    </select>
                    <p className="">
                      <span className="font-bold">
                        {" "}
                        Selected Packege Products:
                      </span>{" "}
                      {selectedOptions && selectedOptions?.join(", ")}
                    </p>
                  </div>

                  <input
                    className="btn mt-6 block m-auto w-full border-none text-white"
                    style={{ backgroundColor: "#9A583B" }}
                    type="submit"
                    value="Create"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatePackage;
