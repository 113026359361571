import React, { useState } from "react";
import { AiTwotoneDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import {
  useGetAllGiftBoxQuery,
  useGetAllSelectGiftBoxQuery,
  useRemoveGiftBoxMutation,
  useUpdateBooleanBoxMutation,
} from "../../../features/api/GiftBoxApi";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
import DashBoardMenu from "../../../Components/DashBoardMenu/DashBoardMenu";
import DataTable from "react-data-table-component";
import { useGetFestivalsQuery } from "../../../features/api/festivalApi";
import { useGetAllBrandsQuery } from "../../../features/api/brandApi";

const GiftItemList = () => {
  const festivals = useGetFestivalsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const brands = useGetAllBrandsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const { data, isLoading } = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allGiftBox = data?.data;

  // const sortedAllgiftboxbyBrand = allGiftBox
  //   ? [...allGiftBox]?.sort((a, b) => b.brand - a.brand)
  //   : [];

  const [updateBooleanBox] = useUpdateBooleanBoxMutation();
  const handleUpdateBoolean = (updateBox) => {
    updateBooleanBox({
      id: updateBox?._id,
      isEnabled: !updateBox?.isEnabled,
    }).then((res) => console.log(res));
  };

  const [removeBox] = useRemoveGiftBoxMutation();

  const handleDelete = (id) => {
    const confirm = window.confirm("Are you want do delete?");
    if (confirm) {
      removeBox(id);
      toast.success("Delete Successfull!!");
    }
  };

  const [searchText, setSearchText] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = (column, direction) => {
    setSortColumn(column.selector);
    setSortDirection(direction);
  };

  const filteredGiftBoxs = searchText
    ? allGiftBox?.filter(
        (giftBox) =>
          giftBox?.name.toLowerCase().includes(searchText.toLowerCase()) ||
          giftBox?.brand.toLowerCase().includes(searchText.toLowerCase())
      )
    : allGiftBox;

  const sortedGiftBoxs =
    sortColumn && sortDirection
      ? [...filteredGiftBoxs].sort((a, b) => {
          if (sortDirection === "asc") {
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
          } else if (sortDirection === "desc") {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
          }
          return 0;
        })
      : filteredGiftBoxs;

  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) => <p className="">{index + 1}</p>,
      sortable: false,
      width: "70px",
    },

    {
      name: "Gift Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Box Image",
      cell: (row) => (
        <img
          src={`https://www.giftshare.jp:5000/${row?.images[0]}`}
          className="w-16"
          alt={row?.name}
        />
      ),
      sortable: false,
    },
    {
      name: "Brand Name",
      selector: (row) => {
        const brandName = brands?.find((b) => b?._id === row?.brand);
        return <p className="">{brandName?.name}</p>;
      },
      sortable: true,
    },
    {
      name: "Festival Name",
      selector: (row) => {
        const festivalName = festivals?.find(
          (fes) => fes?._id === row?.festival
        );
        return <p className="">{festivalName?.name}</p>;
      },
      sortable: true,
    },
    {
      name: "Show in festival",
      selector: (row) => {
        return (
          <button
            onClick={(e) => {
              e.preventDefault();
              console.log("Line 132: ");
              handleUpdateBoolean(row);
            }}
            className={`btn btn-xs ${
              row?.isEnabled ? "bg-green-500" : "bg-red-500"
            } border-0 text-black hover:text-white rounded-md`}
          >{`${row?.isEnabled}`}</button>
        );
      },
      sortable: true,
    },
    {
      name: "Price",
      selector: (row, index) => <p className="">¥{row?.price}</p>,
      sortable: true,
    },
    // {
    //   name: "Default Brand",
    //   cell: (row) => (
    //     <>
    //       {row.isEnabled === true ? (
    //         <button
    //           onClick={() => handleToggleDesable(row._id, row.isEnabled)}
    //           className={`${
    //             row.isEnabled === true
    //               ? "bg-red-500 px-2 py-1 text-white font-bold rounded"
    //               : "bg-green-500 px-2 py-1 text-white font-bold rounded"
    //           }`}
    //         >
    //           Disable
    //         </button>
    //       ) : (
    //         <button
    //           onClick={() =>
    //             handleToggleEnable(row?.brand, row._id, row.isEnabled)
    //           }
    //           className={`${
    //             row.isEnabled === true
    //               ? "bg-red-500 px-2 py-1 text-white font-bold rounded"
    //               : "bg-green-500 px-2 py-1 text-white font-bold rounded"
    //           }`}
    //         >
    //           Enabable
    //         </button>
    //       )}
    //     </>
    //   ),
    //   sortable: false,
    //   width: "120px",
    // },

    //   <button
    //   onClick={() => handleToggle(row?.brand, row._id, row.isEnabled)}
    //   className={`${
    //     row.isEnabled === true
    //       ? "bg-red-500 px-2 py-1 text-white font-bold rounded"
    //       : "bg-green-500 px-2 py-1 text-white font-bold rounded"
    //   }`}
    // >
    //   {row.isEnabled ? "Disable" : "Enable"}
    // </button>

    {
      name: "Action",
      cell: (row) => (
        <div className="flex gap-x-2">
          <button className="text-blue-500 text-xl">
            <Link to={`/dashboard/updategiftboxitem/${row?._id}`}>
              {" "}
              <FaEdit />
            </Link>
          </button>
          <button
            onClick={(e) => handleDelete(row?._id)}
            className="text-red-500 text-xl"
          >
            <AiTwotoneDelete />
          </button>
          <Link
            to="/dashboard/giftbox/box-details"
            state={row}
            className="text-blue-500 font-bold"
          >
            View
          </Link>
        </div>
      ),
      sortable: false,
      width: "150px",
    },
  ];

  const sortIconStyles = {
    base: "mr-1",
    sortNone: "hidden",
    sortAsc: "text-green-500",
    sortDesc: "text-red-500",
  };

  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="p-8">
        <div className="overflow-x-auto">
          <h2 className="text-xl font-bold mt-2">All Gift Box</h2>
          <div className="text-end">
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className=" mb-4 px-4 py-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          {isLoading ? (
            <div className="w-fll h-[70vh] flex flex-col items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={sortedGiftBoxs}
              pagination
              highlightOnHover
              sortServer
              fixedHeader
              responsive
              sortIconStyles={sortIconStyles}
              onSort={handleSort}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GiftItemList;
