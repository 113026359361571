import React from "react";
import { useGetAllProductRatingQuery } from "../../../../features/api/productratingApi";
import { useGetAllProductsQuery } from "../../../../features/api/productsApi";

const TestingProductRating = () => {
  const { data, isLoading } = useGetAllProductRatingQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const productRatings = data?.data;

  const products = useGetAllProductsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const itemsPerRow = 5;
  const rows = [];
  for (let i = 0; i < productRatings?.length; i += itemsPerRow) {
    rows.push(productRatings?.slice(i, i + itemsPerRow));
  }

  return (
    <div>
      {/* For Desktop device */}
      {productRatings?.length === 0 ? (
        <p>There are no rating product</p>
      ) : isLoading ? (
        <div className="w-fll h-screen flex flex-col items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="hidden md:block p-4">
          {rows?.map((rowsItems, rowIndex) => (
            <div
              key={rowIndex}
              className={`grid grid-cols-5 p-2 ${
                rowIndex < rows.length - 1
                  ? "border-b border-dashed border-[#4E362A]"
                  : ""
              }`}
            >
              {rowsItems?.map((ratingPro, index) => {
                const findProduct = products?.find(
                  (pro) => pro?._id === ratingPro?.pro_id
                );
                return (
                  <div
                    key={ratingPro?._id}
                    className={`text-center ${
                      index % 5 === 0
                        ? ""
                        : "border-l border-dashed border-[#4E362A]"
                    }`}
                  >
                    <div className="flex items-center justify-center">
                      <img
                        src={`https://www.giftshare.jp:5000/${findProduct?.image}`}
                        alt={ratingPro?.name}
                        className="w-20"
                      />
                    </div>
                    <div>
                      <p className="font-bold py-2">{findProduct?.name}</p>

                      <p className="mt-4">
                        Rating {ratingPro?.rating} out of 5
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      )}

      {/* For mobile device */}
      {productRatings?.length === 0 ? (
        <p>There are no Rating Product</p>
      ) : isLoading ? (
        <div className="w-fll h-screen flex flex-col items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="px-4 my-4 block md:hidden">
          {productRatings?.map((product) => {
            const findProduct = products?.find(
              (pro) => pro?._id === product?.pro_id
            );
            return (
              <div
                key={product?._id}
                className={`flex flex-col items-center py-6 my-4 border border-dashed border-[#4E362A]`}
              >
                <div className="flex borde rounded-lg border-orange-950 p-2 items-center justify-center cursor-pointer">
                  <img
                    src={`https://www.giftshare.jp:5000/${findProduct?.image}`}
                    alt={findProduct?.name}
                    className="w-24"
                  />
                </div>
                <div className="flex flex-col items-center mt-4">
                  <p className="font-bold py-2 text-3xl">{findProduct?.name}</p>

                  <p className="mt-4">Rating {product?.rating} out of 5</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TestingProductRating;
