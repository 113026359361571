import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetVipnumbersQuery,
  useRemoveVipnumberMutation,
} from "../../../../features/api/vipnumberApi";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";

const VipNumbers = () => {
  const componentRef = useRef();
  const { data, isLoading } = useGetVipnumbersQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const vipNumbers = data?.data;
  const [remvoeVipNumber] = useRemoveVipnumberMutation();

  const handleRemove = async (id) => {
    const confirm = window.confirm("Are you sure?");
    if (confirm) {
      try {
        const response = await remvoeVipNumber(id);
        if (response) {
          toast.success("Successfully delete from database!");
        }
      } catch (error) {
        toast.error("Error saving numbers to database.", error?.response);
      }
    }
  };
  return (
    <div className="p-8">
      <div className="flex justify-between items-center">
        <h1 className="mb-4 text-xl">Manage VIP Numbers</h1>
        <div className="flex items-center gap-x-2">
          <Link
            to="/dashboard/generateVipNumber"
            className="bg-blue-500 font-bold px-4 py-1 rounded text-white"
          >
            Create
          </Link>
          {/* <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button ml-12  bg-cyan-900 px-1 text-white font-bold"
            table="table-to-xls"
            filename="tablexls"
            sheet="tablexls"
            buttonText="Excel Sheet"
          /> */}
          {/* id="table-to-xls" */}
        </div>
      </div>

      <table className="table w-full mt-2">
        {/* head */}
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Number</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {vipNumbers?.map((number, index) => (
            <tr>
              <th>{index + 1}</th>
              <td>{number?.numbers?.match(/.{1,4}/g)?.join(" ")}</td>
              <td
                onClick={() => handleRemove(number?._id)}
                className="text-red-500 font-bold cursor-pointer"
              >
                x
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VipNumbers;
