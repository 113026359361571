import React from "react";
import DashBoardMenu from "../../../../Components/DashBoardMenu/DashBoardMenu";
import { AiTwotoneDelete } from "react-icons/ai";
import CreatePackage from "./CreatePackage";
import UpdatePackage from "./UpdatePackage";
import {
  useGetSubspackageQuery,
  useRemoveSubspackageMutation,
} from "../../../../features/api/subspackageApi";
const SubscriptionUser = () => {
  const { data: pcg } = useGetSubspackageQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const packages = pcg?.data;
  const [removeSubspackage] = useRemoveSubspackageMutation();

  const handleDelete = async (id) => {
    const confirm = window.confirm("Are Your Sure?");
    if (confirm) {
      try {
        const response = await removeSubspackage(id);

        if (response) {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <DashBoardMenu></DashBoardMenu>
      <div className="p-8">
        <div className="overflow-x-auto">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold mt-2">Manage All Package</h2>
            <CreatePackage />
          </div>
          <table className="table w-full mt-2">
            {/* head */}
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Package</th>
                <th>Package Rate</th>
                <th>Package Product</th>
                <th>Subscription Count</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {packages?.map((pcg, i) => (
                <tr key={pcg._id}>
                  <th>{i + 1}</th>
                  <td>{pcg?.name}</td>
                  <td>¥{pcg?.amount}</td>
                  <td>
                    <select>
                      {pcg?.brand?.map((br, i) => (
                        <option key={i}>{br}</option>
                      ))}
                    </select>
                  </td>
                  <td>{pcg?.count}</td>
                  <td className="flex items-center gap-2">
                    <UpdatePackage pcg={pcg} />
                    <button
                      onClick={() => handleDelete(pcg?._id)}
                      className="text-red-500 flex justify-center"
                      style={{ width: "40px", fontSize: "25px" }}
                    >
                      <AiTwotoneDelete></AiTwotoneDelete>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionUser;
