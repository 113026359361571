import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useGetUserQuery } from "../../../features/api/loginApi";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useGetAllsubscribeQuery } from "../../../features/api/subscribeApi";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";
import jaTranslator from "../../../jpTranslator/ja.json";
import enTranslator from "../../../enTranslator/en.json";
import { useGetFestivalsQuery } from "../../../features/api/festivalApi";
import { useGetAllBrandsQuery } from "../../../features/api/brandApi";

i18n.use(initReactI18next).init({
  resources: {
    ja: {
      translation: jaTranslator,
    },
    en: {
      translation: enTranslator,
    },
  },
  lng: "ja",
  fallbackLng: "ja",
  interpolation: {
    escapeValue: false,
  },
});

const TestingBoxModal = ({ boxs }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [alternativeAddress, setAlternativeAddress] = useState(null);
  const [planValue, setPlanValue] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  const brands = useGetAllBrandsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  const brandName = brands?.find((b) => b?._id === boxs?.brand);

  const user = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  // genereate order number by uuid
  function generateOrderNumber() {
    const uuid = uuidv4();
    const truncatedOrderNumber = uuid.substr(0, 8);
    return truncatedOrderNumber;
  }
  const orderNumber = generateOrderNumber();

  const [sub, setSub] = useState("");
  const [term, setTerm] = useState(false);
  const handleChecked = () => {
    setTerm(!term);
    if (sub === "Get Subscription") {
      setSub("");
    } else {
      setSub("Get Subscription");
    }
    handleClose();
  };

  const [quantity, setQuantity] = useState(1);
  const total = boxs?.price * quantity;

  const [shipping, setShippingMethod] = useState("Free");
  const [deliveryProcess, setDeliveryProcess] = useState("sdd");
  const [amazon, setAmazon] = useState(null);
  const [amazonTerm, setAmazonTerm] = useState(false);
  const handleAmazonChecked = (e) => {
    setAmazonTerm(!amazonTerm);
    if (amazon === "amazon") {
      setAmazon(null);
    } else {
      setAmazon("amazon");
    }
  };

  const [paymentMethod, setPaymentMethod] = useState("COD");
  const [pointsMethod, setPointsMethod] = useState("");
  const [inputPoints, setInputPoints] = useState(null);

  const [shippName, setFirstName] = useState("");
  const [shippEmail, setEmail] = useState("");
  const [shippRegion, setRegion] = useState("");
  const [shippDistrict, setDistrict] = useState("");
  const [shippZip, setZip] = useState("");
  const [shippPhone, setPhone] = useState("");
  const [shippAddress, setAddress] = useState("");

  const navigate = useNavigate();
  const handleOrder = async () => {
    // save order data
    const orderData = {
      shippName,
      shippEmail,
      shippRegion,
      shippDistrict,
      shippZip,
      shippPhone,
      shippAddress,
      email: user?.email,
      subtotal: total,
      grandTotal: total,
      paymentMethod: paymentMethod,
      shippFee: "Free",
      orderId: orderNumber,
    };
    const orderTestData = {
      orderId: orderNumber,
      testBoxId: boxs?._id,
      quantity,
      price: boxs?.price,
      subscribe: sub ? true : false,
    };
    // save subscription data
    const subData = {
      name: "Monthly",
      userName: user?.firstName + " " + user?.lastName,
      email: user?.email,
      testBoxId: boxs?._id,
      orderId: orderNumber,
      plan:
        planValue == 1
          ? "One Time"
          : planValue == 2
          ? "Two Times"
          : planValue == 3
          ? "Three Times"
          : null,
      pickupDate: selectedDate,
      alternativeAddress,
    };
    const confirm = window.confirm("Confirm?");
    if (confirm) {
      try {
        const response = await axios.post(
          "https://www.giftshare.jp:5000/api/v1/orders",
          orderData
        );
        const response2 = await axios.post(
          "https://www.giftshare.jp:5000/api/v1/orderTestItem",
          orderTestData
        );
        sub &&
          (await axios.post(`https://www.giftshare.jp:5000/api/v1/subscribe`, subData));
        inputPoints &&
          (await axios.post(`https://www.giftshare.jp:5000/api/v1/couponHistory`, {
            userId: user?._id,
            coin: +inputPoints,
            desc: `You used ${inputPoints} points for ${boxs?.name} Testing-Box`,
            type: "U",
          }));
        if (response && response2) {
          navigate("/user/dashboard/order-history");
          setIsOpen(false);
          document.body.style.overflow = "auto";
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data?.error);
      }
    }
  };

  const [visible, setVisible] = useState(false);
  const [defaults, setDefaults] = useState(90);

  return (
    <div>
      <div onClick={openModal} className="relative cursor-pointer">
        <img
          src={`https://www.giftshare.jp:5000/${boxs?.image}`}
          className="lg:h-[300px] lg:w-[300px] h-[300px] w-[350px]"
        />
        <p className="absolute left-1 top-1 text-orange-800 font-bold bg-white rounded-full bg-opacity-60 text-center px-2 py-1">
          {brandName?.name}
        </p>
      </div>

      {isOpen && (
        <div className="absolute h-full top-10 overflow-auto left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-md h-[90vh] overflow-auto">
            <div className="relative overflow-auto">
              <button
                className="bg-slate-200 w-8 h-8 rounded-full bg-opacity-80 absolute text-red-500 font-bold right-0 top-0 "
                onClick={closeModal}
              >
                X
              </button>
              <div>
                <img
                  src={`https://www.giftshare.jp:5000/${boxs?.image}`}
                  className="w-[370px] h-[350px] lg:w-[600px] lg:h-[600px] object-cover"
                />
              </div>
              <div className=" py-6">
                <div className=" ">
                  <div className="text-black flex flex-col gap-y-1">
                    <p className="lg:text-2xl font-bold text-[#9A583B]">
                      {boxs?.name}
                    </p>
                    <h1 className=" text-gray-600">Brand: {brandName?.name}</h1>
                    <p className="font-bold">Price: ¥{boxs?.price}</p>
                    <p className="lg:w-[600px]">
                      {boxs?.desc?.slice(0, defaults)}
                      <span
                        onClick={
                          visible
                            ? () => {
                                setDefaults(90);
                                setVisible(!visible);
                              }
                            : () => {
                                setDefaults(boxs?.desc?.length);
                                setVisible(!visible);
                              }
                        }
                        className="text-sm cursor-pointer text-[#4E362A] font-bold"
                      >
                        {visible ? "...See Less" : "...See More"}
                      </span>
                    </p>

                    <div className="w-[150px] mt-4 flex justify-between">
                      <label>Quantity:</label>
                      <input
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="border w-16 focus:outline-none pl-1"
                      />
                    </div>
                    <p className="w-[150px] flex justify-between">
                      <span>Total:</span> <span>¥{total}</span>
                    </p>
                  </div>
                  <div className="mt-4 text-black">
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        <span className="font-bold">Shipping method</span>
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Free"
                        name="radio-buttons-group"
                        onChange={(e) => setShippingMethod(e.target.value)}
                      >
                        <FormControlLabel
                          value="Free"
                          control={<Radio />}
                          label="Free delivery - Free shipping"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="mt-4 text-black">
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        <span className="font-bold">Delivery Process</span>
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="sdd"
                        name="radio-buttons-group"
                        onChange={(e) => setDeliveryProcess(e.target.value)}
                      >
                        <FormControlLabel
                          value="sdd"
                          control={<Radio />}
                          label="Same Day Delivery"
                        />
                        <FormControlLabel
                          value="ndd"
                          control={<Radio />}
                          label="Next Day Delivery"
                        />

                        <FormControlLabel
                          value="3dd"
                          control={<Radio />}
                          label="3 Days Delivery"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="mt-4 text-black ">
                    <FormControl className="mx-auto w-full">
                      <FormLabel id="demo-radio-buttons-group-label">
                        <span className="font-bold">Payment method</span>
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="COD"
                        name="radio-buttons-group"
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <FormControlLabel
                          value="COD"
                          control={<Radio />}
                          label="Cash on delivery (COD)"
                        />
                        <FormControlLabel
                          value="amazon"
                          control={<Radio />}
                          label="Payment via amazon"
                        />
                        {paymentMethod === "amazon" && (
                          <div className=" bg-gray-100 border border-gray-200 rounded p-4">
                            <p>Payment by amzaon pay</p>
                          </div>
                        )}
                        <FormControlLabel
                          value="points"
                          control={<Radio />}
                          label="Pay by points"
                        />
                      </RadioGroup>
                    </FormControl>
                    {paymentMethod === "points" && (
                      <div className="w-[270px] border p-2 mx-auto lg:ml-0">
                        <div className="bg-gray-200 p-2">
                          <p className="font-bold text-sm">
                            Current Points Amount
                          </p>
                          <p className="flex items-center gap-x-2 font-bold">
                            <span className="bg-red-600 w-5 h-5 text-white text-sm flex items-center justify-center rounded-full">
                              P
                            </span>{" "}
                            {user?.earnedCoin - user?.usedCoin}{" "}
                            <sub className="text-xs font-normal">Points</sub>
                          </p>
                        </div>
                        <div className="w-full">
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="allpoints"
                              name="radio-buttons-group"
                              onChange={(e) => setPointsMethod(e.target.value)}
                            >
                              <FormControlLabel
                                value="allpoints"
                                control={<Radio />}
                                label="Use all of the amount"
                              />
                              <FormControlLabel
                                value="somepoints"
                                control={<Radio />}
                                label="Use some of the amount"
                              />
                              {pointsMethod === "somepoints" && (
                                <input
                                  type="number"
                                  disabled={
                                    total > user?.earnedCoin - user?.usedCoin
                                  }
                                  onChange={(e) =>
                                    setInputPoints(e.target.value)
                                  }
                                  className={`border focus:outline-none mt-2 p-1 w-[250px] ${
                                    inputPoints != boxs?.price
                                      ? "border-red-400"
                                      : "border-gray-400"
                                  }`}
                                />
                              )}
                              {inputPoints && inputPoints != total && (
                                <p className="text-red-500 text-center text-xs font-bold">
                                  not matched
                                </p>
                              )}
                              {total > user?.earnedCoin - user?.usedCoin && (
                                <p className="text-red-500 text-center text-xs font-bold">
                                  Not enough points
                                </p>
                              )}
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    )}
                  </div>

                  {paymentMethod !== "amazon" && (
                    <div className="mt-4 text-black">
                      <form className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
                        <input
                          type="text"
                          value={shippName}
                          onChange={(e) => setFirstName(e.target.value)}
                          placeholder="Full Name *"
                          className={`border-b  focus:outline-none ${
                            shippName === "" ? "border-red-500" : ""
                          }`}
                        />
                        <input
                          type="number"
                          value={shippPhone}
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="Phone Number *"
                          className={`border-b focus:outline-none ${
                            shippPhone === "" ? "border-red-500" : ""
                          }`}
                        />
                        <input
                          type="text"
                          value={shippEmail}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                          className="border-b focus:outline-none"
                        />
                        <input
                          type="text"
                          value={shippRegion}
                          onChange={(e) => setRegion(e.target.value)}
                          placeholder="Region"
                          className="border-b focus:outline-none"
                        />
                        <input
                          type="text"
                          value={shippDistrict}
                          onChange={(e) => setDistrict(e.target.value)}
                          placeholder="District"
                          className="border-b focus:outline-none"
                        />
                        <input
                          type="text"
                          value={shippZip}
                          onChange={(e) => setZip(e.target.value)}
                          placeholder="Zip / Postal Code"
                          className="border-b focus:outline-none"
                        />
                      </form>
                      <input
                        type="text"
                        value={shippAddress}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Address *"
                        className={`border-b focus:outline-none w-full mt-2 ${
                          shippAddress === "" ? "border-red-500" : ""
                        }`}
                      />
                    </div>
                  )}
                </div>

                {/* <div className="divider text-black">Subscription Policy</div>
                <div
                  className="text-black flex flex-col gap-y-2 font-extralight italic text-justify text-xs lg:w-[600px] p-2"
                  style={{ border: "1px dashed blue" }}
                >
                  <p className="">{t("Subscription_para1")}</p>
                  <p className="">{t("Subscription_para2")}</p>
                  <p>{t("Subscription_para3")}</p>
                  <p>{t("Subscription_para4")}</p>
                  <p>{t("Subscription_para5")}</p>
                  <p className="font-bold">{t("Subscription_para6")}</p>
                </div> */}
                {/* <div className="flex">
                  <label className="label cursor-pointer ">
                    <input
                      onClick={handleChecked}
                      type="radio"
                      name="radio-10"
                      className="radio checked:bg-blue-500 w-4 h-4"
                      checked={term}
                    />
                    <span className="label-text text-blue-600 ml-2">
                      Get Subscription
                    </span>
                  </label>
                </div> */}

                <div className="mt-4">
                  <Button onClick={handleOpen} sx={{ margin: 0, padding: 0 }}>
                    <span
                      className={`w-4 h-4 border rounded-full mr-2 ${
                        term ? "bg-blue-500" : ""
                      }`}
                    ></span>
                    <span className="text-blue-500 font-bold">
                      Get Subscription
                    </span>
                  </Button>{" "}
                  {term && (
                    <span
                      onClick={() => setTerm(false)}
                      className=" cursor-pointer text-red-500 font-bold text-xs"
                    >
                      ( Cancel )
                    </span>
                  )}
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "white",
                        boxShadow: 24,
                        padding: 2,
                        width: "100%",
                        "@media (min-width: 600px)": {
                          width: "600px",
                        },
                      }}
                    >
                      <button
                        onClick={() => {
                          handleClose();
                          setPlanValue(null);
                        }}
                        className="text-red-500 font-bold float-right "
                      >
                        X
                      </button>
                      <div className="divider text-black py-4">
                        Subscription Policy
                      </div>
                      <div
                        className="text-black flex flex-col gap-y-2 font-extralight italic text-justify text-xs p-2"
                        style={{ border: "1px dashed chocolate" }}
                      >
                        <p className="">{t("Subscription_para1")}</p>
                        <p className="">{t("Subscription_para2")}</p>
                        <p>{t("Subscription_para3")}</p>
                        <p>{t("Subscription_para4")}</p>
                        <p>{t("Subscription_para5")}</p>
                        <p className="font-bold">{t("Subscription_para6")}</p>
                      </div>
                      <div className="">
                        <p className="py-2">Choose Plan:</p>
                        <div className="flex flex-col items-start">
                          <Button
                            onClick={() => setPlanValue(1)}
                            sx={{ margin: 0, padding: 0 }}
                          >
                            <span
                              className={`w-4 h-4 ${
                                planValue === 1
                                  ? "bg-blue-500"
                                  : "border border-gray-400"
                              } rounded-full mr-2`}
                            ></span>
                            <span className="text-black font-bold capitalize">
                              One times in a Month
                            </span>
                          </Button>
                          <Button
                            onClick={() => setPlanValue(2)}
                            sx={{ margin: 0, padding: 0 }}
                          >
                            <span
                              className={`w-4 h-4 ${
                                planValue === 2
                                  ? "bg-blue-500"
                                  : "border border-gray-400"
                              } rounded-full mr-2`}
                            ></span>
                            <span className="text-black font-bold capitalize">
                              Two times in a Month
                            </span>
                          </Button>
                          <Button
                            onClick={() => setPlanValue(3)}
                            sx={{ margin: 0, padding: 0 }}
                          >
                            <span
                              className={`w-4 h-4 ${
                                planValue === 3
                                  ? "bg-blue-500"
                                  : "border border-gray-400"
                              } rounded-full mr-2`}
                            ></span>
                            <span className="text-black font-bold capitalize">
                              Three times in a Month
                            </span>
                          </Button>
                        </div>
                      </div>

                      {planValue && (
                        <div className="flex flex-col md:flex-row items-start md:items-center gap-x-2 mt-2">
                          <h1>Select Delivery Date:</h1>
                          <DatePicker
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            dateFormat="MMMM d, yyyy"
                            className="w-full p-2 border rounded"
                          />
                        </div>
                      )}

                      {planValue && (
                        <>
                          <div>
                            <p>Alternative Address: </p>
                            <textarea
                              onChange={(e) =>
                                setAlternativeAddress(e.target.value)
                              }
                              className="border focus:outline-none w-full"
                            />
                          </div>
                          <button
                            onClick={handleChecked}
                            className="bg-orange-950 text-white px-4 py-1 rounded-sm mt-2"
                          >
                            Agree
                          </button>
                        </>
                      )}
                    </Box>
                  </Modal>
                </div>

                <button
                  onClick={handleOrder}
                  className="lg:px-2 py-1 w-full bg-[#9A583B] text-white rounded mt-6 uppercase"
                >
                  Place order
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestingBoxModal;
