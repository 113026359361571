import React, { useEffect, useState } from "react";
import CheckoutDesktop from "./CheckoutDesktop";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CheckoutMobile from "./CheckoutMobile";
import { useGetUserQuery } from "../../features/api/loginApi";

const Checkout = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const getData = JSON.parse(localStorage.getItem("checkout"));
  const cart = getData?.cart;
  const blank = getData?.blank;
  const discount = getData?.discount;

  const { data: me } = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const user = me?.data;

  const [shipping, setShippingMethod] = useState("Free");
  const [paymentMethod, setPaymentMethod] = useState("COD");
  const [shippName, setName] = useState("");
  const [shippEmail, setEmail] = useState("");
  const [shippZip, setZip] = useState("");
  const [shippPhone, setPhone] = useState("");
  const [shippAddress, setAddress] = useState("");
  const [shippDistrict, setDistrict] = useState("");
  const [shippRegion, setRegion] = useState("");

  const [pointsMethod, setPointsMethod] = useState("");
  const [inputPoints, setInputPoints] = useState("");
  // console.log(pointsMethod);

  const passShippDetails = {
    shippName,
    shippEmail,
    shippRegion,
    shippDistrict,
    shippZip,
    shippPhone,
    shippAddress,
    paymentMethod: paymentMethod,
    shippFee: shipping,
    pointsMethod,
    inputPoints,
  };

  return (
    <div className="p-8 min-h-screen">
      <div className="flex flex-col lg:flex-row gap-y-6 lg:gap-y-0 justify-between h-full">
        {/* shipping information for desktop*/}
        <div className="flex-1 hidden lg:block lg:w-[60%] lg:px-8">
          {/* Shipping Method For Desktop Device*/}
          <div className="mt-6">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                <span className="font-bold">Shipping method</span>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Free"
                name="radio-buttons-group"
                onChange={(e) => setShippingMethod(e.target.value)}
              >
                <FormControlLabel
                  value="Free"
                  control={<Radio />}
                  label="Free delivery - Free shipping"
                />
              </RadioGroup>
            </FormControl>
          </div>

          {/* Payment Method For Desktop Device*/}
          <div className="mt-6">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                <span className="font-bold">Payment method</span>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="COD"
                name="radio-buttons-group"
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <FormControlLabel
                  value="COD"
                  control={<Radio />}
                  label="Cash on delivery (COD)"
                />
                <FormControlLabel
                  value="amazon"
                  control={<Radio />}
                  label="Payment via amazon"
                />
                {paymentMethod === "amazon" && (
                  <div className="border border-x-gray-300 bg-gray-100 p-4">
                    Amazon payment method
                  </div>
                )}
                <FormControlLabel
                  value="points"
                  control={<Radio />}
                  label="Pay by points"
                />
              </RadioGroup>
            </FormControl>
            {paymentMethod === "points" && (
              <div className="w-[270px] border p-2">
                <div className="bg-gray-200 p-2">
                  <p className="font-bold text-sm">Current Points Amount</p>
                  <p className="flex items-center gap-x-2 font-bold">
                    <span className="bg-red-600 w-5 h-5 text-white text-sm flex items-center justify-center rounded-full">
                      P
                    </span>{" "}
                    {user?.earnedCoin - user?.usedCoin}{" "}
                    <sub className="text-xs font-normal">Points</sub>
                  </p>
                </div>
                <div className="w-full">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      // defaultValue="COD"
                      name="radio-buttons-group"
                      onChange={(e) => setPointsMethod(e.target.value)}
                    >
                      <FormControlLabel
                        value="allpoints"
                        control={<Radio />}
                        label="Use all of the amount"
                      />
                      <FormControlLabel
                        value="somepoints"
                        control={<Radio />}
                        label="Use some of the amount"
                      />
                      {pointsMethod === "somepoints" && (
                        <input
                          type="number"
                          onChange={(e) => setInputPoints(e.target.value)}
                          className="border focus:outline-none mt-2 mb-4 p-1 w-[250px] border-gray-400"
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                  {/* <button className="bg-orange-900 text-white w-full rounded-full uppercase text-sm py-1 font-bold">
                    Submit
                  </button> */}
                </div>
              </div>
            )}
          </div>

          {/* Shipping Address For Desktop Device*/}
          {paymentMethod !== "amazon" && (
            <div>
              <p className="font-sans font-bold my-4">Shipping information</p>
              <form className="space-y-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div>
                    <input
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      className="border w-full placeholder:text-sm p-1 rounded focus:outline-none"
                      placeholder="Fullname"
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="border w-full placeholder:text-sm p-1 rounded focus:outline-none"
                      placeholder="Email"
                    />
                  </div>

                  <div>
                    <input
                      type="text"
                      onChange={(e) => setRegion(e.target.value)}
                      className="border w-full placeholder:text-sm p-1 rounded focus:outline-none"
                      placeholder="Region"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      onChange={(e) => setDistrict(e.target.value)}
                      className="border w-full placeholder:text-sm p-1 rounded focus:outline-none"
                      placeholder="District"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      onChange={(e) => setZip(e.target.value)}
                      className="border w-full placeholder:text-sm p-1 rounded focus:outline-none"
                      placeholder="Zip"
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      onChange={(e) => setPhone(e.target.value)}
                      className="border w-full placeholder:text-sm p-1 rounded focus:outline-none"
                      placeholder="Phone"
                    />
                  </div>
                </div>
                <div>
                  <input
                    type="text"
                    onChange={(e) => setAddress(e.target.value)}
                    className="border w-full placeholder:text-sm p-1 rounded focus:outline-none"
                    placeholder="Address"
                  />
                </div>
              </form>
            </div>
          )}
        </div>

        {/* checkout for desktop */}
        <div className="hidden lg:block lg:w-[40%] lg:border-l">
          <CheckoutDesktop
            cart={cart}
            blank={blank}
            discount={discount}
            passShippDetails={passShippDetails}
          />
        </div>

        {/* checkout for mobile */}
        <div className="block lg:hidden">
          <CheckoutMobile cart={cart} blank={blank} discount={discount} />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
