import { apiSlice } from "./apiSlice";

const regiondefaultApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRegionDefaults: builder.query({
      query: () => ({
        url: "/region-default",
      }),
      providesTags: ["region-default"],
    }),
    addRegionDefault: builder.mutation({
      query: (newData) => ({
        url: "region-default",
        method: "POST",
        body: newData,
      }),
      invalidatesTags: ["region-default"],
    }),
    editRegionDefault: builder.mutation({
      query: ({ id, ...editData }) => ({
        url: `/region-default/${id}`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["region-default"],
    }),
  }),
});

export const {
  useGetRegionDefaultsQuery,
  useAddRegionDefaultMutation,
  useEditRegionDefaultMutation,
} = regiondefaultApi;
