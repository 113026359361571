import React, { useEffect } from "react";

const TramsCondition = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="min-h-[calc(100vh-190px)] p-8 text-justify">
      <h1 className="font-bold">Terms and Conditions</h1>
      Using our website means you agree to follow the rules: no misuse,
      respecting intellectual property, and understanding our policies. We can
      terminate access, and please review our terms periodically. Remember, it's
      important to ensure that your policies comply with relevant laws and
      regulations in your jurisdiction. Consider consulting legal counsel for
      guidance specific to your website and its users.
    </div>
  );
};

export default TramsCondition;
