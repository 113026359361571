import React, { useEffect, useMemo, useState } from "react";
import { useGetAllGiftBoxQuery } from "../../features/api/GiftBoxApi";
import { useLocation, useParams } from "react-router";
import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";
import jaTranslator from "../../jpTranslator/ja.json";
import enTranslator from "../../enTranslator/en.json";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllBlankBoxQuery } from "../../features/api/blankBoxApi";
import {
  useAddWishlistMutation,
  useGetWishlistsQuery,
  useRemoveWishlistMutation,
} from "../../features/api/wishlistApi";
import { toast } from "react-toastify";
import { addToCart } from "../../features/cart/cartSlice";
import { addToBlank } from "../../features/cart/blankSlice";
import Rating from "@mui/material/Rating";
import FavoriteIcon from "@mui/icons-material/Favorite";
import "./GiftboxSinglePage.css";
import { BsFillBox2HeartFill, BsFillCartCheckFill } from "react-icons/bs";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import {
  EffectCoverflow,
  FreeMode,
  EffectCards,
  Navigation,
  Pagination,
  Keyboard,
} from "swiper";
import { useGetAllTrendGiftQuery } from "../../features/api/trendingGift";
import DescriptionMobile from "../../Components/DescriptionMobile";
import Description from "../../Components/Description";
import { useGetAllRatingQuery } from "../../features/api/ratingsApi";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { useGetFestivalsQuery } from "../../features/api/festivalApi";

i18n.use(initReactI18next).init({
  resources: {
    ja: {
      translation: jaTranslator,
    },
    en: {
      translation: enTranslator,
    },
  },
  lng: "ja",
  fallbackLng: "ja",
  interpolation: {
    escapeValue: false,
  },
});

const GiftboxSinglePage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { boxId, box } = useParams();
  const location = useLocation();
  const totalRating = location?.state;

  const festivals = useGetFestivalsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const allGiftBox = useGetAllGiftBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const findByParams = allGiftBox?.find((boxs) => boxs?._id === boxId);

  const festivalsName = festivals?.find(
    (fes) => fes?._id === findByParams?.festival
  );

  const trendGift = useGetAllTrendGiftQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  const findTrendGift = trendGift?.find(
    (trend) => trend?.brand === findByParams?.brand
  );

  const filterTrendByDistrict = trendGift?.filter(
    (trend) =>
      trend?.district === findTrendGift?.district &&
      trend?.brand !== findByParams?.brand
  );

  const similarGiftBox = useMemo(() => {
    return allGiftBox?.filter((box) => {
      return filterTrendByDistrict?.some(
        (trend) => trend?.brand === box?.brand
      );
    });
  }, [allGiftBox, filterTrendByDistrict]);

  const allBlankbox = useGetAllBlankBoxQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  console.log(allBlankbox)
  const [selectFestival, setSelectFestival] = useState(findByParams?.festival);
  console.log(selectFestival)
  const [filterBlankBox, setFilterBlankBox] = useState([]);
  console.log(filterBlankBox)
  const [filterAllBlankBoxByFestivalId, setFilterAllBlankBoxByFestivalId] =
    useState([]);
    console.log(filterAllBlankBoxByFestivalId)
  useEffect(() => {
    if (allBlankbox?.length > 0 && !selectFestival) {
      const filtered = allBlankbox?.filter(
        (bux) => bux?.festival?._id === findByParams?.festival
      );
      setFilterBlankBox(filtered);
    }
    if (allBlankbox?.length > 0 && selectFestival) {
      const filtered = allBlankbox?.filter(
        (item) => item?.festival?._id === selectFestival
      );
      setFilterAllBlankBoxByFestivalId(filtered);
    }
  }, [allBlankbox, findByParams, selectFestival]);

  const uniqueFestivals = new Set();
  const uniqueData = allBlankbox?.filter((item) => {
    if (uniqueFestivals.has(item.festival?._id)) {
      return false;
    }
    uniqueFestivals.add(item.festival?._id);
    return true;
  });

  const filterFestival = festivals?.filter((fes) => {
    return uniqueData?.some((d) => d?.festival?._id === fes?._id);
  });

  const [selectOpenClose, setSelectOpenClose] = useState(null);
  const [active, setActive] = useState(0);
  const handleOpenClose = (image, index) => {
    setActive(index);
    setSelectOpenClose(image);
  };

  const allWishlist = useGetWishlistsQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;
  const findWishlist = allWishlist?.find(
    (list) => list?.productId === findByParams?._id
  );

  const cart = useSelector((state) => state?.cart?.cart);
  const exist = cart?.find((c) => c?.name === findByParams?.name);
  const [copy, setCopy] = useState({});
  const chooseGiftBox = (box) => {
    setCopy(box);
  };

  const handleAddToCart = () => {
    dispatch(addToCart(findByParams));
    dispatch(addToBlank(copy));
  };

  const [addNewWishlist] = useAddWishlistMutation();
  const [removeWishlist] = useRemoveWishlistMutation();

  const handleWishlist = async (id) => {
    try {
      const saveData = { productId: id };

      await addNewWishlist(saveData);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await removeWishlist(id);
    } catch (error) {
      toast.error(error);
    }
  };

  const allRating = useGetAllRatingQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.data;

  const [visibleBoxMobile, setVisibleBoxMobile] = useState(6);

  const handleShowMoreBoxMobile = () => {
    const nextVisibleItems = visibleBoxMobile + 6;
    if (nextVisibleItems <= similarGiftBox?.length) {
      setVisibleBoxMobile(nextVisibleItems);
    } else {
      setVisibleBoxMobile(similarGiftBox?.length);
    }
  };

  // toggle button for desktop
  const [activeDiv, setActiveDiv] = useState(1);
  const toggleDiv = (divIndex) => {
    setActiveDiv(divIndex);
  };

  return (
    <div className="w-full max-w-[1280px] mx-auto">
      <div className="lg:h-[90vh] flex flex-col md:flex-row gap-16 lg:p-6 border-t lg:border-t-0">
        <div className="relative flex flex-col lg:flex-row flex-1 justify-center items-center bg-white shadow ">
          <div className="flex flex-row flex-wrap justify-center order-2 lg:order-1 lg:flex-col items-center gap-4 bg-white shadow-sm px-4 py-2">
            {findByParams?.images?.map((imgs, index) => (
              <div key={index}>
                <button onClick={() => handleOpenClose(imgs, index)}>
                  <img
                    src={`https://www.giftshare.jp:5000/${imgs}`}
                    alt="Giftbox "
                    className={`w-20 h-20 ${
                      active === index ? "border border-[#4E362A]" : "border-0"
                    }`}
                  />
                </button>
              </div>
            ))}
          </div>
          <div className="order-1 lg:order-2">
            {selectOpenClose ? (
              <img
                src={`https://www.giftshare.jp:5000/${selectOpenClose}`}
                alt="Giftbox"
                className="w-[370px] h-[350px] lg:w-[450px] lg:h-[450px] object-cover"
              />
            ) : (
              <img
                src={`https://www.giftshare.jp:5000/${findByParams?.images?.[0]}`}
                alt="Giftbox"
                className="w-[370px] h-[350px] lg:w-[450px] lg:h-[450px] object-cover"
              />
            )}
            {findWishlist ? (
              <button
                onClick={() => handleDelete(findWishlist?._id)}
                className="text-xl text-red-500 absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer"
                style={{ borderRadius: "0 10px 0 20px" }}
              >
                <FavoriteIcon />
              </button>
            ) : (
              <button
                onClick={() => handleWishlist(findByParams?._id)}
                className="text-xl text-white absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer"
                style={{ borderRadius: "0 10px 0 20px" }}
              >
                <FavoriteIcon />
              </button>
            )}
          </div>
        </div>

        <div className=" overflow-auto flex-1 flex flex-col gap-y-2 bg-white px-6 lg:px-0">
          <p className=" text-[#433832]">
            <span className="md:text-xl uppercase font-bold">
              {findByParams?.name}
            </span>{" "}
            <span>( {festivalsName?.name} )</span>
          </p>
          {/* <div className="flex items-center gap-x-2 ">
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              size="small"
            />
            <p className="text-gray-500">{totalRating} ratings</p>
          </div> */}
          <hr />

          <p className="font-bold text-xl text-[#6b5549]">
            JPY {findByParams?.price}
          </p>
          <div>
            <p className="flex items-center gap-x-1 mb-2">
              <span className="text-slate-600">
                <p>{t("blankBox")}</p>
              </span>{" "}
              <span className="text-[#6b5549]">
                <BsFillBox2HeartFill />
              </span>
            </p>

            <div className="bg-[#FAF5FF] pt- px-2">
              <div className="p-4">
                <label>Select Festival: </label>
                <select onChange={(e) => setSelectFestival(e.target.value)}>
                  <option value={findByParams?.festival}>--select--</option>
                  {filterFestival?.map((fes) => {
                    return (
                      <option key={fes?._id} value={fes?._id}>
                        {fes?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <Swiper
                slidesPerView={window.innerWidth < 512 ? 2 : 4}
                spaceBetween={30}
                keyboard={true}
                loop={true}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode, Pagination, Keyboard]}
                className=""
              >
                {filterAllBlankBoxByFestivalId.length > 0
                  ? filterAllBlankBoxByFestivalId?.map((item) => {
                      return (
                        <SwiperSlide
                          key={item?._id}
                          className={`pb- my-7 border ${
                            copy?._id === item?._id
                              ? "border-2 shadow-lg border-green-500"
                              : ""
                          }`}
                          onClick={() => chooseGiftBox(item)}
                        >
                          <img
                            src={`https://www.giftshare.jp:5000/${item?.image}`}
                            alt={item?.name}
                            className="w-[100%] h-[114px]"
                          />
                          <p className="font-bold text-center py-2">
                            {item?.name}
                          </p>
                        </SwiperSlide>
                      );
                    })
                  : filterBlankBox?.map((item) => {
                      return (
                        <SwiperSlide
                          key={item?._id}
                          className="pb- my-7 border"
                          onClick={() => chooseGiftBox(item)}
                        >
                          <img
                            src={`https://www.giftshare.jp:5000/${item?.image}`}
                            alt={item?.name}
                            className="w-[100%] h-[114px]"
                          />
                          <p className="font-bold text-sm text-center py-2">
                            {item?.name}
                          </p>
                          {copy?._id === item?._id && (
                            <div className="absolute inset-0 flex items-center justify-center">
                              <h2 className="text-white bg-black bg-opacity-50 p-1">
                                Selected
                              </h2>
                            </div>
                          )}
                        </SwiperSlide>
                      );
                    })}
              </Swiper>
            </div>
          </div>
          <div className="">
            <div className="flex justify-center border-b space-x-12 pt-6 mb-4">
              <button
                className={`addGoogleFond font-mono ${
                  activeDiv === 1
                    ? "border-b pb-2 border-[#4E362A]"
                    : "hover:border-b pb-2 border-[#4E362A] transition-all duration-200 ease-in-out"
                }`}
                onClick={() => toggleDiv(1)}
              >
                Giftbox Info & Description
              </button>
            </div>
            {activeDiv === 1 && (
              <div>
                <p className="font-light text-sm text-justify lg:px-4">
                  {findByParams?.desc}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="">
        {/* Similar Gift Box for desktop*/}
        <div className="mt-12 hidden lg:block px-6">
          <Description>Similar Gift Box</Description>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-2 md:gap-10 mt-8">
            {similarGiftBox?.map((box) => {
              const getRating = allRating?.filter(
                (rat) => rat?.pro_id === box?.name
              );
              const sumOfRating = getRating?.reduce(
                (sum, rat) => sum + +rat?.rating,
                0
              );
              const totalRating = getRating?.length;
              const passState = { box, totalRating };

              const findWishlist = allWishlist?.find(
                (list) => list?.productId === box?._id
              );
              return (
                <div className="relative rounded-2xl border " key={box?._id}>
                  <Link
                    to={`/giftbox-items/box/${box?._id}/${box?.name}`}
                    state={totalRating}
                  >
                    <div className="">
                      <img
                        src={`https://www.giftshare.jp:5000/${box?.images[0]}`}
                        className={`w-full lg:h-[272px] rounded-t-2xl`}
                        alt={box?.name}
                      />

                      <div className="p-4 flex flex-col gap-y-2">
                        <h6 className="card-title text-sm text-gray-400">
                          {box?.name}
                        </h6>
                        <h4 className="text-[#433832] font-serif text-lg">
                          JPY {box?.price}
                        </h4>
                        {getRating && (
                          <p className="flex items-center">
                            {sumOfRating}{" "}
                            <span className="text-yellow-500 text-xs">
                              <AiFillStar />
                            </span>
                            <span className="ml-1">({getRating?.length})</span>
                          </p>
                        )}
                      </div>
                    </div>
                  </Link>
                  <button
                    onClick={
                      findWishlist
                        ? () => handleDelete(findWishlist?._id)
                        : () => handleWishlist(box?._id)
                    }
                    className={`text-xl absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer
                      ${findWishlist ? " text-red-500 " : " text-white "}`}
                    style={{ borderRadius: "0 10px 0 20px" }}
                  >
                    <FavoriteIcon />
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        {/* similar gift box for mobile */}
        <div className="mt-8 block lg:hidden px-1">
          <DescriptionMobile>Similar Gift Box</DescriptionMobile>
          <div className="my-6 grid grid-cols-2 gap-1">
            {similarGiftBox?.slice(0, visibleBoxMobile)?.map((box, index) => {
              const getRating = allRating?.filter(
                (rat) => rat?.pro_id === box?.name
              );
              const sumOfRating = getRating?.reduce(
                (sum, rat) => sum + +rat?.rating,
                0
              );
              const totalRating = getRating?.length;
              const passState = { box, totalRating };

              const findWishlist = allWishlist?.find(
                (list) => list?.productId === box?._id
              );
              return (
                <div key={box?._id} className="">
                  <div className="relative rounded-2xl border ">
                    <Link
                      to={`/giftbox-items/box/${box?._id}/${box?.name}`}
                      state={totalRating}
                    >
                      <div className="">
                        <img
                          src={`https://www.giftshare.jp:5000/${box?.images[0]}`}
                          className={`w-full h-[150px] rounded-t-2xl`}
                          alt={box?.name}
                        />

                        <div className="p-4 flex flex-col gap-y-2">
                          <h6 className="card-title text-sm text-gray-400">
                            {box?.name}
                          </h6>
                          <h4 className="text-[#433832] font-serif text-lg">
                            JPY {box?.price}
                          </h4>
                          {getRating && (
                            <p className="flex items-center">
                              {sumOfRating}{" "}
                              <span className="text-yellow-500 text-xs">
                                <AiFillStar />
                              </span>
                              <span className="ml-1">
                                ({getRating?.length})
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                    </Link>
                    <button
                      onClick={
                        findWishlist
                          ? () => handleDelete(findWishlist?._id)
                          : () => handleWishlist(box?._id)
                      }
                      className={`text-xl absolute top-0 right-0 w-[40px] text-center flex items-center justify-center h-[40px] bg-gray-300 bg-opacity-60 cursor-pointer
                      ${findWishlist ? " text-red-500 " : " text-white "}`}
                      style={{ borderRadius: "0 10px 0 20px" }}
                    >
                      <FavoriteIcon />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          {visibleBoxMobile < similarGiftBox?.length && (
            <button
              onClick={handleShowMoreBoxMobile}
              className="bg-[#4E362A] text-white px-3 py-1 rounded block mx-auto mb-4"
            >
              Show More
            </button>
          )}
        </div>
      </div>

      {/* Add to Cart */}
      <div className="fixed z-50 bottom-2 lg:bottom-4 lg:right-8 right-0 rounded uppercase bg-[#4E362A] text-white w-[180px] py-2 text-center">
        {exist?.name === findByParams?.name ? (
          <button className="bg-[#4E362A] cursor-text text-white font-bold mx-auto gap-x-2 rounded flex items-center">
            Has been added
          </button>
        ) : (
          <button
            onClick={handleAddToCart}
            className="bg-[#4E362A] text-white font-bold mx-auto gap-x-2 rounded flex items-center"
          >
            <BsFillCartCheckFill size={20} /> Add To Cart
          </button>
        )}
      </div>
    </div>
  );
};

export default GiftboxSinglePage;
