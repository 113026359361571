import React, { useState } from "react";

const testItems = [
  {
    name: "one",
    image:
      "https://www.giftshare.jp:5000/uploads/1696747895326-joostarijs_180122_1168_2cfc4695be666e33202a8a84f871cf3b.jpg",
  },
  {
    name: "two",
    image: "https://www.giftshare.jp:5000/uploads/1696900567463-86028.png",
  },
  {
    name: "three",
    image:
      "https://www.giftshare.jp:5000/uploads/1696964792270-c1d839eb-0a51-4e6f-98af-925f7bb9f03c.png",
  },
  {
    name: "four",
    image:
      "https://www.giftshare.jp:5000/uploads/1696967133875-antwerpse-handjes-chocolates-no-filling-small-box-2.png",
  },
  {
    name: "five",
    image:
      "https://www.giftshare.jp:5000/uploads/1696975911409-gift-boxes-christmas-sparkling-white-box-milk-dark-white-chocolate-assortment.png",
  },
  {
    name: "six",
    image: "https://www.giftshare.jp:5000/uploads/1696979232908-foto-1-WS-1.png",
  },
  {
    name: "seven",
    image:
      "https://www.giftshare.jp:5000/uploads/1696982127861-5c0987_97068dabd29f4f3a868da5cc79a97dc8~mv2.png",
  },
  {
    name: "eight",
    image:
      "https://www.giftshare.jp:5000/uploads/1696985782784-182716392_3625158477611412_5087599669297965557_n.jpg",
  },
];

const Another = () => {
  return (
    <div className="min-h-screen p-8 bg-gray-400">
      <h1>Slider test</h1>
      <div className="py-6 grid grid-cols-4 gap-12">
        {testItems?.map((item) => {
          return (
            <div className=" p-2 bg-white">
              <div className="bg-white border border-black border-dotted p-2 ">
                <img src={item?.image} className="w-[300px] h-[400px]" />
                <p className="text-center">{item?.name}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Another;
